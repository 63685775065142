import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button } from "react-toolbox";
import { Mutation } from "react-apollo";
import PropTypes from "prop-types";

import updateOrganisationMutation from "src/components/update-organisation/update-organisation.graphql";

import RenderField from "../field";

import {
  parseOrganisationUpdateInput,
  formatFulfilmentAccounts,
  parse,
  format,
} from "./update-fulfilment-form";

const UpdateFulfilmentAccountForm = ({
  handleDialog,
  handleSubmit,
  organisation,
  selectedFulfilmentAccount,
}) => {
  const onUpdateAccount = async (updateOrganisation, formState) => {
    const fulfilmentAccountInput = organisation.fulfilmentAccounts.map(
      (account) => {
        if (account.id === selectedFulfilmentAccount.id) {
          return {
            ...selectedFulfilmentAccount,
            autoApprovedThreshold: formState.autoApprovedThreshold,
          };
        }

        return account;
      }
    );

    const updateOrganisationInput = {
      ...parseOrganisationUpdateInput(organisation),
      fulfilmentAccounts: formatFulfilmentAccounts(fulfilmentAccountInput),
    };

    await updateOrganisation({
      variables: {
        id: organisation.id,
        organisation: updateOrganisationInput,
      },
    });

    handleDialog();
  };

  return (
    <Mutation mutation={updateOrganisationMutation}>
      {(updateOrganisation, { loading }) => (
        <form
          onSubmit={handleSubmit((formState) =>
            onUpdateAccount(updateOrganisation, formState)
          )}
        >
          <h4>
            Update {selectedFulfilmentAccount.name} (
            {selectedFulfilmentAccount.currency}) fulfilment account
          </h4>
          <Field
            component={RenderField}
            label="Auto Approved Threshold"
            name="autoApprovedThreshold"
            parse={parse}
            format={format}
          />

          <Button
            type="button"
            raised
            onClick={handleDialog}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button type="submit" raised primary disabled={loading}>
            Update
          </Button>
        </form>
      )}
    </Mutation>
  );
};

UpdateFulfilmentAccountForm.propTypes = {
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  organisation: PropTypes.object,
  selectedFulfilmentAccount: PropTypes.object,
};

export default reduxForm({
  form: "update-fulfilment-account",
})(UpdateFulfilmentAccountForm);
