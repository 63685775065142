import React from "react";
import { Button } from "react-toolbox";

import PropTypes from "prop-types";

const ProductPicker = ({ input, meta, products }) => (
  <div>
    {!products && <h5>You need to pick a Resource</h5>}
    {products && !products.length && <h5>Resource has no products</h5>}
    {products && products.length && (
      <div>
        {products.map((product) => (
          <Button
            key={product.id}
            onClick={() => input.onChange(product.id)}
            primary={input.value === product.id}
            raised={input.value === product.id}
          >
            {product.description || "No description"},
            {`£${(Math.round(product.price) / 100).toFixed(2)}`}
          </Button>
        ))}
      </div>
    )}
    {meta.error}
  </div>
);

ProductPicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      active: PropTypes.boolean,
      amazonASIN: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.number,
    })
  ),
};

export default ProductPicker;
