import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import subjectsQuery from "../../containers/subjects/subjects.graphql";

import archiveSubjectQuery from "./archive-subject.graphql";

const onSubmit = (id, archiveSubject, handleDialog) => () =>
  archiveSubject({
    variables: { id },
  }).then(handleDialog);

const ArchiveSubjectDialog = ({ active, id, handleDialog, archiveSubject }) => (
  <Dialog
    actions={[
      { label: "Nooo, I'll think about it", onClick: handleDialog },
      {
        label: "Sure, archive it",
        onClick: onSubmit(id, archiveSubject, handleDialog),
        raised: true,
        primary: true,
      },
    ]}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
    title="You can not reverse this action"
  />
);

ArchiveSubjectDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  archiveSubject: PropTypes.func,
  id: PropTypes.string,
};

export default graphql(archiveSubjectQuery, {
  name: "archiveSubject",
  options: {
    update: (proxy, { data: { archiveSubject } }) => {
      const data = proxy.readQuery({ query: subjectsQuery });
      data[
        data.subjects.findIndex((item) => item.id === archiveSubject.id)
      ] = archiveSubject;
      proxy.writeQuery({ query: subjectsQuery, data });
    },
  },
})(ArchiveSubjectDialog);
