import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { Autocomplete, ProgressBar } from "react-toolbox";

import getOrgMembersQuery from "./get-org-members.graphql";

const mapIdToName = ({ id, firstName, lastName }) => ({
  [id]: `${firstName} ${lastName}`,
});

const ApproversPicker = ({ input, meta, organisationId }) => {
  if (!organisationId) {
    return <p>Please pick organistion</p>;
  }

  return (
    <Query
      query={getOrgMembersQuery}
      variables={{ organisationId }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, data }) => {
        if (loading) return <ProgressBar mode="indeterminate" />;
        const { members } = data.organisation;
        const source = Object.assign({}, ...members.results.map(mapIdToName));

        return (
          <Autocomplete
            multiple
            showSuggestionsWhenValueIsSet
            label="Choose user approvers"
            value={input.value}
            source={source}
            onChange={(newValue) => input.onChange(newValue)}
            error={meta.touched && meta.error}
          />
        );
      }}
    </Query>
  );
};

ApproversPicker.propTypes = {
  organisationId: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default ApproversPicker;
