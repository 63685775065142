import { Snackbar, Button } from "react-toolbox";
import { graphql } from "react-apollo";
import { compose } from "redux";
import { Col, Row } from "react-flexbox-grid";
import Joi from "joi-browser";
import { connect } from "react-redux";

import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";

import PropTypes from "prop-types";

import RenderField from "../../components/field";
import SubjectPicker from "../../components/subject-picker";
import ResourcePicker from "../../components/resource-picker";
import UserPicker from "../../components/user-picker";
import ProductPicker from "../../components/product-picker";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";

import createRequest from "./create-request.graphql";

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const onSubmit = (mutate, history, failure) => (data) =>
  mutate({
    variables: { request: data },
  })
    .then(() => history.push("/requests/?requestStatus%5B0%5D=pending"))
    .catch(failure);

const schema = Joi.object({
  user: Joi.string().uuid({ version: "uuidv4" }).required(),
  resource: Joi.string().uuid({ version: "uuidv4" }).required(),
  product: Joi.string().uuid({ version: "uuidv4" }).required(),
  subjects: Joi.array().items(Joi.string().guid({ version: ["uuidv4"] })),
  requestNote: Joi.string().max(500),
  price: Joi.number().integer().min(0).required(),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const mapFormValuesToMutation = (onSubmit) => ({
  searchResource,
  searchUser,
  resource,
  subjects,
  user,
  ...data
}) => {
  return onSubmit({
    resource: resource && resource.id,
    user: user && user.id,
    subjects: subjects && Object.keys(subjects),
    ...data,
  });
};

const RequestFormComponent = ({
  handleSubmit,
  products,
  mutate,
  history,
  failure,
}) => (
  <form
    onSubmit={handleSubmit(
      mapFormValuesToMutation(onSubmit(mutate, history, failure))
    )}
  >
    <Row>
      <Col sm={1}>
        <h4>User</h4>
      </Col>
      <Col sm={9}>
        <Field component={UserPicker} name="user" />
      </Col>
    </Row>
    <Row>
      <Col sm={1}>
        <h4>Resource</h4>
      </Col>
      <Col sm={9}>
        <Field component={ResourcePicker} name="resource" />
      </Col>
    </Row>
    <Row>
      <Col sm={1}>
        <h4>Product</h4>
      </Col>
      <Col sm={9}>
        <Field component={ProductPicker} name="product" products={products} />
      </Col>
    </Row>
    <Field
      component={RenderField}
      parse={parse}
      format={format}
      label="Price"
      name="price"
    />
    <Field
      component={RenderField}
      label="Request Note"
      name="requestNote"
      multiline
    />
    <Field name="subjects" component={SubjectPicker} />
    <Row between="xs">
      <Col />
      <Col>
        <Button type="submit" raised primary>
          Create Request
        </Button>
      </Col>
    </Row>
  </form>
);

RequestFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  mutate: PropTypes.func,
  history: PropTypes.func,
  failure: PropTypes.func,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      amazonASIN: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      price: PropTypes.number,
    })
  ),
};

const selector = formValueSelector("request");

const RequestForm = compose(
  reduxForm({
    form: "request",
    validate: mapFormValuesToMutation(validate),
  }),
  connect((state) => {
    const resource = selector(state, "resource");

    return {
      products: resource && resource.products,
    };
  })
)(RequestFormComponent);

class CreateRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false,
    };
  }

  render() {
    const { mutate, history } = this.props;
    return (
      <div>
        <h1>Create Request</h1>
        <RequestForm
          mutate={mutate}
          history={history}
          failure={() =>
            this.setState({
              message: "Failure",
              alert: true,
            })
          }
        />
        <Snackbar
          action="Dismiss"
          active={this.state.alert}
          label={this.state.message}
          timeout={2000}
          onTimeout={() => this.setState({ alert: false })}
          type="cancel"
        />
      </div>
    );
  }
}

CreateRequest.propTypes = {
  mutate: PropTypes.func,
  history: PropTypes.func,
};

export default graphql(createRequest)(CreateRequest);
