import { Dialog, Button, DatePicker, Input } from "react-toolbox";
import { graphql } from "react-apollo";
import { Mutation } from "react-apollo";
import React, { useState } from "react";
import { partial } from "lodash";
import PropTypes from "prop-types";
import moment from "moment";

import updateBudgetPeriodMutation from "./update-budget-period.graphql";

const UpdateBudgetPeriodDialog = ({
  active,
  handleDialog,
  organisationId,
  budgetPeriod,
}) => {
  const [formState, setFormState] = useState({
    startDate: new Date(budgetPeriod.startDate),
    endDate: new Date(budgetPeriod.endDate),
    organisationId,
    budgetPeriodId: budgetPeriod.id,
    numberOfBudgets: budgetPeriod.numberOfBudgets,
    name: budgetPeriod.name,
  });

  const handleInputChange = (name, value) =>
    setFormState({
      ...formState,
      [name]: value,
    });

  return (
    <Dialog
      onEscKeyDown={handleDialog}
      onOverlayClick={handleDialog}
      active={active}
    >
      <h3>Update Budget Period</h3>
      <p>
        The selected start date is inclusive of the day, and the end date is
        exclusive of the day
      </p>
      <p> Number of budgets affected: {formState.numberOfBudgets}</p>
      <Mutation mutation={updateBudgetPeriodMutation}>
        {(updateBudgetPeriod) => (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              updateBudgetPeriod({
                variables: {
                  organisationId: formState.organisationId,
                  budgetPeriodId: formState.budgetPeriodId,
                  startDate: moment(formState.startDate)
                    .utc()
                    .startOf("day")
                    .toISOString(),
                  endDate: moment(formState.endDate)
                    .utc()
                    .startOf("day")
                    .toISOString(),
                  name: formState.name,
                },
              })
                .catch((error) => alert(error.message))
                .then(handleDialog);
            }}
          >
            <Input
              onChange={partial(handleInputChange, "name")}
              name="name"
              value={formState.name}
              label="Change budget period name"
            />
            {new Date(formState.startDate) >= new Date() && (
              <DatePicker
                label="Start date (inclusive of day)"
                name="startDate"
                onChange={partial(handleInputChange, "startDate")}
                value={formState.startDate}
                autoOk
              />
            )}
            <DatePicker
              label="End date (exclusive of day)"
              name="endDate"
              onChange={partial(handleInputChange, "endDate")}
              value={formState.endDate}
              autoOk
            />
            <Button onClick={handleDialog}>Cancel</Button>
            <Button type="submit" raised primary>
              Save
            </Button>
          </form>
        )}
      </Mutation>
    </Dialog>
  );
};

UpdateBudgetPeriodDialog.propTypes = {
  active: PropTypes.bool,
  budgetPeriod: PropTypes.shape({
    endDate: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    numberOfBudgets: PropTypes.number,
    startDate: PropTypes.string,
  }),
  handleDialog: PropTypes.func,
  onArchiveClick: PropTypes.func,
  organisationId: PropTypes.string,
  updateBudgetPeriodMutation: PropTypes.func,
};

export default graphql(updateBudgetPeriodMutation, {
  name: "updateBudgetPeriodMutation",
})(UpdateBudgetPeriodDialog);
