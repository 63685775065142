import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  CardActions,
  Chip,
  Avatar,
} from "react-toolbox";

import { formatCurrencyValue } from "../../utils/text-display";

import { Status } from "./atoms";

const FULFILMENT_APP_URL =
  process.env.NODE_ENV === "development"
    ? "https://fulfilment.learner.dev/"
    : "https://fulfilment.learner.be/";

export default class RequestCard extends PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onApprove: PropTypes.func.isRequired,
    onPurchase: PropTypes.func.isRequired,
    onRetry: PropTypes.func.isRequired,
    request: PropTypes.shape({
      id: PropTypes.string.isRequired,
      totalCost: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      approvedBy: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        organisation: PropTypes.object,
      }).isRequired,
      status: PropTypes.oneOf([
        "purchased",
        "rejected",
        "cancelled",
        "approved",
        "pending",
      ]).isRequired,
      requestNote: PropTypes.string,
      approveNote: PropTypes.string,
      additionalNote: PropTypes.string,
      approvedAt: PropTypes.string,
      hasFulfilmentAccount: PropTypes.bool,
      adhoc: PropTypes.bool,
      price: PropTypes.number,
      currency: PropTypes.string,
      resource: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.shape({
          name: PropTypes.string,
        }),
        supplier: PropTypes.object,
        link: PropTypes.string,
      }),
      product: PropTypes.shape({
        description: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
      }),
      adhocInfo: PropTypes.shape({
        title: PropTypes.string,
        link: PropTypes.string,
      }),
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        postcode: PropTypes.string,
        street: PropTypes.string,
      }),
      fulfilledByLearnerInfo: PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.string,
      }),
      fulfilmentOrderId: PropTypes.string,
      isFulfilledByLearner: PropTypes.bool,
    }).isRequired,
  };

  render() {
    const request = this.props.request;

    const emailSubject = request.resource
      ? request.resource.title
      : request.adhocInfo
      ? request.adhocInfo.title
      : request.fulfilledByLearnerInfo
      ? request.fulfilledByLearnerInfo.title
      : "Your request with Learnerbly";

    const address = request.address;

    return (
      <Card style={{ margin: "0 0 40px 0" }}>
        <CardTitle>
          <Fragment>
            <Chip>
              <Avatar icon="account_balance" />
              {request.user.organisation.name}
            </Chip>

            <Status status={request.status} />

            {request.adhoc && (
              <Chip>
                <Avatar
                  icon="flash_on"
                  style={{ backgroundColor: "black", color: "gold" }}
                />
                Adhoc
              </Chip>
            )}
            {request.isFulfilledByLearner && (
              <Chip>
                <Avatar
                  icon="flash_on"
                  style={{ backgroundColor: "gold", color: "black" }}
                />
                Fulfilled by learner
              </Chip>
            )}

            {request.resource &&
              request.resource.supplier &&
              request.resource.supplier.commission && (
                <Chip>
                  <Avatar
                    icon="attach_money"
                    style={{ backgroundColor: "black", color: "gold" }}
                  />
                  Commissionable
                </Chip>
              )}

            {request.product && request.product.description && (
              <Chip>
                <Avatar icon="description" />
                {request.product.description}
              </Chip>
            )}

            <a
              href={`mailto:${request.user.email}?subject=${emailSubject}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Chip>
                <Avatar icon="email" /> Email {request.user.firstName}
              </Chip>
            </a>
          </Fragment>
        </CardTitle>

        <CardTitle
          title={
            request.resource
              ? request.resource.title
              : request.adhocInfo
              ? request.adhocInfo.title
              : request.fulfilledByLearnerInfo
              ? request.fulfilledByLearnerInfo.title
              : ""
          }
          subtitle={`By ${request.user.firstName} ${request.user.lastName}
           (${request.user.email}) on
           ${moment(request.createdAt).format("MMMM Do YYYY, h:mm a")}`}
        />

        <CardText>
          {request.price && request.price !== request.totalCost && (
            <div>
              <strong>Price:</strong>
              {formatCurrencyValue(request.price, request.currency)}
            </div>
          )}

          {request.totalCost && (
            <div>
              <strong>Total:</strong>
              {formatCurrencyValue(request.totalCost, request.currency)}
            </div>
          )}
        </CardText>

        <CardText>
          {request.resource && request.resource.type.name === "Course" && (
            <div>
              <strong>Dates: </strong>
              {`${moment(request.product.startDate).format(
                "MMMM Do YYYY, h:mm a"
              )} -
              ${moment(request.product.endDate).format(
                "MMMM Do YYYY, h:mm a"
              )}`}
            </div>
          )}
        </CardText>

        {request.requestNote && (
          <CardText>
            <strong>Request Note:</strong> {request.requestNote}
          </CardText>
        )}

        {request.approveNote && (
          <CardText>
            <strong>Approve Note:</strong> {request.approveNote}
          </CardText>
        )}

        {request.additionalNote && (
          <CardText>
            <strong>Note to Learnerbly:</strong> {request.additionalNote}
          </CardText>
        )}

        {address && (
          <CardText>
            <strong>Delivery address:</strong>
            {address.street && <div>{address.street}</div>}
            {address.postcode && <div>{address.postcode}</div>}
            {address.city && <div>{address.city}</div>}
            {address.country && <div>{address.country}</div>}
          </CardText>
        )}

        {request.approvedBy && (
          <CardText>
            {`Approved by ${request.approvedBy.firstName} ${
              request.approvedBy.lastName
            }
           on ${moment(request.approvedAt).format("MMMM Do YYYY, h:mm a")}`}
          </CardText>
        )}

        <CardActions>
          {request.status === "approved" && !request.hasFulfilmentAccount && (
            <Button
              primary
              accent
              label="Mark as purchased"
              icon="check_circle"
              onClick={this.props.onPurchase(request)}
            />
          )}

          {request.hasFulfilmentAccount && (
            <a
              href="https://fulfilment.learner.be"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button label="Purchase in Fulfilment" icon="link" />
            </a>
          )}

          {request.status === "pending" && (
            <Button
              primary
              label="Approve Request"
              icon="check_circle"
              onClick={this.props.onApprove(request)}
            />
          )}

          {(request.status === "approved" || request.status === "pending") && (
            <Button
              primary
              label="Cancel Request"
              icon="cancel"
              onClick={this.props.onCancel(request)}
            />
          )}

          {((request.status === "approved" && request.status !== "purchased") ||
            request.status !== "paid") && (
            <Button
              primary
              label="Retry Request"
              icon="refresh"
              onClick={this.props.onRetry(request)}
            />
          )}

          <Link to={`/requests/request/${request.id}/`}>
            <Button label="View request" icon="link" />
          </Link>

          {request.resource && (
            <Link to={`/resources/${request.resource.id}/`}>
              <Button label="View resource" icon="exit_to_app" />
            </Link>
          )}

          {request.adhocInfo && (
            <a
              target="_blank"
              href={request.adhocInfo.link}
              rel="noopener noreferrer"
            >
              <Button label="View link" icon="exit_to_app" />
            </a>
          )}

          {request.fulfilledByLearnerInfo &&
            request.fulfilledByLearnerInfo.link && (
              <a
                target="_blank"
                href={request.fulfilledByLearnerInfo.link}
                rel="noopener noreferrer"
              >
                <Button label="View link" icon="exit_to_app" />
              </a>
            )}

          {request.resource && request.resource.link && (
            <a
              target="_blank"
              href={request.resource.link}
              rel="noopener noreferrer"
            >
              <Button label="View at supplier" icon="exit_to_app" />
            </a>
          )}

          {request.fulfilmentOrderId && (
            <Link
              to={`${FULFILMENT_APP_URL}orders/${request.fulfilmentOrderId}/`}
            >
              <Button label="View in fulfilment" icon="exit_to_app" />
            </Link>
          )}
        </CardActions>
      </Card>
    );
  }
}
