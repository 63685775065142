import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Table, TableHead, TableCell, TableRow } from "react-toolbox";

import { formatCurrencyValue } from "../../../../utils/text-display";

const formatDate = (date) => `${moment.utc(date).format("Do MMM yy")}`;

const AccountBudgetList = ({ budgets, currency }) => {
  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <h3>Budgets</h3>
      <Table selectable={false}>
        <TableHead>
          <TableCell>Name</TableCell>
          <TableCell>Time Period (GMT)</TableCell>
          <TableCell># Pots</TableCell>
          <TableCell>Total Spend</TableCell>
          <TableCell>Allocated Funds</TableCell>
          <TableCell>Max Spend</TableCell>
        </TableHead>

        {budgets.map(
          ({
            id,
            name,
            startDate,
            endDate,
            associatedPots,
            funds,
            maxSpend,
          }) => (
            <TableRow key={id}>
              <TableCell>{name}</TableCell>
              <TableCell>
                {startDate
                  ? formatDate(new Date(startDate), "dd MMM yy")
                  : null}
                {endDate
                  ? ` - ${formatDate(new Date(endDate), "dd MMM yy")}`
                  : null}
              </TableCell>
              <TableCell>{associatedPots.length}</TableCell>
              <TableCell>
                {formatCurrencyValue(funds.totalSpend, currency)}
              </TableCell>
              <TableCell>
                {formatCurrencyValue(funds.allocated, currency)}
              </TableCell>
              <TableCell>{formatCurrencyValue(maxSpend, currency)}</TableCell>
            </TableRow>
          )
        )}
      </Table>
    </div>
  );
};

AccountBudgetList.propTypes = {
  budgets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      associatedPots: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string })
      ),
      funds: PropTypes.shape({
        totalSpend: PropTypes.number,
        allocated: PropTypes.number,
      }),
      maxSpend: PropTypes.number,
    })
  ),
  currency: PropTypes.string,
};

export default AccountBudgetList;
