// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-icon-1hCZrez5 {\n  color: #fff !important;\n}\n\n.theme-container-15AA1oKQ {\n  padding: 32px;\n  padding: 2rem;\n}\n\n.theme-link-1cOteCjY {\n  display: block;\n  min-height: 48px;\n  width: 100%;\n  line-height: 48px;\n  color: inherit;\n  text-decoration: none;\n}\n\niframe {\n  border: 0;\n}\n", "",{"version":3,"sources":["/Users/mnavarro/source/origin/backoffice/src/components/main/theme.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EAAb,aAAa;AACf;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,SAAS;AACX","file":"theme.css","sourcesContent":[".icon {\n  color: #fff !important;\n}\n\n.container {\n  padding: 2rem;\n}\n\n.link {\n  display: block;\n  min-height: 48px;\n  width: 100%;\n  line-height: 48px;\n  color: inherit;\n  text-decoration: none;\n}\n\niframe {\n  border: 0;\n}\n"]}]);
// Exports
exports.locals = {
	"icon": "theme-icon-1hCZrez5",
	"container": "theme-container-15AA1oKQ",
	"link": "theme-link-1cOteCjY"
};
module.exports = exports;
