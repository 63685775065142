import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "@aws-amplify/auth";

const httpLink = createHttpLink({
  uri: process.env.API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await Auth.currentSession().then((session) => {
    return session.getAccessToken().getJwtToken();
  });

  return {
    headers: {
      ...headers,
      ...(token && { authorization: token }),
    },
  };
});

export const apolloClient = new ApolloClient({
  name: "backoffice",
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
