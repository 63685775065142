import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import SubjectForm from "../subject-form";

import updateSubjectQuery from "./update-subject.graphql";

const onSubmit = (updateSubjectQuery, id, handleDialog) => (data) =>
  updateSubjectQuery({
    variables: { id, subject: data },
  }).then(handleDialog);

const UpdateSubjectDialog = ({
  active,
  onArchiveClick,
  handleDialog,
  updateSubjectQuery,
  subject: { id, name, description, archived, children },
}) => (
  <Dialog
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
  >
    <SubjectForm
      initialValues={{
        name,
        description,
        children: children ? children.map(({ id }) => id) : [],
      }}
      submitLabel="Update Subject"
      handleDialog={handleDialog}
      onArchiveClick={onArchiveClick(id)}
      onSubmit={onSubmit(updateSubjectQuery, id, handleDialog)}
      archived={archived}
      update
    />
  </Dialog>
);

UpdateSubjectDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  updateSubjectQuery: PropTypes.func,
  subject: PropTypes.object,
  onArchiveClick: PropTypes.func,
};

export default graphql(updateSubjectQuery, {
  name: "updateSubjectQuery",
})(UpdateSubjectDialog);
