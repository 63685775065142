import PropTypes from "prop-types";
import React from "react";
import { graphql } from "react-apollo";
import { Dialog } from "react-toolbox";

import CreateOnlineCourseForm from "../create-online-course-form";

import addOnlineCourseQuery from "../add-online-course-dialog/add-online-course.graphql";

import theme from "./theme.css";

const onSubmit = (copyOnlineCourse, handleDialog, resourceId) => ({
  active,
  country,
  currency,
  description,
  link,
  price,
  codeClaim,
}) => {
  return copyOnlineCourse({
    variables: {
      onlineCourse: {
        active,
        country,
        currency,
        description,
        link,
        price,
        codeClaim,
      },
      resourceId,
    },
  }).then(handleDialog);
};

const CopyOnlineCourseModal = ({
  active,
  copyOnlineCourse,
  handleDialog,
  product,
  resourceId,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <CreateOnlineCourseForm
      handleDialog={handleDialog}
      initialValues={{
        active: product.active,
        country: product.country,
        currency: product.currency,
        description: product.description,
        isGlobal: !product.country,
        link: product.link,
        price: product.price,
        codeClaim: product.codeClaim,
      }}
      onSubmit={onSubmit(copyOnlineCourse, handleDialog, resourceId)}
    />
  </Dialog>
);

CopyOnlineCourseModal.propTypes = {
  active: PropTypes.bool,
  copyOnlineCourse: PropTypes.func,
  handleDialog: PropTypes.func,
  product: PropTypes.object,
  resourceId: PropTypes.string,
};

export default graphql(addOnlineCourseQuery, {
  name: "copyOnlineCourse",
})(CopyOnlineCourseModal);
