import { Button } from "react-toolbox";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import Joi from "joi-browser";
import React from "react";
import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import ContentRegionPicker from "../content-region-picker";
import CountryPicker from "../country-picker";
import RenderField from "../../components/field";
import SwitchField from "../../components/switch";

const schema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  organisationAdmin: Joi.boolean().required(),
  email: Joi.string().email().required(),
  country: Joi.string().length(2).default("GB").required(),
  geographicLocation: Joi.string().length(2).default("GB").allow(null),
}).required();

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const CreateOrganisationAdminForm = ({ handleSubmit, onSubmit }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={RenderField}
        label="First Name"
        name="firstName"
        autoFocus
      />
      <Field component={RenderField} label="Last Name" name="lastName" />
      <Field component={RenderField} label="Email" name="email" type="email" />

      <Field
        component={ContentRegionPicker}
        label="Choose the user's content region"
        name="country"
      />

      <Field
        component={CountryPicker}
        label="Choose the user's geographic location"
        name="geographicLocation"
      />
      <Field
        name="organisationAdmin"
        component={SwitchField}
        label="Organisation Admin"
      />
      <Button type="submit" raised primary>
        Create User
      </Button>
    </form>
  );
};

CreateOrganisationAdminForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default compose(
  reduxForm({
    form: "user",
    validate,
  })
)(CreateOrganisationAdminForm);
