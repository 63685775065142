import { Dialog, DialogContent } from "@material-ui/core";
import { graphql } from "react-apollo";
import React, { useState } from "react";

import PropTypes from "prop-types";

import CreateOnlineCourseForm from "../create-online-course-form";

import resourceQuery from "../../containers/update-resource/resource.graphql";

import addOnlineCourseQuery from "./add-online-course.graphql";

const AddOnlineCourseModal = ({
  active,
  addOnlineCourse,
  handleDialog,
  resourceId,
  resourceLink,
  supplier,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (addOnlineCourse, handleDialog, resourceId) => (data) => {
    const { isGlobal, ...onlineCourse } = data;

    setLoading(true);
    addOnlineCourse({
      variables: { onlineCourse, resourceId },
    })
      .then(handleDialog)
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={active}
      onClose={handleDialog}
      container={() => document.getElementById("root")}
      fullWidth
    >
      <DialogContent>
        <CreateOnlineCourseForm
          handleDialog={handleDialog}
          onSubmit={onSubmit(addOnlineCourse, handleDialog, resourceId)}
          initialValues={{
            active: true,
            currency: "GBP",
            isGlobal: true,
            link: resourceLink,
            codeClaim: false,
          }}
          loading={loading}
          supplier={supplier}
        />
      </DialogContent>
    </Dialog>
  );
};

AddOnlineCourseModal.propTypes = {
  active: PropTypes.bool,
  addOnlineCourse: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  resourceLink: PropTypes.string,
  supplier: PropTypes.shape({
    id: PropTypes.string,
    codeClaim: PropTypes.bool,
  }),
};

export default graphql(addOnlineCourseQuery, {
  name: "addOnlineCourse",
  options: (props) => ({
    update: (proxy, { data: { addOnlineCourse } }) => {
      const options = {
        query: resourceQuery,
        variables: {
          id: props.resourceId,
          productFilter: {
            include_inactive: true,
            include_expired_events: true,
            include_all_countries: true,
            include_all_currencies: true,
          },
        },
      };
      const data = proxy.readQuery(options);
      data.resource.products.push(addOnlineCourse);
      proxy.writeQuery({ ...options, data });
    },
  }),
})(AddOnlineCourseModal);
