import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { change, Field, reduxForm } from "redux-form";
import Joi from "joi-browser";
import React from "react";
import moment from "moment-timezone";

import PropTypes from "prop-types";

import TimezonePicker from "../timezone-picker";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderDateTime from "../datetime";
import RenderField from "../field";
import RenderSwitch from "../switch";

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const parseNull = (value) => value || null;

const schema = Joi.object({
  price: Joi.number().integer().min(0).required(),
  startDate: Joi.date().iso().raw().required(),
  endDate: Joi.date().iso().min(Joi.ref("startDate")).raw().allow(null),
  city: Joi.string().allow(null),
  timezone: Joi.string().valid(moment.tz.names()),
  country: Joi.string().allow(null),
  street: Joi.string().allow(null),
  postcode: Joi.string().allow(null),
  moreInfo: Joi.string().allow(null),
  description: Joi.string().allow(null),
  active: Joi.boolean().label("Active"),
  link: Joi.string().required(),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const EventForm = ({ dispatch, handleDialog, handleSubmit, onSubmit }) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Row>
      <Col sm={4}>
        <Field
          component={RenderField}
          label="Price"
          name="price"
          parse={parse}
          format={format}
          autoFocus
        />
      </Col>
      <Col sm={8}>
        <Field
          component={RenderField}
          label="Street"
          name="street"
          parse={parseNull}
        />
      </Col>
    </Row>
    <Row>
      <Col sm={4}>
        <Field
          component={RenderField}
          label="Postcode"
          name="postcode"
          parse={parseNull}
        />
      </Col>
      <Col sm={8}>
        <Field
          component={RenderField}
          label="City"
          name="city"
          parse={parseNull}
        />
      </Col>
    </Row>
    <Field
      component={RenderDateTime}
      label="Start Date"
      name="startDate"
      onChange={(_, date) =>
        dispatch(
          change("event", "endDate", moment(date).add(1, "hour").utc().format())
        )
      }
    />
    <Field component={RenderDateTime} label="End Date" name="endDate" />
    <Field name="timezone" label="Timezone" component={TimezonePicker} />
    <Field
      component={RenderField}
      label="Description"
      name="description"
      parse={parseNull}
    />
    <Field
      component={RenderField}
      label="More Info"
      name="moreInfo"
      parse={parseNull}
      multiline
    />
    <Field component={RenderField} label="Product URL" name="link" />
    <Row between="xs">
      <Col>
        <Field component={RenderSwitch} label="Active" name="active" />
      </Col>
      <Col>
        <Button onClick={handleDialog}>Cancel</Button>
        <Button type="submit" raised primary>
          Update Event
        </Button>
      </Col>
    </Row>
  </form>
);

EventForm.propTypes = {
  dispatch: PropTypes.func,
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
};

export default reduxForm({
  form: "event",
  validate,
})(EventForm);
