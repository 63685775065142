import React from "react";
import { Button } from "react-toolbox";
import PropTypes from "prop-types";

import SelectResourceDialog from "../../components/select-resource-dialog";

class ResourcePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resource: false,
    };

    this.handleSelectResourceDialog = this.handleSelectResourceDialog.bind(
      this
    );
  }

  handleSelectResourceDialog() {
    this.setState({
      resource: !this.state.resource,
    });
  }

  render() {
    const { input, meta } = this.props;

    return (
      <div>
        {input.value && <div>{input.value.title}</div>}
        <Button
          icon="search"
          onClick={this.handleSelectResourceDialog}
          label="Select Resource"
          primary
          mini
        />
        {meta.error}
        <SelectResourceDialog
          active={this.state.resource}
          handleDialog={this.handleSelectResourceDialog}
          onSuccess={this.props.input.onChange}
        />
      </div>
    );
  }
}

ResourcePicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default ResourcePicker;
