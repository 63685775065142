// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-container-FLgQpquB {\n  margin-bottom: 30px;\n}\n\n.theme-input-3l_VsCVq {\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: absolute;\n  z-index: -1;\n}\n\n.theme-drop-3pCS5BZp {\n  display: block;\n  background: rgba(0, 0, 0, .08);\n  padding: 50px 20px;\n  text-align: center;\n}\n\n.theme-dragOver-3udu3VFA .theme-drop-3pCS5BZp {\n  color: rgba(0, 0, 0, 0);\n  background: #ebebeb inline(\"./snoop.gif\") center center no-repeat;\n}\n\n.theme-imageContainer-1NisoHA3 {\n  position: relative;\n  display: inline-block;\n}\n\n.theme-dialog-1fQ8BNZY {\n  overflow: scroll;\n}\n\n.theme-close-kBA3Eii4 {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n\n.theme-image-2EQmBlF- {\n  width: 100%;\n}\n", "",{"version":3,"sources":["/Users/mnavarro/source/origin/backoffice/src/components/image-field/theme.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,8BAA+B;EAC/B,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,iEAAiE;AACnE;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,WAAW;AACb","file":"theme.css","sourcesContent":[".container {\n  margin-bottom: 30px;\n}\n\n.input {\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: absolute;\n  z-index: -1;\n}\n\n.drop {\n  display: block;\n  background: rgba(0, 0, 0, 0.08);\n  padding: 50px 20px;\n  text-align: center;\n}\n\n.dragOver .drop {\n  color: rgba(0, 0, 0, 0);\n  background: #ebebeb inline(\"./snoop.gif\") center center no-repeat;\n}\n\n.imageContainer {\n  position: relative;\n  display: inline-block;\n}\n\n.dialog {\n  overflow: scroll;\n}\n\n.close {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n\n.image {\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "theme-container-FLgQpquB",
	"input": "theme-input-3l_VsCVq",
	"drop": "theme-drop-3pCS5BZp",
	"dragOver": "theme-dragOver-3udu3VFA",
	"imageContainer": "theme-imageContainer-1NisoHA3",
	"dialog": "theme-dialog-1fQ8BNZY",
	"close": "theme-close-kBA3Eii4",
	"image": "theme-image-2EQmBlF-"
};
module.exports = exports;
