import React, { Component, Fragment } from "react";
import { partial, noop } from "lodash";
import { compose, map, sortBy } from "lodash/fp";
import { Query } from "react-apollo";
import { List, ListItem, ProgressBar } from "react-toolbox";

import UpdateSupplierDialog from "../../components/update-supplier-dialog";

import ArchiveSupplierDialog from "../../components/archive-supplier-dialog";

import query from "./suppliers.graphql";

class Suppliers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSupplier: null,
      showSupplierDialog: false,
      archive: false,
    };

    this.handleUpdateSupplierDialog = this.handleUpdateSupplierDialog.bind(
      this
    );
    this.handleSupplierClick = this.handleSupplierClick.bind(this);
    this.handleArchiveDialog = this.handleArchiveDialog.bind(this);
    this.onArchiveClick = this.onArchiveClick.bind(this);
  }

  handleUpdateSupplierDialog() {
    this.setState((state) => ({
      showSupplierDialog: !state.showSupplierDialog,
    }));
  }

  handleSupplierClick(currentSupplier) {
    this.setState(() => ({
      currentSupplier,
      showSupplierDialog: true,
    }));
  }

  renderSupplier(handler) {
    const RenderSupplier = (supplier) => {
      const { __typename, ...currentSupplier } = supplier;

      return (
        <ListItem
          key={supplier.id}
          caption={supplier.name}
          leftIcon="business"
          rightIcon="edit"
          onClick={partial(handler, currentSupplier)}
        />
      );
    };

    return RenderSupplier;
  }

  sortSuppliers(suppliers) {
    return compose(
      map(this.renderSupplier(this.handleSupplierClick)),
      sortBy(["name"])
    )(suppliers.filter((subject) => !subject.archived));
  }

  onArchiveClick(id) {
    return () => {
      this.setState({
        showSupplierDialog: false,
        archive: true,
        archiveId: id,
      });
    };
  }

  handleArchiveDialog() {
    this.setState({
      archive: !this.state.archive,
    });
  }

  render() {
    return (
      <Query query={query}>
        {({ loading, data }) => {
          if (loading) return <ProgressBar />;

          const { currentSupplier } = this.state;

          return (
            <Fragment>
              <List>{this.sortSuppliers(data.suppliers)}</List>

              {currentSupplier && (
                <UpdateSupplierDialog
                  update={false}
                  active={this.state.showSupplierDialog}
                  handleDialog={this.handleUpdateSupplierDialog}
                  onSuccess={noop}
                  onArchiveClick={this.onArchiveClick}
                  supplier={currentSupplier}
                />
              )}
              <ArchiveSupplierDialog
                active={this.state.archive}
                handleDialog={this.handleArchiveDialog}
                id={this.state.archiveId}
              />
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

export default Suppliers;
