import { Col, Row } from "react-flexbox-grid";
import { Snackbar } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import CreateOrganisationForm from "../../components/create-organisation-form";

import createOrganisation from "./create-organisation.graphql";

const onSubmit = (mutate, history, failure) => ({
  name,
  hasPots,
  hasMagicLinks,
  currency,
}) => {
  return mutate({
    variables: {
      organisation: {
        name,
        hasPots,
        currency,
        testClient: true,
        internalLearning: true,
        internalEvents: true,
        analyticsDashboard: true,
        adhocRequests: false,
        hasAmazonRequests: true,
        hasFulfilledByLearnerRequests: false,
        hasFulfilledByLearnerViaExpensesRequests: false,
        recentActivity: true,
        isInProofOfValue: false,
        hasFunds: true,
        ...(hasMagicLinks && { hasMagicLinks: true }),
      },
    },
  })
    .then(({ data: { createOrganisation } }) =>
      history.push(`/organisations/${createOrganisation.id}/create-admin/`)
    )
    .catch(failure);
};

class CreateOrganisation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false,
    };
  }

  render() {
    const { mutate, history } = this.props;
    return (
      <div>
        <h1>Create Organisation</h1>
        <Row>
          <Col sm={7}>
            <CreateOrganisationForm
              submitLabel="Create Organisation"
              onSubmit={onSubmit(mutate, history, () =>
                this.setState({
                  alert: true,
                  message: "Failure 😞",
                })
              )}
            />
          </Col>
        </Row>
        <Snackbar
          action="Dismiss"
          active={this.state.alert}
          label={this.state.message}
          timeout={2000}
          onTimeout={() => this.setState({ alert: false })}
          type="cancel"
        />
      </div>
    );
  }
}

CreateOrganisation.propTypes = {
  mutate: PropTypes.func,
  history: PropTypes.object,
};

export default graphql(createOrganisation)(CreateOrganisation);
