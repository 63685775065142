import { registerLocale, getNames } from "@learnerbly/i18n-iso-countries";
import PropTypes from "prop-types";
import React from "react";
import Autocomplete from "react-toolbox/lib/autocomplete";

import { permittedContentRegions } from "../../utils/permitted-content-regions";

registerLocale(require("@learnerbly/i18n-iso-countries/langs/en.json"));

const countries = getNames("en");

const contentRegionsWithNames = Object.fromEntries(
  Object.entries(countries).filter(([countryCode]) =>
    permittedContentRegions.includes(countryCode)
  )
);

const ContentRegionPicker = ({
  input: { onChange, value },
  label = "Choose a country",
  meta: { error, touched },
  multiple = false,
}) => {
  return (
    <Autocomplete
      error={touched && error}
      label={label}
      multiple={multiple}
      onChange={(newValue) => onChange(newValue)}
      showSuggestionsWhenValueIsSet
      source={contentRegionsWithNames}
      value={value}
    />
  );
};

ContentRegionPicker.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  multiple: PropTypes.bool,
};

export default ContentRegionPicker;
