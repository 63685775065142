// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-dialog-1SD8ducJ {\n  overflow-y: scroll;\n}\n", "",{"version":3,"sources":["/Users/mnavarro/source/origin/backoffice/src/components/update-feature-toggle-dialog/theme.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB","file":"theme.css","sourcesContent":[".dialog {\n  overflow-y: scroll;\n}\n"]}]);
// Exports
exports.locals = {
	"dialog": "theme-dialog-1SD8ducJ"
};
module.exports = exports;
