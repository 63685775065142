import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { Button } from "react-toolbox";
import React from "react";

import theme from "./theme.css";

const onLoginClick = () => Auth.federatedSignIn({ provider: "Google" });

const Login = () => {
  Hub.listen("auth", ({ payload: { event } }) => {
    if (event === "signIn") {
      Auth.currentSession().then(() => window.location.reload());
    }
  });

  return (
    <div className={theme.layout}>
      <div className={theme.container}>
        <div className={theme.logo} />
        <Button type="button" onClick={onLoginClick} raised primary>
          Login with Google
        </Button>
      </div>
    </div>
  );
};

export default Login;
