import { getAlpha2Code, getName } from "@learnerbly/i18n-iso-countries";
import { difference, omit, uniq } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { Row } from "react-flexbox-grid";
import { Button } from "react-toolbox";

import updateResourceMutation from "src/containers/update-resource/update-resource.graphql";

import { readableList } from "src/utils/text-display";

import styles from "./styles.css";

const ContentRegionMismatch = ({ resource }) => {
  const [isFixingContentRegions, setIsFixingContentRegions] = useState(false);

  if (!resource || !resource.products) {
    return null;
  }

  const contentRegionsOfProducts = resource.products
    .filter(({ active, country }) => active && country)
    .map(({ country }) => getName(country, "en"));
  const contentRegionsOfResource = resource.allowedCountries
    ? resource.allowedCountries.map((country) => getName(country, "en"))
    : [];

  const isAnyProductContentRegionLocked = contentRegionsOfProducts.length > 0;
  const isAnyProductForAllCountries =
    resource.products.filter(({ country }) => !country).length > 0;
  const isResourceContentRegionLocked = contentRegionsOfResource.length > 0;
  const isResourceForAllCountries = !isResourceContentRegionLocked;

  const isResourceForAllCountriesWithLockedProduct =
    isAnyProductContentRegionLocked &&
    isResourceForAllCountries &&
    !isAnyProductForAllCountries;
  const hasDifferentCountriesBetweenResourceAndProduct =
    difference(contentRegionsOfProducts, contentRegionsOfResource).length > 0 &&
    !(isAnyProductForAllCountries && isResourceForAllCountries);

  const hasContentRegionMismatch =
    isResourceForAllCountriesWithLockedProduct ||
    hasDifferentCountriesBetweenResourceAndProduct;

  const listOfProductContentRegions =
    isAnyProductContentRegionLocked &&
    readableList(
      uniq([
        ...contentRegionsOfProducts,
        ...(isAnyProductForAllCountries ? ["Available for all countries"] : []),
      ])
    );
  const listOfResourceContentRegions = isResourceContentRegionLocked
    ? readableList(uniq(contentRegionsOfResource))
    : "Available for all countries";

  const updateAllowedContentRegions = async (updateResource) => {
    const allowedCountries = isAnyProductForAllCountries
      ? []
      : uniq(
          contentRegionsOfProducts.map((country) =>
            getAlpha2Code(country, "en")
          )
        );

    const resourceToUpdate = {
      ...resource,
      allowedCountries,
      type: resource.type.id,
    };

    const propertiesToOmit = [
      "__typename",
      "allowedOrganisations",
      "createdAt",
      "createdBy",
      "disallowedOrganisations",
      "id",
      "learningOutcomes",
      "products",
      "subjects",
      "supplier",
      "updatedAt",
      "updatedBy",
    ];

    const updatedResource = omit(resourceToUpdate, propertiesToOmit);

    await updateResource({
      variables: {
        id: resource.id,
        resource: updatedResource,
      },
    });

    setIsFixingContentRegions(false);
  };

  if (!hasContentRegionMismatch) {
    return null;
  }

  if (!isFixingContentRegions) {
    return (
      <div className={styles.row}>
        <Row>
          <Button
            className={styles.warning}
            onClick={() => setIsFixingContentRegions(true)}
          >
            MISMATCHED ALLOWED COUNTRIES!
          </Button>
        </Row>
      </div>
    );
  }

  return (
    <Mutation mutation={updateResourceMutation}>
      {(updateResource) => (
        <>
          <div className={styles.row}>
            <Row>
              <div>The resource has the following allowed countries:</div>
              <div className={styles.bold}>
                &nbsp;{listOfResourceContentRegions}
              </div>
            </Row>
          </div>
          <div className={styles.row}>
            <Row>
              <div>
                The related product(s) have the following allowed countries:
              </div>
              <div className={styles.bold}>
                &nbsp;{listOfProductContentRegions}
              </div>
            </Row>
          </div>
          <div className={styles.row}>
            <Row>
              <div>
                Updating the resource will set it to the following allowed
                countries:
              </div>
              <div className={styles.bold}>
                &nbsp;
                {isAnyProductForAllCountries
                  ? "Available for all countries"
                  : readableList(uniq(contentRegionsOfProducts))}
              </div>
            </Row>
          </div>
          <div className={styles.row}>
            <Row>
              <div className={styles.action}>
                <Button
                  onClick={() => setIsFixingContentRegions(false)}
                  primary
                  raised
                >
                  This is fine
                </Button>
              </div>
              <Button
                onClick={() => updateAllowedContentRegions(updateResource)}
                primary
                raised
              >
                Update resource
              </Button>
            </Row>
          </div>
        </>
      )}
    </Mutation>
  );
};

ContentRegionMismatch.propTypes = {
  resource: PropTypes.object,
};

export default ContentRegionMismatch;
