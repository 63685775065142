import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import React from "react";
import ReactPlayer from "react-player";

import PropTypes from "prop-types";

import RenderField from "../field";

class VideoField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      play: false,
    };

    this.handlePlay = this.handlePlay.bind(this);
  }

  handlePlay() {
    this.setState({
      play: !this.state.play,
    });
  }

  render() {
    const { input, ...props } = this.props;
    return (
      <Row>
        <Col sm={11}>
          <RenderField {...input} {...props} />
          {input.value && this.state.play && (
            <ReactPlayer url={input.value} height={270} width={480} controls />
          )}
        </Col>
        <Col sm>
          <Button
            icon="featured_video"
            onClick={this.handlePlay}
            primary
            floating
            mini
          />
        </Col>
      </Row>
    );
  }
}

VideoField.propTypes = {
  input: PropTypes.object,
};

export default VideoField;
