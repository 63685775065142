import { Dialog, withStyles } from "@material-ui/core";

export const StyledDialog = withStyles({
  root: {
    // Brace yourself – we run two dialogs in this app, and this forces
    //  the Material UI dialog to be underneath the react-toolbox one
    zIndex: "199 !important",
  },
  paperScrollPaper: {
    // To ensure the header doesn't overlap the dialog box, we reduce the overall size
    maxHeight: "80vh",
  },
})(Dialog);
