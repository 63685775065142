import { Dialog, Button } from "react-toolbox";
import { graphql } from "react-apollo";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import React from "react";

import PropTypes from "prop-types";

import RenderField from "../field";

import approveRequestQuery from "./approve-request.graphql";

const onSubmit = (approveRequestQuery, handleDialog, user, requestId) => (
  data
) => {
  approveRequestQuery({
    variables: {
      request: {
        id: requestId,
        user: user.id,
        approveNote: data.approveNote,
        selectedBudgetId: user.accessibleBudgets[0].id,
      },
    },
  }).then(handleDialog);
};

const ApproveRequestDialog = ({
  active,
  approveRequestQuery,
  handleDialog,
  request,
  handleSubmit,
}) => {
  return (
    <Dialog
      active={active}
      onEscKeyDown={handleDialog}
      onOverlayClick={handleDialog}
      title="Approve Request"
    >
      {request && (
        <form
          onSubmit={handleSubmit(
            onSubmit(
              approveRequestQuery,
              handleDialog,
              request.user,
              request.id
            )
          )}
        >
          <Field
            component={RenderField}
            label="Approve Note"
            name="approveNote"
            multiline
          />
          <Button onClick={handleDialog}>Cancel</Button>
          <Button
            disabled={request.user.accessibleBudgets.length === 0}
            title={
              request.user.accessibleBudgets.length === 0 && "No user budget"
            }
            type="submit"
            raised
            primary
          >
            Approve
          </Button>
        </form>
      )}
    </Dialog>
  );
};

ApproveRequestDialog.propTypes = {
  active: PropTypes.bool,
  approveRequestQuery: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleDialog: PropTypes.func,
  request: PropTypes.shape({
    additionalNote: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      postcode: PropTypes.string,
      street: PropTypes.string,
    }),
    adhoc: PropTypes.bool,
    adhocInfo: PropTypes.string,
    approveNote: PropTypes.string,
    approvedAt: PropTypes.string,
    approvedBy: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    currency: PropTypes.string,
    hasFulfilmentAccount: PropTypes.bool,
    id: PropTypes.string,
    price: PropTypes.number,
    product: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
    requestNote: PropTypes.string,
    resource: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      commission: PropTypes.number,
      link: PropTypes.string,
      supplier: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
    totalCost: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.string,
      accessibleBudgets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      ),
      activeIndividualBudget: PropTypes.shape({
        id: PropTypes.string,
      }),
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      organisation: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
};

export default compose(
  graphql(approveRequestQuery, {
    name: "approveRequestQuery",
  }),
  reduxForm({
    form: "approveRequest",
  })
)(ApproveRequestDialog);
