import { Switch } from "react-toolbox";
import React from "react";

import PropTypes from "prop-types";

const RenderSwitch = ({ input, meta, ...props }) => (
  <div>
    <Switch {...input} {...props} checked={Boolean(input.value)} />
    {meta.error && <div>{meta.error}</div>}
  </div>
);

RenderSwitch.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default RenderSwitch;
