import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import Joi from "joi-browser";
import React from "react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import ImageField from "../image-field";
import RenderField from "../field";

const schema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string().allow(null).email(),
  description: Joi.string().allow(null),
  role: Joi.string().allow(null),
  image: Joi.string().allow(null),
  link: Joi.string().allow(null),
  employer: Joi.string().allow(null),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const normalize = (value) => value || null;

const ExpertForm = ({
  onUnlinkClick,
  handleDialog,
  handleSubmit,
  onSubmit,
  submitLabel,
  update,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Field
      component={RenderField}
      label="First Name"
      name="firstName"
      autoFocus
    />
    <Field component={RenderField} label="Last Name" name="lastName" />
    <Field
      component={RenderField}
      label="Email"
      name="email"
      normalize={normalize}
    />
    <Field
      component={RenderField}
      label="Homepage"
      name="link"
      normalize={normalize}
    />
    <Field
      component={RenderField}
      label="Role"
      name="role"
      normalize={normalize}
    />
    <Field
      component={RenderField}
      label="Employer"
      name="employer"
      normalize={normalize}
    />
    <Field
      component={RenderField}
      label="Description"
      name="description"
      normalize={normalize}
      multiline
    />
    <Field component={ImageField} name="image" width={300} height={300} />

    <Row between="xs">
      <Col>
        {update && <Button onClick={onUnlinkClick}>Unlink Expert</Button>}
      </Col>
      <Col>
        <Button onClick={handleDialog}>Cancel</Button>
        <Button type="submit" raised primary>
          {submitLabel}
        </Button>
      </Col>
    </Row>
  </form>
);

ExpertForm.propTypes = {
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  onUnlinkClick: PropTypes.func,
  update: PropTypes.bool,
  archived: PropTypes.bool,
};

export default reduxForm({
  form: "expert",
  validate,
})(ExpertForm);
