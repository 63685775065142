import { Button, Snackbar, Dropdown, DatePicker } from "react-toolbox";
import { graphql } from "react-apollo";
import { Col, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Markdown from "react-remarkable";
import Joi from "joi-browser";
import React, { useState } from "react";
import { useFeature } from "@growthbook/growthbook-react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import ImageField from "../image-field";
import RenderField from "../field";
import RenderCheckbox from "../checkbox";
import SubjectPicker from "../subject-picker";
import { UploadCodes } from "./upload-codes";
import currencyPicker from "../currency-picker";
import uploadCodes from "./upload-codes.graphql";

import styles from "./styles.css";

const schema = Joi.object({
  name: Joi.string().required(),
  email: Joi.string().allow(null).email(),
  imageTopAlign: Joi.string().allow(null).allow(null).uri(),
  imageCentreAlign: Joi.string().allow(null).allow(null).uri(),
  imageRectangular: Joi.string().allow(null).uri(),
  imageSquare: Joi.string().allow(null).uri(),
  description: Joi.string().allow(null),
  link: Joi.string().allow(null),
  commission: Joi.number().positive().allow([null, ""]),
  codeClaim: Joi.boolean().allow(null),
  codeClaimHelperText: Joi.string().allow([null, ""]),
  codeClaimOption: Joi.string().valid("single", "multi"),
  codes: Joi.array(),
  multiUseCode: Joi.object({
    value: Joi.string(),
    price: Joi.number().integer().min(0),
    expirationTime: Joi.date(),
    currency: Joi.string().length(3).default("GBP"),
  }).allow(null),
  subjects: Joi.array()
    .unique()
    .items(Joi.string().guid({ version: ["uuidv4"] }))
    .allow(null),
});

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const HelperTextFieldWithPreview = ({ input, ...props }) => {
  return (
    <div className={styles.helpertext}>
      <RenderField input={input} {...props} />

      {input.value && (
        <>
          <h4>Preview</h4>
          <div className={styles.preview}>
            <Markdown source={input.value} />
          </div>
        </>
      )}
    </div>
  );
};

HelperTextFieldWithPreview.propTypes = {
  input: PropTypes.object,
};

const SupplierForm = ({
  initialValues,
  uploadCodes,
  onArchiveClick,
  onUnlinkClick,
  handleDialog,
  handleSubmit,
  onSubmit,
  submitLabel,
  update,
  archived,
  codeClaimOption,
}) => {
  const [alert, setAlert] = useState({ message: null, show: false });
  const [loading, setLoading] = useState(false);
  const [displayUploadCodesInput, setDisplayUploadCodesInput] = useState(
    initialValues?.codeClaim
  );

  // TODO: remove this flag once the feature is ready
  const displayUploadCodesFlag = useFeature("self_service_resource_codes").on;

  const invokeUploadCodesMutation = async (inventory, codes) => {
    try {
      const { data } = await uploadCodes({ variables: { inventory, codes } });
      if (data) {
        setAlert({ message: "Success to upload codes 🎉", show: true });
      }
    } catch (error) {
      setAlert({ message: `Failure to upload codes`, show: true });
      throw error;
    }
  };

  const mapFormValuesToMutation = (onSubmit) => async ({ ...formValues }) => {
    const { codes, multiUseCode, ...form } = formValues;

    setLoading(true);

    if (codes?.length && form.codeClaimOption === "single") {
      try {
        await invokeUploadCodesMutation({ supplierId: form.id }, codes);
      } catch (error) {
        console.log(error);
        setLoading(false);
        return;
      }
    }

    try {
      await onSubmit({
        ...form,
        commission: form.commission ? form.commission / 100 : null,
        externalIds: {
          crm: form?.externalIds?.crm ? form.externalIds.crm : undefined,
        },
        multiUseCode:
          codeClaimOption === "multi"
            ? {
                value: multiUseCode.value,
                price: multiUseCode.price,
                currency: multiUseCode.currency,
                expirationTime: new Date(multiUseCode.expirationTime),
              }
            : null,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form
        className={styles.form}
        onSubmit={handleSubmit(mapFormValuesToMutation(onSubmit))}
      >
        <Field component={RenderField} label="Name" name="name" autoFocus />
        <Field component={RenderField} label="Email" name="email" />
        <Field component={RenderField} label="Homepage" name="link" />
        <Field name="subjects" component={SubjectPicker} />
        <Field
          component={RenderField}
          label="Commission"
          name="commission"
          hint="%"
        />
        <Field
          component={RenderField}
          label="CRM (Hubspot) ID"
          name="externalIds.crm"
        />
        <Field
          component={RenderField}
          label="Description"
          name="description"
          multiline
        />

        <h4>Fulfilment Configuration</h4>
        <Field
          component={RenderCheckbox}
          label="Enable self-service learner code claims"
          name="codeClaim"
          onChange={(_, value) => setDisplayUploadCodesInput(value)}
        />

        {initialValues?.id &&
        displayUploadCodesInput &&
        displayUploadCodesFlag ? (
          <div className={styles.codeBlock}>
            <span className={styles.codeBlockLabel}>
              Self-service learner code setup
            </span>
            <Field
              component={({ input, meta }) => (
                <Dropdown
                  value={input.value}
                  error={(meta.touched && meta.error) || ""}
                  source={[
                    { value: "single", label: "Single use codes" },
                    { value: "multi", label: "Multi-use codes" },
                  ]}
                  onChange={input.onChange}
                />
              )}
              name="codeClaimOption"
              label="Select type of codes"
            />

            {codeClaimOption === "single" && (
              <Field component={UploadCodes} name="codes" />
            )}
            {codeClaimOption === "multi" && (
              <>
                <Row around="sm">
                  <Col sm={6}>
                    <Field
                      component={RenderField}
                      label="Multi-use code"
                      name="multiUseCode.value"
                      required
                    />
                    <Field
                      component={RenderField}
                      label="Price"
                      name="multiUseCode.price"
                      parse={parse}
                      format={format}
                      required
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      component={({ input, ...props }) => (
                        <DatePicker {...input} {...props} autoOk />
                      )}
                      label="Expiration Time"
                      name="multiUseCode.expirationTime"
                      required
                    />

                    <Field
                      component={currencyPicker}
                      hasUpcomingCurrencies
                      label="Currency"
                      name="multiUseCode.currency"
                      required
                    />
                  </Col>
                </Row>
              </>
            )}
            <Field
              component={HelperTextFieldWithPreview}
              label="Code helper text (seen by user)"
              name="codeClaimHelperText"
              multiline
            />
          </div>
        ) : null}

        <Row between="xs">
          <p className={styles.info}>
            <em>
              Please save the logo in ONE field only: either “Image (Square)” or
              “Image (Rectangular)” depending on the original image’s
              ratio/dimensions.
            </em>
          </p>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              component={ImageField}
              height={200}
              name="imageRectangular"
              title="(Rectangular)"
              width={600}
            />
          </Col>
          <Col sm={6}>
            <Field
              component={ImageField}
              height={400}
              name="imageSquare"
              title="(Square)"
              width={400}
            />
          </Col>
        </Row>

        <Field
          component={ImageField}
          name="imageCentreAlign"
          width={160}
          height={160}
          isPNG
        />

        <Row between="xs">
          <Col>
            {update && <Button onClick={onUnlinkClick}>Unlink Supplier</Button>}
            {update && !archived && (
              <Button icon="archive" onClick={onArchiveClick} primary>
                Archive Supplier
              </Button>
            )}
          </Col>
          <Col>
            <Button onClick={handleDialog}>Cancel</Button>
            <Button type="submit" disabled={loading} raised primary>
              {submitLabel}
            </Button>
          </Col>
        </Row>
      </form>
      <Snackbar
        action="Dismiss"
        active={alert.show}
        label={alert.message}
        onTimeout={() => setAlert({ show: false, message: null })}
        timeout={3000}
        type="cancel"
      />
    </>
  );
};

SupplierForm.propTypes = {
  initialValues: PropTypes.object,
  uploadCodes: PropTypes.func,
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  onUnlinkClick: PropTypes.func,
  update: PropTypes.bool,
  archived: PropTypes.bool,
  onArchiveClick: PropTypes.func,
  codeClaimOption: PropTypes.string,
};

const selector = formValueSelector("supplier");

export default connect((state) => ({
  codeClaimOption: selector(state, "codeClaimOption"),
}))(
  graphql(uploadCodes, {
    name: "uploadCodes",
  })(
    reduxForm({
      form: "supplier",
      validate,
    })(SupplierForm)
  )
);
