import { Autocomplete } from "react-toolbox";
import React from "react";

import PropTypes from "prop-types";

const moment = require("moment-timezone");

const timezoneNames = moment.tz.names();
const timezones = {};

for (let i = 0; i < timezoneNames.length; i++) {
  if (timezoneNames[i] !== undefined) {
    timezones[timezoneNames[i]] = timezoneNames[i];
  }
}

const TimezonePicker = ({ input, meta }) => (
  <Autocomplete
    error={(meta.touched && meta.error) || ""}
    label="Timezone"
    multiple={false}
    onChange={input.onChange}
    showSuggestionsWhenValueIsSet
    source={timezones}
    value={input.value}
  />
);

TimezonePicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default TimezonePicker;
