import { Dialog, Button } from "react-toolbox";
import { graphql } from "react-apollo";
import { compose } from "redux";
import React, { useState } from "react";

import PropTypes from "prop-types";

import retryRequestQuery from "./retry-approved-request.graphql";

const RetryRequestDialog = ({
  active,
  retryRequestQuery,
  handleDialog,
  request,
}) => {
  const [error, setError] = useState(false);
  const [retrySucceeded, setRetrySucceeded] = useState(false);

  return (
    <Dialog
      active={active}
      onEscKeyDown={handleDialog}
      onOverlayClick={handleDialog}
      title="Retry Request"
    >
      {request && (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            retryRequestQuery({
              variables: {
                userId: request.user.id,
                requestId: request.id,
              },
            }).then((response) => {
              const {
                data: { retryApprovedRequest },
              } = response;

              if (retryApprovedRequest.success) {
                setRetrySucceeded(true);
              } else {
                setError(retryApprovedRequest.error);
              }

              return handleDialog;
            });
          }}
        >
          <span>
            Retrying the request will send the order to fulfilment to be
            purchased if it has not already been recieved.
          </span>
          {error && (
            <span>
              Request retry failed. Please contact engineering for technical
              support. Reason: {error}
            </span>
          )}
          {retrySucceeded && <span>Request retried succesfully.</span>}
          <div>
            <Button onClick={handleDialog}>Cancel</Button>
            <Button type="submit" raised primary disabled={error}>
              Retry Request
            </Button>
          </div>
        </form>
      )}
    </Dialog>
  );
};

RetryRequestDialog.propTypes = {
  active: PropTypes.bool,
  retryRequestQuery: PropTypes.func,
  handleDialog: PropTypes.func,
  request: PropTypes.shape({
    additionalNote: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      postcode: PropTypes.string,
      street: PropTypes.string,
    }),
    adhoc: PropTypes.bool,
    adhocInfo: PropTypes.string,
    approveNote: PropTypes.string,
    approvedAt: PropTypes.string,
    approvedBy: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    currency: PropTypes.string,
    hasFulfilmentAccount: PropTypes.bool,
    id: PropTypes.string,
    price: PropTypes.number,
    product: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
    requestNote: PropTypes.string,
    resource: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      commission: PropTypes.number,
      link: PropTypes.string,
      supplier: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
    totalCost: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.string,
      accessibleBudgets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      ),
      activeIndividualBudget: PropTypes.shape({
        id: PropTypes.string,
      }),
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      organisation: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
};

export default compose(
  graphql(retryRequestQuery, {
    name: "retryRequestQuery",
  })
)(RetryRequestDialog);
