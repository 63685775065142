import { Button, FontIcon, ProgressBar, Switch, Tooltip } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "react-apollo";
import Joi from "joi-browser";
import React, { useState } from "react";
import URL from "url-parse";
import moment from "moment";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import ContentRegionMismatch from "../content-region-mismatch";
import ContentRegionPicker from "../content-region-picker";
import Experts from "../experts";
import ImageField from "../image-field";
import OrganisationPicker from "../organisation-picker";
import OwnedByPicker from "../owned-by-picker";
import Products from "../products";
import RenderField from "../../components/field";
import RenderSwitch from "../switch";
import SubjectPicker from "../subject-picker";
import Supplier from "../supplier";
import TypePicker from "../type-picker";
import VideoField from "../video-field";
import Reviews from "../../containers/reviews";
import { reviewType } from "../../containers/reviews/reviews";

import resourceFormQuery from "./resource-form.graphql";

const schema = Joi.object({
  active: Joi.boolean().label("Active").required(),
  isFree: Joi.boolean().label("isFree"),
  title: Joi.string().max(150).label("Title").required(),
  summary: Joi.string().max(300).label("Summary").required(),
  description: Joi.string().max(10000).label("Description").required(),
  subjects: Joi.array()
    .unique()
    .items(
      Joi.string().guid({
        version: ["uuidv4"],
      })
    ),
  image: Joi.string().allow(null).uri(),
  image1x1: Joi.string().allow(null).uri(),
  image2x1: Joi.string().allow(null).uri(),
  image3x1: Joi.string().allow(null).uri(),
  image4x3: Joi.string().allow(null).uri(),
  image3x4: Joi.string().allow(null).uri(),
  ownedBy: Joi.string()
    .guid({ version: ["uuidv4"] })
    .allow(null),
  type: Joi.string()
    .guid({ version: ["uuidv4"] })
    .label("Resource Type")
    .required(),
  link: Joi.string().allow(null).uri(),
  embeddedVideo: Joi.string().allow(["", null]).uri(),
  supplier: Joi.object().allow(null).label("Supplier"),
  experts: Joi.array().allow(null).items(Joi.object()),
  enquirable: Joi.boolean().label("Enquirable"),
  enquireLabel: Joi.string().allow(null).max(300).label("Enquire Label"),
  allowedOrganisations: Joi.array()
    .unique()
    .items(
      Joi.string().guid({
        version: ["uuidv4"],
      })
    )
    .allow(null),
  disallowedOrganisations: Joi.array()
    .unique()
    .items(
      Joi.string().guid({
        version: ["uuidv4"],
      })
    )
    .allow(null),
  allowedCountries: Joi.array().items(Joi.string()),
  commission: Joi.number().positive().allow([null, ""]),
  isGlobal: Joi.boolean().label("Available for all countries?"),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const mapFormValuesToMutation = (onSubmit) => ({
  searchSupplier,
  searchExpert,
  ownedBy,
  ...data
}) => {
  const { isGlobal, ...resource } = data;

  onSubmit({
    ...resource,
    allowedCountries: isGlobal ? [] : resource.allowedCountries,
    ownedBy: ownedBy || null,
    embeddedVideo: resource.embeddedVideo || null,
    experts: resource.experts && resource.experts.map(({ id }) => id),
    supplier: resource.supplier && resource.supplier.id,
    commission: resource.commission ? resource.commission / 100 : null,
  });
};

const formatLink = (value) => {
  if (value.search("www.amazon.co.uk") !== -1) {
    const url = new URL(value);
    const baseUrl = "https://www.amazon.co.uk";
    const queryString =
      "?ie=UTF8&tag=learnerbly-21&camp=1634&creative=6738&linkCode=as2";
    let newUrl = `${baseUrl}${url.pathname}${queryString}`;
    return newUrl;
  }
  return value;
};

const TooltipIcon = Tooltip(FontIcon);

const normalize = (value) => value || null;

const ResourceForm = ({
  resourceId,
  products,
  data,
  handleSubmit,
  submitLabel,
  onSubmit,
  reviews,
  resource = {},
}) => {
  const [isGlobal, setIsGlobal] = useState(
    !resource.allowedCountries || resource.allowedCountries.length === 0
  );

  if (data.loading) {
    return <ProgressBar mode="indeterminate" />;
  }

  const { createdBy, updatedBy, createdAt, updatedAt, link } = resource;
  return (
    <form onSubmit={handleSubmit(mapFormValuesToMutation(onSubmit))}>
      <ContentRegionMismatch resource={resource} />
      <Row>
        <Col md={7} sm={12}>
          <Field
            component={RenderField}
            label="Title — Sentence Case"
            name="title"
            autoFocus
          />
          <Field
            component={RenderField}
            label="Summary"
            name="summary"
            multiline
          />
          <Field
            component={RenderField}
            label="Description"
            name="description"
            multiline
          />
          <Field name="subjects" component={SubjectPicker} />
          <Field
            component={VideoField}
            label="Video Url"
            name="embeddedVideo"
          />
          <Field
            component={RenderField}
            parse={formatLink}
            label="Resource Link"
            name="link"
          />
          <Row>
            <Col sm={6}>
              <Field
                component={ImageField}
                name="image3x1"
                title="3:1 (Unused)"
                width={600}
                height={200}
              />
            </Col>
            <Col sm={6}>
              <Field
                component={ImageField}
                name="image2x1"
                title="2:1 (Search card)"
                width={500}
                height={250}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field
                component={ImageField}
                name="image4x3"
                title="4:3 (Unused)"
                width={500}
                height={375}
              />
            </Col>
            <Col sm={6}>
              <Field
                component={ImageField}
                name="image1x1"
                title="1:1 (Recommendation email)"
                width={400}
                height={400}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field
                component={ImageField}
                name="image3x4"
                title="3:4 (Resource Page)"
                width={450}
                height={600}
              />
            </Col>
          </Row>
          <Field component={Supplier} name="supplier" />
          <Field component={Experts} name="experts" />
        </Col>
        <Col md={4} mdOffset={1} sm={12}>
          <Row>
            <Col sm={8}>
              {resourceId && (
                <div>
                  <h4>About</h4>
                  <p>
                    <a
                      target="_blank"
                      href={`${process.env.APP_URL}/resources/${resourceId}/`}
                      rel="noopener noreferrer"
                    >
                      <Button icon="link" raised>
                        View in app
                      </Button>
                    </a>
                  </p>
                  {createdBy && (
                    <p>
                      Created by{" "}
                      {`${createdBy.firstName} ${createdBy.lastName}`}
                      <br />
                      <small>
                        on{" "}
                        {`${moment(createdAt).format("MMMM Do YYYY, h:mm a")}`}
                      </small>
                    </p>
                  )}
                  {updatedBy && (
                    <p>
                      Last updated by{" "}
                      {`${updatedBy.firstName} ${updatedBy.lastName}`}
                      <br />
                      <small>
                        on{" "}
                        {`${moment(updatedAt).format("MMMM Do YYYY, h:mm a")}`}
                      </small>
                    </p>
                  )}
                </div>
              )}
            </Col>
            <Col sm={8}>
              <Field
                name="active"
                component={({ input, ...props }) => (
                  <Switch
                    checked={Boolean(input.value)}
                    onChange={input.onChange}
                    {...props}
                  />
                )}
                label="Resource Active"
              />
            </Col>
            <Col sm={4}>
              <TooltipIcon
                tooltip="Active Resources are shown in Search and Recommendations.
                         Non Active Resources are still shown in user histories"
                value="help"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Field
                name="isFree"
                component={({ input, ...props }) => (
                  <Switch
                    checked={Boolean(input.value)}
                    onChange={input.onChange}
                    {...props}
                  />
                )}
                label="Resource is free"
              />
            </Col>
            <Col sm={4}>
              <TooltipIcon
                tooltip="Free Resources help us in filter Search and Recommendations."
                value="help"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Field
                name="enquirable"
                component={({ input, ...props }) => (
                  <Switch
                    checked={Boolean(input.value)}
                    onChange={input.onChange}
                    {...props}
                  />
                )}
                label="Resource Enquirable"
              />
            </Col>
            <Col sm={4}>
              <TooltipIcon
                tooltip="Determine whether a user can enquire about the resource."
                value="help"
              />
            </Col>
          </Row>
          <Field
            component={RenderField}
            label="Label for enquire action"
            name="enquireLabel"
            normalize={normalize}
          />
          <Row>
            <Col sm={12}>
              <Field
                name="type"
                component={TypePicker}
                resourceTypes={data.resourceTypes}
              />
            </Col>
          </Row>
          <Field
            component={RenderField}
            label="Commission"
            hint="%"
            name="commission"
          />
          <h4>Owned By (Don’t Use)</h4>
          <Field name="ownedBy" component={OwnedByPicker} />
          <h4>Allowed Organisations</h4>
          <Field
            component={OrganisationPicker}
            name="allowedOrganisations"
            multiple
          />
          <h4>Allowed Countries</h4>
          <Field
            component={RenderSwitch}
            label="Available for all countries?"
            name="isGlobal"
            onChange={(_, value) => setIsGlobal(value)}
          />
          {!isGlobal && (
            <Field
              component={ContentRegionPicker}
              name="allowedCountries"
              onChange={() => setIsGlobal(false)}
              multiple
            />
          )}
          <h4>Disallowed Organisations</h4>
          <Field
            component={OrganisationPicker}
            name="disallowedOrganisations"
            multiple
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Button type="submit" raised primary>
            {submitLabel}
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={12}>
          {resourceId && (
            <Products
              supplier={resource.supplier}
              resourceId={resourceId}
              resourceLink={link}
              products={products}
            />
          )}
          {!resourceId && <p>Create resource first to add products</p>}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={12}>
          {resourceId && <Reviews resourceId={resourceId} reviews={reviews} />}
        </Col>
      </Row>
    </form>
  );
};

export const productType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  active: PropTypes.bool,
  amazonASIN: PropTypes.string,
  isbn: PropTypes.string,
  country: PropTypes.string,
  currency: PropTypes.string,
  description: PropTypes.string,
  isGlobal: PropTypes.bool,
  link: PropTypes.string,
  price: PropTypes.number,
});

ResourceForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  resourceId: PropTypes.string,
  products: PropTypes.arrayOf(productType),
  reviews: PropTypes.arrayOf(reviewType),
  data: PropTypes.object,
  resource: PropTypes.object,
  type: PropTypes.string,
};

const form = reduxForm({
  form: "resource",
  validate,
})(ResourceForm);

const selector = formValueSelector("resource");

export default compose(
  graphql(resourceFormQuery, {}),
  connect((state, { data }) => {
    const typeId = selector(state, "type");
    const type =
      typeId &&
      data &&
      data.resourceTypes &&
      data.resourceTypes.find((type) => type.id === typeId);
    return {
      type: type && type.name.replace(/ /g, "_").toLowerCase(),
    };
  })
)(form);
