export const permittedContentRegions = [
  "CA", // Canada
  "DE", // Germany
  "ES", // Spain
  "NL", // Netherlands
  "SE", // Sweden
  "GB", // United Kingdom
  "QQ", // Rest of the World
  "US", // United States of America
];
