import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import suppliersQuery from "../../containers/suppliers/suppliers.graphql";

import archiveSupplierQuery from "./archive-supplier.graphql";

const onSubmit = (id, archiveSupplier, handleDialog) => () =>
  archiveSupplier({
    variables: { id },
  }).then(handleDialog);

const ArchiveSupplierDialog = ({
  active,
  id,
  handleDialog,
  archiveSupplier,
}) => (
  <Dialog
    actions={[
      { label: "Nooo, I'll think about it", onClick: handleDialog },
      {
        label: "Sure, archive it",
        onClick: onSubmit(id, archiveSupplier, handleDialog),
        raised: true,
        primary: true,
      },
    ]}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
    title="You can not reverse this action"
  />
);

ArchiveSupplierDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  archiveSupplier: PropTypes.func,
  id: PropTypes.string,
};

export default graphql(archiveSupplierQuery, {
  name: "archiveSupplier",
  options: {
    update: (proxy, { data: { archiveSupplier } }) => {
      const data = proxy.readQuery({ query: suppliersQuery });
      data[
        data.suppliers.findIndex((item) => item.id === archiveSupplier.id)
      ] = archiveSupplier;
      proxy.writeQuery({ query: suppliersQuery, data });
    },
  },
})(ArchiveSupplierDialog);
