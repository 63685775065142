import { Autocomplete } from "react-toolbox";
import React from "react";
import currencies from "currency-codes/data";

import PropTypes from "prop-types";

const CURRENCY_OPTIONS = ["GBP", "USD", "EUR", "SEK"];
const UPCOMING_CURRENCY_OPTIONS = ["CAD", "ILS"];

const currentCurrencies = currencies
  .filter(({ code }) => CURRENCY_OPTIONS.includes(code))
  .map(({ code, currency }) => ({ [code]: currency }));

const upcomingCurrencies = currencies
  .filter(({ code }) => UPCOMING_CURRENCY_OPTIONS.includes(code))
  .map(({ code, currency }) => ({ [code]: currency }));

const CurrencyPicker = ({
  hasUpcomingCurrencies = false,
  input,
  meta,
  label,
  required,
}) => {
  const source = hasUpcomingCurrencies
    ? Object.assign({}, ...currentCurrencies, ...upcomingCurrencies)
    : Object.assign({}, ...currentCurrencies);

  return (
    <Autocomplete
      label={label}
      value={input.value}
      source={source}
      onChange={input.onChange}
      error={meta.error}
      multiple={false}
      showSuggestionsWhenValueIsSet
      required={required ?? false}
    />
  );
};

CurrencyPicker.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  data: PropTypes.shape({
    loading: PropTypes.bool,
    subjects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  hasUpcomingCurrencies: PropTypes.bool,
  required: PropTypes.bool,
};

export default CurrencyPicker;
