import Autocomplete from "react-toolbox/lib/autocomplete";
import PropTypes from "prop-types";
import React from "react";

const source = {
  ET3D6B60851944AF99: "Engineering Manager",
  ET663F9D799CF2319F: "Full Stack Engineer",
  ET56010D0161688A1F: "Head of Engineering",
  ET7611514DEEA8C27E: "Learning Associate",
  ET32D668C2FA197464: "Learning Program Manager",
  ETACA2D69807106832: "Partnership Lead",
  ETDEB70795D2442A72: "Parnership Manager",
  ETF84DB74E67078107: "Platform Engineer",
  ETAA6FBD39376F5BBC: "Product Manager",
  ETC28AA42A6309938E: "Technical Lead",
};

const RolePicker = ({
  input: { onChange, value },
  label = "Choose a role",
  meta: { error, touched },
  multiple = false,
}) => {
  return (
    <Autocomplete
      error={touched && error}
      label={label}
      multiple={multiple}
      onChange={(newValue) => onChange(newValue)}
      showSuggestionsWhenValueIsSet
      source={source}
      value={value}
    />
  );
};

RolePicker.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  multiple: PropTypes.bool,
};

export default RolePicker;
