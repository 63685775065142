import { Col, Row } from "react-flexbox-grid";
import {
  Button,
  ProgressBar,
  Card,
  CardTitle,
  CardActions,
} from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import PurchaseRequestDialog from "../../components/purchase-request-dialog";

import requestsQuery from "./outdated-events.graphql";

class Requests extends React.Component {
  constructor(props) {
    super(props);

    this.handlePurchaseDialog = this.handlePurchaseDialog.bind(this);
    this.onPurchaseClick = this.onPurchaseClick.bind(this);

    this.state = {
      purchase: false,
    };
  }

  handlePurchaseDialog() {
    this.setState({
      purchase: !this.state.purchase,
    });
  }

  onPurchaseClick(request) {
    return () => {
      this.setState({
        purchase: true,
        purchaseRequest: request,
      });
    };
  }

  render() {
    const {
      data: { outdatedEvents, loading },
    } = this.props;

    if (loading) return <ProgressBar mode="indeterminate" />;

    const events =
      outdatedEvents &&
      outdatedEvents.length > 0 &&
      outdatedEvents.filter(
        (event) => event.resource.active && event.type !== "internalEvent"
      );

    return (
      <div>
        <h3>{events.length} Outdated Events</h3>
        <Row between="xs">
          <Col sm={12} lg={8}>
            {events &&
              events.map((event, index) => (
                <Card style={{ margin: "0 0 40px 0" }} key={index}>
                  <CardTitle
                    title={event.resource.title}
                    subtitle={event.description}
                  />
                  <CardActions>
                    {
                      <Link to={`/resources/${event.resource.id}/`}>
                        <Button label="View resource" />
                      </Link>
                    }
                  </CardActions>
                </Card>
              ))}
          </Col>
        </Row>
        <PurchaseRequestDialog
          active={this.state.purchase}
          handleDialog={this.handlePurchaseDialog}
          request={this.state.purchaseRequest}
        />
      </div>
    );
  }
}

Requests.propTypes = {
  data: PropTypes.object,
};

export default graphql(requestsQuery)(Requests);
