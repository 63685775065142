import { Dialog, DialogContent } from "@material-ui/core";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import SupplierForm from "../supplier-form";

import createSupplier from "./create-supplier.graphql";

const onSubmit = (createSupplier, onSuccess, handleDialog) => (data) =>
  createSupplier({
    variables: { supplier: data },
  }).then(({ data }) => {
    onSuccess(data.createSupplier);
    handleDialog();
  });

const CreateSupplierDialog = ({
  active,
  handleDialog,
  onSuccess,
  createSupplier,
}) => (
  <Dialog
    onClose={handleDialog}
    open={active}
    container={() => document.getElementById("root")}
  >
    <DialogContent>
      <SupplierForm
        submitLabel="Create Supplier"
        handleDialog={handleDialog}
        onSubmit={onSubmit(createSupplier, onSuccess, handleDialog)}
      />
    </DialogContent>
  </Dialog>
);

CreateSupplierDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  createSupplier: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default graphql(createSupplier, {
  name: "createSupplier",
})(CreateSupplierDialog);
