import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { change, Field, reduxForm } from "redux-form";
import { codes } from "currency-codes";
import Joi from "joi-browser";
import React, { useState } from "react";

import PropTypes from "prop-types";

import moment from "moment-timezone";

import TimezonePicker from "../timezone-picker";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import ContentRegionPicker from "../content-region-picker";
import CurrencyPicker from "../currency-picker";
import RenderDateTime from "../datetime";
import RenderField from "../field";
import RenderSwitch from "../switch";

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const parseNull = (value) => value || null;

const currencyCodes = codes();
currencyCodes.push("LBC");

const schema = Joi.object({
  price: Joi.number().integer().min(0).required(),
  startDate: Joi.date().iso().raw().required(),
  endDate: Joi.date().iso().min(Joi.ref("startDate")).raw().allow(null),
  city: Joi.string().allow(null),
  street: Joi.string().allow(null),
  postcode: Joi.string().allow(null),
  moreInfo: Joi.string().allow(null),
  description: Joi.string().allow(null),
  timezone: Joi.string().valid(moment.tz.names()),
  active: Joi.boolean().label("Active"),
  currency: Joi.string()
    .length(3)
    .allow(currencyCodes)
    .default("GBP")
    .disallow(null),
  country: Joi.string().length(2).allow(null).default(null),
  isGlobal: Joi.boolean().label("Available for all countries?"),
  link: Joi.string().required(),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const CreateEventForm = ({
  dispatch,
  handleDialog,
  handleSubmit,
  initialValues,
  onSubmit,
}) => {
  const [isGlobal, setIsGlobal] = useState(initialValues.isGlobal);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm={4}>
          <Field
            component={RenderField}
            label="Price"
            name="price"
            parse={parse}
            format={format}
            autoFocus
          />
        </Col>
        <Col sm={8}>
          <Field
            component={RenderField}
            label="Street"
            name="street"
            parse={parseNull}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Field
            component={RenderField}
            label="Postcode"
            name="postcode"
            parse={parseNull}
          />
        </Col>
        <Col sm={8}>
          <Field
            component={RenderField}
            label="City"
            name="city"
            parse={parseNull}
          />
        </Col>
      </Row>
      <Field
        component={RenderDateTime}
        label="Start Date"
        name="startDate"
        onChange={(_, date) =>
          dispatch(
            change(
              "event",
              "endDate",
              moment(date).add(1, "hour").utc().format()
            )
          )
        }
      />
      <Field component={RenderDateTime} label="End Date" name="endDate" />
      <Field name="timezone" label="Timezone" component={TimezonePicker} />
      <Field
        component={RenderField}
        label="Description"
        name="description"
        parse={parseNull}
      />
      <Field
        component={RenderField}
        label="More Info"
        name="moreInfo"
        parse={parseNull}
        multiline
      />
      <Field
        component={RenderSwitch}
        label="Available for all countries?"
        name="isGlobal"
        onChange={(_, value) => {
          if (value) {
            dispatch(change("event", "country", null));
          }

          setIsGlobal(value);
        }}
      />
      {!isGlobal && (
        <Field
          name="country"
          label="Allowed country (Can not be changed after creation)"
          component={ContentRegionPicker}
        />
      )}
      <Field
        component={CurrencyPicker}
        hasUpcomingCurrencies
        label="Currency (Can not be changed after creation)"
        name="currency"
      />
      <Field component={RenderField} label="Product URL" name="link" />
      <Row between="xs">
        <Col>
          <Field component={RenderSwitch} label="Active" name="active" />
        </Col>
        <Col>
          <Button onClick={handleDialog}>Cancel</Button>
          <Button type="submit" raised primary>
            Add Event
          </Button>
        </Col>
      </Row>
    </form>
  );
};

CreateEventForm.propTypes = {
  dispatch: PropTypes.func,
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default reduxForm({
  form: "event",
  validate,
})(CreateEventForm);
