import { ProgressBar, Tabs, Tab } from "react-toolbox";
import { graphql } from "react-apollo";
import { compose } from "redux";
import React from "react";

import PropTypes from "prop-types";

import Alert from "@material-ui/lab/Alert";

import { GrowthBookProvider } from "../../utils/growthbook/growthbook";

import ConnectedStripeAccount from "../../components/connected-stripe-account";
import OrganisationGroups from "../../components/organisation-groups";
import OrganisationOffices from "../../components/organisation-offices";
import OrganisationUsers from "../../components/organisation-users";
import OrganisationUsersCSV from "../../components/organisation-users-csv";
import UpdateOrganisation from "../../components/update-organisation";
import UpdateFulfilment from "../../components/update-fulfilment";
import ShowAPIKey from "../../components/show-api-key";
import ShowSSO from "../../components/show-single-sign-on";

import organisationQuery from "./organisation.graphql";

class Organisation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: parseInt(props.match.params.tab, 10) || 0,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(index) {
    this.setState({ index });
    if (index === 0) {
      this.props.history.push(
        `/organisations/${this.props.match.params.organisationId}/`
      );
      return;
    }
    this.props.history.push(
      `/organisations/${this.props.match.params.organisationId}/${index}/`
    );
  }

  render() {
    const { currentUser, organisationQuery } = this.props;

    if (organisationQuery.loading) {
      return <ProgressBar mode="indeterminate" />;
    }

    const { organisation } = organisationQuery;

    const updateOrganisationObject = ({
      enabledFulfilledByLearnerRequestTypes,
      ...organisation
    }) => {
      return {
        ...organisation,
        hasFulfilledByLearnerViaExpensesRequests: enabledFulfilledByLearnerRequestTypes.includes(
          "EXPENSES"
        ),
        hasFulfilledByLearnerRequests:
          enabledFulfilledByLearnerRequestTypes.includes("VIRTUAL_CARD") ||
          organisation.hasFulfilledByLearnerRequests,
      };
    };

    return (
      <>
        {organisation.archived && (
          <Alert severity="error">
            <b>This organisation is archived!</b> Its users will not be able to
            log in.
          </Alert>
        )}
        {(!organisation.fulfilmentAccounts ||
          !organisation.fulfilmentAccounts.length) && (
          <Alert severity="error">
            No fulfilment account set! Please set at least one in order to
            create users and budgets.
          </Alert>
        )}
        <Tabs index={this.state.index} onChange={this.handleTabChange}>
          <Tab label="Update Organisation">
            <GrowthBookProvider organisationId={organisation.id}>
              <UpdateOrganisation
                currentUser={currentUser}
                organisation={updateOrganisationObject(
                  organisationQuery.organisation
                )}
                fetchMoreAccountBudgets={organisationQuery.fetchMore}
              />
            </GrowthBookProvider>
          </Tab>
          <Tab label="Groups">
            <OrganisationGroups
              organisationId={organisationQuery.organisation.id}
            />
          </Tab>
          <Tab label="Offices">
            <OrganisationOffices
              organisationId={organisationQuery.organisation.id}
            />
          </Tab>
          <Tab label="Send Invite">
            <OrganisationUsers
              organisationId={organisationQuery.organisation.id}
            />
          </Tab>
          <Tab label="Users CSV">
            <OrganisationUsersCSV
              organisationId={organisationQuery.organisation.id}
            />
          </Tab>
          <Tab label="Fulfilment">
            <UpdateFulfilment organisation={organisationQuery.organisation} />
          </Tab>
          <Tab label="Integrations">
            <ShowSSO organisationId={organisationQuery.organisation.id} />
            <ShowAPIKey organisationId={organisationQuery.organisation.id} />
          </Tab>
          <Tab label="Stripe">
            <ConnectedStripeAccount
              connectedStripeAccountId={
                organisationQuery.organisation.connectedStripeAccountId
              }
              organisationId={organisationQuery.organisation.id}
            />
          </Tab>
        </Tabs>
      </>
    );
  }
}

Organisation.propTypes = {
  currentUser: PropTypes.shape({
    admin: PropTypes.bool,
    email: PropTypes.string,
    featureToggles: PropTypes.arrayOf(PropTypes.string),
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
  }),
  organisationQuery: PropTypes.shape({
    error: PropTypes.bool,
    fetchMore: PropTypes.func,
    loading: PropTypes.bool,
    networkStatus: PropTypes.number,
    organisation: PropTypes.shape({
      id: PropTypes.id,
      adhocRequests: PropTypes.bool,
      hasFunds: PropTypes.bool,
      analyticsDashboard: PropTypes.bool,
      archived: PropTypes.bool,
      budgetPeriods: PropTypes.arrayOf(
        PropTypes.shape({
          endDate: PropTypes.string,
          id: PropTypes.string,
          name: PropTypes.string,
          numberOfBudgets: PropTypes.number,
          startDate: PropTypes.string,
        })
      ),
      connectedStripeAccountId: PropTypes.string,
      defaultUserBudgets: PropTypes.arrayOf(
        PropTypes.shape({
          total: PropTypes.number,
          currency: PropTypes.string,
          proRata: PropTypes.bool,
        })
      ),
      fulfilmentAccounts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          internalEvents: PropTypes.bool,
          internalLearning: PropTypes.bool,
          isInProofOfValue: PropTypes.bool,
        })
      ),
      hasAmazonRequests: PropTypes.bool,
      hasFulfilledByLearnerRequests: PropTypes.bool,
      enabledFulfilledByLearnerRequestTypes: PropTypes.arrayOf(
        PropTypes.oneOf(["VIRTUAL_CARDS", "EXPENSES"])
      ),
      name: PropTypes.string,
      oktaInstallation: PropTypes.bool,
      recentActivity: PropTypes.bool,
      singleSignOn: PropTypes.bool,
      singleSignOnCustomProvider: PropTypes.string,
      singleSignOnProviderType: PropTypes.oneOf(["Google", "Okta", "SAML"]),
      slackInstallation: PropTypes.bool,
      testClient: PropTypes.bool,
      wallets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          currency: PropTypes.string,
        })
      ),
    }),
    refetch: PropTypes.func,
    startPolling: PropTypes.func,
    stopPolling: PropTypes.func,
    subscribeToMore: PropTypes.func,
    updateQuery: PropTypes.func,
    variables: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      organisationId: PropTypes.string,
      tab: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  history: PropTypes.shape({
    action: PropTypes.string,
    block: PropTypes.func,
    createHref: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    length: PropTypes.number,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.string,
      key: PropTypes.string,
    }),
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
};

export default compose(
  graphql(organisationQuery, {
    name: "organisationQuery",
    options: ({ match }) => ({
      variables: {
        id: match.params.organisationId,
        accountBudgetStatusFilter: ["ACTIVE", "SCHEDULED", "ARCHIVED"],
        first: 5,
      },
    }),
  })
)(Organisation);
