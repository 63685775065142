import {
  GrowthBook,
  GrowthBookProvider as RawGrowthBookProvider,
} from "@growthbook/growthbook-react";
import React, { FunctionComponent, useEffect } from "react";

export const growthBook = new GrowthBook();

export const fetchFeatureConfig = (
  growthBookKey = process.env.GROWTHBOOK_KEY
): void => {
  fetch(`https://cdn.growthbook.io/api/features/${growthBookKey}`, {
    cache: "reload",
  })
    .then((res) => res.json())
    .then((config) => {
      growthBook.setFeatures(config.features);
    });
};

export const GrowthBookProvider: FunctionComponent<{
  organisationId: string | null;
}> = ({ children, organisationId }) => {
  useEffect(() => {
    growthBook.setAttributes({
      url: process.env.ADMIN_URL,
    });

    if (organisationId) {
      growthBook.setAttributes({ organisationId });
    }
  });

  useEffect(() => {
    fetchFeatureConfig();
  }, []);

  return (
    <RawGrowthBookProvider growthbook={growthBook}>
      {children}
    </RawGrowthBookProvider>
  );
};
