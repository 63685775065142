import { DialogContent } from "@material-ui/core";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import SupplierForm from "../supplier-form";
import { GrowthBookProvider } from "../../utils/growthbook/growthbook";

import updateSupplier from "./update-supplier.graphql";
import { StyledDialog } from "./StyledDialog";
import moment from "moment";

const onSubmit = (id, updateSupplier, onSuccess, handleDialog) => ({
  name,
  email,
  imageTopAlign,
  imageCentreAlign,
  imageRectangular,
  imageSquare,
  description,
  link,
  commission,
  subjects,
  externalIds,
  codeClaim,
  codeClaimHelperText,
  codeClaimOption,
  multiUseCode,
}) =>
  updateSupplier({
    variables: {
      supplier: {
        name,
        email,
        imageTopAlign,
        imageCentreAlign,
        imageRectangular,
        imageSquare,
        description,
        link,
        commission,
        subjects,
        externalIds,
        codeClaim,
        codeClaimHelperText,
        codeClaimOption,
        multiUseCode,
      },
      id,
    },
  }).then(({ data }) => {
    onSuccess(data.updateSupplier);
    handleDialog();
  });

const unLink = (onSuccess, handleDialog) => () => {
  onSuccess(null);
  handleDialog();
};

const mapQueryToInitialValues = ({ commission, subjects, ...data }) => ({
  ...data,
  commission: commission && Math.round(commission * 100),
  subjects: subjects ? subjects.map(({ id }) => id) : [],
  codeClaimOption: data.codeClaimOption ?? "single",
  multiUseCode: {
    ...data.multiUseCode,
    expirationTime: data.multiUseCode?.expirationTime
      ? new Date(data.multiUseCode?.expirationTime)
      : moment(new Date()).add(1, "year").toDate(),
  },
});

const UpdateSupplierDialog = ({
  active,
  onArchiveClick,
  handleDialog,
  onSuccess,
  updateSupplier,
  supplier,
}) => (
  <StyledDialog
    open={active}
    onClose={handleDialog}
    container={() => document.getElementById("root")}
  >
    <GrowthBookProvider>
      <DialogContent>
        <SupplierForm
          submitLabel="Update Supplier"
          handleDialog={handleDialog}
          initialValues={mapQueryToInitialValues(supplier)}
          onSubmit={onSubmit(
            supplier.id,
            updateSupplier,
            onSuccess,
            handleDialog
          )}
          onUnlinkClick={unLink(onSuccess, handleDialog)}
          onArchiveClick={onArchiveClick(supplier.id)}
          update
        />
      </DialogContent>
    </GrowthBookProvider>
  </StyledDialog>
);

UpdateSupplierDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  updateSupplier: PropTypes.func,
  onSuccess: PropTypes.func,
  supplier: PropTypes.object,
  onArchiveClick: PropTypes.func,
};

export default graphql(updateSupplier, {
  name: "updateSupplier",
})(UpdateSupplierDialog);
