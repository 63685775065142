import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";
import moment from "moment";

import PropTypes from "prop-types";

import CreateEventForm from "../create-event-form";

import addEventQuery from "./add-event.graphql";

import theme from "./theme.css";

const onSubmit = (addEvent, handleDialog, resourceId) => ({
  active,
  price,
  city,
  startDate,
  endDate,
  moreInfo,
  description,
  street,
  postcode,
  currency,
  timezone,
  country,
  link,
}) =>
  addEvent({
    variables: {
      event: {
        location: {
          city,
          street,
          postcode,
        },
        moreInfo,
        currency,
        country,
        description,
        price,
        startDate: moment
          .tz(moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
          .utc()
          .format(),
        endDate:
          endDate &&
          moment
            .tz(moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
            .utc()
            .format(),
        timezone,
        active,
        link,
      },
      resourceId,
    },
  }).then(handleDialog);

const AddEventModal = ({
  active,
  addEvent,
  handleDialog,
  resourceId,
  resourceLink,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <CreateEventForm
      handleDialog={handleDialog}
      onSubmit={onSubmit(addEvent, handleDialog, resourceId)}
      initialValues={{
        active: true,
        timezone: "Europe/London",
        startDate: moment().utc().set("hour", 9).set("minutes", 0).format(),
        currency: "GBP",
        isGlobal: true,
        link: resourceLink,
      }}
    />
  </Dialog>
);

AddEventModal.propTypes = {
  active: PropTypes.bool,
  addEvent: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  resourceLink: PropTypes.string,
};

export default graphql(addEventQuery, {
  name: "addEvent",
})(AddEventModal);
