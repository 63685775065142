import React, { FunctionComponent } from "react";
import { Button, Input, ProgressBar, List, ListItem } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { formatResourceSearchResult } from "../../utils/text-display";

import { SEARCH_RESOURCES_QUERY } from "./search-resources.graphql";

const Results: FunctionComponent<{ term: string }> = ({ term }) => {
  const { data, error, loading } = useQuery(SEARCH_RESOURCES_QUERY, {
    variables: {
      query: term,
    },
  });

  if (loading) {
    return <ProgressBar mode="indeterminate" />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (data.resources.results.length === 0) {
    return <div>No results</div>;
  }

  return (
    <List>
      {data.resources.results.map((result) => (
        <Link to={`/resources/${result.id}/`} key={result.id}>
          <ListItem
            rightIcon="edit"
            caption={formatResourceSearchResult(result)}
          />
        </Link>
      ))}
    </List>
  );
};

const SelectInput: FunctionComponent<{
  input: {
    value: string;
  };
  name: string;
}> = ({ input, name }) => (
  <div>
    <Input {...input} label="Search" name={name} />
    <Results term={input.value} />
  </div>
);

const Resources: FunctionComponent<
  InjectedFormProps<{
    query: string;
  }>
> = ({ handleSubmit }) => {
  return (
    <div>
      <Row>
        <Col sm={8}>
          <Form onSubmit={handleSubmit((x) => x)}>
            <Field component={SelectInput} name="query" />
          </Form>
        </Col>
        <Col sm={2} smOffset={1}>
          <Link to="/resources/create/">
            <Button
              icon="add"
              label="New Resource"
              raised
              primary
              style={{ marginBottom: "8px" }}
            />
          </Link>
          <Link to="/resources/create-amazon/">
            <Button icon="add" label="New Amazon Resource" raised />
          </Link>
          <div style={{ paddingTop: "20px" }}>
            <Link to="/resources/outdated-events/">
              <Button icon="update" label="View Outdated Events" />
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default reduxForm({
  form: "searchResources",
  initialValues: { query: "" },
})(Resources);
