import { Dropdown } from "react-toolbox";
import React from "react";

import PropTypes from "prop-types";

const SubjectPicker = ({ input, meta, resourceTypes = [] }) => (
  <Dropdown
    value={input.value}
    error={(meta.touched && meta.error) || ""}
    label="Type"
    onChange={input.onChange}
    source={resourceTypes.map(({ id, name }) => ({
      value: id,
      label: name,
    }))}
  />
);

SubjectPicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  resourceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default SubjectPicker;
