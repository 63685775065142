/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `ASIN` scalar type represents the ASIN code for each Amazon product */
  ASIN: { input: any; output: any; }
  /** The `CountryCode` scalar type represents ISO two-letter country codes as specified by [ISO](https://www.iso.org/iso-3166-country-codes.html). */
  CountryCode: { input: any; output: any; }
  /** The `CurrencyCode` scalar type represents ISO currency codes values as specified by [ISO](https://www.iso.org/iso-4217-currency-codes.html). */
  CurrencyCode: { input: any; output: any; }
  Cursor: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** The `ExternalID` scalar type represents some external ID that is not used for querying against Learnerbly entities */
  ExternalID: { input: any; output: any; }
  Locale: { input: any; output: any; }
  SSOCustomProvider: { input: any; output: any; }
};

export type AccountBudget = {
  __typename?: 'AccountBudget';
  associatedPots?: Maybe<Array<Pot>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currency: Scalars['CurrencyCode']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  funds: AccountBudgetFunds;
  id: Scalars['String']['output'];
  maxSpend?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<AccountBudgetStatus>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type AccountBudgetConnection = {
  __typename?: 'AccountBudgetConnection';
  edges: Array<AccountBudgetEdge>;
  pageInfo: PageInfo;
};

export type AccountBudgetEdge = {
  __typename?: 'AccountBudgetEdge';
  cursor: Scalars['Cursor']['output'];
  node: AccountBudget;
};

export type AccountBudgetFunds = {
  __typename?: 'AccountBudgetFunds';
  allocated: Scalars['Float']['output'];
  available: Scalars['Float']['output'];
  totalSpend: Scalars['Float']['output'];
};

export type AccountBudgetInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  maxSpend: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  status?: InputMaybe<AccountBudgetStatus>;
};

export enum AccountBudgetStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Scheduled = 'SCHEDULED',
  Suspended = 'SUSPENDED'
}

export type AccountBudgetsFilters = {
  status?: InputMaybe<Array<AccountBudgetStatus>>;
};

export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  edges: Array<PotActivityEdge>;
  pageInfo: PageInfo;
};

export type ActivityResource = AdhocInfo | Resource;

export type AdhocInfo = {
  __typename?: 'AdhocInfo';
  author?: Maybe<Scalars['String']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isAmazon?: Maybe<Scalars['Boolean']['output']>;
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AdhocRequest = {
  additionalNote?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<DeliveryAddressRequest>;
  amazonASIN?: InputMaybe<Scalars['ASIN']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['CurrencyCode']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isAmazon?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  link: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  requestNote?: InputMaybe<Scalars['String']['input']>;
  resourceTypeId: Scalars['ID']['input'];
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title: Scalars['String']['input'];
  user: Scalars['ID']['input'];
};

export type AnalyticsDashboardInput = {
  identifier: Scalars['String']['input'];
};

export type AnalyticsDashboardResult = MetabaseDashboard | NotFoundError;

export type ApprovePotRequestInput = {
  approveNote?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['ID']['input'];
};

export type ApproveRequest = {
  approveNote?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  selectedBudgetId?: InputMaybe<Scalars['ID']['input']>;
  user: Scalars['ID']['input'];
};

export type ApproverAnalytics = {
  __typename?: 'ApproverAnalytics';
  mostActiveEmployees?: Maybe<Array<KeyCountValue>>;
  requestsByResourceType?: Maybe<Array<KeyCountValue>>;
  requestsValueGraph?: Maybe<Array<RequestsValueGraph>>;
  totalRequests?: Maybe<Scalars['Int']['output']>;
  totalRequestsValue?: Maybe<Scalars['Int']['output']>;
  userInvitationAcceptance?: Maybe<Scalars['Float']['output']>;
  userTopTopics?: Maybe<Array<KeyCountValue>>;
};

export type ApproverNote = {
  __typename?: 'ApproverNote';
  id: Scalars['ID']['output'];
  note: Scalars['String']['output'];
};

export enum ApproverRequestStatus {
  Approved = 'approved',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Purchased = 'purchased',
  Rejected = 'rejected'
}

export type Assessment = {
  __typename?: 'Assessment';
  id: Scalars['ID']['output'];
};

export enum AssociateCountryCode {
  Ca = 'CA',
  De = 'DE',
  Es = 'ES',
  Gb = 'GB',
  Nl = 'NL',
  Se = 'SE',
  Us = 'US'
}

export type Attachment = S3File | StringBox;

export enum AuthMethod {
  CognitoPassword = 'COGNITO_PASSWORD',
  LegacyPassword = 'LEGACY_PASSWORD',
  MagicLink = 'MAGIC_LINK',
  Sso = 'SSO'
}

export type Book = Product & {
  __typename?: 'Book';
  active: Scalars['Boolean']['output'];
  amazonASIN?: Maybe<Scalars['ID']['output']>;
  autoApproved: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['CountryCode']['output']>;
  currency?: Maybe<Scalars['CurrencyCode']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  format?: Maybe<BookFormat>;
  id: Scalars['ID']['output'];
  isFulfilmentManaged: Scalars['Boolean']['output'];
  isGlobal: Scalars['Boolean']['output'];
  isbn?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  localisedPrice?: Maybe<Price>;
  price?: Maybe<Scalars['Int']['output']>;
  resource: Resource;
  type: Scalars['String']['output'];
};


export type BookLocalisedPriceArgs = {
  currency: Scalars['CurrencyCode']['input'];
};

export type BookFormat = {
  __typename?: 'BookFormat';
  supplier?: Maybe<Supplier>;
  type?: Maybe<BookFormatType>;
};

export enum BookFormatType {
  Audiobook = 'AUDIOBOOK',
  Ebook = 'EBOOK',
  Hardcover = 'HARDCOVER',
  Kindle = 'KINDLE',
  Paperback = 'PAPERBACK'
}

export type BookRequest = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amazonASIN?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  currency?: InputMaybe<Scalars['CurrencyCode']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<BookFormatType>;
  isbn?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Int']['input'];
  supplier?: InputMaybe<Scalars['ID']['input']>;
};

export type Budget = {
  __typename?: 'Budget';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  budgetPeriodId?: Maybe<Scalars['ID']['output']>;
  currency?: Maybe<Scalars['CurrencyCode']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  pending?: Maybe<Scalars['Int']['output']>;
  remaining?: Maybe<Scalars['Int']['output']>;
  remainingForApproval?: Maybe<Scalars['Int']['output']>;
  spent?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<BudgetStatus>;
  total?: Maybe<Scalars['Int']['output']>;
  transactions?: Maybe<Array<BudgetTransaction>>;
  type: BudgetType;
};

export type BudgetPeriod = {
  __typename?: 'BudgetPeriod';
  archived: Scalars['Boolean']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberOfBudgets: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
};

export enum BudgetStatus {
  Archived = 'ARCHIVED',
  Created = 'CREATED'
}

export type BudgetTransaction = {
  __typename?: 'BudgetTransaction';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  isInitialTopUp: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Request>;
  status: BudgetTransactionStatus;
  type: BudgetTransactionType;
  validOn: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export enum BudgetTransactionStatus {
  Approved = 'APPROVED',
  Committed = 'COMMITTED',
  Pending = 'PENDING',
  RolledBack = 'ROLLED_BACK'
}

export enum BudgetTransactionType {
  AdditionalCharge = 'ADDITIONAL_CHARGE',
  Manual = 'MANUAL',
  PartialRefund = 'PARTIAL_REFUND',
  Refund = 'REFUND',
  Request = 'REQUEST'
}

export enum BudgetType {
  Individual = 'INDIVIDUAL',
  Team = 'TEAM'
}

export type BulkUpdatePreview = {
  __typename?: 'BulkUpdatePreview';
  createdGroups: Array<Scalars['String']['output']>;
  createdUsers: Array<BulkUser>;
  errors: Array<Scalars['String']['output']>;
  updatedApproverCount: Scalars['Int']['output'];
  updatedCountryCount: Scalars['Int']['output'];
  updatedEmailCount: Scalars['Int']['output'];
  updatedExternalIdCount: Scalars['Int']['output'];
  updatedFirstNameCount: Scalars['Int']['output'];
  updatedGeographicLocationCount: Scalars['Int']['output'];
  /** @deprecated Do not use this field, use updatedGeographicLocationCount instead */
  updatedGographicLocationCount: Scalars['Int']['output'];
  updatedGroupCount: Scalars['Int']['output'];
  updatedHasLearnerblyAccessCount: Scalars['Int']['output'];
  updatedLastNameCount: Scalars['Int']['output'];
  updatedPotConsumerOfCount: Scalars['Int']['output'];
  updatedPotsApproverOfCount: Scalars['Int']['output'];
  updatedUsers: Array<BulkUser>;
};

export type BulkUpdateResult = {
  __typename?: 'BulkUpdateResult';
  createdGroups: Array<Scalars['String']['output']>;
  createdUsers: Array<BulkUser>;
  errors: Array<Scalars['String']['output']>;
  groupsCreateAttemptedCount: Scalars['Int']['output'];
  updatedUsers: Array<BulkUser>;
  usersCreateAttemptedCount: Scalars['Int']['output'];
  usersUpdateAttemptedCount: Scalars['Int']['output'];
};

export type BulkUploadableUser = {
  approvers: Array<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  geographicLocation: Scalars['String']['input'];
  groups: Array<Scalars['String']['input']>;
  hasLearnerblyAccess?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  potConsumerOf?: InputMaybe<Scalars['String']['input']>;
  potsApproverOf?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BulkUser = {
  __typename?: 'BulkUser';
  approvers: Array<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  geographicLocation: Scalars['String']['output'];
  groups: Array<Scalars['String']['output']>;
  hasLearnerblyAccess: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  potConsumerOf?: Maybe<Scalars['String']['output']>;
  potsApproverOf?: Maybe<Array<Scalars['String']['output']>>;
  user: User;
};

export type CancelPotRequestInput = {
  requestId: Scalars['ID']['input'];
};

export type Community = {
  __typename?: 'Community';
  contents: Array<Content>;
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  members: Array<Member>;
  name: Scalars['String']['output'];
  organisation: Organisation;
  recentPathwayProgression: Array<PathwayActivity>;
  recentRequests: Array<RecentActivity>;
  skills: Array<Skill>;
  updatedBy?: Maybe<User>;
};

export type CommunityConnection = {
  __typename?: 'CommunityConnection';
  edges: Array<CommunityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CommunityContentInput = {
  content: Scalars['ID']['input'];
  type: ContentType;
};

export type CommunityEdge = {
  __typename?: 'CommunityEdge';
  cursor: Scalars['Cursor']['output'];
  node: Community;
};

export type CommunityMemberInput = {
  role?: InputMaybe<MemberRole>;
  user: Scalars['ID']['input'];
};

export type CommunityResponse = Community | NotFoundError;

export type CommunitySkillInput = {
  name: Scalars['String']['input'];
};

export type CompletedBy = {
  __typename?: 'CompletedBy';
  completedTimestamp: Scalars['String']['output'];
  user: User;
};

export type Content = {
  __typename?: 'Content';
  content: ContentResult;
  createdAt: Scalars['DateTime']['output'];
  type: ContentType;
};

export type ContentResult = Playlist | Resource;

export enum ContentType {
  Playlist = 'PLAYLIST',
  Resource = 'RESOURCE'
}

export type CreateBudgetPeriod = {
  endDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type CreateCommunityInput = {
  contents: Array<CommunityContentInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  members: Array<CommunityMemberInput>;
  name: Scalars['String']['input'];
  skills: Array<CommunitySkillInput>;
};

export type CreateCommunityResponse = Community | DuplicateEntityError | InvalidInputError;

export type CreateExpert = {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFeatureToggle = {
  active: Scalars['Boolean']['input'];
  admin: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  organisations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  user: Scalars['Boolean']['input'];
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CreateFulfilmentAccount = {
  autoApprovedThreshold?: InputMaybe<Scalars['Int']['input']>;
  currency: Scalars['CurrencyCode']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateOffice = {
  location: LocationRequest;
  organisationId: Scalars['ID']['input'];
};

export type CreateOrganisation = {
  adhocRequests: Scalars['Boolean']['input'];
  analyticsDashboard: Scalars['Boolean']['input'];
  currency?: InputMaybe<Scalars['CurrencyCode']['input']>;
  externalIds?: InputMaybe<InputOrganisationExternalIds>;
  fulfilmentAccounts?: InputMaybe<Array<InputMaybe<CreateFulfilmentAccount>>>;
  hasAmazonRequests: Scalars['Boolean']['input'];
  hasFulfilledByLearnerRequests: Scalars['Boolean']['input'];
  hasFulfilledByLearnerViaExpensesRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasFunds?: InputMaybe<Scalars['Boolean']['input']>;
  hasMagicLinks?: InputMaybe<Scalars['Boolean']['input']>;
  hasPots?: InputMaybe<Scalars['Boolean']['input']>;
  internalEvents: Scalars['Boolean']['input'];
  internalLearning: Scalars['Boolean']['input'];
  isInProofOfValue?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  recentActivity: Scalars['Boolean']['input'];
  showOnlyFreeResources?: InputMaybe<Scalars['Boolean']['input']>;
  testClient: Scalars['Boolean']['input'];
};

export type CreatePotResponse = Pot | PotNameDuplicationError;

export type CreateRequestApprovalOptions = {
  isAutoApproved: Scalars['Boolean']['input'];
  requestNote?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRequestExternalItemInput = {
  link: Scalars['String']['input'];
  resourceTypeId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateRequestFulfilmentOptions = {
  viaExpenses?: InputMaybe<FulfilledViaExpensesRequestOptions>;
  viaFulfilmentService?: InputMaybe<FulfilledViaFulfilmentServiceRequestOptions>;
  viaVirtualCard?: InputMaybe<FulfilledViaVirtualCardRequestOptions>;
};

export type CreateRequestInput = {
  approvalOptions: CreateRequestApprovalOptions;
  currency: Scalars['CurrencyCode']['input'];
  fulfilmentOptions: CreateRequestFulfilmentOptions;
  isPublic: Scalars['Boolean']['input'];
  item: CreateRequestItemInput;
  price: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type CreateRequestItemInput = {
  externalItem?: InputMaybe<CreateRequestExternalItemInput>;
};

export type CreateSessionResponse = InvalidLoginRequestError | LoginSession;

export type CreateSupplier = {
  codeClaim?: InputMaybe<Scalars['Boolean']['input']>;
  codeClaimHelperText?: InputMaybe<Scalars['String']['input']>;
  codeClaimOption?: InputMaybe<Scalars['String']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalIds?: InputMaybe<InputSupplierExternalIds>;
  imageCentreAlign?: InputMaybe<Scalars['String']['input']>;
  imageRectangular?: InputMaybe<Scalars['String']['input']>;
  imageSquare?: InputMaybe<Scalars['String']['input']>;
  imageTopAlign?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  multiUseCode?: InputMaybe<MultiUseCodeInput>;
  name: Scalars['String']['input'];
  subjects?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type DataExport = {
  __typename?: 'DataExport';
  url: Scalars['String']['output'];
};

export type DataExportResult = {
  __typename?: 'DataExportResult';
  activeUsers: DataExport;
  requests: DataExport;
};

export type DateRange = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type DefaultUserBudget = {
  __typename?: 'DefaultUserBudget';
  currency: Scalars['CurrencyCode']['output'];
  proRata: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
};

export type DeleteCommunityResponse = DeletedCommunity | NotFoundError;

export type DeletedCommunity = {
  __typename?: 'DeletedCommunity';
  id: Scalars['ID']['output'];
};

export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  city: Scalars['String']['output'];
  country: Scalars['CountryCode']['output'];
  diallingCode?: Maybe<Scalars['CountryCode']['output']>;
  id: Scalars['ID']['output'];
  postcode: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  telephone?: Maybe<Scalars['String']['output']>;
};

export type DeliveryAddressRequest = {
  city: Scalars['String']['input'];
  country: Scalars['CountryCode']['input'];
  diallingCode?: InputMaybe<Scalars['CountryCode']['input']>;
  postcode: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  telephone?: InputMaybe<Scalars['String']['input']>;
};

export type DuplicateEntityError = UserError & {
  __typename?: 'DuplicateEntityError';
  message: Scalars['String']['output'];
};

export type Enquiry = {
  __typename?: 'Enquiry';
  comments?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  resource?: Maybe<Resource>;
};

export type EnquiryRequest = {
  comments?: InputMaybe<Scalars['String']['input']>;
  resource: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};

export type Error = {
  message: Scalars['String']['output'];
};

export type Event = Product & {
  __typename?: 'Event';
  active: Scalars['Boolean']['output'];
  autoApproved: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['CountryCode']['output']>;
  currency?: Maybe<Scalars['CurrencyCode']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isFulfilmentManaged: Scalars['Boolean']['output'];
  isGlobal: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  localisedPrice?: Maybe<Price>;
  location?: Maybe<Location>;
  moreInfo?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  resource: Resource;
  startDate: Scalars['DateTime']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};


export type EventLocalisedPriceArgs = {
  currency: Scalars['CurrencyCode']['input'];
};

export type EventRequest = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  currency?: InputMaybe<Scalars['CurrencyCode']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationRequest>;
  moreInfo?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type ExchangeRates = {
  __typename?: 'ExchangeRates';
  CAD: Scalars['Float']['output'];
  EUR: Scalars['Float']['output'];
  GBP: Scalars['Float']['output'];
  SEK: Scalars['Float']['output'];
  USD: Scalars['Float']['output'];
};

export type Expert = {
  __typename?: 'Expert';
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employer?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type FeatureToggle = {
  __typename?: 'FeatureToggle';
  active: Scalars['Boolean']['output'];
  admin: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  organisations: Array<Maybe<Organisation>>;
  user: Scalars['Boolean']['output'];
  users: Array<Maybe<User>>;
};

export type Feedback = {
  __typename?: 'Feedback';
  customSkills: Array<Maybe<Scalars['String']['output']>>;
  requestId: Scalars['ID']['output'];
  skills: Array<Maybe<Scalars['String']['output']>>;
};

export type FilterOptions = {
  __typename?: 'FilterOptions';
  geographicLocation: Array<Scalars['String']['output']>;
};

export type FilterSelection = {
  geographicLocation?: InputMaybe<Array<Scalars['String']['input']>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  invitationNotSent?: InputMaybe<Scalars['Boolean']['input']>;
  needsTopUp?: InputMaybe<Scalars['Boolean']['input']>;
  noActiveBudget?: InputMaybe<Scalars['Boolean']['input']>;
  noApprovers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Focus = {
  __typename?: 'Focus';
  id: Scalars['ID']['output'];
  subject: Subject;
};

export type FormFields = {
  __typename?: 'FormFields';
  algorithm: Scalars['String']['output'];
  bucket: Scalars['String']['output'];
  credential: Scalars['String']['output'];
  date: Scalars['String']['output'];
  key: Scalars['ID']['output'];
  policy: Scalars['String']['output'];
  securityToken: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FulfilledByLearnerInfo = {
  __typename?: 'FulfilledByLearnerInfo';
  link: Scalars['String']['output'];
  phoneNumberFor3ds?: Maybe<PhoneNumberFor3ds>;
  proofOfPurchaseFilename?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  virtualCardId?: Maybe<Scalars['ID']['output']>;
};

export enum FulfilledByLearnerRequestTypes {
  Expenses = 'EXPENSES',
  VirtualCards = 'VIRTUAL_CARDS'
}

export type FulfilledViaExpensesRequestOptions = {
  additionalNote?: InputMaybe<Scalars['String']['input']>;
};

export type FulfilledViaFulfilmentServiceRequestOptions = {
  additionalNote?: InputMaybe<Scalars['String']['input']>;
};

export type FulfilledViaVirtualCardRequestOptions = {
  phoneNumberFor3ds?: InputMaybe<PhoneNumberFor3dsInput>;
};

export type FulfilmentAccount = {
  __typename?: 'FulfilmentAccount';
  autoApprovedThreshold?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['CurrencyCode']['output'];
  id: Scalars['ID']['output'];
  isStripe: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type Goal = {
  __typename?: 'Goal';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  doneAt?: Maybe<Scalars['DateTime']['output']>;
  dueDate: Scalars['DateTime']['output'];
  focuses?: Maybe<Array<PdpFocus>>;
  id: Scalars['ID']['output'];
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  status: Scalars['String']['output'];
  tasks: Array<GoalTask>;
};

export type GoalRequest = {
  description: Scalars['String']['input'];
  dueDate: Scalars['DateTime']['input'];
  focuses?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  tasks?: InputMaybe<Array<InputMaybe<GoalTaskRequest>>>;
};

export type GoalTask = {
  __typename?: 'GoalTask';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  doneAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  resource?: Maybe<Resource>;
  status: Scalars['String']['output'];
};

export type GoalTaskRequest = {
  description: Scalars['String']['input'];
};

export type Group = {
  __typename?: 'Group';
  archived?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  memberCount: Scalars['Int']['output'];
  members?: Maybe<Array<User>>;
  name: Scalars['String']['output'];
  organisation: Organisation;
};


export type GroupMembersArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupAnalytics = {
  __typename?: 'GroupAnalytics';
  averageRequestsValueGraph?: Maybe<Array<RequestsValueGraph>>;
  mostActiveEmployees?: Maybe<Array<KeyCountValue>>;
  requestsByResourceType?: Maybe<Array<KeyCountValue>>;
  requestsValueGraph?: Maybe<Array<RequestsValueGraph>>;
  totalRequests?: Maybe<Scalars['Int']['output']>;
  totalRequestsValue?: Maybe<Scalars['Int']['output']>;
  userInvitationAcceptance?: Maybe<Scalars['Float']['output']>;
  userTopTopics?: Maybe<Array<KeyCountValue>>;
};

export type GroupRequest = {
  members?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  organisation: Scalars['ID']['input'];
};

export enum IdentityProviderOriginType {
  Seed = 'SEED',
  Test = 'TEST'
}

export enum IdentityProviderType {
  Google = 'GOOGLE',
  Okta = 'OKTA',
  Saml = 'SAML'
}

export type IndexOrganisationUsersResponse = {
  __typename?: 'IndexOrganisationUsersResponse';
  updatedUserCount: Scalars['Int']['output'];
};

export type InputOrganisationExternalIds = {
  crm?: InputMaybe<Scalars['ExternalID']['input']>;
  finance?: InputMaybe<Scalars['ExternalID']['input']>;
};

export type InputSupplierExternalIds = {
  crm?: InputMaybe<Scalars['ExternalID']['input']>;
};

export type Integration = OktaIntegration | SlackInstallation;

export type InternalEvent = Product & {
  __typename?: 'InternalEvent';
  active: Scalars['Boolean']['output'];
  attendees: Array<User>;
  attendeesConnection?: Maybe<InternalEventAttendeesConnection>;
  attending: Scalars['Boolean']['output'];
  autoApproved: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['CountryCode']['output']>;
  currency?: Maybe<Scalars['CurrencyCode']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isFulfilmentManaged: Scalars['Boolean']['output'];
  isGlobal: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  localisedPrice?: Maybe<Price>;
  location?: Maybe<Location>;
  price?: Maybe<Scalars['Int']['output']>;
  remainingSpotCount?: Maybe<Scalars['Int']['output']>;
  resource: Resource;
  startDate: Scalars['DateTime']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
  totalSpotCount?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


export type InternalEventLocalisedPriceArgs = {
  currency: Scalars['CurrencyCode']['input'];
};

export type InternalEventAttendeesConnection = {
  __typename?: 'InternalEventAttendeesConnection';
  edges?: Maybe<Array<Maybe<InternalEventAttendeesEdge>>>;
  id: Scalars['ID']['output'];
};

export type InternalEventAttendeesEdge = {
  __typename?: 'InternalEventAttendeesEdge';
  attendee: User;
  hasAttended?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
};

export type InternalEventRequest = {
  active: Scalars['Boolean']['input'];
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationRequest>;
  startDate: Scalars['String']['input'];
  tickets?: InputMaybe<Array<InputMaybe<InternalEventTicketRequest>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  totalSpotCount?: InputMaybe<Scalars['Int']['input']>;
};

export type InternalEventResourcesConnection = {
  __typename?: 'InternalEventResourcesConnection';
  edges: Array<Maybe<InternalEventResourcesEdge>>;
  pageInfo: InternalEventResourcesConnectionPageInfo;
};

export type InternalEventResourcesConnectionPageInfo = {
  __typename?: 'InternalEventResourcesConnectionPageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
};

export type InternalEventResourcesEdge = {
  __typename?: 'InternalEventResourcesEdge';
  node: Resource;
};

export type InternalEventTicket = {
  __typename?: 'InternalEventTicket';
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type InternalEventTicketRequest = {
  name: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type InternalResourceRequest = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowedCountries?: InputMaybe<Array<Scalars['CountryCode']['input']>>;
  description: Scalars['String']['input'];
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  organiserEmail?: InputMaybe<Scalars['String']['input']>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  summary: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: Scalars['ID']['input'];
};

export enum InternalResourceType {
  Article = 'ARTICLE',
  FileDownload = 'FILE_DOWNLOAD',
  Other = 'OTHER',
  Video = 'VIDEO'
}

export type InvalidInputError = UserError & {
  __typename?: 'InvalidInputError';
  message: Scalars['String']['output'];
};

export type InvalidLoginRequestError = UserError & {
  __typename?: 'InvalidLoginRequestError';
  message: Scalars['String']['output'];
};

export type InventoryInput = {
  productId?: InputMaybe<Scalars['ID']['input']>;
  resourceId?: InputMaybe<Scalars['ID']['input']>;
  supplierId: Scalars['ID']['input'];
};

export type Invitation = {
  __typename?: 'Invitation';
  id: Scalars['ID']['output'];
  user: User;
};

export type Item = {
  __typename?: 'Item';
  asin: Scalars['ASIN']['output'];
  author?: Maybe<Scalars['String']['output']>;
  format: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isbn?: Maybe<Scalars['String']['output']>;
  offers: Array<Maybe<Offer>>;
  pageCount?: Maybe<Scalars['Int']['output']>;
  publicationDate?: Maybe<Scalars['String']['output']>;
  publisher?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type KeyCountValue = {
  __typename?: 'KeyCountValue';
  count?: Maybe<Scalars['Int']['output']>;
  key: ResultKey;
  value?: Maybe<Scalars['Float']['output']>;
};

export type LearnerSkill = {
  __typename?: 'LearnerSkill';
  assignedBy: Scalars['ID']['output'];
  learnerId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LearnerSkillsConnection = {
  __typename?: 'LearnerSkillsConnection';
  edges: Array<LearnerSkillsEdge>;
  pageInfo: LearnerSkillsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LearnerSkillsEdge = {
  __typename?: 'LearnerSkillsEdge';
  cursor: Scalars['Cursor']['output'];
  node: LearnerSkill;
};

export type LearnerSkillsFilters = {
  learnerId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LearnerSkillsPageInfo = {
  __typename?: 'LearnerSkillsPageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type LearningPathway = {
  __typename?: 'LearningPathway';
  actions: Array<PathwayAction>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  organisation: Organisation;
  status: PathwayStatus;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userProgression?: Maybe<UserPathwayProgression>;
};

export type LearningPathwayResponse = InvalidInputError | LearningPathway | NotFoundError;

export type ListResult = {
  __typename?: 'ListResult';
  availableFilters: FilterOptions;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  memberCount: Scalars['Int']['output'];
  results: Array<Result>;
  searchAfter?: Maybe<Scalars['String']['output']>;
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  diallingCode?: Maybe<Scalars['CountryCode']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  telephone?: Maybe<Scalars['String']['output']>;
};

export type LocationRequest = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  diallingCode?: InputMaybe<Scalars['CountryCode']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};

export type LoginMethod = {
  __typename?: 'LoginMethod';
  auth: AuthMethod;
  /** @deprecated Use auth instead, supports more auth methods */
  password?: Maybe<PasswordMethod>;
  singleSignOn?: Maybe<SingleSignOnProvider>;
};

export type LoginMethodResponse = InvalidInputError | LoginMethod;

export type LoginSession = {
  __typename?: 'LoginSession';
  token: Scalars['String']['output'];
};

export type MagicLinkEmail = {
  __typename?: 'MagicLinkEmail';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MagicLinkResponse = InvalidInputError | MagicLinkEmail;

export type Member = {
  __typename?: 'Member';
  createdAt: Scalars['DateTime']['output'];
  role: MemberRole;
  user: User;
};

export enum MemberRole {
  Leader = 'LEADER',
  Member = 'MEMBER'
}

export type MerchantInfo = {
  __typename?: 'MerchantInfo';
  feedbackCount?: Maybe<Scalars['Int']['output']>;
  feedbackRating?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MergeHris = {
  __typename?: 'MergeHris';
  accountToken?: Maybe<Scalars['String']['output']>;
  lastSyncDate?: Maybe<Scalars['DateTime']['output']>;
  linkToken?: Maybe<Scalars['String']['output']>;
};

export type MetabaseDashboard = {
  __typename?: 'MetabaseDashboard';
  expiresAt: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type MultiUseCode = {
  __typename?: 'MultiUseCode';
  currency: Scalars['CurrencyCode']['output'];
  expirationTime: Scalars['DateTime']['output'];
  price: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type MultiUseCodeInput = {
  currency: Scalars['CurrencyCode']['input'];
  expirationTime: Scalars['DateTime']['input'];
  price: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateLoginWithMagicLinks: UpdateOrganisationResponse;
  addBook: Book;
  addBudget: User;
  addConnectedStripeAccount: Organisation;
  addEvent: Event;
  addInternalEvent: InternalEvent;
  addOnlineCourse: OnlineCourse;
  addResourceFeedback?: Maybe<Resource>;
  addResourceToPlaylist: Playlist;
  addVirtualCardProduct: VirtualCardProduct;
  approvePotRequest: Request;
  approveRequest: Request;
  archiveBudgetPeriod: Organisation;
  archiveGroup: Group;
  archiveIndividualBudget: User;
  archiveOrganisation: Organisation;
  archiveSubject: Subject;
  archiveSupplier: Supplier;
  archiveUser: User;
  bookEvent: InternalEvent;
  cancelPotRequest: Request;
  cancelRequest: Request;
  commitBulkUpdates: BulkUpdateResult;
  completePathwayAction?: Maybe<LearningPathwayResponse>;
  createAccountBudget: AccountBudget;
  createAdhocRequest: Request;
  createBudgetPeriod: Organisation;
  createCommunity: CreateCommunityResponse;
  createEnquiry: Enquiry;
  createExpert: Expert;
  createFeatureToggle: FeatureToggle;
  createGroup: Group;
  createIndividualBudget: User;
  createInternalResource: Resource;
  createLearningPathway?: Maybe<Scalars['ID']['output']>;
  createOffice: Organisation;
  createOktaIntegration: Organisation;
  createOrganisation: Organisation;
  createOrganisationContent: OrganisationContent;
  createPlaylist: Playlist;
  /** @deprecated Use createValidatedPot instead */
  createPot: Pot;
  createRequest: Request;
  createRequestByType: Request;
  createResource: Resource;
  createReview: Review;
  /** @deprecated Use createSessionWithResponse instead */
  createSession: Scalars['String']['output'];
  createSessionWithResponse: CreateSessionResponse;
  createSubject: Subject;
  createSupplier: Supplier;
  createUser: User;
  createUserContent: UserContent;
  createValidatedPot: CreatePotResponse;
  createVirtualCard: VirtualCard;
  createWallet: Organisation;
  deductBudget: User;
  deleteBudget: User;
  deleteBudgetPeriod: Organisation;
  deleteCommunity: DeleteCommunityResponse;
  deleteDefaultUserBudget?: Maybe<Organisation>;
  deleteIndividualBudget: User;
  deleteMergeIntegration: Organisation;
  deleteOktaIntegration: Organisation;
  deleteOrganisationContent: Scalars['ID']['output'];
  deleteOrganisationContentFile: Scalars['ID']['output'];
  deletePathwayAction?: Maybe<LearningPathwayResponse>;
  deletePlaylist: Playlist;
  deleteReview: Review;
  deleteUserContent?: Maybe<Scalars['ID']['output']>;
  disableIdentityProvider: Organisation;
  dismissFeedback: Feedback;
  editResourceComment: Playlist;
  enableIdentityProvider: Organisation;
  forgotPassword: Scalars['String']['output'];
  freezeOrganisation: Organisation;
  generateLearningPathway?: Maybe<LearningPathwayResponse>;
  generateSlackToken: Organisation;
  getOrganisationContentPostUrl: FormFields;
  getReceiptPostUrl: FormFields;
  indexOrganisationUsers: IndexOrganisationUsersResponse;
  mergeSubject: Subject;
  publishLearningPathway?: Maybe<LearningPathwayResponse>;
  recommendToPeers?: Maybe<Recommendation>;
  rejectRequest: Request;
  removeResourceFromPlaylist: Playlist;
  removeSlackInstallation: Organisation;
  resetUser: User;
  retryApprovedRequest: RetryApprovedRequestResponse;
  saveFeedback: Feedback;
  saveLearnerSkills: User;
  seedPotsDemoData: PotsDemoDataResponse;
  sendInvitation: Scalars['String']['output'];
  sendInvitations: SendInvitationsResponse;
  sendMagicLinkEmail: MagicLinkResponse;
  setApproverNote: ApproverNote;
  setFocuses: User;
  setGoal: User;
  setGoalMarkAsDeleted: User;
  setGoalMarkAsDone: User;
  setMergeAccountToken: Organisation;
  setOnboarding: User;
  setPDPFocuses: User;
  setPassword: Scalars['String']['output'];
  setRequestAsExpensed: Request;
  setRequestProofOfPurchaseFilename: Request;
  setSignup: Scalars['Boolean']['output'];
  setTask: User;
  setTaskAsDeleted: User;
  setTaskAsDone: User;
  sharePathway?: Maybe<LearningPathwayResponse>;
  /** @deprecated Use signUpWithoutPassword instead */
  signUpWithSso: User;
  signUpWithoutPassword: User;
  startLearningPathway?: Maybe<LearningPathwayResponse>;
  toggleOrganisationContentAsComplete: OrganisationContent;
  togglePlaylistHighFive: Playlist;
  toggleResourceBookmark: Resource;
  toggleResourceMarkAsDone: Resource;
  topUpBudget: User;
  unarchiveUser: User;
  unbookEvent: InternalEvent;
  unfreezeOrganisation: Organisation;
  updateAccountBudget: AccountBudget;
  updateAttendance: InternalEventAttendeesEdge;
  updateBook: Book;
  updateBudgetPeriod: Organisation;
  updateCommunity: UpdateCommunityResponse;
  updateDefaultUserBudgets?: Maybe<Organisation>;
  updateEvent: Event;
  updateExpert: Expert;
  updateFeatureToggle: FeatureToggle;
  updateGoal: User;
  updateInternalEvent: InternalEvent;
  updateInternalResource: Resource;
  updateLearningPathway?: Maybe<LearningPathwayResponse>;
  updateOffice: Organisation;
  updateOnlineCourse: OnlineCourse;
  updateOrganisation: Organisation;
  updateOrganisationContent: OrganisationContent;
  updatePassword: Scalars['String']['output'];
  updatePlaylist: Playlist;
  updatePlaylistFields: Playlist;
  updatePot: Pot;
  updateResource: Resource;
  updateSubject: Subject;
  updateSupplier: Supplier;
  updateUser: User;
  updateUserApproverByEmail: User;
  updateUserContent: UserContent;
  updateUserPreferences: User;
  updateUserProfile: User;
  updateVirtualCardProduct: VirtualCardProduct;
  updateWallet: Organisation;
  upload: Scalars['String']['output'];
  uploadCodes: UploadCodesResult;
};


export type MutationActivateLoginWithMagicLinksArgs = {
  organisationId: Scalars['ID']['input'];
};


export type MutationAddBookArgs = {
  book: BookRequest;
  resourceId: Scalars['ID']['input'];
};


export type MutationAddBudgetArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  total: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddConnectedStripeAccountArgs = {
  connectedStripeAccountId: Scalars['ID']['input'];
  organisationId: Scalars['ID']['input'];
};


export type MutationAddEventArgs = {
  event: EventRequest;
  resourceId: Scalars['ID']['input'];
};


export type MutationAddInternalEventArgs = {
  internalEvent: InternalEventRequest;
  resourceId: Scalars['ID']['input'];
};


export type MutationAddOnlineCourseArgs = {
  onlineCourse: OnlineCourseRequest;
  resourceId: Scalars['ID']['input'];
};


export type MutationAddResourceFeedbackArgs = {
  action: UserResourceFeedbackAction;
  reason: UserResourceFeedbackReason;
  resourceId: Scalars['ID']['input'];
};


export type MutationAddResourceToPlaylistArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  playlistId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationAddVirtualCardProductArgs = {
  resourceId: Scalars['ID']['input'];
  virtualCardProduct: VirtualCardProductRequest;
};


export type MutationApprovePotRequestArgs = {
  input: ApprovePotRequestInput;
};


export type MutationApproveRequestArgs = {
  request: ApproveRequest;
};


export type MutationArchiveBudgetPeriodArgs = {
  budgetPeriodId: Scalars['ID']['input'];
  organisationId: Scalars['ID']['input'];
};


export type MutationArchiveGroupArgs = {
  groupId: Scalars['ID']['input'];
  organisationId: Scalars['ID']['input'];
};


export type MutationArchiveIndividualBudgetArgs = {
  budgetId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationArchiveOrganisationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveSubjectArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveSupplierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationBookEventArgs = {
  eventId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationCancelPotRequestArgs = {
  input: CancelPotRequestInput;
};


export type MutationCancelRequestArgs = {
  requestId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationCommitBulkUpdatesArgs = {
  data: Array<BulkUploadableUser>;
  isInviting: Scalars['Boolean']['input'];
  organisationId: Scalars['ID']['input'];
};


export type MutationCompletePathwayActionArgs = {
  actionId: Scalars['ID']['input'];
  pathwayId: Scalars['ID']['input'];
};


export type MutationCreateAccountBudgetArgs = {
  budget: AccountBudgetInput;
};


export type MutationCreateAdhocRequestArgs = {
  doesUserConsentToSharingContactDetailsWithSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  isSavingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  request: AdhocRequest;
};


export type MutationCreateBudgetPeriodArgs = {
  budgetPeriod: CreateBudgetPeriod;
  organisationId: Scalars['ID']['input'];
};


export type MutationCreateCommunityArgs = {
  input: CreateCommunityInput;
};


export type MutationCreateEnquiryArgs = {
  enquiry: EnquiryRequest;
};


export type MutationCreateExpertArgs = {
  expert: CreateExpert;
};


export type MutationCreateFeatureToggleArgs = {
  featureToggle: CreateFeatureToggle;
};


export type MutationCreateGroupArgs = {
  group: GroupRequest;
};


export type MutationCreateIndividualBudgetArgs = {
  budgetPeriodId?: InputMaybe<Scalars['ID']['input']>;
  currency?: InputMaybe<Scalars['CurrencyCode']['input']>;
  endDate: Scalars['String']['input'];
  initialQuantity: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
  topUpOn?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationCreateInternalResourceArgs = {
  resource: InternalResourceRequest;
};


export type MutationCreateLearningPathwayArgs = {
  prompts: PathwayPrompts;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateOfficeArgs = {
  office: LocationRequest;
  organisationId: Scalars['ID']['input'];
};


export type MutationCreateOktaIntegrationArgs = {
  organisationId: Scalars['ID']['input'];
};


export type MutationCreateOrganisationArgs = {
  organisation: CreateOrganisation;
};


export type MutationCreateOrganisationContentArgs = {
  content: OrganisationContentRequest;
  id: Scalars['ID']['input'];
};


export type MutationCreatePlaylistArgs = {
  playlist: PlaylistRequest;
};


export type MutationCreatePotArgs = {
  pot?: InputMaybe<PotInput>;
};


export type MutationCreateRequestArgs = {
  doesUserConsentToSharingContactDetailsWithSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  isSavingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  request: RequestRequest;
};


export type MutationCreateRequestByTypeArgs = {
  request: CreateRequestInput;
};


export type MutationCreateResourceArgs = {
  resource: UpdateResourceRequest;
};


export type MutationCreateReviewArgs = {
  review: ReviewRequest;
};


export type MutationCreateSessionArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCreateSessionWithResponseArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCreateSubjectArgs = {
  subject: SubjectRequest;
};


export type MutationCreateSupplierArgs = {
  supplier: CreateSupplier;
};


export type MutationCreateUserArgs = {
  user: UserRequest;
};


export type MutationCreateUserContentArgs = {
  content: UserContentInput;
};


export type MutationCreateValidatedPotArgs = {
  pot?: InputMaybe<PotInput>;
};


export type MutationCreateVirtualCardArgs = {
  requestId: Scalars['ID']['input'];
};


export type MutationCreateWalletArgs = {
  wallet?: InputMaybe<WalletInput>;
};


export type MutationDeductBudgetArgs = {
  budgetId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  validOn?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Int']['input'];
};


export type MutationDeleteBudgetArgs = {
  budgetId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteBudgetPeriodArgs = {
  budgetPeriodId: Scalars['ID']['input'];
  organisationId: Scalars['ID']['input'];
};


export type MutationDeleteCommunityArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDefaultUserBudgetArgs = {
  currency: Scalars['CurrencyCode']['input'];
  organisationId: Scalars['ID']['input'];
};


export type MutationDeleteIndividualBudgetArgs = {
  budgetId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteMergeIntegrationArgs = {
  organisationId: Scalars['ID']['input'];
};


export type MutationDeleteOktaIntegrationArgs = {
  organisationId: Scalars['ID']['input'];
};


export type MutationDeleteOrganisationContentArgs = {
  id: Scalars['ID']['input'];
  organisationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteOrganisationContentFileArgs = {
  filename: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  organisationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeletePathwayActionArgs = {
  actionId: Scalars['ID']['input'];
  pathwayId: Scalars['ID']['input'];
};


export type MutationDeletePlaylistArgs = {
  playlistId: Scalars['ID']['input'];
};


export type MutationDeleteReviewArgs = {
  id: Scalars['String']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationDeleteUserContentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDisableIdentityProviderArgs = {
  organisationId: Scalars['ID']['input'];
};


export type MutationDismissFeedbackArgs = {
  requestId: Scalars['ID']['input'];
};


export type MutationEditResourceCommentArgs = {
  comment: Scalars['String']['input'];
  playlistId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationEnableIdentityProviderArgs = {
  metaDetails?: InputMaybe<Scalars['String']['input']>;
  organisationId: Scalars['ID']['input'];
  origin?: InputMaybe<IdentityProviderOriginType>;
  providerType: IdentityProviderType;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationFreezeOrganisationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGenerateLearningPathwayArgs = {
  prompts: PathwayPrompts;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateSlackTokenArgs = {
  code: Scalars['String']['input'];
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGetOrganisationContentPostUrlArgs = {
  id: Scalars['ID']['input'];
  organisationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationGetReceiptPostUrlArgs = {
  requestId: Scalars['ID']['input'];
};


export type MutationIndexOrganisationUsersArgs = {
  organisationId: Scalars['String']['input'];
};


export type MutationMergeSubjectArgs = {
  newId: Scalars['ID']['input'];
  oldId: Scalars['ID']['input'];
};


export type MutationPublishLearningPathwayArgs = {
  pathwayId: Scalars['ID']['input'];
};


export type MutationRecommendToPeersArgs = {
  recommendation?: InputMaybe<RecommendToPeersRequest>;
};


export type MutationRejectRequestArgs = {
  request: RejectRequest;
};


export type MutationRemoveResourceFromPlaylistArgs = {
  playlistId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationResetUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRetryApprovedRequestArgs = {
  requestId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSaveFeedbackArgs = {
  customSkills: Array<InputMaybe<Scalars['String']['input']>>;
  requestId: Scalars['ID']['input'];
  skills: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationSaveLearnerSkillsArgs = {
  learnerId: Scalars['ID']['input'];
  skills: Array<Scalars['String']['input']>;
};


export type MutationSendInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSendInvitationsArgs = {
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationSendMagicLinkEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSetApproverNoteArgs = {
  approveeId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};


export type MutationSetFocusesArgs = {
  subjects?: InputMaybe<Array<Scalars['ID']['input']>>;
  user: Scalars['ID']['input'];
};


export type MutationSetGoalArgs = {
  goal: GoalRequest;
  userId: Scalars['ID']['input'];
};


export type MutationSetGoalMarkAsDeletedArgs = {
  goalId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSetGoalMarkAsDoneArgs = {
  goalId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSetMergeAccountTokenArgs = {
  organisationId: Scalars['ID']['input'];
  publicToken: Scalars['String']['input'];
};


export type MutationSetOnboardingArgs = {
  id: Scalars['ID']['input'];
  user?: InputMaybe<OnboardingRequest>;
};


export type MutationSetPdpFocusesArgs = {
  pdpFocuses?: InputMaybe<Array<Scalars['ID']['input']>>;
  userId: Scalars['ID']['input'];
};


export type MutationSetPasswordArgs = {
  user: PasswordRequest;
};


export type MutationSetRequestAsExpensedArgs = {
  requestId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSetRequestProofOfPurchaseFilenameArgs = {
  proofOfPurchaseFilename: Scalars['String']['input'];
  requestId: Scalars['ID']['input'];
  requestOwnerId: Scalars['ID']['input'];
};


export type MutationSetSignupArgs = {
  invitation: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};


export type MutationSetTaskArgs = {
  goalId: Scalars['ID']['input'];
  resourceId?: InputMaybe<Scalars['ID']['input']>;
  task: GoalTaskRequest;
  userId: Scalars['ID']['input'];
};


export type MutationSetTaskAsDeletedArgs = {
  taskId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSetTaskAsDoneArgs = {
  taskId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSharePathwayArgs = {
  note?: InputMaybe<Scalars['String']['input']>;
  pathwayId: Scalars['ID']['input'];
  recipientIds: Array<Scalars['ID']['input']>;
};


export type MutationSignUpWithSsoArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSignUpWithoutPasswordArgs = {
  id: Scalars['ID']['input'];
};


export type MutationStartLearningPathwayArgs = {
  pathwayId: Scalars['ID']['input'];
};


export type MutationToggleOrganisationContentAsCompleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationTogglePlaylistHighFiveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationToggleResourceBookmarkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationToggleResourceMarkAsDoneArgs = {
  id: Scalars['ID']['input'];
};


export type MutationTopUpBudgetArgs = {
  budgetId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  validOn?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Int']['input'];
};


export type MutationUnarchiveUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnbookEventArgs = {
  eventId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationUnfreezeOrganisationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateAccountBudgetArgs = {
  budgetId: Scalars['String']['input'];
  input: UpdateAccountBudgetInput;
};


export type MutationUpdateAttendanceArgs = {
  attendeeId: Scalars['ID']['input'];
  eventId: Scalars['ID']['input'];
  hasAttended: Scalars['Boolean']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationUpdateBookArgs = {
  book: BookRequest;
  id: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationUpdateBudgetPeriodArgs = {
  budgetPeriodId: Scalars['ID']['input'];
  endDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  organisationId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type MutationUpdateCommunityArgs = {
  input: UpdateCommunityInput;
};


export type MutationUpdateDefaultUserBudgetsArgs = {
  input: Array<UpdateDefaultUserBudgets>;
  organisationId: Scalars['ID']['input'];
};


export type MutationUpdateEventArgs = {
  event: EventRequest;
  id: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationUpdateExpertArgs = {
  expert: UpdateExpert;
  id: Scalars['ID']['input'];
};


export type MutationUpdateFeatureToggleArgs = {
  featureToggle: UpdateFeatureToggle;
  id: Scalars['ID']['input'];
};


export type MutationUpdateGoalArgs = {
  description: Scalars['String']['input'];
  goalId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUpdateInternalEventArgs = {
  id: Scalars['ID']['input'];
  internalEvent: InternalEventRequest;
  resourceId: Scalars['ID']['input'];
};


export type MutationUpdateInternalResourceArgs = {
  id: Scalars['ID']['input'];
  resource: InternalResourceRequest;
};


export type MutationUpdateLearningPathwayArgs = {
  pathway: PathwayInput;
};


export type MutationUpdateOfficeArgs = {
  office: LocationRequest;
  officeId: Scalars['ID']['input'];
  organisationId: Scalars['ID']['input'];
};


export type MutationUpdateOnlineCourseArgs = {
  id: Scalars['ID']['input'];
  onlineCourse: OnlineCourseRequest;
  resourceId: Scalars['ID']['input'];
};


export type MutationUpdateOrganisationArgs = {
  id: Scalars['ID']['input'];
  organisation: UpdateOrganisation;
};


export type MutationUpdateOrganisationContentArgs = {
  content: OrganisationContentRequest;
  id: Scalars['ID']['input'];
};


export type MutationUpdatePasswordArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdatePlaylistArgs = {
  id: Scalars['ID']['input'];
  playlist: PlaylistRequest;
};


export type MutationUpdatePlaylistFieldsArgs = {
  playlistFields: UpdatePlaylistFieldsRequest;
  playlistId: Scalars['ID']['input'];
};


export type MutationUpdatePotArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePotInput;
};


export type MutationUpdateResourceArgs = {
  id: Scalars['ID']['input'];
  resource: UpdateResourceRequest;
};


export type MutationUpdateSubjectArgs = {
  id: Scalars['ID']['input'];
  subject: SubjectRequest;
};


export type MutationUpdateSupplierArgs = {
  id: Scalars['ID']['input'];
  supplier: UpdateSupplier;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  user: UpdateUserRequest;
};


export type MutationUpdateUserApproverByEmailArgs = {
  approverEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['ID']['input'];
};


export type MutationUpdateUserContentArgs = {
  content: UpdateUserContentInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUserPreferencesArgs = {
  preferences: UpdateUserPreferencesInput;
};


export type MutationUpdateUserProfileArgs = {
  profile: UpdateUserProfileInput;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateVirtualCardProductArgs = {
  id: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
  virtualCardProduct: VirtualCardProductRequest;
};


export type MutationUpdateWalletArgs = {
  wallet?: InputMaybe<WalletInput>;
};


export type MutationUploadArgs = {
  dataUri: Scalars['String']['input'];
};


export type MutationUploadCodesArgs = {
  codes: Array<UploadCodeInput>;
  inventory: InventoryInput;
};

export type NotEligibleForCodeRedemptionError = Error & {
  __typename?: 'NotEligibleForCodeRedemptionError';
  message: Scalars['String']['output'];
};

export type NotFoundError = UserError & {
  __typename?: 'NotFoundError';
  message: Scalars['String']['output'];
};

export type Offer = {
  __typename?: 'Offer';
  availability: Scalars['String']['output'];
  condition?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  isAmazonFulfilled: Scalars['Boolean']['output'];
  isFreeShippingEligible: Scalars['Boolean']['output'];
  isPrimeEligible: Scalars['Boolean']['output'];
  listPrice: Scalars['Int']['output'];
  merchantInfo: MerchantInfo;
  offerId: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type OktaIntegration = {
  __typename?: 'OktaIntegration';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  integrationType: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  organisation: Scalars['String']['output'];
};

export type OnboardingRequest = {
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobFamily?: InputMaybe<Scalars['String']['input']>;
  jobFamilyOther?: InputMaybe<Scalars['String']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  jobLevelOther?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  resourceTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  subjects?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OnlineCourse = Product & {
  __typename?: 'OnlineCourse';
  active: Scalars['Boolean']['output'];
  autoApproved: Scalars['Boolean']['output'];
  codeClaim: Scalars['Boolean']['output'];
  codeClaimHelperText?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['CountryCode']['output']>;
  currency?: Maybe<Scalars['CurrencyCode']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFulfilmentManaged: Scalars['Boolean']['output'];
  isGlobal: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  localisedPrice?: Maybe<Price>;
  price?: Maybe<Scalars['Int']['output']>;
  resource: Resource;
  type: Scalars['String']['output'];
};


export type OnlineCourseLocalisedPriceArgs = {
  currency: Scalars['CurrencyCode']['input'];
};

export type OnlineCourseRequest = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  codeClaim: Scalars['Boolean']['input'];
  codeClaimHelperText?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  currency?: InputMaybe<Scalars['CurrencyCode']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Int']['input'];
};

export type Organisation = {
  __typename?: 'Organisation';
  accountBudget: AccountBudget;
  accountBudgets: AccountBudgetConnection;
  adhocRequests?: Maybe<Scalars['Boolean']['output']>;
  analyticsDashboard?: Maybe<Scalars['Boolean']['output']>;
  archived: Scalars['Boolean']['output'];
  budgetPeriods?: Maybe<Array<Maybe<BudgetPeriod>>>;
  connectedStripeAccountId?: Maybe<Scalars['ID']['output']>;
  contents: Array<OrganisationContent>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dataExports: DataExportResult;
  defaultOffice?: Maybe<Location>;
  defaultUserBudgets: Array<DefaultUserBudget>;
  enabledFulfilledByLearnerRequestTypes: Array<FulfilledByLearnerRequestTypes>;
  externalIds?: Maybe<OrganisationExternalIds>;
  fulfilmentAccounts?: Maybe<Array<Maybe<FulfilmentAccount>>>;
  groups?: Maybe<Array<Group>>;
  hasAmazonRequests: Scalars['Boolean']['output'];
  /** @deprecated Use enabledFulfilledByLearnerRequestTypes instead */
  hasFulfilledByLearnerRequests: Scalars['Boolean']['output'];
  hasFunds: Scalars['Boolean']['output'];
  hasMagicLinks?: Maybe<Scalars['Boolean']['output']>;
  hasPots?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  internalEvents?: Maybe<Scalars['Boolean']['output']>;
  internalLearning?: Maybe<Scalars['Boolean']['output']>;
  internalResources: OrganisationContentConnection;
  isFrozen: Scalars['Boolean']['output'];
  isInProofOfValue?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  memberCount?: Maybe<Scalars['Int']['output']>;
  members: ListResult;
  membersFrom: ListResult;
  mergeHris?: Maybe<MergeHris>;
  name: Scalars['String']['output'];
  offices?: Maybe<Array<Maybe<Location>>>;
  oktaInstallation?: Maybe<OktaIntegration>;
  playlists: Array<Playlist>;
  pot: Pot;
  pots?: Maybe<OrganisationPotsConnection>;
  receiptBundleUrl?: Maybe<Scalars['String']['output']>;
  recentActivity?: Maybe<Scalars['Boolean']['output']>;
  requests?: Maybe<ListResult>;
  showOnlyFreeResources?: Maybe<Scalars['Boolean']['output']>;
  singleSignOn?: Maybe<Scalars['Boolean']['output']>;
  singleSignOnCustomProvider?: Maybe<Scalars['String']['output']>;
  singleSignOnProviderType?: Maybe<Scalars['SSOCustomProvider']['output']>;
  slackInstallation?: Maybe<SlackInstallation>;
  testClient?: Maybe<Scalars['Boolean']['output']>;
  wallets?: Maybe<Array<Wallet>>;
};


export type OrganisationAccountBudgetArgs = {
  id: Scalars['ID']['input'];
};


export type OrganisationAccountBudgetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AccountBudgetsFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganisationGroupsArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type OrganisationInternalResourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganisationMembersArgs = {
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganisationMembersFromArgs = {
  filters?: InputMaybe<FilterSelection>;
  from?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganisationPlaylistsArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganisationPotArgs = {
  id: Scalars['ID']['input'];
};


export type OrganisationPotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganisationRequestsArgs = {
  filter?: InputMaybe<RequestFilter>;
  orderBy?: InputMaybe<RequestOrderBy>;
  organisations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganisationAnalytics = {
  __typename?: 'OrganisationAnalytics';
  id: Scalars['ID']['output'];
  mostActiveGroups?: Maybe<Array<KeyCountValue>>;
  pathways: PathwaysAnalytics;
  requestsByResourceType?: Maybe<Array<KeyCountValue>>;
  requestsValueGraph?: Maybe<Array<RequestsValueGraph>>;
  totalRequests?: Maybe<Scalars['Int']['output']>;
  totalRequestsValue?: Maybe<Scalars['Int']['output']>;
  userInvitationAcceptance?: Maybe<Scalars['Float']['output']>;
  userTopTopics?: Maybe<Array<KeyCountValue>>;
};

export type OrganisationContent = {
  __typename?: 'OrganisationContent';
  allowCompletion: Scalars['Boolean']['output'];
  attachment: Attachment;
  completed: Scalars['Boolean']['output'];
  completedBy: Array<CompletedBy>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageName?: Maybe<Scalars['String']['output']>;
  organisation: Organisation;
  playlists?: Maybe<ResourcePlaylists>;
  resourceImageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<ResourceType>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OrganisationContentConnection = {
  __typename?: 'OrganisationContentConnection';
  edges: Array<OrganisationContentEdge>;
  pageInfo: PageInfo;
};

export type OrganisationContentEdge = {
  __typename?: 'OrganisationContentEdge';
  cursor: Scalars['Cursor']['output'];
  node: OrganisationContent;
};

export type OrganisationContentRequest = {
  allowCompletion: Scalars['Boolean']['input'];
  filename?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  organisation: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationExternalIds = {
  __typename?: 'OrganisationExternalIds';
  crm?: Maybe<Scalars['ExternalID']['output']>;
  finance?: Maybe<Scalars['ExternalID']['output']>;
};

export type OrganisationPotsConnection = {
  __typename?: 'OrganisationPotsConnection';
  edges: Array<OrganisationPotsEdge>;
  pageInfo: PageInfo;
};

export type OrganisationPotsEdge = {
  __typename?: 'OrganisationPotsEdge';
  cursor: Scalars['Cursor']['output'];
  node: Pot;
};

export type OrganisationUpdateError = Error & {
  __typename?: 'OrganisationUpdateError';
  message: Scalars['String']['output'];
};

export type PdpFocus = {
  __typename?: 'PDPFocus';
  id: Scalars['ID']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export enum PasswordMethod {
  Cognito = 'COGNITO',
  Legacy = 'LEGACY'
}

export type PasswordRequest = {
  id: Scalars['ID']['input'];
  invitation: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};

export type PathwayAction = {
  __typename?: 'PathwayAction';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resource?: Maybe<Resource>;
  type: PathwayActionType;
};

export type PathwayActionInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<PathwayActionType>;
};

export enum PathwayActionType {
  Evaluate = 'EVALUATE',
  Listen = 'LISTEN',
  Practice = 'PRACTICE',
  Read = 'READ',
  Speak = 'SPEAK',
  Unknown = 'UNKNOWN',
  Watch = 'WATCH'
}

export type PathwayActivity = {
  __typename?: 'PathwayActivity';
  pathway: LearningPathway;
  user: User;
};

export type PathwayConnection = {
  __typename?: 'PathwayConnection';
  edges: Array<PathwayEdge>;
  pageInfo: PathwayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PathwayEdge = {
  __typename?: 'PathwayEdge';
  cursor: Scalars['Cursor']['output'];
  node: LearningPathway;
};

export type PathwayInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PathwayPageInfo = {
  __typename?: 'PathwayPageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PathwayPrompts = {
  outcomes: Scalars['String']['input'];
};

export enum PathwayStatus {
  Draft = 'DRAFT',
  Generating = 'GENERATING',
  Published = 'PUBLISHED'
}

export type PathwaysAnalytics = {
  __typename?: 'PathwaysAnalytics';
  completedCount: Scalars['Int']['output'];
  creatorCount: Scalars['Int']['output'];
  publishedCount: Scalars['Int']['output'];
  startedCount: Scalars['Int']['output'];
  uniqueLearnersStartedAndCompletedCount: Scalars['Int']['output'];
};

export type PhoneNumberFor3ds = {
  __typename?: 'PhoneNumberFor3ds';
  diallingCode: Scalars['String']['output'];
  telephoneNumber: Scalars['String']['output'];
};

export type PhoneNumberFor3dsInput = {
  diallingCode: Scalars['String']['input'];
  telephoneNumber: Scalars['String']['input'];
};

export type Playlist = {
  __typename?: 'Playlist';
  allowedCountries: Array<Scalars['CountryCode']['output']>;
  allowedOrganisations: Array<Organisation>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  entries: Array<PlaylistEntry>;
  expert?: Maybe<Expert>;
  global?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isDiscoverable: Scalars['Boolean']['output'];
  isHighFived: Scalars['Boolean']['output'];
  paginatedEntries: PlaylistEntryConnection;
  subjects: Array<Subject>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type PlaylistPaginatedEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  playlistId: Scalars['ID']['input'];
};

export type PlaylistConnection = {
  __typename?: 'PlaylistConnection';
  edges: Array<PlaylistEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PlaylistEdge = {
  __typename?: 'PlaylistEdge';
  cursor: Scalars['Cursor']['output'];
  node?: Maybe<Playlist>;
};

export type PlaylistEntry = {
  __typename?: 'PlaylistEntry';
  comment?: Maybe<Scalars['String']['output']>;
  index: Scalars['Int']['output'];
  resource: Resource;
};

export type PlaylistEntryConnection = {
  __typename?: 'PlaylistEntryConnection';
  edges: Array<PlaylistEntryEdge>;
  pageInfo: PlaylistEntryPageInfo;
};

export type PlaylistEntryEdge = {
  __typename?: 'PlaylistEntryEdge';
  cursor: Scalars['Cursor']['output'];
  node: PlaylistEntry;
};

export type PlaylistEntryPageInfo = {
  __typename?: 'PlaylistEntryPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PlaylistEntryRequest = {
  comment?: InputMaybe<Scalars['String']['input']>;
  resource: Scalars['ID']['input'];
};

export type PlaylistRequest = {
  allowedCountries: Array<Scalars['String']['input']>;
  allowedOrganisations: Array<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  entries: Array<PlaylistEntryRequest>;
  expert?: InputMaybe<Scalars['ID']['input']>;
  global: Scalars['Boolean']['input'];
  subjects: Array<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type PlaylistUpdateRequest = {
  allowedCountries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  allowedOrganisations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  entries?: InputMaybe<Array<InputMaybe<PlaylistEntryRequest>>>;
  expert?: InputMaybe<Scalars['ID']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title: Scalars['String']['input'];
};

export type Pot = {
  __typename?: 'Pot';
  activity: ActivityConnection;
  allocatedFunds: Scalars['Float']['output'];
  approvers: Array<User>;
  budget?: Maybe<AccountBudget>;
  budgetId?: Maybe<Scalars['String']['output']>;
  consumers: Array<User>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  currency: Scalars['CurrencyCode']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberOfConsumers: Scalars['Float']['output'];
  organisationId: Scalars['String']['output'];
  requests: RequestConnection;
  status: PotStatus;
  totalSpend: Scalars['Float']['output'];
  totalUserSpend: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
};


export type PotActivityArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type PotRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type PotTotalUserSpendArgs = {
  userId: Scalars['ID']['input'];
};

export type PotActivity = {
  __typename?: 'PotActivity';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  id: Scalars['ID']['output'];
  potId: Scalars['ID']['output'];
  resourceTitle?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type PotActivityEdge = {
  __typename?: 'PotActivityEdge';
  cursor: Scalars['Cursor']['output'];
  node: PotActivity;
};

export type PotInput = {
  allocatedFunds?: InputMaybe<Scalars['Float']['input']>;
  approvers?: InputMaybe<Array<Scalars['ID']['input']>>;
  budgetId?: InputMaybe<Scalars['String']['input']>;
  consumers?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
};

export type PotNameDuplicationError = Error & {
  __typename?: 'PotNameDuplicationError';
  message: Scalars['String']['output'];
};

export enum PotStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Suspended = 'SUSPENDED'
}

export type PotsDemoDataResponse = {
  __typename?: 'PotsDemoDataResponse';
  success: Scalars['Boolean']['output'];
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Int']['output'];
  currency: Scalars['CurrencyCode']['output'];
};

export type PriceRangeFilter = {
  currency: Scalars['CurrencyCode']['input'];
  maximum?: InputMaybe<Scalars['Int']['input']>;
  minimum?: InputMaybe<Scalars['Int']['input']>;
};

export type Product = {
  active: Scalars['Boolean']['output'];
  autoApproved: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['CountryCode']['output']>;
  currency?: Maybe<Scalars['CurrencyCode']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFulfilmentManaged: Scalars['Boolean']['output'];
  isGlobal: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  localisedPrice?: Maybe<Price>;
  price?: Maybe<Scalars['Int']['output']>;
  resource: Resource;
  type: Scalars['String']['output'];
};


export type ProductLocalisedPriceArgs = {
  currency: Scalars['CurrencyCode']['input'];
};

export type ProductFilter = {
  by_country?: InputMaybe<Scalars['CountryCode']['input']>;
  by_time_range?: InputMaybe<TimeRange>;
  include_all_countries?: InputMaybe<Scalars['Boolean']['input']>;
  include_all_currencies?: InputMaybe<Scalars['Boolean']['input']>;
  include_expired_events?: InputMaybe<Scalars['Boolean']['input']>;
  include_inactive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PsapiItem = {
  __typename?: 'PsapiItem';
  asin: Scalars['ASIN']['output'];
  author?: Maybe<Scalars['String']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  imageUrl: Scalars['String']['output'];
  offers: Price;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  analyticsDashboard: AnalyticsDashboardResult;
  approverAnalytics?: Maybe<ApproverAnalytics>;
  assessments?: Maybe<Array<Maybe<Assessment>>>;
  community: CommunityResponse;
  doesIdentityProviderExist?: Maybe<DoesIdentityProviderExistOutput>;
  enquiries?: Maybe<Array<Maybe<Enquiry>>>;
  enquiry?: Maybe<Enquiry>;
  featureToggles?: Maybe<Array<Maybe<FeatureToggle>>>;
  fulfilmentAccounts?: Maybe<Array<FulfilmentAccount>>;
  getAdminCreatedContent: UserContentConnection;
  getEphemeralKeySecret: Scalars['String']['output'];
  getItemByUrl: PsapiItem;
  getLearnerSkills: LearnerSkillsConnection;
  getUserByEmail: User;
  getUserContent: UserContent;
  getUserContentPostUrl: FormFields;
  getUserCreatedContent: UserContentConnection;
  group?: Maybe<Group>;
  groupAnalytics?: Maybe<GroupAnalytics>;
  internalEventsPaginated?: Maybe<InternalEventResourcesConnection>;
  internalResources: Array<Resource>;
  invitation?: Maybe<Invitation>;
  itemsByASINs: Array<Item>;
  latestPlaylists?: Maybe<Array<Maybe<Playlist>>>;
  leaderCommunities: CommunityConnection;
  /** @deprecated Use loginMethod */
  loginInfo: UserLoginInfo;
  loginMethod: LoginMethodResponse;
  mostRecentNotCompletedOrganisationContent?: Maybe<OrganisationContent>;
  organisation: Organisation;
  organisationAnalytics?: Maybe<OrganisationAnalytics>;
  organisationCommunities: CommunityConnection;
  organisations: Array<Organisation>;
  outdatedEvents?: Maybe<Array<Maybe<Event>>>;
  pathway?: Maybe<LearningPathwayResponse>;
  playlist: Playlist;
  playlists?: Maybe<Array<Maybe<Playlist>>>;
  playlistsById: Array<Playlist>;
  previewBulkUpdates: BulkUpdatePreview;
  product?: Maybe<Product>;
  recentActivity: Array<RecentActivity>;
  redeemCodeForRequest: RedeemCodeResult;
  request?: Maybe<Request>;
  requestForSkillsFeedback?: Maybe<Request>;
  requests?: Maybe<ListResult>;
  resource: Resource;
  resourcePlaylistCount?: Maybe<ResourcePlaylistCount>;
  resourceTypes: Array<ResourceType>;
  resources: ResourceList;
  searchAllContent: Array<Maybe<ContentResult>>;
  searchExperts?: Maybe<Array<Maybe<Expert>>>;
  searchItems: Array<Maybe<Item>>;
  searchOrganisationMembers?: Maybe<ListResult>;
  searchOrganisationMembersFrom: ListResult;
  searchPlaylists: Array<Playlist>;
  searchResources: ResourceList;
  searchResourcesAsAdmin?: Maybe<ResourceList>;
  searchSkills: Array<Skill>;
  searchSuppliers?: Maybe<Array<Maybe<Supplier>>>;
  searchUsers?: Maybe<ListResult>;
  similarResources?: Maybe<Array<Maybe<Resource>>>;
  /** @deprecated This query returns hardcoded data */
  skill: RoleSkill;
  subject: Subject;
  subjects: Array<Subject>;
  supplier: Supplier;
  suppliers?: Maybe<Array<Supplier>>;
  suppliersByIds: Array<Supplier>;
  suppliersBySubject: Array<Supplier>;
  suppliersPaginated: ListResult;
  trendingResources: Array<Resource>;
  user?: Maybe<User>;
  userCommunities: CommunityConnection;
};


export type QueryAnalyticsDashboardArgs = {
  input: AnalyticsDashboardInput;
};


export type QueryApproverAnalyticsArgs = {
  approverId: Scalars['ID']['input'];
  dateRange?: InputMaybe<DateRange>;
};


export type QueryCommunityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDoesIdentityProviderExistArgs = {
  customProvider: Scalars['String']['input'];
};


export type QueryEnquiryArgs = {
  id: Scalars['ID']['input'];
  resource: Scalars['ID']['input'];
};


export type QueryGetAdminCreatedContentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetEphemeralKeySecretArgs = {
  cardId: Scalars['String']['input'];
  nonce: Scalars['String']['input'];
};


export type QueryGetItemByUrlArgs = {
  country: Scalars['CountryCode']['input'];
  url: Scalars['String']['input'];
};


export type QueryGetLearnerSkillsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  filters?: InputMaybe<LearnerSkillsFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetUserContentArgs = {
  resourceId: Scalars['ID']['input'];
};


export type QueryGetUserContentPostUrlArgs = {
  resourceId: Scalars['ID']['input'];
};


export type QueryGetUserCreatedContentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGroupArgs = {
  id: Scalars['ID']['input'];
  organisation?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGroupAnalyticsArgs = {
  dateRange?: InputMaybe<DateRange>;
  groupId: Scalars['ID']['input'];
};


export type QueryInternalEventsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ResourceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ResourceOrderBy>;
};


export type QueryInternalResourcesArgs = {
  filter?: InputMaybe<ResourceFilter>;
  orderBy?: InputMaybe<ResourceOrderBy>;
};


export type QueryInvitationArgs = {
  invitationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryItemsByAsiNsArgs = {
  ASINs: Array<Scalars['ASIN']['input']>;
  country: AssociateCountryCode;
};


export type QueryLatestPlaylistsArgs = {
  organisation?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLeaderCommunitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLoginInfoArgs = {
  email: Scalars['String']['input'];
};


export type QueryLoginMethodArgs = {
  email: Scalars['String']['input'];
};


export type QueryOrganisationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryOrganisationAnalyticsArgs = {
  dateRange?: InputMaybe<DateRange>;
};


export type QueryOrganisationCommunitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPathwayArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlaylistArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlaylistsByIdArgs = {
  playlistIds: Array<Scalars['ID']['input']>;
};


export type QueryPreviewBulkUpdatesArgs = {
  data: Array<BulkUploadableUser>;
  organisationId: Scalars['ID']['input'];
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type QueryRecentActivityArgs = {
  jobFamily?: InputMaybe<Scalars['String']['input']>;
  organisation?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedeemCodeForRequestArgs = {
  requestId: Scalars['ID']['input'];
};


export type QueryRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRequestsArgs = {
  filter?: InputMaybe<RequestFilter>;
  orderBy?: InputMaybe<RequestOrderBy>;
  organisations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryResourceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryResourcePlaylistCountArgs = {
  resourceId: Scalars['ID']['input'];
};


export type QueryResourcesArgs = {
  filter?: InputMaybe<ResourceFilter>;
  orderBy?: InputMaybe<ResourceOrderBy>;
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchAllContentArgs = {
  query: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchExpertsArgs = {
  query: Scalars['String']['input'];
};


export type QuerySearchItemsArgs = {
  country: AssociateCountryCode;
  keywords: Scalars['String']['input'];
};


export type QuerySearchOrganisationMembersArgs = {
  query: Scalars['String']['input'];
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchOrganisationMembersFromArgs = {
  filters?: InputMaybe<FilterSelection>;
  from?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchPlaylistsArgs = {
  query: Scalars['String']['input'];
};


export type QuerySearchResourcesArgs = {
  filter?: InputMaybe<ResourceFilter>;
  orderBy?: InputMaybe<ResourceOrderBy>;
  query: Scalars['String']['input'];
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchResourcesAsAdminArgs = {
  filter?: InputMaybe<ResourceFilter>;
  orderBy?: InputMaybe<ResourceOrderBy>;
  query: Scalars['String']['input'];
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchSkillsArgs = {
  query: Scalars['String']['input'];
};


export type QuerySearchSuppliersArgs = {
  query: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchUsersArgs = {
  query: Scalars['String']['input'];
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySimilarResourcesArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySkillArgs = {
  skillId: Scalars['ID']['input'];
};


export type QuerySubjectArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubjectsArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySuppliersArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySuppliersByIdsArgs = {
  supplierIds: Array<Scalars['ID']['input']>;
};


export type QuerySuppliersBySubjectArgs = {
  filter?: InputMaybe<SuppliersFilter>;
  subjectId: Scalars['ID']['input'];
};


export type QuerySuppliersPaginatedArgs = {
  filter?: InputMaybe<SuppliersFilter>;
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTrendingResourcesArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserCommunitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type RecentActivity = {
  __typename?: 'RecentActivity';
  action: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<User>;
  resource: ActivityResource;
};

export type RecommendToPeersRequest = {
  isUserContent?: InputMaybe<Scalars['Boolean']['input']>;
  recommendedNote?: InputMaybe<Scalars['String']['input']>;
  resource: Scalars['ID']['input'];
  users: Array<Scalars['ID']['input']>;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  id?: Maybe<Scalars['String']['output']>;
};

export type RedeemCodeResult = NotEligibleForCodeRedemptionError | RedeemedCode;

export type RedeemedCode = {
  __typename?: 'RedeemedCode';
  code: Scalars['String']['output'];
  expirationTime: Scalars['DateTime']['output'];
  redemptionDate: Scalars['DateTime']['output'];
};

export type RejectRequest = {
  id: Scalars['ID']['input'];
  rejectNote?: InputMaybe<Scalars['String']['input']>;
  user: Scalars['ID']['input'];
};

export type Request = {
  __typename?: 'Request';
  additionalNote?: Maybe<Scalars['String']['output']>;
  address?: Maybe<DeliveryAddress>;
  adhoc?: Maybe<Scalars['Boolean']['output']>;
  adhocInfo?: Maybe<AdhocInfo>;
  approveNote?: Maybe<Scalars['String']['output']>;
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approvedBy?: Maybe<User>;
  autoApproved: Scalars['Boolean']['output'];
  cancelledBy?: Maybe<User>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['CurrencyCode']['output'];
  fulfilledByLearnerInfo?: Maybe<FulfilledByLearnerInfo>;
  fulfilmentAccount?: Maybe<FulfilmentAccount>;
  fulfilmentOrderId?: Maybe<Scalars['ID']['output']>;
  hasFulfilmentAccount?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isFulfilledByLearner: Scalars['Boolean']['output'];
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  other?: Maybe<Scalars['Int']['output']>;
  pot?: Maybe<Pot>;
  price?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<Product>;
  purchasedAt?: Maybe<Scalars['DateTime']['output']>;
  rejectNote?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  rejectedBy?: Maybe<User>;
  requestNote?: Maybe<Scalars['String']['output']>;
  resource?: Maybe<Resource>;
  resourceType?: Maybe<ResourceType>;
  /** @deprecated Use resourceType */
  resourceTypeId?: Maybe<Scalars['ID']['output']>;
  reviews?: Maybe<Array<Maybe<Review>>>;
  status: Scalars['String']['output'];
  totalCost?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type RequestConnection = {
  __typename?: 'RequestConnection';
  edges: Array<RequestEdge>;
  pageInfo: PageInfo;
};

export type RequestEdge = {
  __typename?: 'RequestEdge';
  cursor: Scalars['Cursor']['output'];
  node: Request;
};

export type RequestFilter = {
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum RequestOrderBy {
  ApprovedAtDesc = 'approvedAt_DESC',
  CreatedAtDesc = 'createdAt_DESC',
  PurchasedAtDesc = 'purchasedAt_DESC',
  RejectedAtDesc = 'rejectedAt_DESC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RequestRequest = {
  additionalNote?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<DeliveryAddressRequest>;
  algoliaQueryId?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['CurrencyCode']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  other?: InputMaybe<Scalars['Int']['input']>;
  product: Scalars['ID']['input'];
  requestNote?: InputMaybe<Scalars['String']['input']>;
  resource: Scalars['ID']['input'];
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  user: Scalars['ID']['input'];
};

export type RequestsValueGraph = {
  __typename?: 'RequestsValueGraph';
  date?: Maybe<Scalars['String']['output']>;
  requests?: Maybe<Scalars['Int']['output']>;
  spend?: Maybe<Scalars['Int']['output']>;
};

export type Resource = {
  __typename?: 'Resource';
  active?: Maybe<Scalars['Boolean']['output']>;
  allowedCountries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  allowedOrganisations?: Maybe<Array<Maybe<Organisation>>>;
  bookmarked: Scalars['Boolean']['output'];
  cheapestProduct?: Maybe<Product>;
  commission?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  description: Scalars['String']['output'];
  disallowedOrganisations?: Maybe<Array<Maybe<Organisation>>>;
  embeddedVideo?: Maybe<Scalars['String']['output']>;
  enquirable?: Maybe<Scalars['Boolean']['output']>;
  enquireLabel?: Maybe<Scalars['String']['output']>;
  experts?: Maybe<Array<Maybe<Expert>>>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  image1x1?: Maybe<Scalars['String']['output']>;
  image1x2?: Maybe<Scalars['String']['output']>;
  image1x3?: Maybe<Scalars['String']['output']>;
  image2x1?: Maybe<Scalars['String']['output']>;
  image3x1?: Maybe<Scalars['String']['output']>;
  image3x4?: Maybe<Scalars['String']['output']>;
  image4x3?: Maybe<Scalars['String']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  isInternalContent: Scalars['Boolean']['output'];
  isInternalEvent: Scalars['Boolean']['output'];
  learningOutcomes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  link?: Maybe<Scalars['String']['output']>;
  lowestPrice?: Maybe<Scalars['Int']['output']>;
  markedAsDone: Scalars['Boolean']['output'];
  organiserEmail?: Maybe<Scalars['String']['output']>;
  ownedBy?: Maybe<Scalars['ID']['output']>;
  products: Array<Product>;
  rating?: Maybe<Scalars['Float']['output']>;
  requested: Scalars['Boolean']['output'];
  requestedByColleagues?: Maybe<Array<Maybe<User>>>;
  reviews?: Maybe<Array<Maybe<Review>>>;
  skills: SkillConnection;
  subjects?: Maybe<Array<Maybe<Subject>>>;
  summary: Scalars['String']['output'];
  supplier?: Maybe<Supplier>;
  title: Scalars['String']['output'];
  type: ResourceType;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<User>;
};


export type ResourceCheapestProductArgs = {
  filter?: InputMaybe<ProductFilter>;
};


export type ResourceProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
};


export type ResourceSkillsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type ResourceFilter = {
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  include_inactive?: InputMaybe<Scalars['Boolean']['input']>;
  internalOnly?: InputMaybe<Scalars['Boolean']['input']>;
  price_range?: InputMaybe<PriceRangeFilter>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  suppliers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ResourceList = {
  __typename?: 'ResourceList';
  availableFilters: Array<TypeFilter>;
  results: Array<Resource>;
  searchAfter?: Maybe<Scalars['String']['output']>;
};

export type ResourceListViewItem = {
  id: Scalars['ID']['input'];
  index: Scalars['Int']['input'];
  pageViewId: Scalars['ID']['input'];
};

export enum ResourceOrderBy {
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  RelevanceDesc = 'relevance_DESC',
  TitleDesc = 'title_DESC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ResourcePlaylistCount = {
  __typename?: 'ResourcePlaylistCount';
  count: Scalars['Int']['output'];
};

export type ResourcePlaylists = {
  __typename?: 'ResourcePlaylists';
  count: Scalars['Int']['output'];
};

export type ResourceType = {
  __typename?: 'ResourceType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Result = Request | Resource | Supplier | User;

export type ResultKey = Group | ResourceType | Subject | User;

export type RetryApprovedRequestResponse = {
  __typename?: 'RetryApprovedRequestResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Review = {
  __typename?: 'Review';
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  focuses?: Maybe<Array<Maybe<Focus>>>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  rating?: Maybe<Scalars['Int']['output']>;
  request?: Maybe<Request>;
  resource?: Maybe<Resource>;
  user?: Maybe<User>;
};

export type ReviewRequest = {
  headline?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['ID']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  request?: InputMaybe<Scalars['ID']['input']>;
  resource: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  skills: SkillsConnection;
};


export type RoleSkillsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type RoleSkill = {
  __typename?: 'RoleSkill';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type S3File = {
  __typename?: 'S3File';
  filename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SendInvitationsResponse = {
  __typename?: 'SendInvitationsResponse';
  failedInvitationCount: Scalars['Int']['output'];
  sentInvitationCount: Scalars['Int']['output'];
};

export type SingleSignOnProvider = {
  __typename?: 'SingleSignOnProvider';
  provider: Scalars['SSOCustomProvider']['output'];
};

export type Skill = {
  __typename?: 'Skill';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type SkillConnection = {
  __typename?: 'SkillConnection';
  edges: Array<SkillEdge>;
  pageInfo: SkillsPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SkillEdge = {
  __typename?: 'SkillEdge';
  cursor: Scalars['Cursor']['output'];
  node?: Maybe<Skill>;
};

export type SkillsConnection = {
  __typename?: 'SkillsConnection';
  edges: Array<SkillsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SkillsEdge = {
  __typename?: 'SkillsEdge';
  cursor: Scalars['Cursor']['output'];
  node?: Maybe<RoleSkill>;
};

export type SkillsPageInfo = {
  __typename?: 'SkillsPageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type SlackInstallation = {
  __typename?: 'SlackInstallation';
  id: Scalars['ID']['output'];
  installedAt: Scalars['DateTime']['output'];
  installedBy: User;
  scopes: Array<Scalars['String']['output']>;
  teamName: Scalars['String']['output'];
};

export type StringBox = {
  __typename?: 'StringBox';
  value: Scalars['String']['output'];
};

export type Subject = {
  __typename?: 'Subject';
  archived?: Maybe<Scalars['Boolean']['output']>;
  children?: Maybe<Array<Subject>>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Subject>;
  playlists: Array<Playlist>;
  suppliers?: Maybe<Array<Supplier>>;
};


export type SubjectPlaylistsArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type SubjectRequest = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Supplier = {
  __typename?: 'Supplier';
  archived?: Maybe<Scalars['Boolean']['output']>;
  codeClaim: Scalars['Boolean']['output'];
  codeClaimHelperText?: Maybe<Scalars['String']['output']>;
  codeClaimOption?: Maybe<Scalars['String']['output']>;
  commission?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalIds?: Maybe<SupplierExternalIds>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imageCentreAlign?: Maybe<Scalars['String']['output']>;
  imageRectangular?: Maybe<Scalars['String']['output']>;
  imageSquare?: Maybe<Scalars['String']['output']>;
  imageTopAlign?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  multiUseCode?: Maybe<MultiUseCode>;
  name: Scalars['String']['output'];
  subjects?: Maybe<Array<Subject>>;
};

export type SupplierCodes = {
  __typename?: 'SupplierCodes';
  uploadedCodeCount: Scalars['Int']['output'];
};

export type SupplierExternalIds = {
  __typename?: 'SupplierExternalIds';
  crm?: Maybe<Scalars['ExternalID']['output']>;
};

export type SuppliersFilter = {
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Task = {
  __typename?: 'Task';
  approvals: Array<Request>;
  requestsHistory: Array<Request>;
};


export type TaskApprovalsArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type TaskRequestsHistoryArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeRange = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type TypeFilter = {
  __typename?: 'TypeFilter';
  count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type UpdateAccountBudgetInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  maxSpend?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCommunityInput = {
  contents: Array<CommunityContentInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  members: Array<CommunityMemberInput>;
  name: Scalars['String']['input'];
  skills: Array<CommunitySkillInput>;
};

export type UpdateCommunityResponse = Community | DuplicateEntityError | InvalidInputError | NotFoundError;

export type UpdateDefaultUserBudgets = {
  currency: Scalars['CurrencyCode']['input'];
  proRata: Scalars['Boolean']['input'];
  total: Scalars['Int']['input'];
};

export type UpdateExpert = {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFeatureToggle = {
  active: Scalars['Boolean']['input'];
  admin: Scalars['Boolean']['input'];
  organisations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  user: Scalars['Boolean']['input'];
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateFulfilmentAccount = {
  autoApprovedThreshold?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['CurrencyCode']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOffice = {
  location: LocationRequest;
  officeId: Scalars['ID']['input'];
  organisationId: Scalars['ID']['input'];
};

export type UpdateOrganisation = {
  adhocRequests: Scalars['Boolean']['input'];
  analyticsDashboard: Scalars['Boolean']['input'];
  defaultOffice?: InputMaybe<Scalars['ID']['input']>;
  externalIds?: InputMaybe<InputOrganisationExternalIds>;
  fulfilmentAccounts?: InputMaybe<Array<InputMaybe<UpdateFulfilmentAccount>>>;
  hasAmazonRequests: Scalars['Boolean']['input'];
  hasFulfilledByLearnerRequests: Scalars['Boolean']['input'];
  hasFulfilledByLearnerViaExpensesRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasFunds?: InputMaybe<Scalars['Boolean']['input']>;
  hasMagicLinks?: InputMaybe<Scalars['Boolean']['input']>;
  internalEvents: Scalars['Boolean']['input'];
  internalLearning: Scalars['Boolean']['input'];
  isInProofOfValue?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  recentActivity: Scalars['Boolean']['input'];
  showOnlyFreeResources?: InputMaybe<Scalars['Boolean']['input']>;
  testClient: Scalars['Boolean']['input'];
};

export type UpdateOrganisationResponse = Organisation | OrganisationUpdateError;

export type UpdatePlaylistFieldsRequest = {
  allowedCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  allowedOrganisations?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  entries?: InputMaybe<Array<PlaylistEntryRequest>>;
  expert?: InputMaybe<Scalars['ID']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  subjects?: InputMaybe<Array<Scalars['ID']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePotInput = {
  allocatedFunds?: InputMaybe<Scalars['Float']['input']>;
  approvers?: InputMaybe<Array<Scalars['ID']['input']>>;
  budgetId?: InputMaybe<Scalars['String']['input']>;
  consumers?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateResourceRequest = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowedCountries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  allowedOrganisations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  description: Scalars['String']['input'];
  disallowedOrganisations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  embeddedVideo?: InputMaybe<Scalars['String']['input']>;
  enquirable?: InputMaybe<Scalars['Boolean']['input']>;
  enquireLabel?: InputMaybe<Scalars['String']['input']>;
  experts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  image?: InputMaybe<Scalars['String']['input']>;
  image1x1?: InputMaybe<Scalars['String']['input']>;
  image1x2?: InputMaybe<Scalars['String']['input']>;
  image1x3?: InputMaybe<Scalars['String']['input']>;
  image2x1?: InputMaybe<Scalars['String']['input']>;
  image3x1?: InputMaybe<Scalars['String']['input']>;
  image3x4?: InputMaybe<Scalars['String']['input']>;
  image4x3?: InputMaybe<Scalars['String']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  learningOutcomes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link?: InputMaybe<Scalars['String']['input']>;
  ownedBy?: InputMaybe<Scalars['ID']['input']>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  summary: Scalars['String']['input'];
  supplier?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  type: Scalars['ID']['input'];
};

export type UpdateSupplier = {
  codeClaim?: InputMaybe<Scalars['Boolean']['input']>;
  codeClaimHelperText?: InputMaybe<Scalars['String']['input']>;
  codeClaimOption?: InputMaybe<Scalars['String']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalIds?: InputMaybe<InputSupplierExternalIds>;
  imageCentreAlign?: InputMaybe<Scalars['String']['input']>;
  imageRectangular?: InputMaybe<Scalars['String']['input']>;
  imageSquare?: InputMaybe<Scalars['String']['input']>;
  imageTopAlign?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  multiUseCode?: InputMaybe<MultiUseCodeInput>;
  name: Scalars['String']['input'];
  subjects?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateUserContentInput = {
  allowCompletion?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  imageName?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: InternalResourceType;
};

export type UpdateUserPreferencesInput = {
  focuses: Array<Scalars['ID']['input']>;
  jobTitle: Scalars['String']['input'];
};

export type UpdateUserProfileInput = {
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobFamily?: InputMaybe<Scalars['String']['input']>;
  jobFamilyOther?: InputMaybe<Scalars['String']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  jobLevelOther?: InputMaybe<Scalars['String']['input']>;
  jobProfileSkippedAt?: InputMaybe<Scalars['DateTime']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserRequest = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  approvers?: InputMaybe<Array<Scalars['ID']['input']>>;
  country?: InputMaybe<Scalars['CountryCode']['input']>;
  customField1?: InputMaybe<Scalars['String']['input']>;
  customField2?: InputMaybe<Scalars['String']['input']>;
  customField3?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  geographicLocation?: InputMaybe<Scalars['CountryCode']['input']>;
  groups?: InputMaybe<Array<Scalars['ID']['input']>>;
  impersonate?: InputMaybe<Scalars['ID']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobFamily?: InputMaybe<Scalars['String']['input']>;
  jobFamilyOther?: InputMaybe<Scalars['String']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  jobLevelOther?: InputMaybe<Scalars['String']['input']>;
  jobProfileSkippedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisationAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UploadCodeInput = {
  code: Scalars['String']['input'];
  costPrice?: InputMaybe<Scalars['Int']['input']>;
  costPriceCurrency?: InputMaybe<Scalars['CurrencyCode']['input']>;
  expirationTime: Scalars['DateTime']['input'];
};

export type UploadCodesError = Error & {
  __typename?: 'UploadCodesError';
  message: Scalars['String']['output'];
};

export type UploadCodesResult = SupplierCodes | UploadCodesError;

export type User = {
  __typename?: 'User';
  accessibleBudgets?: Maybe<Array<Maybe<Budget>>>;
  activeIndividualBudget?: Maybe<Budget>;
  addresses: Array<DeliveryAddress>;
  admin: Scalars['Boolean']['output'];
  algoliaSearchKey: Scalars['String']['output'];
  approverNote?: Maybe<ApproverNote>;
  approvers: Array<Maybe<User>>;
  approversOf: Array<User>;
  archived: Scalars['Boolean']['output'];
  bookmarkedResourceIdsForSearchResult: Array<Scalars['ID']['output']>;
  bookmarkedResources?: Maybe<ListResult>;
  budget?: Maybe<Budget>;
  budgets?: Maybe<Array<Maybe<Budget>>>;
  country: Scalars['CountryCode']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['CurrencyCode']['output'];
  customField1?: Maybe<Scalars['String']['output']>;
  customField2?: Maybe<Scalars['String']['output']>;
  customField3?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  exchangeRates: ExchangeRates;
  featureToggles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  firstName: Scalars['String']['output'];
  focuses?: Maybe<Array<Maybe<Focus>>>;
  geographicLocation?: Maybe<Scalars['CountryCode']['output']>;
  goals?: Maybe<Array<Goal>>;
  groups?: Maybe<Array<Maybe<Group>>>;
  hasCookieConsent: Scalars['Boolean']['output'];
  hasPassword?: Maybe<Scalars['Boolean']['output']>;
  hasProvidedConsentToShareContactDetails: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  impersonate?: Maybe<User>;
  inProgressPathways: PathwayConnection;
  individualBudgets?: Maybe<Array<Maybe<Budget>>>;
  intercomHmac?: Maybe<Scalars['String']['output']>;
  invitedAt?: Maybe<Scalars['DateTime']['output']>;
  isImpersonated?: Maybe<Scalars['Boolean']['output']>;
  isManager?: Maybe<Scalars['Boolean']['output']>;
  jobFamily?: Maybe<Scalars['String']['output']>;
  jobFamilyOther?: Maybe<Scalars['String']['output']>;
  jobFamilyRecommendedResources?: Maybe<ResourceList>;
  jobLevel?: Maybe<Scalars['String']['output']>;
  jobLevelOther?: Maybe<Scalars['String']['output']>;
  jobProfileSkippedAt?: Maybe<Scalars['DateTime']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  latestPlaylists?: Maybe<Array<Maybe<Playlist>>>;
  learnerSkills: Array<LearnerSkill>;
  locale: Scalars['Locale']['output'];
  mostRecentRequestDate?: Maybe<Scalars['DateTime']['output']>;
  office?: Maybe<Location>;
  onboarded?: Maybe<Scalars['Boolean']['output']>;
  organisation: Organisation;
  organisationAdmin: Scalars['Boolean']['output'];
  pathways: PathwayConnection;
  pathwaysAnalytics: PathwaysAnalytics;
  pdpFocuses: Array<Maybe<PdpFocus>>;
  playlists: PlaylistConnection;
  potApprovals: RequestConnection;
  pots?: Maybe<Array<Pot>>;
  potsActivity: RequestConnection;
  potsApproverOf?: Maybe<UserPotsConnection>;
  recommendedPlaylists?: Maybe<Array<Maybe<Playlist>>>;
  recommendedResources?: Maybe<ResourceList>;
  requests?: Maybe<Array<Maybe<Request>>>;
  resourceTypes?: Maybe<Array<Maybe<ResourceType>>>;
  role?: Maybe<Role>;
  startedPDP: Scalars['Boolean']['output'];
  tasks?: Maybe<Task>;
  totalPotSpend: Scalars['Float']['output'];
  upcomingBudget?: Maybe<Budget>;
};


export type UserApproversOfArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type UserBookmarkedResourceIdsForSearchResultArgs = {
  resourceIdsFromSearchResult: Array<Scalars['ID']['input']>;
};


export type UserBookmarkedResourcesArgs = {
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type UserGroupsArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type UserInProgressPathwaysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type UserJobFamilyRecommendedResourcesArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type UserLatestPlaylistsArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type UserMostRecentRequestDateArgs = {
  potId: Scalars['ID']['input'];
};


export type UserPathwaysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPlaylistsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPotApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<ApproverRequestStatus>>;
};


export type UserPotsActivityArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPotsApproverOfArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type UserRecommendedPlaylistsArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type UserRecommendedResourcesArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type UserRequestsArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTotalPotSpendArgs = {
  potId: Scalars['ID']['input'];
};

export type UserContent = {
  __typename?: 'UserContent';
  allowCompletion: Scalars['Boolean']['output'];
  attachment: Attachment;
  completed: Scalars['Boolean']['output'];
  completedBy: Array<CompletedBy>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageName?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  playlists?: Maybe<UserContentPlaylists>;
  resourceImageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<ResourceType>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type UserContentConnection = {
  __typename?: 'UserContentConnection';
  edges: Array<UserContentEdge>;
  pageInfo: PageInfo;
};

export type UserContentEdge = {
  __typename?: 'UserContentEdge';
  cursor: Scalars['Cursor']['output'];
  node: UserContent;
};

export type UserContentInput = {
  allowCompletion: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageName?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: InternalResourceType;
};

export type UserContentPlaylists = {
  __typename?: 'UserContentPlaylists';
  count: Scalars['Int']['output'];
};

export type UserError = {
  message: Scalars['String']['output'];
};

export type UserLoginInfo = {
  __typename?: 'UserLoginInfo';
  singleSignOn: Scalars['Boolean']['output'];
  singleSignOnCustomProvider?: Maybe<Scalars['SSOCustomProvider']['output']>;
};

export type UserPathwayActionProgression = {
  __typename?: 'UserPathwayActionProgression';
  id: Scalars['ID']['output'];
  status?: Maybe<UserPathwayActionProgressionStatus>;
};

export enum UserPathwayActionProgressionStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type UserPathwayProgression = {
  __typename?: 'UserPathwayProgression';
  actions: Array<UserPathwayActionProgression>;
  createdAt: Scalars['DateTime']['output'];
  status?: Maybe<UserPathwayProgressionStatus>;
};

export enum UserPathwayProgressionStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Started = 'STARTED'
}

export type UserPotsConnection = {
  __typename?: 'UserPotsConnection';
  edges: Array<UserPotsEdge>;
  pageInfo: PageInfo;
};

export type UserPotsEdge = {
  __typename?: 'UserPotsEdge';
  cursor: Scalars['Cursor']['output'];
  node: Pot;
};

export type UserRequest = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  approvers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  country: Scalars['CountryCode']['input'];
  customField1?: InputMaybe<Scalars['String']['input']>;
  customField2?: InputMaybe<Scalars['String']['input']>;
  customField3?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  geographicLocation?: InputMaybe<Scalars['CountryCode']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lastName: Scalars['String']['input'];
  organisation: Scalars['ID']['input'];
  organisationAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserResourceFeedbackAction {
  Hide = 'HIDE'
}

export enum UserResourceFeedbackReason {
  Consumed = 'CONSUMED',
  Relevance = 'RELEVANCE'
}

export type VirtualCard = {
  __typename?: 'VirtualCard';
  id: Scalars['String']['output'];
};

export type VirtualCardProduct = Product & {
  __typename?: 'VirtualCardProduct';
  active: Scalars['Boolean']['output'];
  autoApproved: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['CountryCode']['output']>;
  currency?: Maybe<Scalars['CurrencyCode']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFulfilmentManaged: Scalars['Boolean']['output'];
  isGlobal: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  localisedPrice?: Maybe<Price>;
  price?: Maybe<Scalars['Int']['output']>;
  resource: Resource;
  type: Scalars['String']['output'];
};


export type VirtualCardProductLocalisedPriceArgs = {
  currency: Scalars['CurrencyCode']['input'];
};

export type VirtualCardProductRequest = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['ID']['output'];
  currency: Scalars['CurrencyCode']['output'];
  id: Scalars['ID']['output'];
  organisationId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type WalletInput = {
  currency: Scalars['CurrencyCode']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organisationId?: InputMaybe<Scalars['ID']['input']>;
};

export type DoesIdentityProviderExistOutput = {
  __typename?: 'doesIdentityProviderExistOutput';
  doesExist: Scalars['Boolean']['output'];
};

export type SearchResourcesQueryVariables = Exact<{
  query: Scalars['String']['input'];
  searchAfter?: InputMaybe<Scalars['String']['input']>;
}>;


export type SearchResourcesQuery = { __typename?: 'Query', resources?: { __typename?: 'ResourceList', searchAfter?: string | null, results: Array<{ __typename?: 'Resource', id: string, title: string, type: { __typename?: 'ResourceType', id: string, name: string } }> } | null };


export const SearchResourcesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchResources"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"searchAfter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"resources"},"name":{"kind":"Name","value":"searchResourcesAsAdmin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}},{"kind":"Argument","name":{"kind":"Name","value":"size"},"value":{"kind":"IntValue","value":"10"}},{"kind":"Argument","name":{"kind":"Name","value":"searchAfter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"searchAfter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchAfter"}},{"kind":"Field","name":{"kind":"Name","value":"results"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Resource"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<SearchResourcesQuery, SearchResourcesQueryVariables>;