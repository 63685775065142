import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import ExpertForm from "../expert-form";

import createExpert from "./create-expert.graphql";

import theme from "./theme.css";

const onSubmit = (createExpert, onSuccess, handleDialog) => (data) =>
  createExpert({
    variables: { expert: data },
  }).then(({ data }) => {
    onSuccess(data.createExpert);
    handleDialog();
  });

const CreateExpertDialog = ({
  active,
  handleDialog,
  onSuccess,
  createExpert,
}) => (
  <Dialog
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
    theme={theme}
  >
    <ExpertForm
      submitLabel="Create Expert"
      handleDialog={handleDialog}
      onSubmit={onSubmit(createExpert, onSuccess, handleDialog)}
    />
  </Dialog>
);

CreateExpertDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  createExpert: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default graphql(createExpert, {
  name: "createExpert",
})(CreateExpertDialog);
