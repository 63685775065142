import { Col, Row } from "react-flexbox-grid";
import { List, ListItem, Button, Tabs, Tab, ProgressBar } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import ArchiveSubjectDialog from "../../components/archive-subject-dialog";
import CreateSubjectDialog from "../../components/create-subject-dialog";
import UpdateSubjectDialog from "../../components/update-subject-dialog";

import subjectsQuery from "./subjects.graphql";

const sortAlpha = (a, b) => {
  if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
  if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
  return 0;
};

class Subjects extends React.Component {
  constructor(props) {
    super(props);

    this.handleCreateDialog = this.handleCreateDialog.bind(this);
    this.handleUpdateDialog = this.handleUpdateDialog.bind(this);
    this.handleArchiveDialog = this.handleArchiveDialog.bind(this);
    this.onSubjectClick = this.onSubjectClick.bind(this);
    this.onArchiveClick = this.onArchiveClick.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);

    this.state = {
      create: false,
      update: false,
      archive: false,
      updateSubject: {},
      index: 0,
    };
  }

  handleCreateDialog() {
    this.setState({
      create: !this.state.create,
    });
  }

  handleUpdateDialog() {
    this.setState({
      update: !this.state.update,
    });
  }

  handleArchiveDialog() {
    this.setState({
      archive: !this.state.archive,
    });
  }

  onSubjectClick(subject) {
    return () => {
      this.setState({
        update: true,
        updateSubject: subject,
      });
    };
  }

  onArchiveClick(id) {
    return () => {
      this.setState({
        update: false,
        archive: true,
        archiveId: id,
      });
    };
  }

  handleTabChange(index) {
    this.setState({ index });
  }

  render() {
    const {
      data: { subjects, loading },
    } = this.props;

    if (loading) {
      return <ProgressBar mode="indeterminate" />;
    }

    const sortedSubjects = subjects
      ? subjects
          .map(({ id, name, description, archived, children }) => ({
            id,
            name,
            description,
            archived,
            children,
          }))
          .sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase())
      : [];

    const activeSubjects = sortedSubjects
      .filter((subject) => !subject.archived)
      .sort(sortAlpha);
    const archivedSubjects = sortedSubjects
      .filter((subject) => subject.archived)
      .sort(sortAlpha);

    return (
      <div>
        <Row between="xs">
          <Col xs={9}>
            <Tabs index={this.state.index} onChange={this.handleTabChange}>
              {activeSubjects.length > 0 && (
                <Tab label="Active">
                  <List>
                    {activeSubjects.map((subject, index) => (
                      <ListItem
                        key={index}
                        caption={subject.name}
                        legend={subject.description}
                        onClick={this.onSubjectClick(subject)}
                        leftIcon={subject.children ? "folder" : "subject"}
                      />
                    ))}
                  </List>
                </Tab>
              )}
              {archivedSubjects.length > 0 && (
                <Tab label="Archived">
                  <List>
                    {archivedSubjects.map((subject, index) => (
                      <ListItem
                        key={index}
                        caption={subject.name}
                        legend={subject.description}
                        rightIcon="blocked"
                      />
                    ))}
                  </List>
                </Tab>
              )}
            </Tabs>
          </Col>
          <Col>
            <Button
              icon="add"
              label="Create Subject"
              onClick={this.handleCreateDialog}
              raised
              primary
            />
          </Col>
        </Row>
        <CreateSubjectDialog
          active={this.state.create}
          handleDialog={this.handleCreateDialog}
        />
        <UpdateSubjectDialog
          active={this.state.update}
          handleDialog={this.handleUpdateDialog}
          onArchiveClick={this.onArchiveClick}
          subject={this.state.updateSubject}
        />
        <ArchiveSubjectDialog
          active={this.state.archive}
          handleDialog={this.handleArchiveDialog}
          id={this.state.archiveId}
        />
      </div>
    );
  }
}

Subjects.propTypes = {
  data: PropTypes.object,
};

export default graphql(subjectsQuery)(Subjects);
