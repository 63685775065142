import React, { useState } from "react";
import { Button, Dropdown, ProgressBar } from "react-toolbox";
import { Field, reduxForm } from "redux-form";
import Joi from "joi-browser";
import { useFeature } from "@growthbook/growthbook-react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderCheckbox from "../checkbox";
import RenderField from "../field";
import PotsOrgDialog from "../pots-org-dialog";

const schema = Joi.object({
  name: Joi.string().label("Name").required(),
  hasPots: Joi.boolean().default(false),
  currency: Joi.string()
    .valid("GBP", "EUR", "USD")
    .when("hasPots", {
      is: Joi.boolean().equal(true),
      then: Joi.required(),
    }),
});

const RenderDropdown = ({ input, ...props }) => (
  <Dropdown {...input} {...props} />
);

RenderDropdown.propTypes = {
  input: PropTypes.object,
};

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const mapFormValuesToMutation = (onSubmit) => ({ ...data }) =>
  onSubmit({
    ...data,
  });

const OrganisationForm = ({
  handleSubmit,
  submitLabel,
  onSubmit,
  loading,
  change,
}) => {
  const [showPotsConfirmationDialog, setShowPotsConfirmationDialog] = useState(
    false
  );
  const [hasPots, setHasPots] = useState(false);

  const onHasPotsChecked = (_, hasPotsValue) => {
    if (hasPotsValue) {
      setShowPotsConfirmationDialog(true);
      return;
    }

    setHasPots(false);
    change("hasPots", false);
    change("currency", undefined);
  };

  const onConfirmPotsOrg = () => {
    setHasPots(true);
    setShowPotsConfirmationDialog(false);
  };

  const onCancelPotsOrg = () => {
    change("hasPots", false);
    setShowPotsConfirmationDialog(false);
  };

  const hasMagicLinks = useFeature("create_org_magic_links").on;

  return (
    <form
      onSubmit={handleSubmit(
        mapFormValuesToMutation((formData) =>
          onSubmit({
            ...formData,
            hasMagicLinks,
          })
        )
      )}
    >
      <Field component={RenderField} label="Name" name="name" />
      <Field
        name="hasPots"
        label="Make this a Pots organisation"
        component={RenderCheckbox}
        onChange={onHasPotsChecked}
      />

      {hasPots && (
        <Field
          name="currency"
          label="Currency"
          component={RenderDropdown}
          auto
          source={[
            {
              value: "EUR",
              label: "EUR",
            },
            {
              value: "GBP",
              label: "GBP",
            },
            {
              value: "USD",
              label: "USD",
            },
          ]}
        />
      )}

      <Button type="submit" raised primary disabled={loading}>
        {submitLabel}
      </Button>

      {loading && <ProgressBar mode="indeterminate" />}

      <PotsOrgDialog
        isOpen={showPotsConfirmationDialog}
        onCancel={onCancelPotsOrg}
        onConfirm={onConfirmPotsOrg}
      />
    </form>
  );
};

OrganisationForm.propTypes = {
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  organisationId: PropTypes.string,
};

export default reduxForm({
  form: "organisation",
  validate: mapFormValuesToMutation(validate),
})(OrganisationForm);
