import React, { useState } from "react";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import { ProgressBar, List, ListItem, Button, Tabs, Tab } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Link } from "react-router-dom";

import organisationsQuery from "./organisations.graphql";

const Organisations = ({ organisationsQuery, history }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  if (organisationsQuery.loading) {
    return <ProgressBar mode="indeterminate" />;
  }

  if (!organisationsQuery.organisations.length) {
    return <div>No results</div>;
  }

  const organisations = [...organisationsQuery.organisations].sort((a, b) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
    return 0;
  });

  return (
    <div>
      <Row>
        <Col sm={8}>
          <div>
            <Tabs
              index={selectedTab}
              onChange={(index) => setSelectedTab(index)}
            >
              <Tab label="Active">
                <List>
                  {organisations
                    .filter(
                      (organisation) =>
                        !organisation.archived && !organisation.testClient
                    )
                    .map((result) => (
                      <ListItem
                        key={result.id}
                        rightIcon="edit"
                        leftIcon={
                          !result.archived
                            ? result.testClient
                              ? "timelapses"
                              : "verified_user"
                            : "archive"
                        }
                        caption={result.name}
                        onClick={() =>
                          history.push(`/organisations/${result.id}/`)
                        }
                      />
                    ))}
                </List>
              </Tab>
              <Tab label="Demo">
                <List>
                  {organisations
                    .filter(
                      (organisation) =>
                        organisation.testClient && !organisation.archived
                    )
                    .map((result) => (
                      <ListItem
                        key={result.id}
                        rightIcon="edit"
                        leftIcon={
                          !result.archived
                            ? result.testClient
                              ? "timelapses"
                              : "verified_user"
                            : "archive"
                        }
                        caption={result.name}
                        onClick={() =>
                          history.push(`/organisations/${result.id}/`)
                        }
                      />
                    ))}
                </List>
              </Tab>
              <Tab label="Archived">
                <List>
                  {organisations
                    .filter((organisation) => organisation.archived)
                    .map((result) => (
                      <ListItem
                        key={result.id}
                        rightIcon="edit"
                        leftIcon={
                          !result.archived
                            ? result.testClient
                              ? "timelapses"
                              : "verified_user"
                            : "archive"
                        }
                        caption={result.name}
                        onClick={() =>
                          history.push(`/organisations/${result.id}/`)
                        }
                      />
                    ))}
                </List>
              </Tab>
            </Tabs>
          </div>
        </Col>
        <Col sm={2} smOffset={1}>
          <Link to="/organisations/create/">
            <Button icon="add" label="New Organisation" raised primary />
          </Link>
        </Col>
      </Row>
    </div>
  );
};

Organisations.propTypes = {
  organisationsQuery: PropTypes.shape({
    error: PropTypes.bool,
    fetchMore: PropTypes.func,
    loading: PropTypes.bool,
    networkStatus: PropTypes.number,
    organisations: PropTypes.arrayOf(
      PropTypes.shape({
        analyticsDashboard: PropTypes.bool,
        archived: PropTypes.bool,
        id: PropTypes.string,
        name: PropTypes.string,
        recentActivity: PropTypes.bool,
        testClient: PropTypes.bool,
      })
    ),
    refetch: PropTypes.func,
    startPolling: PropTypes.func,
    stopPolling: PropTypes.func,
    subscribeToMore: PropTypes.func,
    updateQuery: PropTypes.func,
  }),
  history: PropTypes.shape({
    action: PropTypes.string,
    block: PropTypes.func,
    createHref: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    length: PropTypes.number,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.string,
      key: PropTypes.string,
    }),
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
};

export default graphql(organisationsQuery, {
  name: "organisationsQuery",
  options: { fetchPolicy: "cache-and-network" },
})(Organisations);
