import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import PurchaseRequestForm from "../purchase-request-form";

import purchaseRequestQuery from "./purchase-request.graphql";

const onSubmit = (purchaseRequestQuery, handleDialog, user, requestId) => (
  data
) =>
  purchaseRequestQuery({
    variables: {
      request: {
        id: requestId,
        user,
        price: data.price,
        commission: data.commission,
        vat: data.vat,
      },
    },
  }).then(handleDialog);

const getCommission = (resource) => {
  if (!resource) {
    return;
  }

  const commission =
    resource.commission || (resource.supplier && resource.supplier.commission);

  return commission && Math.round(commission * 100);
};

const PurchaseRequestDialog = ({
  active,
  purchaseRequestQuery,
  handleDialog,
  request,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
  >
    {request && (
      <PurchaseRequestForm
        onSubmit={onSubmit(
          purchaseRequestQuery,
          handleDialog,
          request.user.id,
          request.id
        )}
        initialValues={{
          price: request.price,
          commission: getCommission(request.resource),
        }}
        handleDialog={handleDialog}
      />
    )}
  </Dialog>
);

PurchaseRequestDialog.propTypes = {
  active: PropTypes.bool,
  purchaseRequestQuery: PropTypes.func,
  handleDialog: PropTypes.func,
  request: PropTypes.shape({
    additionalNote: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      postcode: PropTypes.string,
      street: PropTypes.string,
    }),
    adhoc: PropTypes.bool,
    adhocInfo: PropTypes.string,
    approveNote: PropTypes.string,
    approvedAt: PropTypes.string,
    approvedBy: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    currency: PropTypes.string,
    hasFulfilmentAccount: PropTypes.bool,
    id: PropTypes.string,
    price: PropTypes.number,
    product: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
    requestNote: PropTypes.string,
    resource: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      commission: PropTypes.number,
      link: PropTypes.string,
      supplier: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
    totalCost: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.string,
      accessibleBudgets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      ),
      activeIndividualBudget: PropTypes.shape({
        id: PropTypes.string,
      }),
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      organisation: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
};

export default graphql(purchaseRequestQuery, {
  name: "purchaseRequestQuery",
})(PurchaseRequestDialog);
