import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-flexbox-grid";

import Review from "./review";

import theme from "./theme.css";

const ReviewTableHeader = () => (
  <>
    <Row middle="xs" between="xs">
      <h3>Reviews</h3>
    </Row>
    <Row between="xs" className={theme.tableHeader}>
      <Col sm={1}>User ID</Col>
      <Col sm={1}>User name</Col>
      <Col sm={1}>Organsation</Col>
      <Col sm={2}>Headline</Col>
      <Col sm={1}>Rating</Col>
      <Col sm={3}>Content</Col>
      <Col sm={1}>Delete</Col>
    </Row>
  </>
);

const filterDeletedReviews = (reviews) =>
  reviews.filter((review) => !review.isDeleted);

const Reviews = ({ reviews, resourceId }) => (
  <div className={theme.container} id="reviews">
    <ReviewTableHeader />
    {reviews && !!reviews.length && (
      <>
        {filterDeletedReviews(reviews).map((review) => (
          <Review key={review.id} review={review} resourceId={resourceId} />
        ))}
      </>
    )}
  </div>
);

export const reviewType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  rating: PropTypes.number,
  note: PropTypes.string,
  headline: PropTypes.string,
  deletedAt: PropTypes.string,
  deletedBy: PropTypes.string,
  isDeleted: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organisation: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
});

Reviews.propTypes = {
  resourceId: PropTypes.string.isRequired,
  reviews: PropTypes.arrayOf(reviewType),
};

export default Reviews;
