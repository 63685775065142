import { Snackbar } from "react-toolbox";
import { graphql } from "react-apollo";
import { compose } from "redux";
import React from "react";

import PropTypes from "prop-types";

import UserForm from "../../components/user-form";

import createUser from "./create-user.graphql";
import sendInvitation from "./send-invitation.graphql";

const onSubmit = (
  createUser,
  sendInvitation,
  history,
  organisationId,
  failure
) => ({ sendNotification, ...user }) =>
  createUser({
    variables: {
      user: {
        organisation: organisationId,
        ...user,
      },
    },
  })
    .then(({ data: { createUser } }) => createUser)
    .then((user) => {
      if (sendNotification) {
        return sendInvitation({
          variables: {
            userId: user.id,
          },
        }).then(() => user);
      }

      return user;
    })
    .then((user) => history.push(`/users/${user.id}/`))
    .catch(failure);

class CreateUser extends React.Component {
  constructor(...props) {
    super(...props);

    this.state = {
      alert: false,
    };
  }

  render() {
    const {
      createUser,
      currentUser,
      sendInvitation,
      history,
      match,
    } = this.props;
    return (
      <div>
        <h1>Create User</h1>
        <UserForm
          submitLabel="Create User"
          onSubmit={onSubmit(
            createUser,
            sendInvitation,
            history,
            match.params.organisationId,
            () =>
              this.setState({
                alert: true,
                message: "Failure 😞",
              })
          )}
          organisationId={match.params.organisationId}
          initialValues={{
            sendNotification: true,
            country: "GB",
          }}
          currentUser={currentUser}
        />
        <Snackbar
          action="Dismiss"
          active={this.state.alert}
          label={this.state.message}
          timeout={2000}
          onTimeout={() => this.setState({ alert: false })}
          type="cancel"
        />
      </div>
    );
  }
}

CreateUser.propTypes = {
  createUser: PropTypes.func,
  currentUser: PropTypes.object,
  sendInvitation: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default compose(
  graphql(createUser, { name: "createUser" }),
  graphql(sendInvitation, { name: "sendInvitation" })
)(CreateUser);
