import { Button, Dropdown, DatePicker } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { graphql } from "react-apollo";
import Joi from "joi-browser";
import React, { useState } from "react";
import Markdown from "react-remarkable";
import { useFeature } from "@growthbook/growthbook-react";
import { Snackbar } from "@material-ui/core";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderField from "../field";
import RenderSwitch from "../switch";
import RenderCheckbox from "../checkbox/checkbox";
import { UploadCodes } from "../supplier-form/upload-codes";
import currencyPicker from "../currency-picker";

import uploadProductCodes from "./upload-product-codes.graphql";

import styles from "./styles.css";

const schema = Joi.object({
  price: Joi.number().integer().min(0).required(),
  description: Joi.string().allow(null, ""),
  active: Joi.boolean().label("Active"),
  link: Joi.string().required(),
  codeClaim: Joi.boolean().default(false),
  codeClaimHelperText: Joi.string().allow(null, ""),
  codes: Joi.array(),
  codeClaimOption: Joi.string().valid("single", "multi"),
  multiUseCode: Joi.object({
    value: Joi.string(),
    price: Joi.number().integer().min(0),
    expirationTime: Joi.date(),
    currency: Joi.string().length(3).default("GBP"),
  }).allow(null),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const parseNull = (value) => value || null;

const HelperTextFieldWithPreview = ({ input, ...props }) => {
  return (
    <div className={styles.helpertext}>
      <RenderField input={input} {...props} />

      {input.value && (
        <>
          <h4>Preview</h4>
          <div className={styles.preview}>
            <Markdown source={input.value} />
          </div>
        </>
      )}
    </div>
  );
};

HelperTextFieldWithPreview.propTypes = {
  input: PropTypes.object,
};

const UpdateOnlineCourseForm = ({
  handleDialog,
  handleSubmit,
  onSubmit,
  initialValues,
  loading,
  supplier,
  product,
  resourceId,
  uploadProductCodes,
  codeClaimOption,
}) => {
  const [alert, setAlert] = useState({ message: null, show: false });
  const [uploadLoading, setUploadLoading] = useState(false);
  const [codeClaimCheckbox, setCodeClaimCheckbox] = useState(
    initialValues.codeClaim
  );
  const showUploadCode = useFeature("self_service_product_codes").on;

  const invokeUploadCodesMutation = async (inventory, codes) => {
    try {
      const { data } = await uploadProductCodes({
        variables: { inventory, codes },
      });
      if (data) {
        setAlert({ message: "Success to upload codes 🎉", show: true });
      }
    } catch (error) {
      setAlert({ message: `Failure to upload codes`, show: true });
      throw error;
    }
  };

  const mapFormValuesToMutation = (onSubmit) => async ({ ...formValues }) => {
    const { codes, multiUseCode, ...form } = formValues;
    setUploadLoading(true);

    if (codes?.length && form.codeClaimOption === "single") {
      try {
        await invokeUploadCodesMutation(
          {
            supplierId: supplier.id,
            resourceId: resourceId,
            productId: product.id,
          },
          codes
        );
      } catch (error) {
        console.log(error);
        setUploadLoading(false);
        return;
      }
    }

    try {
      await onSubmit({
        ...form,
        multiUseCode:
          codeClaimOption === "multi"
            ? {
                value: multiUseCode.value,
                price: multiUseCode.price,
                currency: multiUseCode.currency,
                expirationTime: new Date(multiUseCode.expirationTime),
              }
            : null,
      });
    } finally {
      setUploadLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(mapFormValuesToMutation(onSubmit))}>
        <Field
          component={RenderField}
          label="Price"
          name="price"
          parse={parse}
          format={format}
          autoFocus
        />
        <Field
          component={RenderField}
          label="Description"
          name="description"
          parse={parseNull}
        />
        <Field component={RenderField} label="Product URL" name="link" />
        {showUploadCode && (
          <div className={styles.codeClaim}>
            {!supplier?.codeClaim && (
              <p className={styles.supplierMessage}>
                To enable self-service learner code claims, you will need to
                enable it at the supplier level first.
              </p>
            )}
            <Field
              disabled={!supplier?.codeClaim}
              component={RenderCheckbox}
              label="Enable self-service learner code claims"
              name="codeClaim"
              onChange={(_, value) => setCodeClaimCheckbox(value)}
            />

            {supplier?.codeClaim && codeClaimCheckbox && (
              <div className={styles.codeBlock}>
                <span className={styles.codeBlockLabel}>
                  Self-service learner code setup
                </span>
                <Field
                  component={({ input, meta }) => (
                    <Dropdown
                      value={input.value}
                      error={(meta.touched && meta.error) || ""}
                      source={[
                        { value: "single", label: "Single use codes" },
                        { value: "multi", label: "Multi-use codes" },
                      ]}
                      onChange={input.onChange}
                    />
                  )}
                  name="codeClaimOption"
                  label="Select type of codes"
                />

                {codeClaimOption === "single" && (
                  <Field component={UploadCodes} name="codes" />
                )}
                {codeClaimOption === "multi" && (
                  <>
                    <Row around="sm">
                      <Col sm={6}>
                        <Field
                          component={RenderField}
                          label="Multi-use code"
                          name="multiUseCode.value"
                          required
                        />
                        <Field
                          component={RenderField}
                          label="Price"
                          name="multiUseCode.price"
                          parse={parse}
                          format={format}
                          required
                        />
                      </Col>
                      <Col sm={6}>
                        <Field
                          component={({ input, ...props }) => (
                            <DatePicker {...input} {...props} autoOk />
                          )}
                          label="Expiration Time"
                          name="multiUseCode.expirationTime"
                          required
                        />

                        <Field
                          component={currencyPicker}
                          hasUpcomingCurrencies
                          label="Currency"
                          name="multiUseCode.currency"
                          required
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Field
                  component={HelperTextFieldWithPreview}
                  label="Code helper text (seen by user)"
                  name="codeClaimHelperText"
                  multiline
                />
              </div>
            )}
          </div>
        )}
        <Row between="xs">
          <Col>
            <Field component={RenderSwitch} label="Active" name="active" />
          </Col>
          <Col>
            <Button onClick={handleDialog}>Cancel</Button>
            <Button
              type="submit"
              disabled={loading && uploadLoading}
              raised
              primary
            >
              Update Online Course
            </Button>
          </Col>
        </Row>
      </form>
      <Snackbar
        action="Dismiss"
        active={alert.show}
        label={alert.message}
        onTimeout={() => setAlert({ show: false, message: null })}
        timeout={3000}
        type="cancel"
      />
    </>
  );
};

UpdateOnlineCourseForm.propTypes = {
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  uploadProductCodes: PropTypes.func,
  submitLabel: PropTypes.string,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  supplier: PropTypes.shape({
    id: PropTypes.string,
    codeClaim: PropTypes.bool,
  }),
  product: PropTypes.shape({
    id: PropTypes.string,
  }),
  resourceId: PropTypes.string,
  codeClaimOption: PropTypes.string,
};

const selector = formValueSelector("onlineCourse");

export default connect((state) => ({
  codeClaimOption: selector(state, "codeClaimOption"),
}))(
  graphql(uploadProductCodes, {
    name: "uploadProductCodes",
  })(
    reduxForm({
      form: "onlineCourse",
      validate,
    })(UpdateOnlineCourseForm)
  )
);
