import React from "react";
import { Button } from "react-toolbox";
import PropTypes from "prop-types";

import SelectUserDialog from "../../components/select-user-dialog";

class UserPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: false,
    };

    this.handleSelectUserDialog = this.handleSelectUserDialog.bind(this);
  }

  handleSelectUserDialog() {
    this.setState({
      user: !this.state.user,
    });
  }

  render() {
    const { input, meta } = this.props;

    return (
      <div>
        {input.value && (
          <div>
            {input.value.firstName} {input.value.lastName},{" "}
            {input.value.organisation.name}
          </div>
        )}
        <Button
          icon="search"
          onClick={this.handleSelectUserDialog}
          label="Select User"
          primary
          mini
        />
        {meta.error}
        <SelectUserDialog
          active={this.state.user}
          handleDialog={this.handleSelectUserDialog}
          onSuccess={input.onChange}
        />
      </div>
    );
  }
}

UserPicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default UserPicker;
