import Joi from "joi-browser";
import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { Button, ProgressBar, Snackbar } from "react-toolbox";
import { Field, Form, reduxForm } from "redux-form";
import PropTypes from "prop-types";

import InputField from "../field";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";

import addConnectedStripeAccountMutation from "./add-connected-stripe-account.graphql";

const ConnectedStripeAccountForm = ({
  handleSubmit,
  invalid,
  organisationId,
}) => {
  const [alert, setAlert] = useState({
    message: null,
    show: false,
  });

  async function handleSubmitForm(formValues, addConnectedStripeAccount) {
    try {
      const { connectedStripeAccountId } = formValues;

      const { data } = await addConnectedStripeAccount({
        variables: { connectedStripeAccountId, organisationId },
      });

      if (data) {
        setAlert({ message: "Success 🎉", show: true });
      }
    } catch (error) {
      console.log(error);

      setAlert({ message: `Failure`, show: true });
    }
  }

  return (
    <Mutation mutation={addConnectedStripeAccountMutation}>
      {(addConnectedStripeAccount, { loading }) => {
        return (
          <>
            <Form
              onSubmit={handleSubmit((formValues) =>
                handleSubmitForm(formValues, addConnectedStripeAccount)
              )}
            >
              <Field
                component={InputField}
                label="Stripe account ID"
                name="connectedStripeAccountId"
              />
              <Button
                disabled={invalid || loading}
                type="submit"
                primary
                raised
              >
                Add Stripe account ID
              </Button>

              {loading && <ProgressBar mode="indeterminate" />}
            </Form>
            <Snackbar
              action="Dismiss"
              active={alert.show}
              label={alert.message}
              onTimeout={() => setAlert({ show: false, message: null })}
              timeout={3000}
              type="cancel"
            />
          </>
        );
      }}
    </Mutation>
  );
};

ConnectedStripeAccountForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  organisationId: PropTypes.string.isRequired,
};

const schema = Joi.object({
  connectedStripeAccountId: Joi.string().label("Stripe account ID").required(),
});

const validate = (formValues) => {
  const { error } = schema.validate(formValues, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

export default reduxForm({ form: "add-connected-stripe-account", validate })(
  ConnectedStripeAccountForm
);
