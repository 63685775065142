import { Autocomplete } from "react-toolbox";
import React from "react";
import currencies from "currency-codes/data";

import PropTypes from "prop-types";

const orgCurrencySelector = ({ input, meta, label, fulfilmentAccounts }) => {
  const availableCurrencies = fulfilmentAccounts.map(
    ({ currency }) => currency
  );
  const currencyList = currencies
    .filter(({ code }) => availableCurrencies.includes(code))
    .map(({ code, currency }) => ({
      [code]: currency,
    }));
  const source = Object.assign({}, ...currencyList);

  return (
    <Autocomplete
      label={label}
      value={input.value}
      source={source}
      onChange={input.onChange}
      error={meta.error}
      multiple={false}
      showSuggestionsWhenValueIsSet
    />
  );
};

orgCurrencySelector.propTypes = {
  meta: PropTypes.shape({
    active: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    autofilled: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitting: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
  }),
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  data: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  fulfilmentAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      autoApprovedThreshold: PropTypes.number,
      currency: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default orgCurrencySelector;
