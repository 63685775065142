import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";
import moment from "moment-timezone";

import PropTypes from "prop-types";

import CreateInternalEventForm from "../create-internal-event-form";

import addInternalEventQuery from "./add-internal-event.graphql";

import theme from "./theme.css";

const onSubmit = (addInternalEvent, handleDialog, resourceId) => ({
  active,
  city,
  startDate,
  endDate,
  description,
  street,
  postcode,
  timezone,
  country,
  link,
}) =>
  addInternalEvent({
    variables: {
      internalEvent: {
        location: {
          city,
          street,
          postcode,
        },
        country,
        description,
        startDate: moment
          .tz(moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
          .utc()
          .format(),
        endDate:
          endDate &&
          moment
            .tz(moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
            .utc()
            .format(),
        timezone,
        active,
        link,
      },
      resourceId,
    },
  }).then(handleDialog);

const AddInternalEventModal = ({
  active,
  addInternalEvent,
  handleDialog,
  resourceId,
  resourceLink,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <CreateInternalEventForm
      handleDialog={handleDialog}
      onSubmit={onSubmit(addInternalEvent, handleDialog, resourceId)}
      initialValues={{
        active: true,
        timezone: "Europe/London",
        startDate: moment().utc().set("hour", 9).set("minutes", 0).format(),
        link: resourceLink,
        isGlobal: true,
      }}
    />
  </Dialog>
);

AddInternalEventModal.propTypes = {
  active: PropTypes.bool,
  addInternalEvent: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  resourceLink: PropTypes.string,
};

export default graphql(addInternalEventQuery, {
  name: "addInternalEvent",
})(AddInternalEventModal);
