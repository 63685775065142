import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import Joi from "joi-browser";
import React from "react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderField from "../field";
import RenderSwitch from "../switch";

const schema = Joi.object({
  price: Joi.number().integer().min(0).required(),
  commission: Joi.number().positive().allow([null, ""]),
  vat: Joi.boolean().allow(null),
});

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const mapFormValuesToMutation = (onSubmit) => ({ ...data }) =>
  onSubmit({
    ...data,
    commission: data.commission ? data.commission / 100 : null,
  });

const PurchaseRequestForm = ({ handleDialog, handleSubmit, onSubmit }) => (
  <form onSubmit={handleSubmit(mapFormValuesToMutation(onSubmit))}>
    <Field
      component={RenderField}
      parse={parse}
      format={format}
      label="Price"
      name="price"
      autoFocus
    />
    <Field
      component={RenderField}
      label="Commission"
      name="commission"
      hint="%"
    />
    <Field component={RenderSwitch} label="VAT" name="vat" />
    <Row between="xs">
      <Col />
      <Col>
        <Button onClick={handleDialog}>Cancel</Button>
        <Button type="submit" raised primary>
          Mark as purchased
        </Button>
      </Col>
    </Row>
  </form>
);

PurchaseRequestForm.propTypes = {
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default reduxForm({
  form: "purchaseRequest",
  validate,
})(PurchaseRequestForm);
