import { partial } from "lodash";
import { Button, DatePicker, Input } from "react-toolbox";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import moment from "moment";

import Alert from "@material-ui/lab/Alert";

import theme from "./theme.css";

import createBudgetPeriodMutation from "./create-budget-period.graphql";

const getUtcDate = (date) => {
  date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return date;
};

const CreateBudgetPeriod = ({ createBudgetPeriodMutation }) => {
  const defaultStartDate = moment().utc().startOf("year").toDate();
  const defaultEndDate = moment(defaultStartDate)
    .utc()
    .add(1, "years")
    .toDate();

  const [formState, setFormState] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });

  const handleInputChange = (name, isDate, value) =>
    setFormState({
      ...formState,
      [name]: isDate ? getUtcDate(value) : value,
    });

  const handleSubmit = (event) => {
    event.preventDefault();

    createBudgetPeriodMutation({
      variables: {
        budgetPeriod: {
          name: formState.name,
          startDate: moment(formState.startDate)
            .utc()
            .startOf("day")
            .toISOString(),
          endDate: moment(formState.endDate).utc().startOf("day").toISOString(),
        },
      },
    }).catch((error) => alert(error.message));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Add a new budget period:</h3>
      <Input
        onChange={partial(handleInputChange, "name", false)}
        placeholder="Name"
      />
      <DatePicker
        label="Start date (inclusive of day)"
        name="startDate"
        onChange={partial(handleInputChange, "startDate", true)}
        value={formState.startDate}
        autoOk
      />
      <DatePicker
        label="End date (exclusive of day)"
        name="endDate"
        onChange={partial(handleInputChange, "endDate", true)}
        value={formState.endDate}
        autoOk
      />
      {formState.endDate && formState.startDate ? (
        <Alert
          className={theme.alert}
          severity="info"
        >{`This budget will start at ${moment(formState.startDate)
          .utc()
          .format("HH:mm")} on ${moment(formState.startDate)
          .utc()
          .format("D MMMM YYYY")} and end at ${moment(formState.endDate)
          .utc()
          .format("HH:mm")} on ${moment(formState.endDate)
          .utc()
          .format("D MMMM YYYY")}`}</Alert>
      ) : null}
      <Button
        type="submit"
        label="Add"
        raised
        primary
        disabled={!formState.name}
      />
    </form>
  );
};

CreateBudgetPeriod.propTypes = {
  createBudgetPeriodMutation: PropTypes.func,
};

export default graphql(createBudgetPeriodMutation, {
  name: "createBudgetPeriodMutation",
})(CreateBudgetPeriod);
