import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { IconButton, ProgressBar } from "react-toolbox";
import { Mutation } from "react-apollo";

import { deleteReview } from "./delete-review.graphql";

import theme from "./theme.css";

const DeleteReview = ({ resourceId, id }) => {
  return (
    <Mutation mutation={deleteReview}>
      {(deleteReviewByIds, { loading }) => (
        <>
          <IconButton
            icon="delete"
            onClick={(e) => {
              e.preventDefault();
              if (resourceId && id) {
                deleteReviewByIds({
                  variables: {
                    resourceId,
                    id,
                  },
                });
              }
            }}
            primary
          />
          {loading && <ProgressBar mode="indeterminate" />}
        </>
      )}
    </Mutation>
  );
};

const Review = ({ review, resourceId }) => {
  const { id, user, headline, rating, note } = review;
  return (
    <Row key={id} between="xs" className={theme.tableRow}>
      <Col sm={1}>
        <p>{user.id}</p>
      </Col>
      <Col sm={1}>
        {user?.firstName && user?.lastName && (
          <p>{`${user.firstName} ${user.lastName}`}</p>
        )}
      </Col>
      <Col sm={1}>
        <p>{user.organisation.name}</p>
      </Col>
      <Col sm={2}>{headline && <p>{headline}</p>}</Col>
      <Col sm={1}>{rating && <p>{rating}</p>}</Col>
      <Col sm={3} className={theme.content}>
        {note &&
          note.split("\n").map((item, index) => (
            <span key={index}>
              {item}
              <br />
            </span>
          ))}
      </Col>
      <Col sm={1}>
        <DeleteReview resourceId={resourceId} id={id} />
      </Col>
    </Row>
  );
};

Review.propTypes = {
  resourceId: PropTypes.string.isRequired,
  review: PropTypes.object.isRequired,
};
DeleteReview.propTypes = {
  resourceId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Review;
