import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  ProgressBar,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, Form, reduxForm } from "redux-form";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import InfiniteScroll from "react-infinite-scroller";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderField from "../../components/field";
import SubjectPicker from "../subject-picker";
import ContentRegionPicker from "../content-region-picker";
import SelectResourceDialog from "../select-resource-dialog";
import OrganisationPicker from "../organisation-picker";
import RenderSwitch from "../switch";
import Experts from "../experts";

const schema = Joi.object({
  title: Joi.string().label("Title").required(),
  description: Joi.string().max(300).allow(""),
  global: Joi.boolean()
    .label("All organisations")
    .when("allowedOrganisations", {
      is: Joi.array().min(1),
      then: Joi.valid(false),
    }),
  allowedOrganisations: Joi.array()
    .unique()
    .items(
      Joi.string().guid({
        version: ["uuidv4"],
      })
    )
    .allow(null),
  allowedCountries: Joi.array().items(Joi.string()).allow(null),
  subjects: Joi.array()
    .unique()
    .max(5)
    .items(
      Joi.string().guid({
        version: ["uuidv4"],
      })
    ),
  entries: Joi.array()
    .unique()
    .items(
      Joi.object({
        resource: Joi.string().guid({ version: ["uuidv4"] }),
      })
    ),
  isForAllCountries: Joi.boolean()
    .label("Available for all countries?")
    .when("allowedCountries", {
      is: Joi.array().min(1),
      then: Joi.valid(false),
    }),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
    stripUnknown: true,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const validateComment = (value) =>
  value && value.length > 300 ? "Must be fewer than 300 characters" : undefined;

const EditCommentDialog = reduxForm({
  form: "comment",
  enableReinitialize: true,
  validate,
})(({ active, handleDialog, onSuccess, handleSubmit, valid }) => (
  <Dialog
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
  >
    <Form onSubmit={handleSubmit(onSuccess)}>
      <Field
        component={RenderField}
        name="comment"
        validate={validateComment}
      />
      <Button disabled={!valid} type="submit">
        Save
      </Button>
    </Form>
  </Dialog>
));

SelectResourceDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  onSuccess: PropTypes.func,
};

const mapFormValuesToMutation = (onSubmit) => ({
  entries,
  title,
  description,
  global,
  allowedOrganisations,
  allowedCountries,
  experts,
  subjects = [],
  isForAllCountries,
}) =>
  onSubmit({
    title,
    description: description || null,
    subjects,
    global,
    entries: entries.map(({ comment, resource: { id } }) => ({
      resource: id,
      comment: comment || null,
    })),
    allowedOrganisations: global ? [] : allowedOrganisations,
    allowedCountries: isForAllCountries ? [] : allowedCountries,
    expert: experts && experts.length ? experts[0].id : null,
  });

const Resources = ({ endCursor, hasMore, input, getMorePaginatedEntries }) => {
  const [selectResourceDialog, setSelectResourceDialog] = useState(false);
  const [editCommentDialog, setEditCommentDialog] = useState(false);
  const [editCommentIndex, setEditCommentIndex] = useState(0);

  const handleSelectResourceDialog = () =>
    setSelectResourceDialog(!selectResourceDialog);
  const handleEditCommentDialog = () =>
    setEditCommentDialog(!editCommentDialog);

  const resources = input.value ? [...input.value] : [];

  const addResource = (resource) => {
    resources.push({ resource });
    input.onChange(resources);
  };

  const addComment = (comment) => {
    resources[editCommentIndex]["comment"] = comment;
    input.onChange(resources);
  };

  const removeResource = (index) => {
    resources.splice(index, 1);
    input.onChange(resources);
  };

  const moveUp = (index) => {
    const newValue = resources[index];
    const currentValue = resources[index - 1];

    resources[index - 1] = newValue;
    resources[index] = currentValue;

    input.onChange(resources);
  };

  const moveDown = (index) => {
    const newValue = resources[index];
    const currentValue = resources[index + 1];

    resources[index + 1] = newValue;
    resources[index] = currentValue;

    input.onChange(resources);
  };

  const tableHeadingCellStyle = {
    backgroundColor: "black",
    borderTopWidth: "0",
    color: "white",
    position: "sticky",
    top: "0",
  };

  return (
    <div>
      <Row>
        <h3>Resources</h3>
      </Row>

      {input.value.length >= 0 ? (
        <div
          style={{
            marginBottom: "1.5rem",
          }}
        >
          <div
            style={{
              border: "solid 1px #f4f6f7",
              marginBottom: "0.75rem",
              maxHeight: "20rem",
              overflowY: "auto",
            }}
          >
            <InfiniteScroll
              hasMore={hasMore}
              loader={<ProgressBar key={endCursor} mode="indeterminate" />}
              loadMore={getMorePaginatedEntries}
              useWindow={false}
            >
              <Table selectable={false}>
                <TableHead>
                  <TableCell style={tableHeadingCellStyle}>Resource</TableCell>
                  <TableCell style={tableHeadingCellStyle}>Title</TableCell>
                  <TableCell style={tableHeadingCellStyle}>Type</TableCell>
                  <TableCell style={tableHeadingCellStyle}>Supplier</TableCell>
                  <TableCell style={tableHeadingCellStyle}>Comment</TableCell>
                  <TableCell style={tableHeadingCellStyle}>
                    Edit comment
                  </TableCell>
                  <TableCell style={tableHeadingCellStyle}>
                    Move entry up
                  </TableCell>
                  <TableCell style={tableHeadingCellStyle}>
                    Move entry down
                  </TableCell>
                  <TableCell style={tableHeadingCellStyle}>
                    Remove entry
                  </TableCell>
                </TableHead>

                {input.value.length > 0 &&
                  input.value.map(({ comment, resource }, index) => {
                    return (
                      <TableRow key={resource.id}>
                        <TableCell>
                          {!resource.isInternalContent ? (
                            <Link to={`/resources/${resource.id}/`}>
                              <Button icon="link" />
                            </Link>
                          ) : (
                            <strong style={{ color: "#43AB95" }}>
                              Internal Content
                            </strong>
                          )}
                        </TableCell>
                        <TableCell>{resource.title}</TableCell>
                        <TableCell>{resource.type.name}</TableCell>
                        <TableCell>
                          {resource.supplier
                            ? resource.supplier.name
                            : "No supplier"}
                        </TableCell>
                        <TableCell>{comment}</TableCell>
                        <TableCell>
                          <Button
                            icon="edit_note"
                            onClick={() => {
                              handleEditCommentDialog();
                              setEditCommentIndex(index);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            disabled={index === 0}
                            icon="arrow_upward"
                            onClick={() => moveUp(index)}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            disabled={input.value.length <= index + 1}
                            icon="arrow_downward"
                            onClick={() => moveDown(index)}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            icon="delete_forever"
                            onClick={() => removeResource(index)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </Table>
            </InfiniteScroll>
          </div>
          <Button
            disabled={hasMore}
            icon="add"
            label={`Add resource to list (${
              hasMore && input.value.length > 0
                ? "All entries must be loaded - scroll within resources table above / "
                : ""
            }"Update playlist" to save changes)`}
            onClick={handleSelectResourceDialog}
            raised
            style={{ minHeight: "61px", width: "100%" }}
          />
        </div>
      ) : null}

      <SelectResourceDialog
        active={selectResourceDialog}
        handleDialog={handleSelectResourceDialog}
        onSuccess={addResource}
      />
      {resources.length > 0 && (
        <EditCommentDialog
          active={editCommentDialog}
          handleDialog={handleEditCommentDialog}
          onSuccess={({ comment }) => {
            addComment(comment);
            handleEditCommentDialog();
          }}
          initialValues={{
            comment: resources[editCommentIndex].comment,
          }}
        />
      )}
    </div>
  );
};

Resources.propTypes = {
  endCursor: PropTypes.string,
  getMorePaginatedEntries: PropTypes.func,
  hasMore: PropTypes.bool,
  input: PropTypes.object,
};

const PlaylistForm = ({
  endCursor,
  getMorePaginatedEntries,
  handleSubmit,
  hasMore,
  isForAllCountriesInitialValue,
  isGlobalForOrganisationsInitialValue,
  submitLabel,
  onSubmit,
}) => {
  const [isForAllCountries, setIsForAllCountries] = useState(
    isForAllCountriesInitialValue
  );
  const [isGlobalForOrganisations, setIsGlobalForOrganisations] = useState(
    isGlobalForOrganisationsInitialValue
  );

  return (
    <form onSubmit={handleSubmit(mapFormValuesToMutation(onSubmit))}>
      <Row>
        <Col sm={12}>
          <Field component={RenderField} label="Title" name="title" />
        </Col>
      </Row>

      <Row style={{ marginBottom: "20px" }}>
        <Col sm={12}>
          <Field
            component={RenderField}
            label="Description"
            name="description"
          />

          <h4>Subjects</h4>
          <p>Please only choose a maximum of 5 subjects to tag.</p>
          <Field
            multiple
            label="Subjects"
            name="subjects"
            component={SubjectPicker}
          />
          <h4>Allowed Organisations</h4>
          <Field
            component={RenderSwitch}
            label="All organisations"
            name="global"
            onChange={(_, value) => setIsGlobalForOrganisations(value)}
          />
          {!isGlobalForOrganisations && (
            <Field
              name="allowedOrganisations"
              component={OrganisationPicker}
              multiple
            />
          )}

          <h4>Allowed Countries</h4>
          <Field
            component={RenderSwitch}
            label="Available for all countries?"
            name="isForAllCountries"
            onChange={(_, value) => setIsForAllCountries(value)}
          />
          {!isForAllCountries && (
            <Field
              multiple
              name="allowedCountries"
              component={ContentRegionPicker}
            />
          )}

          <Field component={Experts} name="experts" />
        </Col>
      </Row>

      <Row>
        <Col sm={12} style={{ marginBottom: "20px" }}>
          <Field
            component={Resources}
            endCursor={endCursor}
            getMorePaginatedEntries={getMorePaginatedEntries}
            hasMore={hasMore}
            name="entries"
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Button disabled={hasMore} type="submit" raised primary>
            {submitLabel}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

PlaylistForm.propTypes = {
  endCursor: PropTypes.string,
  getMorePaginatedEntries: PropTypes.func,
  handleSubmit: PropTypes.func,
  hasMore: PropTypes.bool,
  isForAllCountriesInitialValue: PropTypes.bool,
  isGlobalForOrganisationsInitialValue: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
};

export default compose(
  connect((_, { initialValues }) => ({
    isForAllCountriesInitialValue:
      initialValues && initialValues.isForAllCountries,
    isGlobalForOrganisationsInitialValue: initialValues && initialValues.global,
  })),
  reduxForm({
    enableReinitialize: true,
    form: "playlist",
    keepDirtyOnReinitialize: true,
    validate,
  })
)(PlaylistForm);
