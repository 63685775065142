import React, { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  Snackbar,
  ProgressBar,
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
} from "react-toolbox";
import { graphql } from "react-apollo";
import { compose } from "redux";
import copy from "copy-to-clipboard";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import UserForm from "../../components/user-form";
import UpdateUserBudgetDialog from "../../components/update-user-budget-dialog";

import archiveUserQuery from "./archive-user.graphql";
import unarchiveUserQuery from "./unarchive-user.graphql";
import sendInvitationQuery from "./send-invitation.graphql";
import resetUserQuery from "./reset-user.graphql";
import updateUserQuery from "./update-user.graphql";
import updateUserRoleMutation from "./update-user-role.graphql";
import userQuery from "./user.graphql";

const mapQueryToInitialValues = ({
  organisation,
  id,
  budget,
  budgets,
  activeIndividualBudget,
  individualBudgets,
  approvers,
  __typename,
  hasPassword,
  archived,
  impersonate,
  country,
  geographicLocation,
  role,
  ...props
}) => {
  return {
    approvers: approvers ? approvers.map(({ id }) => id) : null,
    impersonate: impersonate && impersonate.id,
    country: country && country,
    geographicLocation: geographicLocation && geographicLocation,
    role: role && role.id,
    ...props,
  };
};

const onSubmit = (
  updateUser,
  id,
  success,
  failure,
  updateUserRoleMutation,
  setIsUpdatingUser
) => async ({ archived, impersonate, role, ...user }) => {
  setIsUpdatingUser(true);

  if (!role) {
    try {
      await updateUser({
        variables: {
          id,
          user: {
            impersonate: impersonate || null,
            ...user,
          },
        },
      });

      success();
    } catch (error) {
      console.log(error);

      failure();
    }

    return setIsUpdatingUser(false);
  }

  try {
    await updateUser({
      variables: {
        id,
        user: {
          impersonate: impersonate || null,
          ...user,
        },
      },
    });

    await updateUserRoleMutation({
      variables: {
        profile: {
          role,
        },
        userId: id,
      },
    });

    success();
  } catch (error) {
    console.log(error);

    failure();
  }

  return setIsUpdatingUser(false);
};

const onSendButtonClick = (mutate, userId, success, failure) => () =>
  mutate({
    variables: { userId },
  })
    .then(success)
    .catch(failure);

const onArchiveOrUnarchiveConfirmationButtonClick = (
  mutate,
  id,
  success,
  failure
) => () =>
  mutate({
    variables: { id },
  })
    .then(success)
    .catch(failure);

const onResetConfirmationButtonClick = (mutate, id, success, failure) => () =>
  mutate({
    variables: { id },
  })
    .then(success)
    .catch(failure);

const UpdateUser = ({
  resetUserQuery,
  updateUserQuery,
  sendInvitationQuery,
  archiveUserQuery,
  unarchiveUserQuery,
  userQuery,
  currentUser,
  updateUserRoleMutation,
}) => {
  const [alert, setAlert] = useState({
    isActive: false,
    message: null,
  });

  const [isDialogOpen, setDialogOpen] = useState(false);

  const [isUpdatingUser, setIsUpdatingUser] = useState(false);

  const toggleDialog = () => setDialogOpen(!isDialogOpen);

  const [selectedBudget, setSelectedBudget] = useState(null);

  if (userQuery.loading) {
    return <ProgressBar mode="indeterminate" />;
  }

  const user = userQuery.user;
  const signedUp = user && user.hasPassword;
  const isActiveIndividualBudget = (budgetId) =>
    user.activeIndividualBudget && user.activeIndividualBudget.id === budgetId;

  return (
    <div>
      <Row middle="sm" style={{ marginBottom: "30px" }}>
        <Col sm={5}>
          <h2>Update User</h2>
        </Col>
        <Col sm={6} smOffset={1}>
          <Button icon={signedUp ? "check_circle" : "highlight_off"} primary>
            {signedUp ? "Signed up" : "Not signed up"}
          </Button>
          {user.archived && (
            <Button icon="archive" primary>
              Archived
            </Button>
          )}
          <Button icon="language" primary>
            {user.country}
          </Button>
          <Link to={`/organisations/${user.organisation.id}/`}>
            <Button icon="account_balance" primary>
              {user.organisation.name}
            </Button>
          </Link>
          {!signedUp && (
            <Button
              icon="email"
              onClick={onSendButtonClick(
                sendInvitationQuery,
                user.id,
                ({ data: { sendInvitation } }) => {
                  copy(sendInvitation);
                  setAlert({
                    isActive: true,
                    message: "Send invitation and copied invite URL",
                  });
                },
                () =>
                  setAlert({
                    isActive: true,
                    message: "Failure 😞",
                  })
              )}
              raised
            >
              Create Invitation
            </Button>
          )}
          {user.archived ? (
            <Button
              icon="unarchive"
              onClick={onArchiveOrUnarchiveConfirmationButtonClick(
                unarchiveUserQuery,
                user.id,
                () =>
                  setAlert({
                    isActive: true,
                    message: "Success 🎉",
                  }),
                ({ message }) =>
                  setAlert({
                    isActive: true,
                    message: `Failure 😞 ${message}`,
                  })
              )}
              raised
            >
              Unarchive User
            </Button>
          ) : (
            <Button
              icon="archive"
              onClick={onArchiveOrUnarchiveConfirmationButtonClick(
                archiveUserQuery,
                user.id,
                () =>
                  setAlert({
                    isActive: true,
                    message: "Success 🎉",
                  }),
                ({ message }) =>
                  setAlert({
                    isActive: true,
                    message: `Failure 😞 ${message}`,
                  })
              )}
              raised
            >
              Archive User
            </Button>
          )}
          <Button
            icon="refresh"
            onClick={onResetConfirmationButtonClick(
              resetUserQuery,
              user.id,
              () =>
                setAlert({
                  isActive: true,
                  message: "Success 🎉",
                }),
              ({ message }) =>
                setAlert({
                  isActive: true,
                  message: `Failure 😞 ${message}`,
                })
            )}
            raised
          >
            Reset User
          </Button>
        </Col>
      </Row>

      <Row>
        <Col sm={5}>
          <UserForm
            userId={user.id}
            submitLabel="Update User"
            organisationId={user.organisation.id}
            initialValues={mapQueryToInitialValues(user)}
            onSubmit={onSubmit(
              updateUserQuery,
              user.id,
              () =>
                setAlert({
                  isActive: true,
                  message: "Success 🎉",
                }),
              () =>
                setAlert({
                  isActive: true,
                  message: "Failure 😞",
                }),
              updateUserRoleMutation,
              setIsUpdatingUser
            )}
            currentUser={currentUser}
            isLoading={isUpdatingUser}
          />
        </Col>
        <Col sm={6} smOffset={1}>
          <h4>Individual Budgets</h4>
          {
            <Table selectable={false}>
              <TableHead>
                <TableCell>Is Active</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Spent</TableCell>
                <TableCell>Remaining</TableCell>
              </TableHead>
              {Array.isArray(user.individualBudgets) &&
                user.individualBudgets.map((budget, idx) => {
                  return (
                    <TableRow
                      key={idx}
                      style={{
                        cursor: "pointer",
                        backgroundColor: isActiveIndividualBudget(budget.id)
                          ? "#C5E8D9"
                          : null,
                      }}
                      onClick={() => {
                        setSelectedBudget(budget);
                        toggleDialog();
                      }}
                    >
                      <TableCell>
                        <div>
                          {isActiveIndividualBudget(budget.id)
                            ? "ACTIVE"
                            : "INACTIVE"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>{budget.status}</div>
                      </TableCell>
                      <TableCell>
                        <div>{budget.currency || "GBP"}</div>
                      </TableCell>
                      <TableCell>
                        <div>{(budget.total / 100).toFixed(2)}</div>
                      </TableCell>
                      <TableCell>
                        <div>{(budget.spent / 100).toFixed(2)}</div>
                      </TableCell>
                      <TableCell>
                        <div>{(budget.remaining / 100).toFixed(2)}</div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </Table>
          }
        </Col>
      </Row>
      <>
        <Snackbar
          action="Dismiss"
          active={alert.isActive}
          label={alert.message}
          timeout={2000}
          onTimeout={() => setAlert({ isActive: false })}
          type="cancel"
        />
        <UpdateUserBudgetDialog
          active={isDialogOpen}
          handleDialog={toggleDialog}
          userId={user.id}
          budget={selectedBudget}
          setAlert={setAlert}
        />
      </>
    </div>
  );
};

UpdateUser.propTypes = {
  updateUserQuery: PropTypes.func,
  sendInvitationQuery: PropTypes.func,
  archiveUserQuery: PropTypes.func,
  unarchiveUserQuery: PropTypes.func,
  resetUserQuery: PropTypes.func,
  userQuery: PropTypes.object,
  currentUser: PropTypes.object,
  updateUserRoleMutation: PropTypes.func,
};

export default compose(
  graphql(userQuery, {
    name: "userQuery",
    options: ({ match }) => ({ variables: { id: match.params.userId } }),
  }),
  graphql(resetUserQuery, {
    name: "resetUserQuery",
  }),
  graphql(updateUserQuery, {
    name: "updateUserQuery",
  }),
  graphql(updateUserRoleMutation, {
    name: "updateUserRoleMutation",
  }),
  graphql(sendInvitationQuery, {
    name: "sendInvitationQuery",
  }),
  graphql(archiveUserQuery, {
    name: "archiveUserQuery",
  }),
  graphql(unarchiveUserQuery, {
    name: "unarchiveUserQuery",
  })
)(UpdateUser);
