import React from "react";
import PropTypes from "prop-types";

import ConnectedStripeAccountForm from "../connected-stripe-account-form";

const ConnectedStripeAccount = ({
  connectedStripeAccountId,
  organisationId,
}) => {
  return (
    <div>
      <h2>Connected Stripe account</h2>
      <p>
        Virtual card functionality requires an organisation to have a connected
        Stripe account.
      </p>
      <p>
        {connectedStripeAccountId
          ? `Connected account: ${connectedStripeAccountId}`
          : "Please enter the ID of the connected Stripe account below."}
      </p>
      <ConnectedStripeAccountForm organisationId={organisationId} />
    </div>
  );
};

ConnectedStripeAccount.propTypes = {
  connectedStripeAccountId: PropTypes.string,
  organisationId: PropTypes.string.isRequired,
};

export default ConnectedStripeAccount;
