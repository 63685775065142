import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { Link } from "react-router-dom";
import qs from "qs";
import { Col, Row } from "react-flexbox-grid";
import InfiniteScroll from "react-infinite-scroller";
import { Button, ProgressBar } from "react-toolbox";

import {
  onApprove,
  onPurchase,
  onCancel,
  onRetry,
  handlePurchaseDialog,
  handleCancelDialog,
  handleApproveDialog,
  handleRetryDialog,
} from "src/helpers";

import RequestCard from "src/components/request-card";
import Filters from "src/components/request-filters";
import PurchaseRequestDialog from "src/components/purchase-request-dialog";
import CancelRequestDialog from "src/components/cancel-request-dialog";
import ApproveRequestDialog from "src/components/approve-request-dialog";
import RetryRequestDialog from "src/components/retry-request-dialog";

import requestsQuery from "./requests.graphql";

const parseQuery = (search) => {
  const { orderBy, requestStatus, organisations } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  return {
    orderBy,
    requestStatus,
    organisations,
  };
};

class RequestsListComponent extends PureComponent {
  static propTypes = {
    requests: PropTypes.shape({
      results: PropTypes.arrayOf(
        PropTypes.shape({
          additionalNote: PropTypes.string,
          address: PropTypes.shape({
            city: PropTypes.string,
            country: PropTypes.string,
            postcode: PropTypes.string,
            street: PropTypes.string,
          }),
          adhoc: PropTypes.bool,
          adhocInfo: PropTypes.shape({
            link: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
          }),
          approveNote: PropTypes.string,
          approvedAt: PropTypes.string,
          approvedBy: PropTypes.shape({
            id: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
          }),
          createdAt: PropTypes.string,
          currency: PropTypes.string,
          fulfilledByLearnerInfo: PropTypes.shape({
            link: PropTypes.string,
            title: PropTypes.string,
          }),
          hasFulfilmentAccount: PropTypes.bool,
          id: PropTypes.string,
          isFulfilledByLearner: PropTypes.bool,
          price: PropTypes.number,
          product: PropTypes.shape({
            id: PropTypes.string,
            description: PropTypes.string,
          }),
          requestNote: PropTypes.string,
          resource: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            commission: PropTypes.number,
            link: PropTypes.string,
            supplier: PropTypes.string,
            type: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
          status: PropTypes.string,
          totalCost: PropTypes.number,
          user: PropTypes.shape({
            id: PropTypes.string,
            accessibleBudgets: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
              })
            ),
            activeIndividualBudget: PropTypes.shape({
              id: PropTypes.string,
            }),
            email: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            organisation: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
        })
      ),
      searchAfter: PropTypes.string,
    }),

    loading: PropTypes.bool,
    error: PropTypes.bool,
    loadMoreEntries: PropTypes.func,
  };

  state = {
    purchase: false,
    cancel: false,
    approve: false,
    retry: false,
  };

  handlePurchaseDialog = handlePurchaseDialog.bind(this);
  handleCancelDialog = handleCancelDialog.bind(this);
  handleApproveDialog = handleApproveDialog.bind(this);
  handleRetryDialog = handleRetryDialog.bind(this);

  onPurchase = onPurchase.bind(this);
  onCancel = onCancel.bind(this);
  onApprove = onApprove.bind(this);
  onRetry = onRetry.bind(this);

  hasResults = () => {
    const {
      requests: { results },
    } = this.props;
    return results && results.length;
  };

  render() {
    const { requests, loading, error, loadMoreEntries } = this.props;
    const results = requests.results;
    const hasResults = this.hasResults();

    if (loading) return <ProgressBar mode="indeterminate" />;

    if (error) return <div>Error!</div>;

    if (!hasResults) return <div>No Requests!</div>;

    return (
      <Fragment>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreEntries || (() => {})}
          hasMore={Boolean(loadMoreEntries)}
          loader={
            <ProgressBar mode="indeterminate" key={requests.searchAfter} />
          }
        >
          {results.map((request) => (
            <RequestCard
              key={request.id}
              request={request}
              onPurchase={this.onPurchase}
              onCancel={this.onCancel}
              onApprove={this.onApprove}
              onRetry={this.onRetry}
            />
          ))}
        </InfiniteScroll>

        <PurchaseRequestDialog
          active={this.state.purchase}
          handleDialog={this.handlePurchaseDialog}
          request={this.state.purchaseRequest}
        />

        <CancelRequestDialog
          active={this.state.cancel}
          handleDialog={this.handleCancelDialog}
          request={this.state.cancelRequest}
        />

        <ApproveRequestDialog
          active={this.state.approve}
          handleDialog={this.handleApproveDialog}
          request={this.state.approveRequest}
        />

        <RetryRequestDialog
          active={this.state.retry}
          handleDialog={this.handleRetryDialog}
          request={this.state.retryRequest}
        />
      </Fragment>
    );
  }
}

const updateQuery = (previousResult, { fetchMoreResult }) => {
  const previousData = previousResult.requests;
  const fetchedData = fetchMoreResult.requests;
  if (previousData.searchAfter === fetchedData.searchAfter) {
    return previousResult;
  }

  return {
    requests: {
      __typename: "ListResult",
      searchAfter: fetchedData.searchAfter,
      results: [...previousData.results, ...fetchedData.results],
    },
  };
};

const afterUpdateQuery = (value) =>
  value.data.requests.searchAfter ? false : value;

const extractRequests = (data) =>
  data.requests || { searchAfter: null, results: [] };

const RequestsList = graphql(requestsQuery, {
  options: ({ variables }) => ({
    variables,
    fetchPolicy: "network-only",
  }),
  props: ({ ownProps, data }) => {
    const { loading, error, fetchMore } = data;
    const requests = extractRequests(data);

    return {
      loading,
      error,
      ...(requests && {
        requests,
        ...(requests.searchAfter && {
          loadMoreEntries: () => {
            return fetchMore({
              requestsQuery,
              variables: {
                ...(ownProps.requestsQuery && {
                  requestsQuery: ownProps.requestsQuery,
                }),
                orderBy: ownProps.orderBy,
                filter: ownProps.filter,
                searchAfter: requests.searchAfter,
              },
              updateQuery,
            }).then(afterUpdateQuery);
          },
        }),
      }),
    };
  },
})(RequestsListComponent);

class Requests extends PureComponent {
  render() {
    const { location } = this.props;
    const { orderBy, requestStatus, organisations } = parseQuery(
      location.search
    );

    return (
      <Row between="xs">
        <Col sm={12} lg={8}>
          <RequestsList
            {...{
              filter: {
                status: requestStatus || ["approved"],
              },
              organisations,
              orderBy,
            }}
          />
        </Col>
        <Col lg={3} lgOffset={1}>
          {/* TODO: This whole thing should be removed – see ticket PL-367 */}
          <div style={{ marginBottom: "20px" }}>
            <Link to="/requests/create/">
              <Button icon="add" label="New Request" raised primary />
            </Link>
          </div>
          <Link to="/requests/create-adhoc/">
            <Button icon="add" label="New Adhoc Request" raised primary />
          </Link>
          <Filters
            initialValues={{
              orderBy,
              organisations,
              requestStatus: requestStatus || ["approved"],
            }}
          />
        </Col>
      </Row>
    );
  }
}

Requests.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.string,
  }),
};

export default Requests;
