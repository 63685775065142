import React from "react";
import { Button } from "react-toolbox";

import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const PotsOrgDialog = ({ isOpen, onCancel, onConfirm }) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle id="alert-dialog-title">
        You&apos;re about to create a Pots organisation
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          A Pots organisation cannot be switched to the regular PLB one. Please
          check with the Rocket team if you&apos;re unsure.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} raised>
          Cancel
        </Button>

        <Button onClick={onConfirm} raised primary>
          Choose currency
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PotsOrgDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default PotsOrgDialog;
