import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { Autocomplete, ProgressBar } from "react-toolbox";

import organisationQuery from "./organisations.graphql";

const mapIdToName = ({ id, name }) => ({ [id]: name });

const OrganisationPicker = ({ input, multiple = false }) => (
  <Query query={organisationQuery} fetchPolicy="cache-and-network">
    {({ loading, data }) => {
      if (loading) return <ProgressBar mode="indeterminate" />;

      const { organisations = [] } = data;

      return (
        <Autocomplete
          showSuggestionsWhenValueIsSet
          multiple={multiple}
          value={input.value}
          label="Choose an organisation"
          source={Object.assign({}, ...organisations.map(mapIdToName))}
          onChange={(newValue) => input.onChange(newValue)}
        />
      );
    }}
  </Query>
);

OrganisationPicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.string),
  }),
  multiple: PropTypes.bool,
};

export default OrganisationPicker;
