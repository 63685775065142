import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import UpdateBookForm from "../update-book-form";

import { GrowthBookProvider } from "../../utils/growthbook/growthbook";

import updateBookQuery from "./update-book.graphql";

const onSubmit = (updateBook, handleDialog, id, resourceId) => (data) => {
  const book = {
    ...data,
    format: data.format?.type,
    supplier: data.format?.supplier?.id,
  };

  updateBook({
    variables: { book, id, resourceId },
  }).then(handleDialog);
};

const UpdateBookDialog = ({
  active,
  updateBook,
  handleDialog,
  resourceId,
  product,
  resourceLink,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
  >
    <GrowthBookProvider>
      <UpdateBookForm
        handleDialog={handleDialog}
        onSubmit={onSubmit(updateBook, handleDialog, product.id, resourceId)}
        initialValues={{
          description: product.description,
          price: product.price,
          active: Boolean(product.active),
          link: product.link || resourceLink || null,
          country: product.country,
          currency: product.currency,
          amazonASIN: product.amazonASIN,
          isbn: product.isbn,
          format: product.format,
        }}
      />
    </GrowthBookProvider>
  </Dialog>
);

UpdateBookDialog.propTypes = {
  active: PropTypes.bool,
  updateBook: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  product: PropTypes.object,
  resourceLink: PropTypes.string,
  amazonASIN: PropTypes.string,
  isbn: PropTypes.string,
};

export default graphql(updateBookQuery, {
  name: "updateBook",
})(UpdateBookDialog);
