import { ProgressBar, Snackbar } from "react-toolbox";
import { graphql } from "react-apollo";
import { compose } from "redux";
import React from "react";

import PropTypes from "prop-types";

import ResourceForm from "../../components/resource-form";

import updateResourceQuery from "./update-resource.graphql";
import resourceQuery from "./resource.graphql";

const onSubmit = (mutate, id, success, failure) => (resource) =>
  mutate({
    variables: { id, resource },
  })
    .then(success)
    .catch(failure);

const mapQueryToInitialValues = ({
  active,
  isFree,
  ownedBy,
  title,
  description,
  summary,
  subjects,
  image,
  image1x1,
  image2x1,
  image3x1,
  image4x3,
  image3x4,
  embeddedVideo,
  type,
  link,
  supplier,
  experts,
  enquirable,
  enquireLabel,
  allowedOrganisations,
  disallowedOrganisations,
  allowedCountries,
  commission,
}) => ({
  active,
  isFree,
  title,
  description,
  summary,
  image,
  image1x1,
  image2x1,
  image3x1,
  image4x3,
  image3x4,
  embeddedVideo,
  link,
  supplier,
  experts,
  enquireLabel,
  ownedBy,
  commission: commission && Math.round(commission * 100),
  enquirable: enquirable || false,
  type: type.id,
  subjects: subjects ? subjects.map(({ id }) => id) : [],
  allowedOrganisations: allowedOrganisations
    ? allowedOrganisations.map(({ id }) => id)
    : [],
  disallowedOrganisations: disallowedOrganisations
    ? disallowedOrganisations.map(({ id }) => id)
    : [],
  allowedCountries: allowedCountries
    ? allowedCountries.map((country) => country)
    : [],
  isGlobal: !allowedCountries || allowedCountries.length === 0,
});

class UpdateResource extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false,
    };
  }

  render() {
    const {
      updateResourceQuery,
      resourceQuery,
      match: {
        params: { resourceId },
      },
    } = this.props;
    return (
      <div>
        {resourceQuery.loading && <ProgressBar mode="indeterminate" />}
        {!resourceQuery.loading && !resourceQuery.resource && (
          <div>
            <h2>Resource not found. Bummer.</h2>
            <p>We&#39;ve looked everywhere. Seriously.</p>
          </div>
        )}
        {!resourceQuery.loading && resourceQuery.resource && (
          <div>
            <h1>Update Resource</h1>
            <ResourceForm
              resource={resourceQuery.resource}
              resourceId={resourceId}
              products={resourceQuery.resource.products}
              reviews={resourceQuery.resource.reviews}
              initialValues={mapQueryToInitialValues(
                resourceQuery.resource || {}
              )}
              submitLabel="Save Resource"
              onSubmit={onSubmit(
                updateResourceQuery,
                resourceId,
                () =>
                  this.setState({
                    alert: true,
                    message: "Success 🎉",
                  }),
                () =>
                  this.setState({
                    alert: true,
                    message: "Failure 😞",
                  })
              )}
              loading={updateResourceQuery.loading}
            />
            <Snackbar
              action="Dismiss"
              active={this.state.alert}
              label={this.state.message}
              timeout={2000}
              onTimeout={() => this.setState({ alert: false })}
              type="cancel"
            />
          </div>
        )}
      </div>
    );
  }
}

UpdateResource.propTypes = {
  resourceQuery: PropTypes.object,
  updateResourceQuery: PropTypes.func,
  match: PropTypes.object,
};

export default compose(
  graphql(resourceQuery, {
    name: "resourceQuery",
    options: ({ match }) => ({
      variables: {
        id: match.params.resourceId,
      },
    }),
  }),
  graphql(updateResourceQuery, {
    name: "updateResourceQuery",
  })
)(UpdateResource);
