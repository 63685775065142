import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Query } from "react-apollo";
import { Button, ProgressBar } from "react-toolbox";

import {
  onApprove,
  onPurchase,
  onCancel,
  onRetry,
  handlePurchaseDialog,
  handleCancelDialog,
  handleApproveDialog,
  handleRetryDialog,
} from "src/helpers";

import RequestCard from "src/components/request-card";
import PurchaseRequestDialog from "src/components/purchase-request-dialog";
import CancelRequestDialog from "src/components/cancel-request-dialog";
import ApproveRequestDialog from "src/components/approve-request-dialog";
import RetryRequestDialog from "src/components/retry-request-dialog";

import query from "./request.graphql";

export default
@withRouter
class Request extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        requestId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    purchase: false,
    cancel: false,
    approve: false,
    retry: false,
  };

  onPurchase = onPurchase.bind(this);
  onCancel = onCancel.bind(this);
  onApprove = onApprove.bind(this);
  onRetry = onRetry.bind(this);

  handlePurchaseDialog = handlePurchaseDialog.bind(this);
  handleCancelDialog = handleCancelDialog.bind(this);
  handleApproveDialog = handleApproveDialog.bind(this);
  handleRetryDialog = handleRetryDialog.bind(this);

  render() {
    const requestId = this.props.match.params.requestId;

    return (
      <Fragment>
        <div style={{ margin: "0 0 1rem" }}>
          <Link to="/requests/">
            <Button raised onClick={() => {}}>
              Back to requests
            </Button>
          </Link>
        </div>

        <Query query={query} variables={{ id: requestId }}>
          {({ loading, error, data }) => {
            if (loading) return <ProgressBar mode="indeterminate" />;

            if (error) return <div>Error!</div>;

            const request = data.request;

            return (
              <RequestCard
                request={request}
                onPurchase={this.onPurchase}
                onCancel={this.onCancel}
                onApprove={this.onApprove}
                onRetry={this.onRetry}
              />
            );
          }}
        </Query>

        <PurchaseRequestDialog
          active={this.state.purchase}
          handleDialog={this.handlePurchaseDialog}
          request={this.state.purchaseRequest}
        />

        <CancelRequestDialog
          active={this.state.cancel}
          handleDialog={this.handleCancelDialog}
          request={this.state.cancelRequest}
        />

        <ApproveRequestDialog
          active={this.state.approve}
          handleDialog={this.handleApproveDialog}
          request={this.state.approveRequest}
        />

        <RetryRequestDialog
          active={this.state.retry}
          handleDialog={this.handleRetryDialog}
          request={this.state.retryRequest}
        />
      </Fragment>
    );
  }
}
