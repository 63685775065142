import React from "react";
import { Query } from "react-apollo";
import { ProgressBar } from "react-toolbox";
import PropTypes from "prop-types";

import UpdateFulfilmentForm from "./update-fulfilment-form";

import fulfilmentAccountQuery from "./fulfilment-accounts.graphql";

const fakeDemoFulfilmentAccounts = [
  {
    id: "231a6d10-5edd-42ac-ac97-0d922c61fdb1",
    name: "Demo",
    currency: "GBP",
  },
  {
    id: "22149653-27c5-43d0-8e6e-ab99e8f14474",
    name: "Demo",
    currency: "EUR",
  },
  {
    id: "80e55c03-1a7b-4e83-a525-39d77b4f2eda",
    name: "Demo",
    currency: "USD",
  },
];

const UpdateFulfilment = ({ organisation }) => {
  return (
    <Query query={fulfilmentAccountQuery} fetchPolicy="cache-and-network">
      {({ loading, data }) => {
        if (loading) return <ProgressBar mode="indeterminate" />;

        const { fulfilmentAccounts = [] } = data;

        const accounts = organisation.testClient
          ? fakeDemoFulfilmentAccounts
          : fulfilmentAccounts;

        return (
          fulfilmentAccounts && (
            <UpdateFulfilmentForm
              organisation={organisation}
              fulfilmentAccounts={accounts}
            />
          )
        );
      }}
    </Query>
  );
};

UpdateFulfilment.propTypes = {
  organisation: PropTypes.object.isRequired,
};

export default UpdateFulfilment;
