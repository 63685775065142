import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Button,
  Chip,
  Snackbar,
  Table,
  TableHead,
  TableCell,
  TableRow,
} from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import { graphql } from "react-apollo";
import { compose } from "redux";
import React from "react";
import moment from "moment";
import { pick } from "lodash";
import { useFeature } from "@growthbook/growthbook-react";

import PropTypes from "prop-types";

import SeedOrganisationDialog from "../seed-organisation-dialog";
import UpdateOrganisationForm from "../../components/update-organisation-form";

import CreateBudgetPeriod from "./components/create-budget-period";
import UpdateDefaultBudget from "./components/update-default-budget";
import Wallet from "./components/wallet";
import AccountBudgetList from "./components/account-budget-list";

import updateOrganisationMutation from "./update-organisation.graphql";
import archiveOrganisationMutation from "./archive-organisation.graphql";
import freezeOrganisationMutation from "./freeze-organisation.graphql";
import unfreezeOrganisationMutation from "./unfreeze-organisation.graphql";

import archiveBudgetPeriodMutation from "./archive-budget-period.graphql";
import UpdateBudgetPeriodDialog from "./components/update-budget-period";

import theme from "./theme.css";

const formatDateForUtc = (date) =>
  `${moment.utc(date).format("Do MMM YYYY HH:mm:ss")} GMT`;

const UpdateOrganisation = (props) => {
  const [alert, setAlert] = React.useState({ active: false, label: null });
  const [cursors, setCursors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [seedOrganisationDialog, setSeedOrganisationDialog] = React.useState(
    false
  );
  const [
    updateBudgetPeriodDialog,
    setUpdateBudgetPeriodDialog,
  ] = React.useState(false);
  const [budgetPeriodToUpdateId, setBudgetPeriodToUpdateId] = React.useState(
    null
  );

  const onSubmit = (formValues) => {
    const {
      updateOrganisation,
      organisation: { id },
    } = props;
    const {
      name,
      internalLearning,
      internalEvents,
      recentActivity,
      analyticsDashboard,
      adhocRequests,
      hasAmazonRequests,
      hasFulfilledByLearnerRequests,
      hasFulfilledByLearnerViaExpensesRequests,
      testClient,
      isInProofOfValue,
      hasFunds,
      externalIds,
    } = formValues;

    setLoading(true);

    return updateOrganisation({
      variables: {
        id,
        organisation: {
          name,
          internalLearning,
          internalEvents,
          recentActivity,
          analyticsDashboard,
          adhocRequests,
          hasAmazonRequests,
          hasFulfilledByLearnerRequests,
          hasFulfilledByLearnerViaExpensesRequests,
          testClient,
          isInProofOfValue,
          hasFunds,
          externalIds: {
            crm: externalIds.crm || undefined,
            finance: externalIds.finance || undefined,
          },
        },
      },
    })
      .then(() => {
        setAlert({ active: true, label: "Success 🎉" });
        setLoading(false);
      })
      .catch((err) => {
        setAlert({ active: true, label: `Failure 😞 (${err})` });
        setLoading(false);
      });
  };

  const handleUpdateBudgetPeriod = (budgetPeriodToUpdateId) => {
    setUpdateBudgetPeriodDialog(!updateBudgetPeriodDialog);
    setBudgetPeriodToUpdateId(budgetPeriodToUpdateId);
  };

  const handleCreateOrUpdateWallet = () => {
    setAlert({ active: true, label: "Success 🎉" });
    setLoading(false);
  };

  const {
    currentUser,
    organisation,
    archiveOrganisationMutation,
    archiveBudgetPeriodMutation,
    freezeOrganisationMutation,
    unfreezeOrganisationMutation,
    fetchMoreAccountBudgets,
  } = props;
  const {
    name,
    internalLearning,
    internalEvents,
    recentActivity,
    analyticsDashboard,
    adhocRequests,
    hasAmazonRequests,
    hasFulfilledByLearnerRequests,
    hasFulfilledByLearnerViaExpensesRequests,
    testClient,
    isInProofOfValue,
    hasFunds,
    slackInstallation,
    budgetPeriods,
    defaultUserBudgets,
    fulfilmentAccounts,
    isFrozen,
    wallets,
    externalIds,
    accountBudgets: accountBudgetsConnection,
  } = organisation;

  const accountBudgets = accountBudgetsConnection.edges.map(
    (edge) => edge.node
  );

  const pageInfo = accountBudgetsConnection.pageInfo;
  const hasPreviousPage = cursors.length > 0;

  const loadPreviousPage = () => {
    const previousCursor = cursors[cursors.length - 2];

    fetchMoreAccountBudgets({
      variables: {
        after: previousCursor || null,
      },
      updateQuery: (_, { fetchMoreResult }) => {
        setCursors(cursors.slice(0, -1));

        return fetchMoreResult;
      },
    });
  };

  const loadNextPage = () => {
    if (!pageInfo?.hasNextPage) {
      return;
    }
    fetchMoreAccountBudgets({
      variables: {
        after: pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (prev.organisation.accountBudgets.pageInfo.endCursor) {
          setCursors([
            ...cursors,
            prev.organisation.accountBudgets.pageInfo.endCursor,
          ]);
        }

        return fetchMoreResult;
      },
    });
  };

  const showPotsInfo = useFeature("pots-prototype-3").on;

  const renderPotsInfo = () => {
    const [defaultWallet] = wallets;

    // TODO: remove this flag once the Learnerbly is fully Pots enabled

    return (
      <>
        <Alert className={theme.alert} severity="info">
          This organisation is using Pots.
        </Alert>

        <Wallet
          organisationId={organisation.id}
          onUpdate={handleCreateOrUpdateWallet}
          {...defaultWallet}
        />

        <AccountBudgetList
          budgets={accountBudgets}
          currency={defaultWallet.currency}
        />
        <div className={theme.paginationContainer}>
          <Button
            icon="arrow_back"
            primary
            mini
            onClick={loadPreviousPage}
            disabled={!hasPreviousPage}
          >
            Previous
          </Button>
          <Button
            icon="arrow_forward"
            primary
            mini
            onClick={loadNextPage}
            disabled={!pageInfo.hasNextPage}
          >
            Next
          </Button>
        </div>
      </>
    );
  };

  const renderPLBInfo = () => {
    return (
      <>
        <h3>Budget Periods</h3>
        <Table selectable={false}>
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell># Budgets</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableHead>
          {budgetPeriods &&
            budgetPeriods.map((budgetPeriod) => (
              <TableRow key={budgetPeriod.id}>
                <TableCell>{budgetPeriod.name}</TableCell>
                <TableCell>{budgetPeriod.numberOfBudgets}</TableCell>
                <TableCell>
                  {formatDateForUtc(budgetPeriod.startDate)}
                </TableCell>
                <TableCell>{formatDateForUtc(budgetPeriod.endDate)}</TableCell>
                <TableCell>
                  <Button
                    label="Update"
                    onClick={() => {
                      handleUpdateBudgetPeriod(budgetPeriod.id);
                    }}
                    icon="edit"
                    raised
                    disabled={budgetPeriods.some(
                      (budgetPeriodForComparison) =>
                        new Date(budgetPeriodForComparison.startDate) >=
                        new Date(budgetPeriod.endDate)
                    )}
                  />

                  <UpdateBudgetPeriodDialog
                    active={
                      updateBudgetPeriodDialog &&
                      budgetPeriodToUpdateId === budgetPeriod.id
                    }
                    organisationId={organisation.id}
                    budgetPeriod={budgetPeriod}
                    handleDialog={handleUpdateBudgetPeriod}
                  ></UpdateBudgetPeriodDialog>

                  <Button
                    label="Archive"
                    icon="delete"
                    onClick={() =>
                      window.confirm(
                        "Are you sure you wish to archive this item? This will also archive all budgets associated to users with this budget period!"
                      ) &&
                      archiveBudgetPeriodMutation({
                        variables: {
                          budgetPeriodId: budgetPeriod.id,
                          organisationId: organisation.id,
                        },
                      }).then(
                        () =>
                          setAlert({
                            active: true,
                            label: "Success 🎉",
                          }),
                        () =>
                          setAlert({
                            active: true,
                            label: "Failure 😞",
                          })
                      )
                    }
                    raised
                  />
                </TableCell>
              </TableRow>
            ))}
        </Table>
        <CreateBudgetPeriod organisationId={organisation.id} />
        <UpdateDefaultBudget
          organisationId={organisation.id}
          defaultUserBudgets={defaultUserBudgets}
          fulfilmentAccounts={fulfilmentAccounts}
          budgetPeriods={budgetPeriods}
        />
      </>
    );
  };

  return (
    <div>
      <Row>
        <Col sm={7}>
          <UpdateOrganisationForm
            submitLabel="Update Organisation"
            onSubmit={onSubmit}
            loading={loading}
            organisationId={organisation.id}
            initialValues={{
              name,
              recentActivity,
              internalLearning,
              internalEvents,
              analyticsDashboard,
              adhocRequests,
              hasAmazonRequests,
              hasFulfilledByLearnerRequests,
              hasFulfilledByLearnerViaExpensesRequests,
              testClient,
              isInProofOfValue,
              hasFunds,
              externalIds: pick(externalIds, ["crm", "finance"]),
            }}
          />
        </Col>
        <Col sm={5}>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <Link to={`/users/create/${organisation.id}/`}>
              <Button icon="add" label="New User" raised primary />
            </Link>
            {!organisation.archived && (
              <>
                <Button
                  label="Archive Organisation"
                  raised
                  accent
                  icon="delete_forever"
                  onClick={() =>
                    window.confirm(
                      "Are you sure you wish to archive this organisation?"
                    ) &&
                    archiveOrganisationMutation({
                      variables: {
                        id: organisation.id,
                      },
                    }).then(
                      () =>
                        setAlert({
                          active: true,
                          label: "Success 🎉",
                        }),
                      () =>
                        setAlert({
                          active: true,
                          label: "Failure 😞",
                        })
                    )
                  }
                />
                {isFrozen ? (
                  <Button
                    label="Unfreeze Organisation"
                    raised
                    accent
                    icon="sunny"
                    onClick={() =>
                      window.confirm(
                        "Are you sure you wish to unfreeze this organisation?"
                      ) &&
                      unfreezeOrganisationMutation({
                        variables: {
                          id: organisation.id,
                        },
                      }).then(
                        () =>
                          setAlert({
                            active: true,
                            label: "Unfrozen! ☀️",
                          }),
                        () =>
                          setAlert({
                            active: true,
                            label: "Failure 😞",
                          })
                      )
                    }
                  />
                ) : (
                  <Button
                    label="Freeze Organisation"
                    raised
                    accent
                    icon="ac_unit"
                    onClick={() =>
                      window.confirm(
                        "Are you sure you wish to freeze this organisation?"
                      ) &&
                      freezeOrganisationMutation({
                        variables: {
                          id: organisation.id,
                        },
                      }).then(
                        () =>
                          setAlert({
                            active: true,
                            label: "Frozen! ❄️",
                          }),
                        () =>
                          setAlert({
                            active: true,
                            label: "Failure 😞",
                          })
                      )
                    }
                  />
                )}
              </>
            )}
            {testClient && (
              <div>
                <Button
                  icon="supervised_user_circle"
                  label="Seed Organisation"
                  onClick={() => setSeedOrganisationDialog(true)}
                  raised
                  primary
                />
              </div>
            )}
          </div>
          <div>
            {showPotsInfo
              ? wallets.length > 0
                ? renderPotsInfo()
                : renderPLBInfo()
              : renderPLBInfo()}

            {slackInstallation && (
              <div>
                <Chip>
                  <Avatar icon="mms"></Avatar>
                  <span>
                    {`Slack integration (${moment(
                      slackInstallation.installedAt
                    ).format("Do MMM YYYY")})`}
                  </span>
                </Chip>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Snackbar
        action="Dismiss"
        {...alert}
        timeout={2000}
        onTimeout={() => setAlert(false)}
        type="cancel"
      />
      {organisation.members &&
        // Seed is usually done right after creation, so the only existing member will be the org admin.
        Array.isArray(organisation.members.results) &&
        organisation.members.results.length > 0 && (
          <SeedOrganisationDialog
            handleDialog={() => setSeedOrganisationDialog(false)}
            active={seedOrganisationDialog}
            organisationId={organisation.id}
            leadUserId={organisation.members.results[0].id}
            currentUser={currentUser}
          />
        )}
    </div>
  );
};

UpdateOrganisation.propTypes = {
  updateOrganisation: PropTypes.func,
  archiveOrganisationMutation: PropTypes.func,
  freezeOrganisationMutation: PropTypes.func,
  unfreezeOrganisationMutation: PropTypes.func,
  archiveBudgetPeriodMutation: PropTypes.func,
  organisation: PropTypes.shape({
    id: PropTypes.string,
    adhocRequests: PropTypes.bool,
    analyticsDashboard: PropTypes.bool,
    archived: PropTypes.bool,
    budgetPeriods: PropTypes.arrayOf(
      PropTypes.shape({
        endDate: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        numberOfBudgets: PropTypes.number,
        startDate: PropTypes.string,
      })
    ),
    defaultUserBudgets: PropTypes.arrayOf(
      PropTypes.shape({
        total: PropTypes.number,
        currency: PropTypes.string,
        proRata: PropTypes.bool,
      })
    ),
    fulfilmentAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        internalEvents: PropTypes.bool,
        internalLearning: PropTypes.bool,
        isInProofOfValue: PropTypes.bool,
      })
    ),
    hasAmazonRequests: PropTypes.bool,
    hasFulfilledByLearnerRequests: PropTypes.bool,
    hasFulfilledByLearnerViaExpensesRequests: PropTypes.bool,
    members: PropTypes.shape({
      results: PropTypes.arrayOf(
        PropTypes.shape({
          activeIndividualBudget: PropTypes.shape({
            remaining: PropTypes.number,
            spent: PropTypes.number,
            total: PropTypes.number,
          }),
          approvers: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
            })
          ),
          createdAt: PropTypes.string,
          email: PropTypes.string,
          firstName: PropTypes.string,
          geographicLocation: PropTypes.string,
          id: PropTypes.string,
          lastName: PropTypes.string,
          onboarded: PropTypes.bool,
          hasPassword: PropTypes.bool,
        })
      ),
      searchAfter: PropTypes.string,
    }),
    name: PropTypes.string,
    oktaInstallation: PropTypes.bool,
    recentActivity: PropTypes.bool,
    slackInstallation: PropTypes.bool,
    testClient: PropTypes.bool,
    internalLearning: PropTypes.bool,
    internalEvents: PropTypes.bool,
    isInProofOfValue: PropTypes.bool,
    hasFunds: PropTypes.bool,
    isFrozen: PropTypes.bool,

    // Pots related
    wallets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        currency: PropTypes.string,
        credit: PropTypes.number,
      })
    ),
    accountBudgets: PropTypes.shape({
      pageInfo: PropTypes.shape({
        hasNextPage: PropTypes.bool,
        endCursor: PropTypes.string,
      }),
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            maxSpend: PropTypes.number,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            funds: PropTypes.shape({
              allocated: PropTypes.number,
              totalSpend: PropTypes.number,
            }),
            associatedPots: PropTypes.arrayOf(
              PropTypes.shape({
                name: PropTypes.string,
              })
            ),
          }),
        })
      ),
    }),

    externalIds: PropTypes.shape({
      crm: PropTypes.string,
      finance: PropTypes.string,
    }),
  }),
  currentUser: PropTypes.shape({
    admin: PropTypes.bool,
    email: PropTypes.string,
    featureToggles: PropTypes.arrayOf(PropTypes.string),
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
  }),
  fetchMoreAccountBudgets: PropTypes.func,
};

export default compose(
  graphql(updateOrganisationMutation, {
    name: "updateOrganisation",
  }),
  graphql(archiveOrganisationMutation, {
    name: "archiveOrganisationMutation",
  }),
  graphql(archiveBudgetPeriodMutation, {
    name: "archiveBudgetPeriodMutation",
  }),
  graphql(freezeOrganisationMutation, {
    name: "freezeOrganisationMutation",
  }),
  graphql(unfreezeOrganisationMutation, {
    name: "unfreezeOrganisationMutation",
  })
)(UpdateOrganisation);
