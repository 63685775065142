import React from "react";

import PropTypes from "prop-types";

const Home = ({ user }) => (
  <div>
    <p>Hey {`${user.firstName} ${user.lastName}`} 👋 , thanks for coming in</p>
  </div>
);

Home.propTypes = {
  user: PropTypes.object,
};

export default Home;
