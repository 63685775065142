import { Input } from "react-toolbox";
import React from "react";

import PropTypes from "prop-types";

const RenderField = ({ input, meta, ...props }) => (
  <Input {...input} {...props} error={meta.touched && meta.error} />
);

RenderField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
};

export default RenderField;
