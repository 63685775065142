import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";
import moment from "moment-timezone";

import PropTypes from "prop-types";

import UpdateEventForm from "../update-event-form";

import updateEventQuery from "./update-event.graphql";

import theme from "./theme.css";

const onSubmit = (updateEvent, handleDialog, id, resourceId) => ({
  price,
  city,
  startDate,
  endDate,
  moreInfo,
  street,
  postcode,
  description,
  timezone,
  active,
  link,
  country,
  currency,
}) =>
  updateEvent({
    variables: {
      event: {
        location: {
          city,
          street,
          postcode,
        },
        moreInfo,
        description,
        price,
        startDate: moment
          .tz(moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
          .utc()
          .format(),
        endDate:
          endDate &&
          moment
            .tz(moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
            .utc()
            .format(),
        timezone,
        active,
        link,
        country,
        currency,
      },
      resourceId,
      id,
    },
  }).then(handleDialog);

const UpdateEventDialog = ({
  active,
  updateEvent,
  handleDialog,
  resourceId,
  product,
  resourceLink,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <UpdateEventForm
      handleDialog={handleDialog}
      onSubmit={onSubmit(updateEvent, handleDialog, product.id, resourceId)}
      initialValues={{
        city: product.location && product.location.city,
        street: product.location && product.location.street,
        postcode: product.location && product.location.postcode,
        moreInfo: product.moreInfo,
        description: product.description,
        price: product.price,
        startDate: moment
          .utc(
            moment
              .tz(product.startDate, product.timezone)
              .format("YYYY-MM-DDTHH:mm")
          )
          .format(),
        timezone: product.timezone,
        endDate:
          product.endDate &&
          moment
            .utc(
              moment
                .tz(product.endDate, product.timezone)
                .format("YYYY-MM-DDTHH:mm")
            )
            .format(),
        active: Boolean(product.active),
        link: product.link || resourceLink || null,
        country: product.country,
        currency: product.currency,
      }}
    />
  </Dialog>
);

UpdateEventDialog.propTypes = {
  active: PropTypes.bool,
  updateEvent: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  product: PropTypes.object,
  resourceLink: PropTypes.string,
};

export default graphql(updateEventQuery, {
  name: "updateEvent",
})(UpdateEventDialog);
