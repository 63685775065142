import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { Input } from "react-toolbox";
import { partial } from "lodash";
import PropTypes from "prop-types";

import createWalletMutation from "./create-wallet.graphql";
import updateWalletMutation from "./update-wallet.graphql";

const Wallet = ({ organisationId, currency, id, onUpdate }) => {
  const [formState, setFormState] = useState({
    currency,
  });

  const handleInputChange = (name, value) => {
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (event, mutation) => {
    event.preventDefault();

    mutation({
      variables: {
        ...(id ? { id } : { organisationId }),
        currency: formState.currency,
      },
    })
      .then(onUpdate)
      .catch((error) => alert(error.message));
  };

  return (
    <>
      <h3>Wallet</h3>
      <Mutation mutation={id ? updateWalletMutation : createWalletMutation}>
        {(mutation) => (
          <form onSubmit={(event) => handleSubmit(event, mutation)}>
            <Input
              label="Currency"
              disabled
              autoFocus
              value={formState.currency}
              onChange={partial(handleInputChange, "currency")}
            />
          </form>
        )}
      </Mutation>
    </>
  );
};

Wallet.propTypes = {
  organisationId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  id: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

export default Wallet;
