import PropTypes from "prop-types";
import React from "react";
import { graphql } from "react-apollo";
import { Dialog } from "react-toolbox";

import CreateVirtualCardProductForm from "../create-virtual-card-product-form";

import addVirtualCardProductQuery from "../add-virtual-card-product-dialog/add-virtual-card-product.graphql";

import theme from "./theme.css";

const onSubmit = (copyVirtualCardProduct, handleDialog, resourceId) => ({
  active,
  description,
  link,
}) =>
  copyVirtualCardProduct({
    variables: {
      virtualCardProduct: {
        active,
        description,
        link,
      },
      resourceId,
    },
  }).then(handleDialog);

const CopyVirtualCardProductModal = ({
  active,
  copyVirtualCardProduct,
  handleDialog,
  product,
  resourceId,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <CreateVirtualCardProductForm
      handleDialog={handleDialog}
      initialValues={{
        active: product.active,
        description: product.description,
        isGlobal: !product.country,
        link: product.link,
      }}
      onSubmit={onSubmit(copyVirtualCardProduct, handleDialog, resourceId)}
    />
  </Dialog>
);

CopyVirtualCardProductModal.propTypes = {
  active: PropTypes.bool,
  copyVirtualCardProduct: PropTypes.func,
  handleDialog: PropTypes.func,
  product: PropTypes.object,
  resourceId: PropTypes.string,
};

export default graphql(addVirtualCardProductQuery, {
  name: "copyVirtualCardProduct",
})(CopyVirtualCardProductModal);
