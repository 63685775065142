import { TextField } from "@material-ui/core/";
import { Autocomplete } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import { Query } from "react-apollo";
import ProgressBar from "react-toolbox/lib/progress_bar";

import organisationQuery from "./organisations.graphql";

const OwnedByPicker = ({ input }) => {
  return (
    <Query query={organisationQuery}>
      {({ data, loading }) => {
        if (loading) {
          return <ProgressBar mode="indeterminate" />;
        }

        const { organisations = [] } = data;

        return (
          <Autocomplete
            clearOnEscape
            getOptionLabel={(option) =>
              !option
                ? ""
                : option.name
                ? option.name
                : organisations.find((org) => org.id === input.value).name
            }
            onChange={(_, value) =>
              value ? input.onChange(value.id) : input.onChange(null)
            }
            value={input.value}
            options={organisations}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose an organisation"
                variant="standard"
              />
            )}
          />
        );
      }}
    </Query>
  );
};

OwnedByPicker.propTypes = {
  input: PropTypes.object,
};

export default OwnedByPicker;
