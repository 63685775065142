import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import CreateBookForm from "../create-book-form";

import { GrowthBookProvider } from "../../utils/growthbook/growthbook";

import addBookQuery from "./add-book.graphql";

import theme from "./theme.css";

const onSubmit = (addBook, handleDialog, resourceId) => (data) => {
  const { isGlobal, supplier, ...bookData } = data;

  addBook({
    variables: {
      book: {
        ...bookData,
        supplier: supplier?.id,
      },
      resourceId,
    },
  }).then(handleDialog);
};

const AddBookModal = ({
  active,
  addBook,
  handleDialog,
  resourceId,
  resourceLink,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <GrowthBookProvider>
      <CreateBookForm
        handleDialog={handleDialog}
        onSubmit={onSubmit(addBook, handleDialog, resourceId)}
        initialValues={{
          active: true,
          isGlobal: false,
          link: resourceLink,
        }}
      />
    </GrowthBookProvider>
  </Dialog>
);

AddBookModal.propTypes = {
  active: PropTypes.bool,
  addBook: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  resourceLink: PropTypes.string,
};

export default graphql(addBookQuery, {
  name: "addBook",
})(AddBookModal);
