import { getNames, registerLocale } from "@learnerbly/i18n-iso-countries";
import React, { FunctionComponent } from "react";
import { Autocomplete } from "react-toolbox/lib/autocomplete";
import { WrappedFieldProps } from "redux-form";

interface ComponentProps {
  label?: string;
  multiple?: boolean;
}

registerLocale(require("@learnerbly/i18n-iso-countries/langs/en.json"));

const countries = getNames("en");

const CountryPicker: FunctionComponent<ComponentProps & WrappedFieldProps> = ({
  input,
  label,
  meta,
  multiple = false,
}) => {
  return (
    <Autocomplete
      error={meta.touched && meta.error}
      label={label || "Choose a Country"}
      multiple={multiple}
      onChange={(newValue: string) => input.onChange(newValue)}
      showSuggestionsWhenValueIsSet
      source={countries}
      value={input.value}
    />
  );
};

export default CountryPicker;
