import { Button, ProgressBar } from "react-toolbox";
import { Field, reduxForm } from "redux-form";
import Joi from "joi-browser";
import React from "react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderField from "../field";
import RenderSwitch from "../switch";

const schema = Joi.object({
  name: Joi.string().label("Name").required(),
  recentActivity: Joi.boolean().required(),
  internalLearning: Joi.boolean().required(),
  internalEvents: Joi.boolean().required(),
  analyticsDashboard: Joi.boolean().required(),
  adhocRequests: Joi.boolean()
    .required()
    .default(false)
    .when("hasAmazonRequests", {
      is: Joi.boolean().equal(true),
      then: Joi.boolean().equal(false),
    }),
  hasAmazonRequests: Joi.boolean().required().default(true),
  hasFulfilledByLearnerRequests: Joi.boolean().required().default(false),
  hasFulfilledByLearnerViaExpensesRequests: Joi.boolean()
    .required()
    .default(false),
  testClient: Joi.boolean().required(),
  isInProofOfValue: Joi.boolean().allow(null),
  hasFunds: Joi.boolean().required().default(true),
  externalIds: Joi.object({
    crm: Joi.string(),
    finance: Joi.string(),
  }),
  fulfilmentAccounts: Joi.array()
    .allow(null)
    .items(
      Joi.object({
        id: Joi.string().allow(null),
        name: Joi.string().allow(null),
        currency: Joi.string().allow(null),
        autoApprovedThreshold: Joi.number().integer().min(0).allow(null),
      })
    ),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const mapFormValuesToMutation = (onSubmit) => (data) => onSubmit(data);

const OrganisationForm = ({ handleSubmit, submitLabel, onSubmit, loading }) => {
  return (
    <form onSubmit={handleSubmit(mapFormValuesToMutation(onSubmit))}>
      <Field component={RenderField} label="Name" name="name" />
      <Field
        component={RenderSwitch}
        label="Recent Activity"
        name="recentActivity"
      />
      <Field
        component={RenderSwitch}
        label="Internal Learning"
        name="internalLearning"
      />
      <Field
        component={RenderSwitch}
        label="Internal Events"
        name="internalEvents"
      />
      <Field
        component={RenderSwitch}
        label="Analytics Dashboard"
        name="analyticsDashboard"
      />
      <Field component={RenderSwitch} label="Test Client" name="testClient" />
      <Field
        component={RenderSwitch}
        label="Adhoc Requests (cannot be on when Amazon Requests is on)"
        name="adhocRequests"
      />
      <Field
        component={RenderSwitch}
        label="Amazon Requests (cannot be on when Adhoc Requests is on)"
        name="hasAmazonRequests"
      />
      <Field
        component={RenderSwitch}
        label="Virtual Card Requests"
        name="hasFulfilledByLearnerRequests"
      />
      <Field
        component={RenderSwitch}
        label="Expense Requests"
        name="hasFulfilledByLearnerViaExpensesRequests"
      />
      <Field
        component={RenderSwitch}
        label="Customer in Proof-of-Value phase"
        name="isInProofOfValue"
      />
      <Field
        component={RenderSwitch}
        label="Customer has fund available"
        name="hasFunds"
      />
      <Field
        component={RenderField}
        label="CRM (Hubspot) ID"
        name="externalIds.crm"
      />
      <Field
        component={RenderField}
        label="Finance (Chargebee) ID"
        name="externalIds.finance"
      />
      <Button type="submit" raised primary disabled={loading}>
        {submitLabel}
      </Button>

      {loading && <ProgressBar mode="indeterminate" />}
    </form>
  );
};

OrganisationForm.propTypes = {
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  organisationId: PropTypes.string,
};

export default reduxForm({
  form: "organisation",
  validate: mapFormValuesToMutation(validate),
})(OrganisationForm);
