import { Button, List, ListItem } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import React from "react";

import PropTypes from "prop-types";

import CreateExpertDialog from "../create-expert-dialog";
import SelectExpertDialog from "../select-expert-dialog";
import UpdateExpertDialog from "../update-expert-dialog";
import ConfirmExpertChangeDialog from "../confirm-expert-change-dialog";

class Expert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      createExpertDialog: false,
      updateExpertDialog: false,
      selectExpertDialog: false,
      showConfirmUpdateDialog: false,
      expert: null,
    };

    this.handleCreateExpertDialog = this.handleCreateExpertDialog.bind(this);
    this.handleUpdateExpertDialog = this.handleUpdateExpertDialog.bind(this);
    this.handleSelectExpertDialog = this.handleSelectExpertDialog.bind(this);
    this.onExpertClick = this.onExpertClick.bind(this);
    this.updateExperts = this.updateExperts.bind(this);
    this.addExpert = this.addExpert.bind(this);
    this.removeExpert = this.removeExpert.bind(this);
  }

  handleCreateExpertDialog() {
    this.setState({
      createExpertDialog: !this.state.createExpertDialog,
    });
  }

  handleUpdateExpertDialog() {
    this.setState({
      updateExpertDialog: !this.state.updateExpertDialog,
    });
  }

  onExpertClick(expert) {
    this.setState({
      showConfirmUpdateDialog: true,
      expert,
    });
  }

  handleSelectExpertDialog() {
    this.setState({
      selectExpertDialog: !this.state.selectExpertDialog,
    });
  }

  updateExperts(expert) {
    const { input } = this.props;
    input.onChange(
      input.value.map((item) => {
        if (item.id === expert.id) {
          return expert;
        } else {
          return item;
        }
      })
    );
  }

  removeExpert(id) {
    const { input } = this.props;
    input.onChange(input.value.filter((item) => item.id !== id));
  }

  addExpert(expert) {
    const { input } = this.props;
    const experts = input.value || [];

    experts.push(expert);
    input.onChange(experts);
  }

  render() {
    const { input } = this.props;

    return (
      <Row>
        <Col sm={7}>
          <h4>Experts</h4>
          {!input.value && <p>Not set</p>}
          {input.value && (
            <List>
              {input.value.map((value) => (
                <ListItem
                  key={value.id}
                  caption={`${value.firstName} ${value.lastName}`}
                  rightActions={[
                    <Button
                      key="edit"
                      icon="edit"
                      onClick={() => this.onExpertClick(value)}
                      primary
                      mini
                    >
                      Edit
                    </Button>,
                    <Button
                      key="unlink"
                      icon="link"
                      onClick={() => this.removeExpert(value.id)}
                      primary
                      mini
                    >
                      Unlink
                    </Button>,
                  ]}
                />
              ))}
            </List>
          )}
        </Col>
        <Col sm={1}>
          <Button
            icon="add"
            onClick={this.handleCreateExpertDialog}
            primary
            floating
            mini
          />
        </Col>
        <Col sm={1}>
          <Button
            icon="search"
            onClick={this.handleSelectExpertDialog}
            primary
            floating
            mini
          />
        </Col>

        <CreateExpertDialog
          active={this.state.createExpertDialog}
          handleDialog={this.handleCreateExpertDialog}
          onSuccess={this.addExpert}
        />

        {input.value && this.state.showConfirmUpdateDialog && (
          <ConfirmExpertChangeDialog
            active={this.state.showConfirmUpdateDialog}
            expertName={`${this.state.expert.firstName} ${this.state.expert.lastName}`}
            handleDialog={(showUpdateDialog) => {
              this.setState({
                showConfirmUpdateDialog: false,
                updateExpertDialog: showUpdateDialog,
              });
            }}
          ></ConfirmExpertChangeDialog>
        )}

        {input.value && this.state.updateExpertDialog && (
          <UpdateExpertDialog
            active={this.state.updateExpertDialog}
            handleDialog={this.handleUpdateExpertDialog}
            onSuccess={this.updateExperts}
            onUnlink={this.removeExpert}
            expert={this.state.expert}
          />
        )}

        <SelectExpertDialog
          active={this.state.selectExpertDialog}
          handleDialog={this.handleSelectExpertDialog}
          onSuccess={this.addExpert}
        />
      </Row>
    );
  }
}

Expert.propTypes = {
  input: PropTypes.object,
};

export default Expert;
