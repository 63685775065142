import React from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "react-toolbox";

const mapIdToName = ({ id, name, currency }) => ({
  [id]: `${name} - ${currency}`,
});

const FulfilmentAccountPicker = ({
  fulfilmentAccounts,
  input,
  meta,
  multiple = false,
}) => {
  const source = Object.assign(
    {
      [""]: "None",
    },
    ...fulfilmentAccounts.map(mapIdToName)
  );

  const onChange = (selectedAccountId) =>
    input.onChange(
      fulfilmentAccounts.find(({ id }) => id === selectedAccountId)
    );

  return (
    <Autocomplete
      showSuggestionsWhenValueIsSet
      multiple={multiple}
      value={input.value && `${input.value.name} - ${input.value.currency}`}
      label="Fulfilment Account (no fulfilment for test clients)"
      source={source}
      onChange={onChange}
      error={meta.error}
    />
  );
};

FulfilmentAccountPicker.propTypes = {
  fulfilmentAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      currency: PropTypes.string,
    })
  ).isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  multiple: PropTypes.bool,
};

export default FulfilmentAccountPicker;
