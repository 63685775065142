import { graphql } from "../../__generated__";

export const SEARCH_RESOURCES_QUERY = graphql(`
  query SearchResources($query: String!, $searchAfter: String) {
    resources: searchResourcesAsAdmin(
      query: $query
      size: 10
      searchAfter: $searchAfter
    ) {
      searchAfter
      results {
        ... on Resource {
          id
          title
          type {
            id
            name
          }
        }
      }
    }
  }
`);
