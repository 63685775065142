import { useFeature } from "@growthbook/growthbook-react";
import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import Joi from "joi-browser";
import React, { useState } from "react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderField from "../field";
import RenderSwitch from "../switch";
import BookFormatPicker, {
  bookFormatTypes,
} from "../book-format-picker/book-format-picker";
import SearchSupplier from "../search-supplier";

const schema = Joi.object({
  price: Joi.number().integer().min(0).required(),
  description: Joi.string().required(),
  active: Joi.boolean().label("Active"),
  link: Joi.string().required(),
  amazonASIN: Joi.string()
    .min(10)
    .when("format.type", {
      is: Joi.string().valid("PAPERBACK", "HARDCOVER", "KINDLE"),
      then: Joi.required(),
      otherwise: Joi.allow(null),
    }),
  isbn: Joi.string()
    .min(10)
    .max(13)
    .when("format.type", {
      is: Joi.string().valid("EBOOK", "AUDIOBOOK"),
      then: Joi.required(),
      otherwise: Joi.allow(null),
    }),
  format: Joi.object({
    type: Joi.string().valid(bookFormatTypes),
    supplier: Joi.string().allow(null),
  }),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const BookForm = ({
  handleDialog,
  handleSubmit,
  onSubmit,
  initialValues,
  change,
}) => {
  const [isDigitalProduct, setIsDigitalProduct] = useState(
    ["EBOOK", "AUDIOBOOK"].includes(initialValues.format?.type)
  );
  const showDigitalResourcesInput = useFeature("digital_resources").on;

  const onFormatTypeChange = (event, value) => {
    setIsDigitalProduct(["EBOOK", "AUDIOBOOK"].includes(value));
    change(isDigitalProduct ? "amazonASIN" : "isbn", null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={RenderField}
        label="Price"
        name="price"
        parse={parse}
        format={format}
        autoFocus
      />
      <Field
        component={RenderField}
        label="Description"
        hint="E.g. Hardcover, Kindle"
        name="description"
      />
      <Field component={RenderField} label="Product URL" name="link" />

      {showDigitalResourcesInput ? (
        <Field
          component={BookFormatPicker}
          name="format.type"
          onChange={onFormatTypeChange}
        />
      ) : null}
      {isDigitalProduct ? (
        <Field component={RenderField} label="ISBN" name="isbn" />
      ) : (
        <Field component={RenderField} label="Amazon ASIN" name="amazonASIN" />
      )}
      {showDigitalResourcesInput ? (
        <Field
          component={SearchSupplier}
          label="Supplier"
          name="format.supplier"
        />
      ) : null}
      <Row between="xs">
        <Col>
          <Field component={RenderSwitch} label="Active" name="active" />
        </Col>
        <Col>
          <Button onClick={handleDialog}>Cancel</Button>
          <Button type="submit" raised primary>
            Update Book
          </Button>
        </Col>
      </Row>
    </form>
  );
};

BookForm.propTypes = {
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  change: PropTypes.func,
  submitLabel: PropTypes.string,
  initialValues: PropTypes.object,
};

export default reduxForm({
  form: "book",
  validate,
})(BookForm);
