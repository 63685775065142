// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-ssoContainer-2wYPywxA {\n  margin-top: 30px;\n}\n", "",{"version":3,"sources":["/Users/mnavarro/source/origin/backoffice/src/components/show-single-sign-on/theme.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","file":"theme.css","sourcesContent":[".ssoContainer {\n  margin-top: 30px;\n}\n"]}]);
// Exports
exports.locals = {
	"ssoContainer": "theme-ssoContainer-2wYPywxA"
};
module.exports = exports;
