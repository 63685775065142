import { Dialog, Button } from "react-toolbox";
import React from "react";

import PropTypes from "prop-types";

const ConfirmExpertChangeDialog = ({ expertName, active, handleDialog }) => (
  <Dialog
    active={active}
    onEscKeyDown={() => handleDialog(false)}
    onOverlayClick={() => handleDialog(false)}
    title="Update Expert - THIS WILL EDIT THE SELECTED EXPERT FOR THIS PLAYLIST (BE CAREFUL IF IT IS LEARNERBLY). IF YOU WANT TO REPLACE THE EXPERT, PLEASE CLICK UNLINK INSTEAD OF EDIT"
  >
    <div>
      <div>
        <img src="https://media.giphy.com/media/1zSz5MVw4zKg0/giphy-downsized-large.gif" />
      </div>
      Do you want to update {expertName} as the expert for this playlist?
      <Button onClick={() => handleDialog(false)}>Hell no</Button>
      <Button type="submit" onClick={() => handleDialog(true)} raised primary>
        Yep
      </Button>
    </div>
  </Dialog>
);

ConfirmExpertChangeDialog.propTypes = {
  expertName: PropTypes.string,
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
};

export default ConfirmExpertChangeDialog;
