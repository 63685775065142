import { Col, Row } from "react-flexbox-grid";
import { Snackbar } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import CreateOrganisationAdminForm from "../../components/create-organisation-admin-form";

import createAdminUserMutation from "./create-organisation-admin.graphql";

const onSubmit = (mutate, history, failure) => ({
  firstName,
  lastName,
  email,
  country,
  organisationAdmin,
  organisationId,
  geographicLocation,
}) =>
  mutate({
    variables: {
      user: {
        firstName,
        lastName,
        email,
        country,
        organisationAdmin,
        organisation: organisationId,
        geographicLocation,
      },
    },
  })
    .then(() => history.push(`/organisations/${organisationId}/`))
    .catch(failure);

class CreateOrganisationAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false,
    };
  }

  render() {
    const { currentUser, mutate, history, match } = this.props;
    return (
      <div>
        <h1>Create Organisation Admin User</h1>
        <Row>
          <Col sm={7}>
            <CreateOrganisationAdminForm
              initialValues={{
                organisationId: match.params.organisationId,
                organisationAdmin: true,
              }}
              onSubmit={onSubmit(
                mutate,
                history,
                () =>
                  this.setState({
                    alert: true,
                    message: "Failure 😞",
                  }),
                currentUser
              )}
              currentUser={currentUser}
            />
          </Col>
        </Row>
        <Snackbar
          action="Dismiss"
          active={this.state.alert}
          label={this.state.message}
          timeout={2000}
          onTimeout={() => this.setState({ alert: false })}
          type="cancel"
        />
      </div>
    );
  }
}

CreateOrganisationAdmin.propTypes = {
  mutate: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  currentUser: PropTypes.object,
};

export default graphql(createAdminUserMutation)(CreateOrganisationAdmin);
