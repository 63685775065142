import { Button } from "react-toolbox";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import Joi from "joi-browser";
import React from "react";
import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";

import ContentRegionPicker from "../content-region-picker";
import ApproverPicker from "../approver-picker";
import CountryPicker from "../country-picker";
import RenderField from "../../components/field";
import RolePicker from "../../components/role-picker";
import SwitchField from "../../components/switch";

const schema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string().email().required(),
  organisation: Joi.string().uuid({ version: "uuidv4" }).required(),
  approvers: Joi.array()
    .unique()
    .required()
    .min(1)
    .items(
      Joi.string().guid({
        version: ["uuidv4"],
      })
    ),
  admin: Joi.boolean(),
  organisationAdmin: Joi.boolean(),
  sendNotification: Joi.boolean(),
  country: Joi.string().length(2).default("GB").required(),
  impersonate: Joi.string().uuid({ version: "uuidv4" }).allow(null, ""),
  geographicLocation: Joi.string().length(2).default("GB").allow(null),
  role: Joi.string().allow(null),
}).required();

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const UserForm = ({
  userId,
  organisationId,
  handleSubmit,
  submitLabel,
  onSubmit,
  isLoading,
  currentUser,
}) => {
  const discoveryTeam = [
    "9f8e7965-87ae-479a-adf2-a3ad9ae9fb03", // Cecilia
    "3c983c49-70ac-43af-8d9e-fdd10f45a868", // Charlie
    "d03bad31-40e7-4ef6-865f-1602da28d705", // Chris
    "a124616b-19b8-40d5-ba41-42c5b1a4ee1b", // James
    "9d56889f-7f35-4aed-9830-948a24a1e24c", // Joe
    "357a679a-31ab-40ff-abbb-6a0215f8c32a", // Kath
    "a5d5870f-a916-4586-9cad-d0b1b0813bc1", // Mike
  ];

  const isCurrentUserMemberOfDiscoveryTeam = discoveryTeam.includes(
    currentUser.id
  );

  const isUserBeingUpdatedInTesterbly =
    organisationId === "a4a8ea18-7b3a-49d2-93d7-f81d7499da11";

  // limits potential for a `role` being mistakenly assigned whilst we build the `assessment` feature (assessments are linked to skills which are linked to roles)
  const shouldRenderRolePicker =
    isCurrentUserMemberOfDiscoveryTeam || isUserBeingUpdatedInTesterbly;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {organisationId && (
        <React.Fragment>
          <Field
            component={RenderField}
            label="First Name"
            name="firstName"
            autoFocus
          />
          <Field component={RenderField} label="Last Name" name="lastName" />
          <Field
            component={RenderField}
            label="Email"
            name="email"
            type="email"
          />
          <Field
            component={RenderField}
            label="Impersonate user id (superadmin only)"
            name="impersonate"
          />
          <Field
            label="Choose the user's content region"
            name="country"
            component={ContentRegionPicker}
          />
          <Field
            name="approvers"
            organisationId={organisationId}
            component={ApproverPicker}
          />
          <Field
            label="Choose the user's geographic location"
            name="geographicLocation"
            component={CountryPicker}
          />
          {shouldRenderRolePicker && (
            <Field
              label="Choose the user's role"
              name="role"
              component={RolePicker}
            />
          )}
          {!userId && (
            <Field
              name="sendNotification"
              component={SwitchField}
              label="Send Email Notification"
            />
          )}
          <Field
            name="admin"
            component={SwitchField}
            label="Give access to back office"
          />
          <Field
            name="organisationAdmin"
            component={SwitchField}
            label="Admin for the organisation"
          />
        </React.Fragment>
      )}
      <Button disabled={isLoading} type="submit" raised primary>
        {submitLabel}
      </Button>
    </form>
  );
};

UserForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  userId: PropTypes.string,
  organisationId: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default compose(
  reduxForm({
    form: "user",
    validate,
  })
)(UserForm);
