import "normalize.css";

import { ApolloProvider as DeprecatedApolloProvider } from "react-apollo";
import { BrowserRouter } from "react-router-dom";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { Provider } from "react-redux";
import { createHttpLink } from "apollo-link-http";
import { createStore, combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { setContext } from "apollo-link-context";
import Auth from "@aws-amplify/auth";
import ApolloClient from "apollo-client";
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";

import Main from "./components/main";
import { GrowthBookProvider } from "./utils/growthbook/growthbook";
import { apolloClient } from "./apollo-client";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "INTERFACE",
          name: "Product",
          possibleTypes: [
            { name: "Event" },
            { name: "Book" },
            { name: "OnlineCourse" },
            { name: "VirtualCardProduct" },
          ],
        },
      ],
    },
  },
});

const httpLink = createHttpLink({ uri: process.env.API_URL });

const authMiddleware = setContext(async (req, { headers }) => {
  const token = await Auth.currentSession().then((session) => {
    return session.accessToken.jwtToken;
  });

  return {
    headers: {
      ...headers,
      ...(token && { authorization: token }),
    },
  };
});

const deprecatedApolloClient = new ApolloClient({
  name: "backoffice",
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache({ fragmentMatcher }).restore(
    window.__APOLLO_STATE__
  ),
  dataIdFromObject: (o) => o.id,
});

const store = createStore(
  combineReducers({
    form: formReducer,
  }),
  {}, // initial state
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

Auth.configure({
  identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
  region: process.env.AWS_REGION,
  userPoolId: process.env.AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.AWS_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.AWS_COGNITO_DOMAIN,
    scope: ["email", "profile", "openid"],
    redirectSignIn: process.env.ADMIN_URL,
    redirectSignOut: `${process.env.ADMIN_URL}/logout`,
    responseType: "code",
    options: {
      AdvancedSecurityDataCollectionFlag: true,
    },
  },
});

window.onload = () => {
  ReactDOM.render(
    <GrowthBookProvider>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <DeprecatedApolloProvider client={deprecatedApolloClient}>
            <BrowserRouter>
              <Main />
            </BrowserRouter>
          </DeprecatedApolloProvider>
        </ApolloProvider>
      </Provider>
    </GrowthBookProvider>,
    document.getElementById("root")
  );
};
