const addCurrency = (value, currency, minimumFractionDigits = 2) => {
  if (currency === "LBC") {
    const number = new Intl.NumberFormat("en-GB", {
      minimumFractionDigits: 0, // there are no fractions in coins
    }).format(value);
    return `${number} Coins`;
  }

  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency,
    minimumFractionDigits,
  }).format(value);
};

export const formatCurrencyValue = (value, currency) => {
  return addCurrency(value / 100, currency);
};

export const formatResourceSearchResult = (resource) => {
  let caption = resource.title;

  if (resource.type && resource.type.name) {
    caption += ` (${resource.type.name})`;
  }

  return caption;
};

export const readableList = (words = []) =>
  words.reduce((list, word, index, array) => {
    if (!list) {
      return word;
    }

    if (array.length - 1 === index) {
      return `${list} and ${word}`;
    }

    return `${list}, ${word}`;
  }, "");
