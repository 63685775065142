import { Col, Row } from "react-flexbox-grid";
import { List, ListItem, Button, ProgressBar } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import playlistsQuery from "./playlists.graphql";

const byTitle = (a, b) => {
  if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
  if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;
  return 0;
};

class Playlists extends React.Component {
  onPlaylistClick(playlist, history) {
    history.push(`/playlists/${playlist.id}/`);
  }

  render() {
    const {
      data: { playlists, loading },
      history,
    } = this.props;

    if (loading) return <ProgressBar mode="indeterminate" />;

    return (
      <div>
        <Row between="xs">
          <Col xs={9}>
            {playlists && playlists.length > 0 && (
              <List>
                {playlists.sort(byTitle).map((playlist, index) => (
                  <ListItem
                    key={index}
                    caption={playlist.title}
                    legend={
                      playlist.organisations &&
                      playlist.organisations
                        .map((organisation) => organisation.name)
                        .join(", ")
                    }
                    onClick={() => this.onPlaylistClick(playlist, history)}
                    rightIcon="edit"
                  />
                ))}
              </List>
            )}
          </Col>
          <Col>
            <Link to="/playlists/create/">
              <Button icon="add" label="New Playlist" raised primary />
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

Playlists.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
};

export default graphql(playlistsQuery)(Playlists);
