import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { change, Field, reduxForm } from "redux-form";
import { codes } from "currency-codes";
import Joi from "joi-browser";
import React, { useState } from "react";
import Markdown from "react-remarkable";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import ContentRegionPicker from "../content-region-picker";
import CurrencyPicker from "../currency-picker";
import RenderField from "../field";
import RenderSwitch from "../switch";
import RenderCheckbox from "../checkbox/checkbox";

import theme from "./styles.css";

const currencyCodes = codes();
currencyCodes.push("LBC");

const schema = Joi.object({
  price: Joi.number().integer().min(0).required(),
  description: Joi.string().allow(null, ""),
  active: Joi.boolean().label("Active"),
  currency: Joi.string()
    .length(3)
    .allow(currencyCodes)
    .default("GBP")
    .disallow(null),
  country: Joi.string().length(2).allow(null).default(null),
  isGlobal: Joi.boolean().label("Available for all countries?"),
  link: Joi.string().required(),
  codeClaim: Joi.boolean().default(false),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const parseNull = (value) => value || null;

const HelperTextFieldWithPreview = ({ input, ...props }) => {
  return (
    <div className={theme.helpertext}>
      <RenderField input={input} {...props} />

      {input.value && (
        <>
          <h4>Preview</h4>
          <div className={theme.preview}>
            <Markdown source={input.value} />
          </div>
        </>
      )}
    </div>
  );
};

HelperTextFieldWithPreview.propTypes = {
  input: PropTypes.object,
};

const CreateOnlineCourseForm = ({
  dispatch,
  handleDialog,
  handleSubmit,
  initialValues,
  onSubmit,
  loading,
  supplier,
}) => {
  const [isGlobal, setIsGlobal] = useState(initialValues.isGlobal);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={RenderField}
        label="Price"
        name="price"
        parse={parse}
        format={format}
        autoFocus
      />
      <Field
        component={RenderField}
        label="Description"
        name="description"
        parse={parseNull}
      />
      <Field
        component={RenderSwitch}
        label="Available for all countries?"
        name="isGlobal"
        onChange={(_, value) => {
          if (value) {
            dispatch(change("onlineCourse", "country", null));
          }

          setIsGlobal(value);
        }}
      />
      {!isGlobal && (
        <Field
          name="country"
          label="Allowed country (Can not be changed after creation)"
          component={ContentRegionPicker}
        />
      )}
      <Field
        component={CurrencyPicker}
        hasUpcomingCurrencies
        label="Currency (Can not be changed after creation)"
        name="currency"
      />
      <Field component={RenderField} label="Product URL" name="link" />
      {
        <div className={theme.codeClaim}>
          {!supplier?.codeClaim && (
            <p className={theme.supplierMessage}>
              To enable self-service learner code claims, you will need to
              enable it at the supplier level first.
            </p>
          )}
          <Field
            disabled={!supplier?.codeClaim}
            component={RenderCheckbox}
            label="Enable self-service learner code claims"
            name="codeClaim"
          />
        </div>
      }
      <Row between="xs">
        <Col>
          <Field component={RenderSwitch} label="Active" name="active" />
        </Col>
        <Col>
          <Button onClick={handleDialog}>Cancel</Button>
          <Button type="submit" disabled={loading} raised primary>
            Add Online Course
          </Button>
        </Col>
      </Row>
    </form>
  );
};

CreateOnlineCourseForm.propTypes = {
  dispatch: PropTypes.func,
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  loading: PropTypes.bool,
  supplier: PropTypes.shape({
    id: PropTypes.string,
    codeClaim: PropTypes.bool,
  }),
};

export default reduxForm({
  form: "onlineCourse",
  validate,
})(CreateOnlineCourseForm);
