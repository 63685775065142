import React from "react";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import { ProgressBar, List, ListItem, ListDivider } from "react-toolbox";

import theme from "./theme.css";
import { getOrganisationSingleSignOn } from "./single-sign-on.graphql";

const GetSSO = ({ organisationId }) => {
  return (
    <div className={theme.ssoContainer}>
      <h3>Single Sign On Integration:</h3>
      <Query
        query={getOrganisationSingleSignOn}
        fetchPolicy="cache-and-network"
        variables={{ id: organisationId }}
      >
        {({ loading, data }) => {
          const organisation = data?.organisation;
          if (loading) return <ProgressBar mode="indeterminate" />;
          return <ListSSO {...organisation} />;
        }}
      </Query>
    </div>
  );
};

const ListSSO = ({
  hasMagicLinks,
  singleSignOn,
  singleSignOnCustomProvider,
  singleSignOnProviderType,
}) => (
  <List>
    <ListDivider />
    <ListItem
      caption="Enabled: "
      disabled={!singleSignOn}
      legend={`${!!singleSignOn}`}
    />
    <ListItem
      caption="Provider type:"
      disabled={!singleSignOn}
      legend={`${singleSignOnProviderType}`}
    />
    <ListItem
      caption="Provider name:"
      disabled={!singleSignOn}
      legend={`${singleSignOnCustomProvider}`}
    />
    <ListItem
      caption="Magic links enabled:"
      disabled={!hasMagicLinks}
      legend={`${!!hasMagicLinks}`}
    />
    <ListDivider />
  </List>
);

GetSSO.propTypes = {
  organisationId: PropTypes.string.isRequired,
};

ListSSO.propTypes = {
  hasMagicLinks: PropTypes.bool,
  singleSignOn: PropTypes.bool,
  singleSignOnCustomProvider: PropTypes.string,
  singleSignOnProviderType: PropTypes.oneOf(["Google", "Okta", "SAML"]),
};

export default GetSSO;
