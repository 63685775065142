import { Dialog, List, ListItem, ProgressBar } from "react-toolbox";
import { Mutation, Query } from "react-apollo";
import React, { useState } from "react";
import PropTypes from "prop-types";

import archiveGroupsMutation from "./archive-group.graphql";
import organisationGroupsQuery from "./organisation-groups.graphql";

const onSubmit = (groupId, archiveGroup, handleDialog) => () =>
  archiveGroup({
    variables: { groupId },
  }).then(handleDialog);

const ArchiveGroupDialog = ({
  groupId,
  organisationId,
  handleDialog,
  active,
}) => (
  <Mutation
    mutation={archiveGroupsMutation}
    variables={{
      groupId,
      organisationId,
    }}
  >
    {(archiveGroup) => (
      <Dialog
        actions={[
          { label: "Nooo, I'll think about it", onClick: handleDialog },
          {
            label: "Sure, archive group",
            onClick: onSubmit(groupId, archiveGroup, handleDialog),
            raised: true,
            primary: true,
          },
        ]}
        onEscKeyDown={handleDialog}
        onOverlayClick={handleDialog}
        active={active}
        title="You can not reverse this action"
      />
    )}
  </Mutation>
);

ArchiveGroupDialog.propTypes = {
  groupId: PropTypes.string,
  organisationId: PropTypes.string,
  handleDialog: PropTypes.func,
  active: PropTypes.bool,
};

const OrganisationGroups = ({ organisationId }) => {
  const [showArchiveDialog, setArchiveDialog] = useState(false);
  return (
    <Query
      query={organisationGroupsQuery}
      variables={{
        id: organisationId,
      }}
    >
      {({ data, loading }) => {
        if (loading) {
          return <ProgressBar mode="indeterminate" />;
        }

        const {
          organisation: { groups },
        } = data;

        if (!groups) {
          return <div>No Groups</div>;
        }

        return (
          <div>
            <List>
              {groups.map(({ id, name, members, archived }) => (
                <ListItem
                  key={id}
                  caption={name}
                  legend={`${members ? members.length : 0} Members, ${
                    archived ? "Archived" : "Active"
                  }`}
                  leftIcon={archived ? "blocked" : "check_circle"}
                  rightIcon="archive"
                  onClick={() => setArchiveDialog(id)}
                />
              ))}
            </List>
            <ArchiveGroupDialog
              active={!!showArchiveDialog}
              groupId={showArchiveDialog}
              organisationId={organisationId}
              handleDialog={() => setArchiveDialog(null)}
            />
          </div>
        );
      }}
    </Query>
  );
};

OrganisationGroups.propTypes = {
  organisationId: PropTypes.string,
};

export default OrganisationGroups;
