import { useFeature } from "@growthbook/growthbook-react";
import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import { codes } from "currency-codes";
import Joi from "joi-browser";
import React, { useState } from "react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import ContentRegionPicker from "../content-region-picker";
import CurrencyPicker from "../currency-picker";
import RenderField from "../field";
import RenderSwitch from "../switch";
import BookFormatPicker, {
  bookFormatTypes,
} from "../book-format-picker/book-format-picker";
import SearchSupplier from "../search-supplier";

const currencyCodes = codes();
currencyCodes.push("LBC");

const schema = Joi.object({
  price: Joi.number().integer().min(0).required(),
  description: Joi.string().required(),
  active: Joi.boolean().label("Active"),
  currency: Joi.string()
    .length(3)
    .allow(currencyCodes)
    .default("GBP")
    .disallow(null),
  country: Joi.string().length(2).allow(null).default(null),
  isGlobal: Joi.boolean().label("Available for all countries?"),
  link: Joi.string().required(),
  amazonASIN: Joi.string()
    .min(10)
    .when("format", {
      is: Joi.string().valid("PAPERBACK", "HARDCOVER", "KINDLE"),
      then: Joi.required(),
      otherwise: Joi.allow(null),
    }),
  isbn: Joi.string()
    .min(10)
    .max(13)
    .when("format", {
      is: Joi.string().valid("EBOOK", "AUDIOBOOK"),
      then: Joi.required(),
      otherwise: Joi.allow(null),
    }),
  format: Joi.string().valid(bookFormatTypes),
  supplier: Joi.object({
    id: Joi.string(),
    name: Joi.string(),
  })
    .allow(null)
    .default(null),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const BookForm = ({
  dispatch,
  handleDialog,
  handleSubmit,
  initialValues,
  onSubmit,
  change,
}) => {
  const [isDigitalProduct, setIsDigitalProduct] = useState(
    ["EBOOK", "AUDIOBOOK"].includes(initialValues.format?.type)
  );
  const [isGlobal, setIsGlobal] = useState(initialValues.isGlobal);
  const showDigitalResourcesInput = useFeature("digital_resources").on;

  const onFormatTypeChange = (event, value) => {
    setIsDigitalProduct(["EBOOK", "AUDIOBOOK"].includes(value));
    change(isDigitalProduct ? "amazonASIN" : "isbn", null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={RenderField}
        label="Price"
        name="price"
        parse={parse}
        format={format}
        autoFocus
      />
      <Field
        component={RenderField}
        label="Description"
        hint="e.g. Hardcover, Kindle"
        name="description"
      />
      <Field
        component={RenderSwitch}
        label="Available for all countries?"
        name="isGlobal"
        onChange={(_, value) => {
          if (value) {
            dispatch(change("create-book", "country", null));
          }

          setIsGlobal(value);
        }}
      />
      {!isGlobal && (
        <Field
          name="country"
          label="Allowed country (Can not be changed after creation)"
          component={ContentRegionPicker}
        />
      )}
      <Field
        component={CurrencyPicker}
        hasUpcomingCurrencies
        label="Currency (Can not be changed after creation)"
        name="currency"
      />
      <Field component={RenderField} label="Product URL" name="link" />
      {showDigitalResourcesInput ? (
        <Field
          component={BookFormatPicker}
          name="format"
          onChange={onFormatTypeChange}
        />
      ) : null}
      {isDigitalProduct ? (
        <Field component={RenderField} label="ISBN" name="isbn" />
      ) : (
        <Field component={RenderField} label="Amazon ASIN" name="amazonASIN" />
      )}
      {showDigitalResourcesInput ? (
        <Field component={SearchSupplier} label="Supplier" name="supplier" />
      ) : null}

      <Row between="xs">
        <Col>
          <Field component={RenderSwitch} label="Active" name="active" />
        </Col>
        <Col>
          <Button onClick={handleDialog}>Cancel</Button>
          <Button type="submit" raised primary>
            Add Book
          </Button>
        </Col>
      </Row>
    </form>
  );
};

BookForm.propTypes = {
  dispatch: PropTypes.func,
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  change: PropTypes.func,
};

export default reduxForm({
  form: "create-book",
  validate,
})(BookForm);
