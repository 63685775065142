import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import ExpertForm from "../expert-form";

import updateExpert from "./update-expert.graphql";

import theme from "./theme.css";

const onSubmit = (id, updateExpert, onSuccess, handleDialog) => ({
  firstName,
  lastName,
  email,
  image,
  description,
  link,
  role,
  employer,
}) =>
  updateExpert({
    variables: {
      expert: {
        firstName,
        lastName,
        email,
        image,
        description,
        link,
        role,
        employer,
      },
      id,
    },
  }).then(({ data }) => {
    onSuccess(data.updateExpert);
    handleDialog();
  });

const unLink = (expertId, onUnlink, handleDialog) => () => {
  onUnlink(expertId);
  handleDialog();
};

const UpdateExpertDialog = ({
  active,
  handleDialog,
  onSuccess,
  updateExpert,
  onUnlink,
  expert,
}) => (
  <Dialog
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
    theme={theme}
  >
    <ExpertForm
      submitLabel="Update Expert"
      handleDialog={handleDialog}
      initialValues={expert}
      onSubmit={onSubmit(expert.id, updateExpert, onSuccess, handleDialog)}
      onUnlinkClick={unLink(expert.id, onUnlink, handleDialog)}
      update
    />
  </Dialog>
);

UpdateExpertDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  updateExpert: PropTypes.func,
  onSuccess: PropTypes.func,
  onUnlink: PropTypes.func,
  expert: PropTypes.object,
};

export default graphql(updateExpert, {
  name: "updateExpert",
})(UpdateExpertDialog);
