import { Col, Row } from "react-flexbox-grid";
import { DatePicker, Dropdown } from "react-toolbox";
import React from "react";
import moment from "moment-timezone";

import PropTypes from "prop-types";

const hours = [
  { value: 0, label: "00" },
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
];

const minutes = [
  { value: 0, label: "00" },
  { value: 15, label: "15" },
  { value: 30, label: "30" },
  { value: 45, label: "45" },
];

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datePicker: false,
    };

    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.onHourPick = this.onHourPick.bind(this);
    this.onMinutePick = this.onMinutePick.bind(this);
    this.onDatePick = this.onDatePick.bind(this);
  }

  handleDatePicker() {
    this.setState({
      datePicker: !this.state.datePicker,
    });
  }

  onDatePick(value) {
    const date = moment(value).utc();
    const inputValue = moment(this.props.input.value).utc();

    this.props.input.onChange(
      moment(inputValue || new Date())
        .set({
          year: date.get("year"),
          month: date.get("month"),
          date: date.get("date"),
        })
        .utc()
        .format()
    );
  }

  onHourPick(value) {
    const date = moment(this.props.input.value).utc().hour(value);
    this.props.input.onChange(date.format());
  }

  onMinutePick(value) {
    const date = moment(this.props.input.value).utc().minute(value);
    this.props.input.onChange(date.format());
  }

  render() {
    const {
      input,
      meta: { error, touched },
      label,
    } = this.props;

    const momentValue = moment(input.value);

    return (
      <div>
        <Row middle="xs">
          <Col xs={3}>{label}</Col>
          <Col xs>
            <DatePicker
              label={momentValue.format("MMM Do YY")}
              onEscKeyDown={this.handleDatePicker}
              onOverlayClick={this.handleDatePicker}
              active={this.state.datePicker}
              onChange={this.onDatePick}
              value={input.value ? new Date(momentValue.utc().format()) : null}
              autoOk
            />
          </Col>
          <Col xs>
            <Dropdown
              label="Hour"
              source={hours}
              value={momentValue.utc().hour()}
              onChange={this.onHourPick}
            />
          </Col>
          <Col xs>
            <Dropdown
              label="Minute"
              source={minutes}
              value={
                momentValue.utc().minute() - (momentValue.utc().minute() % 15)
              }
              onChange={this.onMinutePick}
            />
          </Col>
        </Row>
        {touched && error && <div>{error}</div>}
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
};

export default DateTimePicker;
