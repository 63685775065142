import React from "react";
import { Input } from "react-toolbox";
import csvToJson from "csvtojson";
import PropTypes from "prop-types";

const UploadCodes = ({ meta, input, ...props }) => {
  const onInput = async (event) => {
    const file = event.target?.files?.item(0);
    if (!file) return input.onChange([]);

    const text = await file.text();
    const json = await csvToJson({
      colParser: { costPrice: "number" },
    }).fromString(text);

    input.onChange(json);
  };

  return (
    <Input
      type="file"
      accept=".csv"
      onInput={onInput}
      {...props}
      error={meta.touched && meta.error}
    />
  );
};

UploadCodes.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
};

export { UploadCodes };
