import React, { useState } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { Mutation } from "react-apollo";
import {
  ProgressBar,
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Snackbar,
} from "react-toolbox";
import PropTypes from "prop-types";

import updateOrganisationMutation from "src/components/update-organisation/update-organisation.graphql";

import FulfilmentAccountPicker from "../fulfilment-account-picker";
import RenderField from "../field";

import UpdateFulfilmentAccountDialog from "./update-fulfilment-account-dialog";

export const parseOrganisationUpdateInput = ({
  name,
  internalLearning,
  internalEvents,
  analyticsDashboard,
  adhocRequests,
  hasAmazonRequests,
  hasFulfilledByLearnerRequests,
  recentActivity,
  testClient,
  defaultFulfilmentAccountId,
}) => ({
  name,
  internalLearning,
  internalEvents,
  analyticsDashboard,
  adhocRequests,
  hasAmazonRequests,
  hasFulfilledByLearnerRequests,
  recentActivity,
  testClient,
  defaultFulfilmentAccountId,
});

export const formatFulfilmentAccounts = (fulfilmentAccounts) =>
  fulfilmentAccounts.map(({ __typename, ...account }) => account);

export const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : null;

export const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const UpdateFulfilmentForm = ({
  organisation,
  fulfilmentAccounts,
  handleSubmit,
}) => {
  const [alert, setAlert] = useState({
    show: false,
    message: null,
  });
  const [isDialogActive, setIsDialogActive] = useState(false);
  const [selectedFulfilmentAccount, setSelectedFulfilmentAccount] = useState(
    null
  );

  const toggleDialogVisibility = () => setIsDialogActive(!isDialogActive);

  const handleAddAccount = async (updateOrganisation, formState) => {
    const {
      selectedFulfilmentAccount: { id, currency, name },
      autoApprovedThreshold,
    } = formState;

    const createFulfilmentAccountInput = {
      id,
      currency,
      name,
      autoApprovedThreshold,
    };

    const fulfilmentAccountInput = organisation.fulfilmentAccounts
      ? [...organisation.fulfilmentAccounts, createFulfilmentAccountInput]
      : [createFulfilmentAccountInput];

    const updateOrganisationInput = {
      ...parseOrganisationUpdateInput(organisation),
      fulfilmentAccounts: formatFulfilmentAccounts(fulfilmentAccountInput),
    };

    await updateOrganisation({
      variables: {
        id: organisation.id,
        organisation: updateOrganisationInput,
      },
    });
  };

  const onDeleteAccount = async (updateOrganisation, fulfilmentAccountId) => {
    const fulfilmentAccountInput = organisation.fulfilmentAccounts.filter(
      ({ id }) => id !== fulfilmentAccountId
    );

    const updateOrganisationInput = {
      ...parseOrganisationUpdateInput(organisation),
      fulfilmentAccounts: formatFulfilmentAccounts(fulfilmentAccountInput),
    };

    await updateOrganisation({
      variables: {
        id: organisation.id,
        organisation: updateOrganisationInput,
      },
    });
  };

  return (
    <>
      <Mutation mutation={updateOrganisationMutation}>
        {(updateOrganisation, { loading }) => (
          <>
            {loading && <ProgressBar mode="indeterminate" />}
            <Form
              onSubmit={handleSubmit((formState) =>
                handleAddAccount(updateOrganisation, formState)
              )}
            >
              <Field
                name="selectedFulfilmentAccount"
                component={FulfilmentAccountPicker}
                fulfilmentAccounts={fulfilmentAccounts}
              />
              <Field
                component={RenderField}
                label="Auto Approved Threshold"
                name="autoApprovedThreshold"
                parse={parse}
                format={format}
              />
              <Button type="submit" label="Add" raised primary />
            </Form>

            {organisation &&
            organisation.fulfilmentAccounts &&
            organisation.fulfilmentAccounts.length > 0 ? (
              <>
                <Table selectable={false}>
                  <TableHead>
                    <TableCell>Account</TableCell>
                    <TableCell>Currency</TableCell>
                    <TableCell>Auto approved threshold</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableHead>
                  {organisation.fulfilmentAccounts.map((fulfilmentAccount) => {
                    return (
                      <TableRow key={fulfilmentAccount.id}>
                        <TableCell>{fulfilmentAccount.name}</TableCell>
                        <TableCell>{fulfilmentAccount.currency}</TableCell>
                        <TableCell>
                          {format(fulfilmentAccount.autoApprovedThreshold) ||
                            "Not set"}
                        </TableCell>

                        <TableCell>
                          <Button
                            label="Update"
                            onClick={() => {
                              setSelectedFulfilmentAccount(fulfilmentAccount);
                              setIsDialogActive(true);
                            }}
                            raised
                            style={{
                              marginRight: "12px",
                            }}
                          />
                          <Button
                            label="Delete"
                            icon="delete"
                            onClick={() =>
                              window.confirm(
                                "Are you sure you wish to delete this item?"
                              ) &&
                              onDeleteAccount(
                                updateOrganisation,
                                fulfilmentAccount.id
                              )
                            }
                            raised
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Table>

                <UpdateFulfilmentAccountDialog
                  active={isDialogActive}
                  handleDialog={toggleDialogVisibility}
                  organisation={organisation}
                  selectedFulfilmentAccount={selectedFulfilmentAccount}
                />
              </>
            ) : (
              <div>
                <strong>No default fulfilment account id set</strong>
                <Snackbar
                  action="Dismiss"
                  active={alert.show}
                  label={alert.message}
                  timeout={3000}
                  onTimeout={() => setAlert({ show: false, message: null })}
                  type="cancel"
                />
              </div>
            )}
          </>
        )}
      </Mutation>
    </>
  );
};

UpdateFulfilmentForm.propTypes = {
  organisation: PropTypes.object.isRequired,
  fulfilmentAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "update-fulfilment",
})(UpdateFulfilmentForm);
