import { Dialog, ProgressBar } from "react-toolbox";
import { compose } from "redux";
import { graphql } from "react-apollo";
import React, { useState } from "react";
import { v4 } from "uuid";

import PropTypes from "prop-types";

import {
  createGroup,
  createUser,
  createGoal,
  createRequest,
  purchaseRequest,
} from "./seed-organisation-dialog.graphql";

const update = (setProgress, setAction, progress, action) => {
  setProgress(progress);
  setAction(action);
  return Promise.resolve();
};

const requestDataIds =
  process.env.NODE_ENV === "development"
    ? [
        {
          resourceId: "cae73a36-0339-48e7-916e-d3bd84c4523d",
          productId: "9e5db79f-eeda-4128-a9c8-152653535210",
        },
        {
          resourceId: "3bc8d593-884b-4b62-9bac-f1871b708730",
          productId: "f95a9d4e-95bc-4036-8919-d75bca36a7be",
        },
        {
          resourceId: "f7be354b-44b0-4886-bf47-40f051d62ec2",
          productId: "f6b39d29-fa95-45da-ab99-6bd0172542f4",
        },
        {
          resourceId: "cae73a36-0339-48e7-916e-d3bd84c4523d",
          productId: "9e5db79f-eeda-4128-a9c8-152653535210",
        },
        {
          resourceId: "3bc8d593-884b-4b62-9bac-f1871b708730",
          productId: "f95a9d4e-95bc-4036-8919-d75bca36a7be",
        },
        {
          resourceId: "f7be354b-44b0-4886-bf47-40f051d62ec2",
          productId: "f6b39d29-fa95-45da-ab99-6bd0172542f4",
        },
        {
          resourceId: "cae73a36-0339-48e7-916e-d3bd84c4523d",
          productId: "9e5db79f-eeda-4128-a9c8-152653535210",
        },
      ]
    : [
        {
          resourceId: "c18aa738-e20d-4f04-ad56-ea96f22bc473",
          productId: "e8e11271-8a16-4ca7-8539-18eb145c6b1c",
        },
        {
          resourceId: "01978f6d-d571-43c0-aa59-e71db221dc24",
          productId: "6ee085d5-af54-493a-b9e8-abbf0851785a",
        },
        {
          resourceId: "ad9b768e-6722-44b1-8412-48359c42326a",
          productId: "6d62677d-d37e-48f9-b985-04cf5ae328f0",
        },
        {
          resourceId: "adf27e77-e943-4706-bca2-faae56c605d9",
          productId: "a640812d-70a4-46f7-b50a-a139f4303f62",
        },
        {
          resourceId: "35aff474-3d2a-4a0b-8425-1eb139786d96",
          productId: "654acef8-0729-4fff-bda1-b78dfb081c7e",
        },
        {
          resourceId: "bf55fab8-9483-4edf-b254-fc60c55ced47",
          productId: "922f36aa-6f73-43a2-9c65-27faae543501",
        },
        {
          resourceId: "120060f2-693d-4155-9e42-80c56dfe2645",
          productId: "c987a35c-25b0-4bf3-b85d-9cc2a5cd57ef",
        },
      ];

const SeedOrganisationDialog = ({
  active,
  handleDialog,
  createGroup,
  createUser,
  createGoal,
  createRequest,
  purchaseRequest,
  organisationId,
  leadUserId,
}) => {
  const [progress, setProgress] = useState(0);
  const [action, setAction] = useState("");
  const closeDialog = () => {
    handleDialog();
    setProgress(0);
    setAction("");
  };
  const groupData = (groupName) => ({
    variables: {
      group: {
        name: groupName,
        organisation: organisationId,
      },
    },
  });

  const userData = (firstName, lastName, groups, approvers) => ({
    variables: {
      user: {
        firstName,
        lastName,
        email: `demo-account+${v4()}@learnerbly.com`,
        organisation: organisationId,
        groups,
        approvers,
        country: "UK",
        geographicLocation: "UK",
      },
    },
  });

  const goalData = (description, tasks, userId) => ({
    variables: {
      userId,
      goal: {
        description,
        dueDate: new Date(Date.now() + 8 * 1.21e9),
        isPublic: true,
        tasks: tasks.map((task) => ({
          description: task,
        })),
      },
    },
  });

  const requestData = ({ resourceId, productId }, userId) => ({
    variables: {
      request: {
        user: userId,
        resource: resourceId,
        product: productId,
        price: 3000,
        isPublic: true,
      },
    },
  });

  const seed = () =>
    update(setProgress, setAction, 5, "Creating Groups")
      .then(() =>
        Promise.all([
          createGroup(groupData("Marketing")),
          createGroup(groupData("Leadership")),
          createGroup(groupData("Legal")),
        ])
      )
      .then((groups) => {
        const groupIds = groups.map(
          ({
            data: {
              createGroup: { id },
            },
          }) => id
        );
        setProgress(20);
        setAction("Creating Users");

        return Promise.all([
          createUser(
            userData("Amanda", "Matthews", [groupIds[0]], [leadUserId])
          ),
          createUser(
            userData(
              "Zara",
              "Gilbert",
              [groupIds[0], groupIds[2]],
              [leadUserId]
            )
          ),
          createUser(
            userData(
              "Samantha",
              "Orwell",
              [groupIds[0], groupIds[1]],
              [leadUserId]
            )
          ),
          createUser(userData("Joseph", "Adjei", [groupIds[1]], [leadUserId])),
          createUser(userData("Bobby", "Costa", [groupIds[2]], [leadUserId])),
        ]);
      })
      .then((users) => {
        const userIds = users.map(
          ({
            data: {
              createUser: { id },
            },
          }) => id
        );
        setProgress(50);
        setAction("Creating Goals");

        return Promise.all([
          createGoal(
            goalData(
              "Become a learning wizard",
              ["Ask 5 peers for feedback", "Run a lunch and learn"],
              userIds[0]
            )
          ),
          createGoal(
            goalData(
              "Become a learning wizard",
              ["Ask 5 peers for feedback", "Run a lunch and learn"],
              userIds[1]
            )
          ),
          createGoal(
            goalData(
              "Become a learning wizard",
              ["Ask 5 peers for feedback", "Run a lunch and learn"],
              userIds[3]
            )
          ),
          createGoal(
            goalData(
              "Learn to build a website",
              ["Build a ToDo App", "Run a lunch and learn"],
              userIds[0]
            )
          ),
          createGoal(
            goalData(
              "Learn to build a website",
              ["Build a ToDo App", "Run a lunch and learn"],
              userIds[1]
            )
          ),
          createGoal(
            goalData(
              "Learn to build a website",
              ["Build a ToDo App", "Run a lunch and learn"],
              userIds[2]
            )
          ),
        ]);
      })
      .then((users) => {
        const userIds = users.map(
          ({
            data: {
              setGoal: { id },
            },
          }) => id
        );
        setProgress(70);
        setAction("Creating Requests");

        return Promise.all([
          createRequest(requestData(requestDataIds[0], userIds[0])).then(
            ({
              data: {
                createRequest: { id },
              },
            }) =>
              purchaseRequest({
                variables: { request: { id, user: userIds[0], price: 2000 } },
              })
          ),
          createRequest(requestData(requestDataIds[1], userIds[0])).then(
            ({
              data: {
                createRequest: { id },
              },
            }) =>
              purchaseRequest({
                variables: { request: { id, user: userIds[0], price: 2300 } },
              })
          ),
          createRequest(requestData(requestDataIds[2], userIds[0])),
          createRequest(requestData(requestDataIds[3], userIds[1])).then(
            ({
              data: {
                createRequest: { id },
              },
            }) =>
              purchaseRequest({
                variables: { request: { id, user: userIds[1], price: 43543 } },
              })
          ),
          createRequest(requestData(requestDataIds[4], userIds[1])),
          createRequest(requestData(requestDataIds[5], userIds[2])),
          createRequest(requestData(requestDataIds[6], userIds[3])).then(
            ({
              data: {
                createRequest: { id },
              },
            }) =>
              purchaseRequest({
                variables: { request: { id, user: userIds[3], price: 12565 } },
              })
          ),
        ]);
      })
      .then(() => {
        setProgress(100);
        setAction("All done");
      })
      .then(closeDialog);

  return (
    <Dialog
      actions={[
        { label: "Nooo, I'll think about it", onClick: handleDialog },
        {
          label: "Sure, plant those seeds",
          onClick: seed,
          raised: true,
          primary: true,
        },
      ]}
      onEscKeyDown={closeDialog}
      onOverlayClick={closeDialog}
      active={active}
      title="This will populate this organisation with demo users and actions"
    >
      <ProgressBar type="linear" mode="determinate" value={progress} />
      {action}
    </Dialog>
  );
};

SeedOrganisationDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  organisationId: PropTypes.string,
  leadUserId: PropTypes.string,
  id: PropTypes.string,
  createUser: PropTypes.func,
  createGoal: PropTypes.func,
  createRequest: PropTypes.func,
  createGroup: PropTypes.func,
  purchaseRequest: PropTypes.func,
};

export default compose(
  graphql(createGroup, {
    name: "createGroup",
  }),
  graphql(createUser, {
    name: "createUser",
  }),
  graphql(createRequest, {
    name: "createRequest",
  }),
  graphql(createGoal, {
    name: "createGoal",
  }),
  graphql(purchaseRequest, {
    name: "purchaseRequest",
  })
)(SeedOrganisationDialog);
