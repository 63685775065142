import { Dialog, Input, ProgressBar, List, ListItem } from "react-toolbox";
import { Form, Field } from "redux-form";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import selectSupplierQuery from "./select-supplier.graphql";

const ResultsComponent = ({ selectSupplierQuery, onSuccess }) => {
  if (!selectSupplierQuery) {
    return null;
  }

  if (selectSupplierQuery.loading) {
    return <ProgressBar mode="indeterminate" />;
  }

  if (!selectSupplierQuery.searchSuppliers.length) {
    return <div>No results</div>;
  }

  return (
    <List>
      {selectSupplierQuery.searchSuppliers.map((result) => (
        <ListItem
          key={result.id}
          caption={`${result.name} ${result.archived ? " (Archived)" : ""}`}
          rightIcon="play_for_work"
          onClick={() => onSuccess(result)}
        />
      ))}
    </List>
  );
};

ResultsComponent.propTypes = {
  selectSupplierQuery: PropTypes.object,
  onSuccess: PropTypes.func,
};

const Results = graphql(selectSupplierQuery, {
  name: "selectSupplierQuery",
  options: ({ value }) => ({ variables: { query: value } }),
  skip: (props) => !props.value,
})(ResultsComponent);

const SelectInput = ({ input, name, onSuccess }) => (
  <div>
    <Input {...input} label="Search" name={name} autoFocus />
    <Results value={input.value} onSuccess={onSuccess} />
  </div>
);

SelectInput.propTypes = {
  input: PropTypes.object,
  name: PropTypes.string,
  onSuccess: PropTypes.func,
};

const selectSupplier = (handleDialog, onSuccess) => (value) => {
  onSuccess(value);
  handleDialog();
};

const SelectSupplierDialog = ({ active, handleDialog, onSuccess }) => (
  <Dialog
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
  >
    <Form onSubmit={(x) => x}>
      <Field
        component={SelectInput}
        name="searchSupplier"
        onSuccess={selectSupplier(handleDialog, onSuccess)}
      />
    </Form>
  </Dialog>
);

SelectSupplierDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default SelectSupplierDialog;
