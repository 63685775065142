import React from "react";
import PropTypes from "prop-types";
import { Field, Form, reduxForm } from "redux-form";
import {
  Button,
  Snackbar,
  Table,
  TableHead,
  TableCell,
  TableRow,
} from "react-toolbox";
import { Mutation } from "react-apollo";

import Alert from "@material-ui/lab/Alert";

import orgCurrencySelector from "../../../../components/organisation-currency-selector";

import RenderField from "../../../../components/field";
import RenderSwitch from "../../../../components/switch";

import { formatCurrencyValue } from "../../../../utils/text-display";

import updateDefaultBudgetMutation from "./update-default-budget.graphql";
import deleteDefaultBudgetMutation from "./delete-default-budget.graphql";

const parse = (value) =>
  value ? Number(String(value).replace(/\D/g, "")) : value;

const format = (value) =>
  value || value === 0 ? (Math.round(value) / 100).toFixed(2) : value;

const UpdateDefaultBudget = ({
  handleSubmit,
  organisationId,
  defaultUserBudgets,
  fulfilmentAccounts,
  budgetPeriods,
}) => {
  return (
    <div>
      <h3>Default budgets (SCIM only)</h3>
      {budgetPeriods.length === 0 ? (
        <Alert severity="error">
          Cannot create a default budget if no budget periods exist
        </Alert>
      ) : null}
      <Table selectable={false}>
        {defaultUserBudgets.length && (
          <TableHead>
            <TableCell>Currency</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Pro-Rated</TableCell>
            <TableCell>Delete</TableCell>
          </TableHead>
        )}
        {defaultUserBudgets &&
          defaultUserBudgets.map((defaultUserBudget) => (
            <TableRow key={defaultUserBudget.currency}>
              <TableCell>{defaultUserBudget.currency}</TableCell>
              <TableCell>
                {formatCurrencyValue(
                  defaultUserBudget.total,
                  defaultUserBudget.currency
                )}
              </TableCell>
              <TableCell>{defaultUserBudget.proRata ? "Yes" : "No"}</TableCell>
              <TableCell>
                <Mutation mutation={deleteDefaultBudgetMutation}>
                  {(deleteBudget) => (
                    <Button
                      label="Delete"
                      icon="delete"
                      onClick={() =>
                        window.confirm(
                          "Are you sure you wish to delete this item?"
                        ) &&
                        deleteBudget({
                          variables: {
                            organisationId,
                            currency: defaultUserBudget.currency,
                          },
                        })
                      }
                      raised
                    />
                  )}
                </Mutation>
              </TableCell>
            </TableRow>
          ))}
      </Table>

      <Mutation mutation={updateDefaultBudgetMutation}>
        {(updateBudget, { error }) => (
          <Form
            onSubmit={handleSubmit((budget) => {
              const budgets = defaultUserBudgets.map(
                ({ __typename, ...budget }) => {
                  return budget;
                }
              );
              updateBudget({
                variables: {
                  organisationId,
                  input: [
                    ...budgets,
                    {
                      total: budget.total,
                      currency: budget.currency,
                      proRata: budget.proRata || false,
                    },
                  ],
                },
              });
            })}
          >
            <Field
              component={orgCurrencySelector}
              label="Currency"
              name="currency"
              fulfilmentAccounts={fulfilmentAccounts}
            />
            <Field
              component={RenderField}
              parse={parse}
              format={format}
              label="Total"
              name="total"
            />
            <Field
              component={RenderSwitch}
              label="Pro-rate budget from user creation date"
              name="proRata"
            />
            <Button
              type="submit"
              disabled={budgetPeriods.length === 0}
              title={"No budget periods set"}
              raised
              primary
            >
              Add default budget
            </Button>
            <Snackbar
              action="Dismiss"
              active={!!error}
              label={error && error.message}
              timeout={2000}
              onTimeout={() => this.setState({ alert: false })}
              type="cancel"
            />
          </Form>
        )}
      </Mutation>
    </div>
  );
};

UpdateDefaultBudget.propTypes = {
  defaultUserBudgets: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      proRata: PropTypes.bool,
      total: PropTypes.number,
    })
  ),
  handleSubmit: PropTypes.func,
  organisationId: PropTypes.string,
  updateDefaultBudgetMutation: PropTypes.func,
  fulfilmentAccounts: PropTypes.arrayOf(PropTypes.object),
  budgetPeriods: PropTypes.arrayOf(PropTypes.object),
};

export default reduxForm({
  form: "default-budget",
})(UpdateDefaultBudget);
