import { DialogContent } from "@material-ui/core";
import { graphql } from "react-apollo";
import React, { useState } from "react";
import moment from "moment";

import PropTypes from "prop-types";

import UpdateOnlineCourseForm from "../update-online-course-form";

import updateOnlineCourseQuery from "./update-online-course.graphql";
import { StyledDialog } from "../update-supplier-dialog/StyledDialog";

const UpdateOnlineCourseDialog = ({
  active,
  updateOnlineCourse,
  handleDialog,
  resourceId,
  product,
  resourceLink,
  supplier,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (updateOnlineCourse, handleDialog, id, resourceId) => (
    data
  ) => {
    setLoading(true);
    updateOnlineCourse({
      variables: { onlineCourse: data, id, resourceId },
    })
      .then(handleDialog)
      .finally(() => setLoading(false));
  };

  return (
    <StyledDialog
      open={active}
      onClose={handleDialog}
      container={() => document.getElementById("root")}
      fullWidth
    >
      <DialogContent>
        <UpdateOnlineCourseForm
          handleDialog={handleDialog}
          onSubmit={onSubmit(
            updateOnlineCourse,
            handleDialog,
            product.id,
            resourceId
          )}
          initialValues={{
            description: product.description,
            price: product.price,
            active: Boolean(product.active),
            link: product.link || resourceLink || null,
            country: product.country,
            currency: product.currency,
            codeClaim: product.codeClaim,
            codeClaimHelperText: product.codeClaimHelperText,
            codeClaimOption: product.codeClaimOption ?? "single",
            multiUseCode: {
              ...product.multiUseCode,
              expirationTime: product.multiUseCode?.expirationTime
                ? new Date(product.multiUseCode?.expirationTime)
                : moment(new Date()).add(1, "year").toDate(),
            },
          }}
          loading={loading}
          supplier={supplier}
          product={product}
          resourceId={resourceId}
        />
      </DialogContent>
    </StyledDialog>
  );
};

UpdateOnlineCourseDialog.propTypes = {
  active: PropTypes.bool,
  updateOnlineCourse: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  product: PropTypes.object,
  resourceLink: PropTypes.string,
  supplier: PropTypes.shape({
    id: PropTypes.string,
    codeClaim: PropTypes.bool,
  }),
};

export default graphql(updateOnlineCourseQuery, {
  name: "updateOnlineCourse",
})(UpdateOnlineCourseDialog);
