import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import React, { useState } from "react";

import SEARCH_SUPPLIER_QUERY from "./search-supplier.graphql";

import styles from "./styles.css";

// The input prop is the Redux Form field context containing functions to access field and value
const SearchSupplier = ({ input }) => {
  const [open, setOpen] = useState(false);
  const [textQuery, setTextQuery] = useState("");

  return (
    <div className={styles.supplierSearch}>
      <Query
        query={SEARCH_SUPPLIER_QUERY}
        variables={{ query: textQuery }}
        // We don't want to run the query if the user hasn't typed anything
        skip={!textQuery}
      >
        {({ loading, data }) => {
          // We don't check loading here as it is returning 'true' even when there is data
          const options =
            data && data.searchSuppliers ? data.searchSuppliers : [];

          return (
            <Autocomplete
              id="supplier"
              options={options}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => (option ? option.name : "")}
              loading={loading}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              // When the user has selected an option
              onChange={(event, value) => {
                // Updates Redux Form field
                input.onChange(
                  value ? { id: value.id, name: value.name } : null
                );
              }}
              // When the selected autocomplete value changes
              onInputChange={(event, value, reason) => {
                // If we're resetting or clearing we don't want to trigger a new GraphQL query
                if (reason === "reset" || reason === "clear") {
                  return;
                }

                // When the user has typed into the text field we updates GraphQL query variable triggering a query.
                setTextQuery(value);
              }}
              // Initial value from Redux Form field
              value={input.value}
              renderInput={(params) => {
                return (
                  <TextField {...params} label="Supplier" variant="standard" />
                );
              }}
            />
          );
        }}
      </Query>
    </div>
  );
};

SearchSupplier.propTypes = {
  input: PropTypes.object,
};

export default SearchSupplier;
