import React from "react";
import PropTypes from "prop-types";

import qs from "qs";

import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";

import RenderCheckbox from "../checkbox";
import OrganisationPicker from "../../components/organisation-picker";

const RenderStatus = ({ input }) => (
  <div>
    {["approved", "pending", "purchased", "cancelled", "rejected"].map(
      (status, index) => (
        <Field
          key={index}
          name={status}
          label={status}
          checked={input.value.includes(status)}
          component={RenderCheckbox}
          onChange={(event, value) => {
            if (value) {
              input.onChange([status, ...input.value]);
            } else {
              input.onChange(input.value.filter((item) => item !== status));
            }
          }}
        />
      )
    )}
  </div>
);

RenderStatus.propTypes = {
  input: PropTypes.object,
};

class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit({ requestStatus, organisations }) {
    const {
      history: { location },
    } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    this.props.history.push(
      `${location.pathname}${qs.stringify(
        {
          ...params,
          ...{
            requestStatus,
            organisations,
          },
        },
        { addQueryPrefix: true }
      )}`
    );
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div>
        <h4>Status</h4>
        <Field
          name="requestStatus"
          component={RenderStatus}
          onChange={() => setTimeout(handleSubmit(this.onSubmit), 100)}
        />
        <h4>Organisations</h4>
        <Field
          name="organisations"
          component={OrganisationPicker}
          onChange={() => setTimeout(handleSubmit(this.onSubmit), 100)}
          multiple
        />
      </div>
    );
  }
}

Filters.propTypes = {
  initialValues: PropTypes.object,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
};

export default compose(
  withRouter,
  reduxForm({
    form: "filter",
    enableReinitialize: true,
  })
)(Filters);
