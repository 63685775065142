export function onPurchase(request) {
  return () => {
    this.setState({
      purchase: true,
      purchaseRequest: request,
    });
  };
}

export function onCancel(request) {
  return () => {
    this.setState({
      cancel: true,
      cancelRequest: request,
    });
  };
}

export function onApprove(request) {
  return () => {
    this.setState({
      approve: true,
      approveRequest: request,
    });
  };
}

export function onRetry(request) {
  return () => {
    this.setState({
      retry: true,
      retryRequest: request,
    });
  };
}

export function handlePurchaseDialog() {
  this.setState({
    purchase: !this.state.purchase,
  });
}

export function handleCancelDialog() {
  this.setState({
    cancel: !this.state.cancel,
  });
}

export function handleApproveDialog() {
  this.setState({
    approve: !this.state.approve,
  });
}

export function handleRetryDialog() {
  this.setState({
    retry: !this.state.retry,
  });
}
