import {
  Button,
  Menu,
  MenuItem,
  FontIcon,
  IconButton,
  ProgressBar,
} from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import React, { Fragment } from "react";
import moment from "moment";
import { flag } from "country-emoji";
import { Checkbox, Chip, Tooltip } from "@material-ui/core";
import { graphql } from "react-apollo";
import { compose } from "redux";

import PropTypes from "prop-types";

import { formatCurrencyValue } from "../../utils/text-display";
import AddBookDialog from "../add-book-dialog";
import AddEventDialog from "../add-event-dialog";
import AddInternalEventDialog from "../add-internal-event-dialog";
import AddOnlineCourseDialog from "../add-online-course-dialog";
import AddVirtualCardProductDialog from "../add-virtual-card-product-dialog";
import CopyBookDialog from "../copy-book-dialog";
import CopyEventDialog from "../copy-event-dialog";
import CopyInternalEventDialog from "../copy-internal-event-dialog";
import CopyOnlineCourseDialog from "../copy-online-course-dialog";
import CopyVirtualCardProductDialog from "../copy-virtual-card-product-dialog";
import UpdateBookDialog from "../update-book-dialog";
import UpdateEventDialog from "../update-event-dialog";
import UpdateInternalEventDialog from "../update-internal-event-dialog";
import UpdateOnlineCourseDialog from "../update-online-course-dialog";
import UpdateVirtualCardProductDialog from "../update-virtual-card-product-dialog";

import updateBookMutation from "./update-book.graphql";
import updateEventMutation from "./update-event.graphql";
import updateInternalEventMutation from "./update-internal-event.graphql";
import updateOnlineCourseMutation from "./update-online-course.graphql";
import updateVirtualCardProductMutation from "./update-virtual-card-product.graphql";

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      addBookDialog: false,
      copyBookDialog: false,
      updateBookDialog: false,
      addOnlineCourseDialog: false,
      copyOnlineCourseDialog: false,
      updateOnlineCourseDialog: false,
      addVirtualCardProductDialog: false,
      copyVirtualCardProductDialog: false,
      updateVirtualCardProductDialog: false,
      addEventDialog: false,
      copyEventDialog: false,
      updateEventDialog: false,
      addInternalEventDialog: false,
      copyInternalEventDialog: false,
      updateInternalEventDialog: false,
      product: {},
      supplier: {},
      whichProductsChecked: [],
      errorsFromUpdatingActiveStatus: [],
      isUpdatingActiveStatus: false,
      areInactiveProductsVisible: true,
    };

    this.handleMenu = this.handleMenu.bind(this);
    this.handleAddBookDialog = this.handleAddBookDialog.bind(this);
    this.handleCopyBookDialog = this.handleCopyBookDialog.bind(this);
    this.handleUpdateBookDialog = this.handleUpdateBookDialog.bind(this);
    this.handleAddOnlineCourseDialog = this.handleAddOnlineCourseDialog.bind(
      this
    );
    this.handleCopyOnlineCourseDialog = this.handleCopyOnlineCourseDialog.bind(
      this
    );
    this.handleUpdateOnlineCourseDialog = this.handleUpdateOnlineCourseDialog.bind(
      this
    );
    this.handleAddVirtualCardProductDialog = this.handleAddVirtualCardProductDialog.bind(
      this
    );
    this.handleCopyVirtualCardProductDialog = this.handleCopyVirtualCardProductDialog.bind(
      this
    );
    this.handleUpdateVirtualCardProductDialog = this.handleUpdateVirtualCardProductDialog.bind(
      this
    );
    this.handleAddEventDialog = this.handleAddEventDialog.bind(this);
    this.handleCopyEventDialog = this.handleCopyEventDialog.bind(this);
    this.handleUpdateEventDialog = this.handleUpdateEventDialog.bind(this);
    this.handleAddInternalEventDialog = this.handleAddInternalEventDialog.bind(
      this
    );
    this.handleCopyInternalEventDialog = this.handleCopyInternalEventDialog.bind(
      this
    );
    this.handleUpdateInternalEventDialog = this.handleUpdateInternalEventDialog.bind(
      this
    );
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.handleMakeProductsInactive = this.handleMakeProductsInactive.bind(
      this
    );
    this.handleHideShowInactiveProducts = this.handleHideShowInactiveProducts.bind(
      this
    );
    this.sortProductsByCountry = this.sortProductsByCountry.bind(this);
  }

  handleMenu() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  handleAddBookDialog() {
    this.setState({
      addBookDialog: !this.state.addBookDialog,
    });
  }

  handleCopyBookDialog() {
    this.setState({
      copyBookDialog: !this.state.copyBookDialog,
    });
  }

  handleUpdateBookDialog() {
    this.setState({
      updateBookDialog: !this.state.updateBookDialog,
    });
  }

  handleAddOnlineCourseDialog() {
    this.setState({
      addOnlineCourseDialog: !this.state.addOnlineCourseDialog,
    });
  }

  handleCopyOnlineCourseDialog() {
    this.setState({
      copyOnlineCourseDialog: !this.state.copyOnlineCourseDialog,
    });
  }

  handleUpdateOnlineCourseDialog() {
    this.setState({
      updateOnlineCourseDialog: !this.state.updateOnlineCourseDialog,
    });
  }

  handleAddVirtualCardProductDialog() {
    this.setState({
      addVirtualCardProductDialog: !this.state.addVirtualCardProductDialog,
    });
  }

  handleCopyVirtualCardProductDialog() {
    this.setState({
      copyVirtualCardProductDialog: !this.state.copyVirtualCardProductDialog,
    });
  }

  handleUpdateVirtualCardProductDialog() {
    this.setState({
      updateVirtualCardProductDialog: !this.state
        .updateVirtualCardProductDialog,
    });
  }

  handleAddEventDialog() {
    this.setState({
      addEventDialog: !this.state.addEventDialog,
    });
  }

  handleCopyEventDialog() {
    this.setState({
      copyEventDialog: !this.state.copyEventDialog,
    });
  }

  handleUpdateEventDialog() {
    this.setState({
      updateEventDialog: !this.state.updateEventDialog,
    });
  }

  handleAddInternalEventDialog() {
    this.setState({
      addInternalEventDialog: !this.state.addInternalEventDialog,
    });
  }

  handleCopyInternalEventDialog() {
    this.setState({
      copyInternalEventDialog: !this.state.copyInternalEventDialog,
    });
  }

  handleUpdateInternalEventDialog() {
    this.setState({
      updateInternalEventDialog: !this.state.updateInternalEventDialog,
    });
  }

  onCheckboxChange(product) {
    this.setState((prevState) => {
      if (this.state.whichProductsChecked.includes(product)) {
        return {
          whichProductsChecked: prevState.whichProductsChecked.filter(
            (checkedProduct) => checkedProduct !== product
          ),
        };
      }

      return {
        whichProductsChecked: [...prevState.whichProductsChecked, product],
      };
    });
  }

  handleHideShowInactiveProducts() {
    this.setState((prevState) => ({
      areInactiveProductsVisible: !prevState.areInactiveProductsVisible,
    }));
  }

  async handleMakeProductsInactive() {
    this.setState({ isUpdatingActiveStatus: true });

    let errorsFromUpdatingActiveStatus = [];
    const { resourceLink } = this.props;

    await this.state.whichProductsChecked.reduce(async (promise, product) => {
      await promise;

      switch (product.__typename) {
        case "Book":
          try {
            const { __typename, id, isGlobal, link, format, ...book } = product;

            await this.props.updateBookActiveStatus({
              variables: {
                id,
                resourceId: this.props.resourceId,
                book: {
                  ...book,
                  format: format?.type,
                  supplier: format?.supplier?.id,
                  active: !book.active,
                  link: link || resourceLink,
                },
              },
            });

            this.onCheckboxChange(product);

            break;
          } catch {
            errorsFromUpdatingActiveStatus = [
              ...errorsFromUpdatingActiveStatus,
              product,
            ];

            break;
          }
        case "Event":
          try {
            const { __typename, id, isGlobal, ...rest } = product;
            const { link, location, ...eventToUpdate } = rest;
            const { __typename: locationTypename, ...loc } = location;
            const event = {
              ...eventToUpdate,
              link: link || resourceLink,
              location: loc,
            };

            await this.props.updateEventActiveStatus({
              variables: {
                id,
                resourceId: this.props.resourceId,
                event: { ...event, active: !event.active },
              },
            });

            this.onCheckboxChange(product);

            break;
          } catch {
            errorsFromUpdatingActiveStatus = [
              ...errorsFromUpdatingActiveStatus,
              product,
            ];

            break;
          }
        case "InternalEvent":
          try {
            const {
              __typename,
              currency,
              id,
              isGlobal,
              link,
              price,
              ...rest
            } = product;
            const { location, ...internalEventToUpdate } = rest;
            const { __typename: locationTypename, ...loc } = location;
            const internalEvent = {
              ...internalEventToUpdate,
              link: link || resourceLink,
              location: loc,
            };

            await this.props.updateInternalEventActiveStatus({
              variables: {
                id,
                resourceId: this.props.resourceId,
                internalEvent: {
                  ...internalEvent,
                  active: !internalEvent.active,
                },
              },
            });

            this.onCheckboxChange(product);

            break;
          } catch {
            errorsFromUpdatingActiveStatus = [
              ...errorsFromUpdatingActiveStatus,
              product,
            ];

            break;
          }
        case "OnlineCourse":
          try {
            const { __typename, id, isGlobal, link, ...onlineCourse } = product;

            await this.props.updateOnlineCourseActiveStatus({
              variables: {
                id,
                resourceId: this.props.resourceId,
                onlineCourse: {
                  ...onlineCourse,
                  active: !onlineCourse.active,
                  link: link || resourceLink,
                },
              },
            });

            this.onCheckboxChange(product);

            break;
          } catch {
            errorsFromUpdatingActiveStatus = [
              ...errorsFromUpdatingActiveStatus,
              product,
            ];

            break;
          }
        case "VirtualCardProduct":
          try {
            const { id, link, description, active } = product;

            await this.props.updateVirtualCardProductActiveStatus({
              variables: {
                id,
                resourceId: this.props.resourceId,
                virtualCardProduct: {
                  active: !active,
                  link: link || resourceLink,
                  description,
                },
              },
            });

            this.onCheckboxChange(product);

            break;
          } catch {
            errorsFromUpdatingActiveStatus = [
              ...errorsFromUpdatingActiveStatus,
              product,
            ];

            break;
          }
        default:
          break;
      }
    }, Promise.resolve([]));

    this.setState({
      errorsFromUpdatingActiveStatus,
      isUpdatingActiveStatus: false,
    });
  }

  onBookClick(product) {
    return () =>
      this.setState({
        updateBookDialog: true,
        product,
      });
  }

  onEventClick(product) {
    return () =>
      this.setState({
        updateEventDialog: true,
        product,
      });
  }

  onInternalEventClick(product) {
    return () =>
      this.setState({
        updateInternalEventDialog: true,
        product,
      });
  }

  onOnlineCourseClick(product) {
    return () =>
      this.setState({
        updateOnlineCourseDialog: true,
        product,
      });
  }

  onVirtualCardProductClick(product) {
    return () =>
      this.setState({
        updateVirtualCardProductDialog: true,
        product,
      });
  }

  onCopyProductClick(product, productType) {
    return (event) => {
      event.stopPropagation();

      switch (productType) {
        case "Book":
          this.setState({
            copyBookDialog: true,
            product,
          });
          break;
        case "Event":
          this.setState({
            copyEventDialog: true,
            product,
          });
          break;
        case "InternalEvent":
          this.setState({
            copyInternalEventDialog: true,
            product,
          });
          break;
        case "OnlineCourse":
          this.setState({
            copyOnlineCourseDialog: true,
            product,
          });
          break;
        case "VirtualCardProduct":
          this.setState({
            copyVirtualCardProductDialog: true,
            product,
          });
          break;
        default:
          break;
      }
    };
  }

  sortProductsByCountry(products) {
    return products.sort((a, b) => {
      if (a.country && !b.country) {
        return -1;
      }

      if (!a.country && b.country) {
        return 1;
      }

      if (!a.country && !b.country) {
        return 0;
      }

      if (a.country < b.country) {
        return -1;
      }

      if (a.country > b.country) {
        return 1;
      }

      return 0;
    });
  }

  render() {
    const { resourceId, supplier, resourceLink, products: prods } = this.props;

    const products = this.sortProductsByCountry(prods);

    return (
      <>
        <Row middle="xs" between="xs">
          <Col sm={4}>
            <h3>Products</h3>
          </Col>
          <Col sm={8}>
            <Row end="xs">
              <Col sm={12}>
                {this.state.whichProductsChecked.length > 0 &&
                  this.state.isUpdatingActiveStatus && (
                    <ProgressBar mode="indeterminate" />
                  )}
                {this.state.whichProductsChecked.length > 0 &&
                  !this.state.isUpdatingActiveStatus && (
                    <Button
                      icon="update"
                      mini
                      onClick={this.handleMakeProductsInactive}
                      raised
                      style={{ marginRight: "1rem" }}
                    >
                      {`Update "active" status!`}
                    </Button>
                  )}
                <Button
                  icon="filter_alt"
                  mini
                  onClick={this.handleHideShowInactiveProducts}
                  raised
                  style={{ marginRight: "1rem" }}
                >
                  {this.state.areInactiveProductsVisible
                    ? "Hide inactive & outdated products"
                    : "Show inactive & outdated products"}
                </Button>
                <Button
                  icon="add"
                  primary
                  mini
                  raised
                  onClick={this.handleMenu}
                >
                  Add
                </Button>
                <div style={{ position: "relative" }}>
                  <Menu
                    position="topRight"
                    active={this.state.menu}
                    onHide={this.handleMenu}
                    menuRipple
                  >
                    <MenuItem
                      icon="book"
                      caption="Add Book"
                      onClick={this.handleAddBookDialog}
                    />
                    <MenuItem
                      icon="event"
                      caption="Add Event"
                      onClick={this.handleAddEventDialog}
                    />
                    <MenuItem
                      icon="event_seat"
                      caption="Add Internal Event"
                      onClick={this.handleAddInternalEventDialog}
                    />
                    <MenuItem
                      icon="computer"
                      caption="Add Online Course"
                      onClick={this.handleAddOnlineCourseDialog}
                    />
                    <MenuItem
                      icon="credit_card"
                      caption="Add Virtual Card fulfilment"
                      onClick={this.handleAddVirtualCardProductDialog}
                    />
                  </Menu>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {this.state.errorsFromUpdatingActiveStatus.length > 0 && (
          <Row style={{ border: "1px solid red" }}>
            <Col sm={12}>The following products were not updated:</Col>
            {this.state.errorsFromUpdatingActiveStatus.map(
              ({ __typename, country, currency, description, id, price }) => {
                let value;
                switch (__typename) {
                  case "Book":
                    value = "book";
                    break;
                  case "Event":
                    value = "event";
                    break;
                  case "InternalEvent":
                    value = "event_seat";
                    break;
                  case "OnlineCourse":
                    value = "computer";
                    break;
                  case "VirtualCardProduct":
                    value = "credit_card";
                    break;
                  default:
                    break;
                }
                return (
                  <Fragment key={id}>
                    <Col sm={1}>
                      <FontIcon value={value} />
                    </Col>
                    <Col sm={1}>
                      {!country
                        ? "🌍 ALL"
                        : country === "QQ"
                        ? "🗺 ROTW"
                        : `${flag(country)} ${country}`}
                    </Col>
                    <Col sm={1}>
                      {price && formatCurrencyValue(price, currency)}
                    </Col>
                    <Col sm={3}>{description}</Col>
                  </Fragment>
                );
              }
            )}
          </Row>
        )}

        {products &&
          products.map((product) => {
            const active = product.active;
            let legend = [];

            if (!active) {
              legend.push("Deactivated");
            }

            let startDate,
              endDate,
              outdated,
              internalEventStartDate,
              internalEventOutdated;

            const isDigital = ["EBOOK", "AUDIOBOOK"].includes(
              product.format?.type
            );
            const isCode = !!product.codeClaim;

            const productCountry = !product.country
              ? "🌍 ALL"
              : product.country === "QQ"
              ? "🗺 ROTW"
              : `${flag(product.country)} ${product.country}`;

            switch (product.__typename) {
              case "Book":
                if (!this.state.areInactiveProductsVisible && !active) {
                  return null;
                }

                return (
                  <Row key={product.id} between="xs">
                    <Col sm={1}>
                      <p>
                        <FontIcon
                          value="book"
                          style={{ color: active ? "#43AB95" : null }}
                        />
                      </p>
                    </Col>
                    <Col sm={1}>
                      <p>{productCountry}</p>
                    </Col>
                    <Col sm={1}>
                      <p>
                        {formatCurrencyValue(product.price, product.currency)}
                      </p>
                    </Col>
                    <Col sm={2}>
                      <Row>
                        <p>{product.description}</p>
                        {isDigital && (
                          <Tooltip title="This is a digital product, that can only be visible in Stripe based organisations with the feature toggle enabled.">
                            <Chip
                              style={{
                                marginLeft: "0.5rem",
                                alignSelf: "center",
                              }}
                              label="Digital"
                              size="small"
                            />
                          </Tooltip>
                        )}
                      </Row>
                    </Col>
                    <Col sm={1}>
                      <p>{product.amazonASIN}</p>
                    </Col>
                    <Col sm={3}>
                      {product.link && (
                        <p>
                          <a
                            href={product.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View product
                          </a>
                        </p>
                      )}
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="edit"
                        primary
                        onClick={this.onBookClick(product)}
                      />
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="content_copy"
                        onClick={this.onCopyProductClick(product, "Book")}
                        primary
                      />
                    </Col>
                    <Col sm={1}>
                      <Checkbox
                        checked={this.state.whichProductsChecked.includes(
                          product
                        )}
                        onChange={() => this.onCheckboxChange(product)}
                      />
                    </Col>
                  </Row>
                );
              case "Event":
                startDate = moment(product.startDate).format("MMM Do YY");
                endDate =
                  product.endDate &&
                  moment(product.endDate).format("MMM Do YY");
                outdated = new Date(product.startDate) < new Date();

                if (
                  !this.state.areInactiveProductsVisible &&
                  (!active || outdated)
                ) {
                  return null;
                }

                return (
                  <Row key={product.id} between="xs">
                    <Col sm={1}>
                      <p>
                        <FontIcon
                          value="event"
                          style={{
                            color: active ? "#43AB95" : null,
                          }}
                          title={product.id}
                        />
                      </p>
                    </Col>
                    <Col sm={1}>
                      <p>{productCountry}</p>
                    </Col>
                    <Col sm={1}>
                      <p>
                        {formatCurrencyValue(product.price, product.currency)}
                      </p>
                    </Col>
                    <Col sm={3}>
                      <p>{product.description}</p>
                    </Col>
                    <Col sm={3}>
                      <p>
                        {outdated
                          ? "Outdated"
                          : `${startDate} to ${endDate || "-"}`}
                      </p>
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="edit"
                        onClick={this.onEventClick(product)}
                        primary
                      />
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="content_copy"
                        onClick={this.onCopyProductClick(product, "Event")}
                        primary
                      />
                    </Col>
                    <Col sm={1}>
                      <Checkbox
                        checked={this.state.whichProductsChecked.includes(
                          product
                        )}
                        onChange={() => this.onCheckboxChange(product)}
                      />
                    </Col>
                  </Row>
                );
              case "InternalEvent":
                internalEventStartDate = moment(product.startDate).format(
                  "MMM Do YY"
                );
                internalEventOutdated =
                  new Date(product.startDate) < new Date();

                if (
                  !this.state.areInactiveProductsVisible &&
                  (!active || internalEventOutdated)
                ) {
                  return null;
                }

                return (
                  <Row key={product.id} between="xs">
                    <Col sm={1}>
                      <p>
                        <FontIcon
                          value="event_seat"
                          style={{
                            color: active ? "#43AB95" : null,
                          }}
                          title={product.id}
                        />
                      </p>
                    </Col>
                    <Col sm={1}>
                      <p>{productCountry}</p>
                    </Col>
                    <Col sm={4}>
                      <p>{product.description}</p>
                    </Col>
                    <Col sm={3}>
                      <p>
                        {internalEventOutdated && "Outdated"}
                        {!internalEventOutdated && `${internalEventStartDate}`}
                      </p>
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="edit"
                        onClick={this.onInternalEventClick(product)}
                        primary
                      />
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="content_copy"
                        onClick={this.onCopyProductClick(
                          product,
                          "InternalEvent"
                        )}
                        primary
                      />
                    </Col>
                    <Col sm={1}>
                      <Checkbox
                        checked={this.state.whichProductsChecked.includes(
                          product
                        )}
                        onChange={() => this.onCheckboxChange(product)}
                      />
                    </Col>
                  </Row>
                );
              case "OnlineCourse":
                if (!this.state.areInactiveProductsVisible && !active) {
                  return null;
                }

                return (
                  <Row key={product.id} between="xs">
                    <Col sm={1}>
                      <p>
                        <FontIcon
                          value="computer"
                          style={{ color: active ? "#43AB95" : null }}
                        />
                      </p>
                    </Col>
                    <Col sm={1}>
                      <p>{productCountry}</p>
                    </Col>
                    <Col sm={1}>
                      <p>
                        {formatCurrencyValue(product.price, product.currency)}
                      </p>
                    </Col>
                    <Col sm={3}>
                      <Row>
                        <p>{product.description}</p>
                        {isCode && (
                          <Chip
                            style={{
                              marginLeft: "0.5rem",
                              alignSelf: "center",
                            }}
                            label="Code"
                            size="small"
                          />
                        )}
                      </Row>
                    </Col>
                    <Col sm={3}>
                      {product.link && (
                        <p>
                          <a
                            href={product.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View product
                          </a>
                        </p>
                      )}
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="edit"
                        onClick={this.onOnlineCourseClick(product)}
                        primary
                      />
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="content_copy"
                        onClick={this.onCopyProductClick(
                          product,
                          "OnlineCourse"
                        )}
                        primary
                      />
                    </Col>
                    <Col sm={1}>
                      <Checkbox
                        checked={this.state.whichProductsChecked.includes(
                          product
                        )}
                        onChange={() => this.onCheckboxChange(product)}
                      />
                    </Col>
                  </Row>
                );
              case "VirtualCardProduct":
                if (!this.state.areInactiveProductsVisible && !active) {
                  return null;
                }

                return (
                  <Row key={product.id} between="xs">
                    <Col sm={1}>
                      <p>
                        <FontIcon
                          value="credit_card"
                          style={{ color: active ? "#43AB95" : null }}
                        />
                      </p>
                    </Col>
                    <Col sm={1}>
                      <p>{productCountry}</p>
                    </Col>
                    <Col sm={1}>
                      <p>
                        {formatCurrencyValue(product.price, product.currency)}
                      </p>
                    </Col>
                    <Col sm={3}>
                      <p>{product.description}</p>
                    </Col>
                    <Col sm={3}>
                      {product.link && (
                        <p>
                          <a
                            href={product.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View product
                          </a>
                        </p>
                      )}
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="edit"
                        onClick={this.onVirtualCardProductClick(product)}
                        primary
                      />
                    </Col>
                    <Col sm={1}>
                      <IconButton
                        icon="content_copy"
                        onClick={this.onCopyProductClick(
                          product,
                          "VirtualCardProduct"
                        )}
                        primary
                      />
                    </Col>
                    <Col sm={1}>
                      <Checkbox
                        checked={this.state.whichProductsChecked.includes(
                          product
                        )}
                        onChange={() => this.onCheckboxChange(product)}
                      />
                    </Col>
                  </Row>
                );
            }
          })}

        <AddBookDialog
          active={this.state.addBookDialog}
          handleDialog={this.handleAddBookDialog}
          resourceId={resourceId}
          resourceLink={resourceLink}
        />
        <CopyBookDialog
          active={this.state.copyBookDialog}
          handleDialog={this.handleCopyBookDialog}
          product={this.state.product}
          resourceId={resourceId}
        />
        <UpdateBookDialog
          active={this.state.updateBookDialog}
          handleDialog={this.handleUpdateBookDialog}
          product={this.state.product}
          resourceId={resourceId}
          resourceLink={resourceLink}
        />
        <AddOnlineCourseDialog
          active={this.state.addOnlineCourseDialog}
          handleDialog={this.handleAddOnlineCourseDialog}
          resourceId={resourceId}
          resourceLink={resourceLink}
          supplier={supplier}
        />
        <CopyOnlineCourseDialog
          active={this.state.copyOnlineCourseDialog}
          handleDialog={this.handleCopyOnlineCourseDialog}
          product={this.state.product}
          resourceId={resourceId}
        />
        <UpdateOnlineCourseDialog
          active={this.state.updateOnlineCourseDialog}
          handleDialog={this.handleUpdateOnlineCourseDialog}
          product={this.state.product}
          resourceId={resourceId}
          resourceLink={resourceLink}
          supplier={supplier}
        />
        <AddVirtualCardProductDialog
          active={this.state.addVirtualCardProductDialog}
          handleDialog={this.handleAddVirtualCardProductDialog}
          resourceId={resourceId}
          resourceLink={resourceLink}
        />
        <CopyVirtualCardProductDialog
          active={this.state.copyVirtualCardProductDialog}
          handleDialog={this.handleCopyVirtualCardProductDialog}
          product={this.state.product}
          resourceId={resourceId}
        />
        <UpdateVirtualCardProductDialog
          active={this.state.updateVirtualCardProductDialog}
          handleDialog={this.handleUpdateVirtualCardProductDialog}
          product={this.state.product}
          resourceId={resourceId}
          resourceLink={resourceLink}
        />
        <AddEventDialog
          active={this.state.addEventDialog}
          handleDialog={this.handleAddEventDialog}
          resourceId={resourceId}
          resourceLink={resourceLink}
        />
        <CopyEventDialog
          active={this.state.copyEventDialog}
          handleDialog={this.handleCopyEventDialog}
          product={this.state.product}
          resourceId={resourceId}
        />
        <UpdateEventDialog
          active={this.state.updateEventDialog}
          handleDialog={this.handleUpdateEventDialog}
          product={this.state.product}
          resourceLink={resourceLink}
          resourceId={resourceId}
        />
        <AddInternalEventDialog
          active={this.state.addInternalEventDialog}
          handleDialog={this.handleAddInternalEventDialog}
          resourceId={resourceId}
          resourceLink={resourceLink}
        />
        <CopyInternalEventDialog
          active={this.state.copyInternalEventDialog}
          handleDialog={this.handleCopyInternalEventDialog}
          product={this.state.product}
          resourceId={resourceId}
        />
        <UpdateInternalEventDialog
          active={this.state.updateInternalEventDialog}
          handleDialog={this.handleUpdateInternalEventDialog}
          product={this.state.product}
          resourceId={resourceId}
          resourceLink={resourceLink}
        />
      </>
    );
  }
}

Products.propTypes = {
  resourceId: PropTypes.string,
  supplier: PropTypes.shape({
    id: PropTypes.string,
    codeClaim: PropTypes.bool,
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      amazonASIN: PropTypes.string,
      isbn: PropTypes.string,
      country: PropTypes.string,
      currency: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      isGlobal: PropTypes.bool,
      link: PropTypes.string,
      price: PropTypes.number,
      supplier: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  resourceLink: PropTypes.string,
  updateBookActiveStatus: PropTypes.func,
  updateEventActiveStatus: PropTypes.func,
  updateInternalEventActiveStatus: PropTypes.func,
  updateOnlineCourseActiveStatus: PropTypes.func,
  updateVirtualCardProductActiveStatus: PropTypes.func,
};

export default compose(
  graphql(updateBookMutation, { name: "updateBookActiveStatus" }),
  graphql(updateEventMutation, { name: "updateEventActiveStatus" }),
  graphql(updateInternalEventMutation, {
    name: "updateInternalEventActiveStatus",
  }),
  graphql(updateOnlineCourseMutation, {
    name: "updateOnlineCourseActiveStatus",
  }),
  graphql(updateVirtualCardProductMutation, {
    name: "updateVirtualCardProductActiveStatus",
  })
)(Products);
