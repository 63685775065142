import { Button, ProgressBar } from "react-toolbox";
import { Field, Form, reduxForm } from "redux-form";
import { Mutation } from "react-apollo";
import React from "react";
import PropTypes from "prop-types";

import CountryPicker from "../country-picker";
import RenderField from "../field";

import createOfficeMutation from "./create-office.graphql";

const CreateOfficeForm = ({ handleSubmit, organisationId }) => (
  <Mutation mutation={createOfficeMutation}>
    {(createOffice, { loading }) => (
      <Form
        onSubmit={handleSubmit((office) =>
          createOffice({ variables: { office, organisationId } })
        )}
      >
        {loading && <ProgressBar mode="indeterminate" />}
        <Field component={RenderField} label="Name" name="name" />
        <Field component={RenderField} label="Street" name="street" />
        <Field component={RenderField} label="Postcode" name="postcode" />
        <Field component={RenderField} label="City" name="city" />
        <Field name="country" label="Country" component={CountryPicker} />
        <Button type="submit" raised primary disabled={loading}>
          Create Office
        </Button>
      </Form>
    )}
  </Mutation>
);

CreateOfficeForm.propTypes = {
  handleSubmit: PropTypes.func,
  organisationId: PropTypes.string,
};

export default reduxForm({
  form: "create-office",
})(CreateOfficeForm);
