import { Input, ProgressBar, Button } from "react-toolbox";
import { compose } from "redux";
import { graphql } from "react-apollo";
import React, { useState, useEffect } from "react";
import { capitalize } from "lodash/fp";

import PropTypes from "prop-types";

import resourceTypes from "./resource-types.graphql";
import itemsByASINs from "./itemsByASINs.graphql";
import createResource from "./create-resource.graphql";
import createBookProduct from "./add-book-product.graphql";

const PRODUCT_CREATION_COUNTRIES = ["GB", "DE", "NL", "US", "ES", "SE", "CA"];

const amazonUrlRegex = /(?:https?:\/\/)?(?:www.)?amazon(?:\.[A-z]{1,3})+(?:\/-\/\w{0,2})?(?:\/[A-z0-9-]{0,120})?(?:\/[dg]p\/)(?:[a-z]+\/){0,2}([A-z0-9]{10})/;

const isAmazonResource = (url) => amazonUrlRegex.test(url);

const extractASINFromUrl = (url) => {
  if (!url) {
    return null;
  }

  const [, asin] = url.match(amazonUrlRegex);

  return asin ?? null;
};

const CreateAmazonResource = ({
  createResourceMutation,
  createBookProductMutation,
  getItemsByASINs,
  getResourceTypes,
}) => {
  const initialState = {
    paperback: "",
    hardcover: "",
    kindle: "",
  };

  const [formats, setFormatState] = useState(initialState);

  const [bookResourceTypeId, setBookResourceTypeId] = useState(null);
  const [formatError, setError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [createdResource, setCreatedResource] = useState(null);
  const [isResourceCreationComplete, setResourceCreationComplete] = useState(
    false
  );
  const [productsCreated, setProductsCreated] = useState([]);
  const [productsNotCreated, setProductsNotCreated] = useState([]);

  useEffect(() => {
    if (getResourceTypes && getResourceTypes.resourceTypes) {
      const bookResourceType = getResourceTypes.resourceTypes.find(
        ({ name }) => name.toLowerCase() === "book"
      );

      setBookResourceTypeId(bookResourceType.id);
    }
  }, [getResourceTypes]);

  const onUpdateFormat = (field) => (value) => {
    setError(null);

    if (!isAmazonResource(value)) {
      setError(field);
    }

    setFormatState({
      ...formats,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsCreating(true);

    const response = await createResourceMutation({
      variables: {
        resource: {
          title: `Book title to be added`,
          summary: "Book summary to be added",
          description: "Book description to be added",
          type: bookResourceTypeId,
          allowedCountries: PRODUCT_CREATION_COUNTRIES,
          active: false,
          enquirable: false,
          isFree: false,
        },
      },
    });

    if (!response.data.createResource) {
      throw new Error("Failed to create resource. Please try again");
    }

    const createdResource = response?.data?.createResource;

    const invalidProducts = [];
    const productInput = [];

    // for of loops are best for long running sequential requests
    for (const [format, url] of Object.entries(formats)) {
      if (!url) continue;

      const asin = extractASINFromUrl(url);

      for (const country of PRODUCT_CREATION_COUNTRIES) {
        const response = await getItemsByASINs.refetch({
          asins: [asin],
          country,
        });

        const amazonBook = response?.data?.itemsByASINs?.[0];
        const primaryOffer = amazonBook?.offers?.[0];

        if (!amazonBook || !primaryOffer) {
          invalidProducts.push({ format, asin, country });

          continue;
        }

        const input = {
          price: primaryOffer.price,
          currency: primaryOffer.currency,
          country,
          description: capitalize(format),
          link: amazonBook.url,
          active: true,
          amazonASIN: amazonBook.asin,
        };

        productInput.push(input);
      }
    }

    const productInsertPromises = productInput.map(
      async (input) =>
        await createBookProductMutation({
          variables: {
            resourceId: createdResource.id,
            book: input,
          },
        })
    );

    await Promise.all(productInsertPromises);

    setCreatedResource(createdResource);
    setIsCreating(false);
    setProductsCreated(productInput);
    setProductsNotCreated(invalidProducts);
    setResourceCreationComplete(true);
    setFormatState(initialState);
  };

  return (
    <div>
      <h1>Create Book Resource through Amazon</h1>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <div>
            <p>
              This internal tool creates book resources programmatically via
              Amazon‘s APIs. It will create the products in the formats provided
              for each of our current content regions. You can read{" "}
              <a href="https://www.notion.so/learnerbly/Create-Amazon-Resource-6b82dbf5174048c4a07e11d179399b0a">
                the Documentation here
              </a>
              .
            </p>

            <p>
              Please provide an Amazon URL (ideally amazon.co.uk) for each
              format that you would like to create products for
            </p>

            {isCreating && <ProgressBar mode="indeterminate" />}
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <Input
                type="text"
                label="Paperback"
                name="paperback"
                value={formats.paperback}
                onChange={onUpdateFormat("paperback")}
              />
              {formatError && formatError === "paperback" && (
                <span style={{ color: "red" }}>Invalid Amazon URL</span>
              )}
            </div>
            <div>
              <Input
                type="text"
                label="Hardcover"
                name="hardcover"
                value={formats.hardcover}
                onChange={onUpdateFormat("hardcover")}
              />
              {formatError && formatError === "hardcover" && (
                <span style={{ color: "red" }}>Invalid Amazon URL</span>
              )}
            </div>
            <div>
              <Input
                type="text"
                label="Kindle"
                name="kindle"
                value={formats.kindle}
                onChange={onUpdateFormat("kindle")}
              />
              {formatError && formatError === "kindle" && (
                <span style={{ color: "red" }}>Invalid Amazon URL</span>
              )}
            </div>

            <Button
              type="submit"
              disabled={isCreating || isResourceCreationComplete}
              raised
              primary
            >
              {isCreating ? "Creating resource..." : "Create Resource"}
            </Button>
            {isCreating && (
              <span>
                Please do not reload the page whilst each product is created.
              </span>
            )}
          </form>
          {isResourceCreationComplete && (
            <div>
              <div>
                <h4>Resource created!</h4>
                <a href={`/resources/${createdResource.id}/`}>
                  Go to created resource
                </a>
              </div>
              <div>
                {productsCreated && productsCreated.length > 0 && (
                  <div>
                    <h5>{productsCreated.length} Products created</h5>
                    <ul>
                      {productsCreated.map(({ description, country }) => (
                        <li key={country}>
                          {description} in {country}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div>
                {productsNotCreated && productsNotCreated.length > 0 && (
                  <div>
                    <h5>{productsNotCreated.length} Products not created</h5>
                    <p>
                      There were some products that could not be created. This
                      is most likely because they are not available to purchase
                      in that country.
                    </p>
                    <ul>
                      {productsNotCreated.map(({ format, country }) => (
                        <li key={country}>
                          {format} in {country}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CreateAmazonResource.propTypes = {
  mutate: PropTypes.func,
  getResourceTypes: PropTypes.object,
  getItemsByASINs: PropTypes.object,
  createResourceMutation: PropTypes.func,
  createBookProductMutation: PropTypes.func,
  getItemsByASIN: PropTypes.object,
};

export default compose(
  graphql(resourceTypes, { name: "getResourceTypes" }),
  graphql(itemsByASINs, { name: "getItemsByASINs" }),
  graphql(createResource, { name: "createResourceMutation" }),
  graphql(createBookProduct, { name: "createBookProductMutation" })
)(CreateAmazonResource);
