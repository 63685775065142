import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import Joi from "joi-browser";
import React from "react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderField from "../field";
import RenderSwitch from "../switch";

const schema = Joi.object({
  description: Joi.string().allow(null, ""),
  active: Joi.boolean().label("Active"),
  link: Joi.string().required(),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const parseNull = (value) => value || null;

const VirtualCardProductForm = ({ handleDialog, handleSubmit, onSubmit }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={RenderField}
        label="Description"
        name="description"
        parse={parseNull}
      />
      <Field component={RenderField} label="Product URL" name="link" />
      <Row between="xs">
        <Col>
          <Field component={RenderSwitch} label="Active" name="active" />
        </Col>
        <Col>
          <Button onClick={handleDialog}>Cancel</Button>
          <Button type="submit" raised primary>
            Add Virtual Card fulfilment
          </Button>
        </Col>
      </Row>
    </form>
  );
};

VirtualCardProductForm.propTypes = {
  dispatch: PropTypes.func,
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
};

export default reduxForm({
  form: "virtualCardProduct",
  validate,
})(VirtualCardProductForm);
