import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import SubjectForm from "../subject-form";

import subjectsQuery from "../../containers/subjects/subjects.graphql";

import createSubject from "./create-subject.graphql";

const onSubmit = (createSubject, handleDialog) => (data) =>
  createSubject({
    variables: { subject: data },
  }).then(handleDialog);

const CreateSubjectDialog = ({ active, handleDialog, createSubject }) => (
  <Dialog
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
  >
    <SubjectForm
      submitLabel="Create Subject"
      handleDialog={handleDialog}
      onSubmit={onSubmit(createSubject, handleDialog)}
    />
  </Dialog>
);

CreateSubjectDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  createSubject: PropTypes.func,
};

export default graphql(createSubject, {
  name: "createSubject",
  options: {
    update: (proxy, { data: { createSubject } }) => {
      const data = proxy.readQuery({ query: subjectsQuery });
      data.subjects.unshift(createSubject);
      proxy.writeQuery({ query: subjectsQuery, data });
    },
  },
})(CreateSubjectDialog);
