import {
  Avatar,
  Chip,
  FontIcon,
  Snackbar,
  Tooltip,
  ProgressBar,
  Button,
} from "react-toolbox";
import { graphql } from "react-apollo";
import { compose } from "redux";
import { Col, Row } from "react-flexbox-grid";

import React from "react";

import PropTypes from "prop-types";

import PlaylistForm from "../../components/playlist-form";

import updatePlaylistMutation from "./update-playlist.graphql";
import playlistQuery from "./playlist.graphql";

const mapQueryToInitialValues = ({
  expert,
  allowedOrganisations,
  allowedCountries,
  subjects,
  global,
  paginatedEntries,
  ...restOfQuery
}) => ({
  subjects: subjects ? subjects.map(({ id }) => id) : [],
  allowedOrganisations: allowedOrganisations
    ? allowedOrganisations.map(({ id }) => id)
    : [],
  global: global,
  allowedCountries: allowedCountries || [],
  experts: expert && [expert],
  entries:
    paginatedEntries?.edges
      .sort((a, b) => a["index"] - b["index"])
      .map((edge) => edge.node) || [],
  isForAllCountries: !allowedCountries || allowedCountries.length === 0,
  ...restOfQuery,
});

const onSubmit = (mutate, id, success, failure) => (playlist) =>
  mutate({
    variables: { id, playlist },
  })
    .then(success)
    .catch(failure);

const TooltipIcon = Tooltip(FontIcon);

class UpdatePlaylist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false,
    };
  }

  render() {
    const { updatePlaylistMutation, playlistQuery } = this.props;

    if (playlistQuery.loading) {
      return <ProgressBar mode="indeterminate" />;
    }

    return (
      <div>
        <Row middle="sm" style={{ marginBottom: "30px" }}>
          <Col sm={7}>
            <h2>Update Playlist</h2>
            <Row middle="sm">
              <Chip>
                <Avatar
                  icon={
                    playlistQuery.playlist.isDiscoverable
                      ? "visibility"
                      : "visibility_off"
                  }
                />
                {playlistQuery.playlist.isDiscoverable
                  ? "Discoverable"
                  : "Not discoverable"}
              </Chip>
              <TooltipIcon
                tooltip="Not taking into account other restrictions that may apply, a 'Discoverable' playlist can be found browsing and searching the platform.  Conversely, a playlist that is 'Not discoverable' should not be available to any user in any organisation except those who have the direct URL, in the playlist creator's 'Your playlists' page or in the back office platform."
                value="help"
              />
            </Row>
          </Col>
          <Col sm={4} smOffset={1}>
            <a
              target="_blank"
              href={`${process.env.APP_URL}/playlists/${playlistQuery.playlist.id}/`}
              rel="noopener noreferrer"
            >
              <Button icon="link" raised>
                View in app
              </Button>
            </a>
          </Col>
        </Row>
        <PlaylistForm
          playlistId={playlistQuery.playlist.id}
          submitLabel={`Update Playlist${
            playlistQuery.playlist.paginatedEntries.pageInfo.hasNextPage
              ? " (All entries must be loaded - scroll within Resources table above)"
              : ""
          }`}
          initialValues={mapQueryToInitialValues(playlistQuery.playlist)}
          onSubmit={onSubmit(
            updatePlaylistMutation,
            playlistQuery.playlist.id,
            () =>
              this.setState({
                alert: true,
                message: "Success 🎉",
              }),
            () =>
              this.setState({
                alert: true,
                message: "Failure 😞",
              })
          )}
          getMorePaginatedEntries={this.props.getMorePaginatedEntries}
          endCursor={playlistQuery.playlist.paginatedEntries.pageInfo.endCursor}
          hasMore={playlistQuery.playlist.paginatedEntries.pageInfo.hasNextPage}
        />
        <Snackbar
          action="Dismiss"
          active={this.state.alert}
          label={this.state.message}
          timeout={2000}
          onTimeout={() => this.setState({ alert: false })}
          type="cancel"
        />
      </div>
    );
  }
}

UpdatePlaylist.propTypes = {
  updatePlaylistMutation: PropTypes.func,
  playlistQuery: PropTypes.object,
  getMorePaginatedEntries: PropTypes.func,
};

export default compose(
  graphql(playlistQuery, {
    name: "playlistQuery",
    options: ({ match }) => ({
      variables: { id: match.params.playlistId },
    }),
    props: (optionProps) => {
      return {
        ...optionProps,

        ...(optionProps.playlistQuery.fetchMore && {
          getMorePaginatedEntries: () => {
            return optionProps.playlistQuery.fetchMore({
              playlistQuery,
              variables: {
                ...playlistQuery.variables,
                after:
                  optionProps.playlistQuery.playlist.paginatedEntries.pageInfo
                    .endCursor,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!previousResult?.playlist) {
                  return previousResult;
                }

                const currentPaginatedEntries =
                  previousResult.playlist.paginatedEntries;

                const currentEdges = currentPaginatedEntries.edges;

                const latestPaginatedEntries =
                  fetchMoreResult.playlist.paginatedEntries;

                const latestEdges = latestPaginatedEntries.edges;

                const latestPaginatedEntryEdges = [
                  ...new Set([...currentEdges, ...latestEdges]),
                ];

                const paginatedEntries = {
                  ...latestPaginatedEntries,
                  edges: latestPaginatedEntryEdges,
                };

                const playlist = {
                  ...fetchMoreResult.playlist,
                  paginatedEntries,
                };

                const updatedQueryResult = { playlist };

                return updatedQueryResult;
              },
            });
          },
        }),
      };
    },
  }),
  graphql(updatePlaylistMutation, {
    name: "updatePlaylistMutation",
  })
)(UpdatePlaylist);
