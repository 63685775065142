import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import FeatureToggleForm from "../feature-toggle-form";
import featureTogglesQuery from "../../containers/feature-toggles/feature-toggles.graphql";

import updateFeatureToggleQuery from "./update-feature-toggle.graphql";

import theme from "./theme.css";

const onSubmit = (updateFeatureToggleQuery, id, handleDialog) => ({
  active,
  admin,
  user,
  users,
  organisations,
}) =>
  updateFeatureToggleQuery({
    variables: {
      id,
      featureToggle: { active, admin, user, users, organisations },
    },
  }).then(handleDialog);

const UpdateFeatureToggleDialog = ({
  active,
  handleDialog,
  updateFeatureToggleQuery,
  featureToggle,
}) => (
  <Dialog
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
    theme={theme}
  >
    <FeatureToggleForm
      initialValues={{
        id: featureToggle.id,
        active: featureToggle.active,
        admin: featureToggle.admin,
        user: featureToggle.user,
        users: featureToggle.users,
        organisations:
          featureToggle.organisations &&
          featureToggle.organisations.map((organisation) => organisation.id),
      }}
      submitLabel="Update Feature Toggle"
      handleDialog={handleDialog}
      onSubmit={onSubmit(
        updateFeatureToggleQuery,
        featureToggle.id,
        handleDialog
      )}
      update
    />
  </Dialog>
);

UpdateFeatureToggleDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  updateFeatureToggleQuery: PropTypes.func,
  featureToggle: PropTypes.object,
};

export default graphql(updateFeatureToggleQuery, {
  name: "updateFeatureToggleQuery",
  options: {
    update: (proxy, { data: { updateFeatureToggle } }) => {
      const data = proxy.readQuery({ query: featureTogglesQuery });
      const index = data.featureToggles.findIndex(
        (item) => item.id === updateFeatureToggle.id
      );
      data[index] = updateFeatureToggle;
      proxy.writeQuery({ query: featureTogglesQuery, data });
    },
  },
})(UpdateFeatureToggleDialog);
