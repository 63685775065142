import { Dialog, Slider, IconButton } from "react-toolbox";
import { graphql } from "react-apollo";
import AvatarEditor from "react-avatar-editor";
import React from "react";
import classnames from "classnames";

import PropTypes from "prop-types";

import uploadImage from "./upload-image.graphql";

import theme from "./theme.css";

class ImageField extends React.Component {
  constructor(...props) {
    super(...props);

    this.handleEditor = this.handleEditor.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onScaleChange = this.onScaleChange.bind(this);
    this.onRotateChange = this.onRotateChange.bind(this);
    this.setEditorRef = this.setEditorRef.bind(this);

    this.state = {
      dragOver: false,
      showEditor: false,
      scale: 1,
      rotate: 0,
    };
  }

  setEditorRef(editor) {
    this.editor = editor;
  }

  onClickSave() {
    const {
      uploadImage,
      input: { onChange },
    } = this.props;
    const image = this.editor.getImageScaledToCanvas().toDataURL();

    uploadImage({
      variables: { dataUri: image },
    }).then(({ data }) => {
      this.setState({
        image: data.upload,
        dragOver: false,
        showEditor: false,
        scale: 1,
        rotate: 0,
      });

      onChange(data.upload);
    });
  }

  handleEditor() {
    this.setState({
      showEditor: !this.state.showEditor,
    });
  }

  deleteImage() {
    const {
      input: { onChange },
    } = this.props;
    onChange(null);
  }

  onDrop(event) {
    event.preventDefault();
    this.setState({
      file: event.dataTransfer.files[0],
      dragOver: false,
      scale: 1,
      rotate: 0,
    });
    this.handleEditor();
  }

  onDragLeave() {
    this.setState({ dragOver: false });
  }

  onDragEnter() {
    this.setState({ dragOver: true });
  }

  onDragOver(event) {
    event.preventDefault();
  }

  onScaleChange(value) {
    this.setState({ scale: value });
  }

  onRotateChange(value) {
    this.setState({ rotate: value });
  }

  render() {
    const classes = classnames(theme.container, {
      [theme.dragOver]: this.state.dragOver,
    });

    const { input } = this.props;

    return (
      <div className={classes}>
        <h3>Image {this.props.title}</h3>
        {input.value && (
          <div className={theme.imageContainer}>
            <div className={theme.close}>
              <IconButton onClick={this.deleteImage} icon="close" />
            </div>
            <img src={input.value} className={theme.image} />
          </div>
        )}
        {!input.value && (
          <div
            className={theme.drop}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
            onDragLeave={this.onDragLeave}
            onDragEnter={this.onDragEnter}
          >
            <br />
            <br />
            🔥 Drop it like it’s hot 🔥
            <br />
            <br />
          </div>
        )}
        <Dialog
          actions={[
            { label: "Cancel", onClick: this.handleEditor },
            { label: "Save", onClick: this.onClickSave },
          ]}
          active={this.state.showEditor}
          onEscKeyDown={this.handleEditor}
          onOverlayClick={this.handleEditor}
          title="✂️ Cut it"
          theme={theme}
        >
          <AvatarEditor
            ref={this.setEditorRef}
            image={this.state.file}
            width={this.props.width || 332}
            height={this.props.height || 150}
            border={1}
            scale={this.state.scale}
            rotate={this.state.rotate}
          />
          <p>Scale</p>
          <Slider
            min={0}
            max={2}
            value={this.state.scale}
            onChange={this.onScaleChange}
            editable
          />
          <p>Rotate</p>
          <Slider
            min={-180}
            max={180}
            value={this.state.rotate}
            onChange={this.onRotateChange}
            editable
          />
        </Dialog>
      </div>
    );
  }
}

ImageField.propTypes = {
  input: PropTypes.object,
  title: PropTypes.string,
  uploadImage: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default graphql(uploadImage, {
  name: "uploadImage",
})(ImageField);
