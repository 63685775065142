import { Snackbar } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import PlaylistForm from "../../components/playlist-form";

import createPlaylist from "./create-playlist.graphql";

const onSubmit = (mutate, history, failure) => (data) =>
  mutate({
    variables: { playlist: data },
  })
    .then(({ data: { createPlaylist } }) =>
      history.push(`/playlists/${createPlaylist.id}/`)
    )
    .catch(failure);

class CreatePlaylist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false,
    };
  }

  render() {
    const { mutate, history } = this.props;
    return (
      <div>
        <h1>Create Playlist</h1>
        <PlaylistForm
          submitLabel="Create Playlist"
          initialValues={{
            allowedOrganisations: [],
            allowedCountries: [],
            global: true,
            entries: [],
            isForAllCountries: true,
          }}
          onSubmit={onSubmit(mutate, history, () =>
            this.setState({
              alert: true,
              message: "Failure 😞",
            })
          )}
        />
        <Snackbar
          action="Dismiss"
          active={this.state.alert}
          label={this.state.message}
          timeout={2000}
          onTimeout={() => this.setState({ alert: false })}
          type="cancel"
        />
      </div>
    );
  }
}

CreatePlaylist.propTypes = {
  mutate: PropTypes.func,
  history: PropTypes.object,
};

export default graphql(createPlaylist)(CreatePlaylist);
