import React from "react";
import PropTypes from "prop-types";
import { reduce, partialRight, memoize } from "lodash";
import { graphql } from "react-apollo";
import { Autocomplete, ProgressBar } from "react-toolbox";

import subjectsQuery from "./subjects.graphql";

const subjectsReducer = (accSubjects, { id, name, archived }) => {
  if (archived) return;

  return { ...accSubjects, [id]: name };
};

const subjectsToKeyValue = memoize(partialRight(reduce, subjectsReducer, {}));

const SubjectPicker = ({ input, meta, data: { loading, subjects = [] } }) => {
  if (loading) return <ProgressBar mode="indeterminate" />;

  return (
    <Autocomplete
      label="Choose subjects"
      suggestionMatch="anywhere"
      value={input.value}
      source={subjectsToKeyValue(subjects)}
      onChange={input.onChange}
      error={meta.error}
    />
  );
};

SubjectPicker.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  data: PropTypes.shape({
    loading: PropTypes.bool,
    subjects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
};

export default graphql(subjectsQuery, {
  options: { fetchPolicy: "cache-and-network" },
})(SubjectPicker);
