import { Col, Row } from "react-flexbox-grid";
import { List, ListItem, Button, Tabs, Tab, ProgressBar } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import CreateFeatureToggleDialog from "../../components/create-feature-toggle-dialog";
import UpdateFeatureToggleDialog from "../../components/update-feature-toggle-dialog";

import featureTogglesQuery from "./feature-toggles.graphql";

class FeatureToggles extends React.Component {
  constructor(props) {
    super(props);

    this.handleCreateDialog = this.handleCreateDialog.bind(this);
    this.handleUpdateDialog = this.handleUpdateDialog.bind(this);
    this.onFeatureToggleClick = this.onFeatureToggleClick.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);

    this.state = {
      create: false,
      update: false,
      updateFeatureToggle: {},
      index: 0,
    };
  }

  handleCreateDialog() {
    this.setState({
      create: !this.state.create,
    });
  }

  handleUpdateDialog() {
    this.setState({
      update: !this.state.update,
    });
  }

  onFeatureToggleClick(featureToggle) {
    return () => {
      this.setState({
        update: true,
        updateFeatureToggle: featureToggle,
      });
    };
  }

  handleTabChange(index) {
    this.setState({ index });
  }

  render() {
    const {
      data: { featureToggles, loading },
    } = this.props;
    const sortedToggles = featureToggles
      ? featureToggles
          .map(({ id, active, admin, user, users, organisations }) => ({
            id,
            active,
            admin,
            user,
            users,
            organisations,
          }))
          .sort((a, b) => a.id > b.id)
      : [];

    const activeToggles = sortedToggles.filter((toggle) => toggle.active);
    const deactivedToggles = sortedToggles.filter((toggle) => !toggle.active);

    if (loading) {
      return <ProgressBar mode="indeterminate" />;
    }

    return (
      <div>
        <Row between="xs">
          <Col xs={9}>
            <Tabs index={this.state.index} onChange={this.handleTabChange}>
              {activeToggles.length > 0 && (
                <Tab label="Active">
                  <List>
                    {activeToggles.map((toggle) => (
                      <ListItem
                        key={toggle.id}
                        caption={toggle.id}
                        legend={toggle.description}
                        onClick={this.onFeatureToggleClick(toggle)}
                        rightIcon="edit"
                      />
                    ))}
                  </List>
                </Tab>
              )}
              {deactivedToggles.length > 0 && (
                <Tab label="Deactivated">
                  <List>
                    {deactivedToggles.map((toggle) => (
                      <ListItem
                        key={toggle.id}
                        caption={toggle.id}
                        legend={toggle.description}
                        onClick={this.onFeatureToggleClick(toggle)}
                        rightIcon="blocked"
                      />
                    ))}
                  </List>
                </Tab>
              )}
            </Tabs>
          </Col>
          <Col>
            <Button
              icon="add"
              label="Create Toggle"
              onClick={this.handleCreateDialog}
              raised
              primary
            />
          </Col>
        </Row>
        <CreateFeatureToggleDialog
          active={this.state.create}
          handleDialog={this.handleCreateDialog}
        />
        <UpdateFeatureToggleDialog
          active={this.state.update}
          handleDialog={this.handleUpdateDialog}
          onArchiveClick={this.onArchiveClick}
          featureToggle={this.state.updateFeatureToggle}
        />
      </div>
    );
  }
}

FeatureToggles.propTypes = {
  data: PropTypes.object,
};

export default graphql(featureTogglesQuery)(FeatureToggles);
