import { Dropdown } from "react-toolbox";
import React from "react";

import PropTypes from "prop-types";

export const bookFormatOptions = [
  { label: "eBook", value: "EBOOK" },
  { label: "AudioBook", value: "AUDIOBOOK" },
  { label: "Paperback", value: "PAPERBACK" },
  { label: "Hardcover", value: "HARDCOVER" },
  { label: "Kindle", value: "KINDLE" },
];

export const bookFormatTypes = bookFormatOptions.map((type) => type.value);

const BookFormatPicker = ({ input, meta }) => (
  <Dropdown
    value={input.value}
    error={(meta.touched && meta.error) || ""}
    label="Book Format"
    onChange={input.onChange}
    source={bookFormatOptions}
  />
);

BookFormatPicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default BookFormatPicker;
