import React, { useState, useRef } from "react";
import { Mutation } from "react-apollo";
import { ProgressBar, Button, Dialog, IconButton } from "react-toolbox";
import PropTypes from "prop-types";

import theme from "./theme.css";
import {
  createOrganisationApiKey,
  deleteOrganisationApiKey,
} from "./api-key.graphql";

const CopyApiKey = ({ id }) => {
  const textAreaRef = useRef(null);

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    alert("Copied the text: " + textAreaRef.current.value);
  };

  return (
    <div className={theme.copyContainer}>
      {document.queryCommandSupported("copy") && (
        <IconButton
          icon="content_copy"
          className={theme.copyIcon}
          primary
          onClick={copyToClipboard}
        />
      )}
      <textarea
        className={theme.textarea}
        readOnly
        ref={textAreaRef}
        value={`Static ${id}`}
      />
    </div>
  );
};

export const CreateApiKey = ({ organisationId, hasKey }) => {
  const [active, setActive] = useState(false);
  const handleToggle = () => setActive(!active);

  const handleToggleUpdate = () => {
    handleToggle();
    window.location.reload();
  };
  return (
    <Mutation mutation={createOrganisationApiKey}>
      {(createKey, { loading, data }) => (
        <>
          <Button
            className={theme.button}
            onClick={(e) => {
              e.preventDefault();
              if (organisationId) {
                createKey({
                  variables: {
                    organisationId: organisationId,
                  },
                });
                handleToggle();
              }
            }}
            raised
            primary
          >
            {hasKey ? "Regenerate key" : "Generate key"}
          </Button>
          <Dialog
            actions={[{ label: "Close", onClick: handleToggleUpdate }]}
            active={active}
            onEscKeyDown={handleToggleUpdate}
            onOverlayClick={handleToggleUpdate}
          >
            <p>
              Use this API key to integrate any of your fancy tools with
              Learnerbly. Keep it safe and don’t share with random people. Thnx!
            </p>
            {data && (
              <CopyApiKey id={data.createOktaIntegration.oktaInstallation.id} />
            )}
          </Dialog>
          {loading && <ProgressBar mode="indeterminate" />}
        </>
      )}
    </Mutation>
  );
};

export const DeleteApiKey = ({ organisationId }) => {
  const [active, setActive] = useState(false);
  const handleToggle = () => setActive(!active);

  const handleToggleUpdate = () => {
    handleToggle();
    window.location.reload();
  };
  return (
    <Mutation mutation={deleteOrganisationApiKey}>
      {(deleteKey, { loading }) => (
        <>
          <Button
            className={theme.button}
            onClick={handleToggle}
            raised
            primary
          >
            Disable
          </Button>
          <Dialog
            actions={[
              { label: "Close", onClick: handleToggle },
              {
                label: "Delete key",
                onClick: (e) => {
                  e.preventDefault();
                  if (organisationId) {
                    deleteKey({
                      variables: {
                        organisationId: organisationId,
                      },
                    });
                  }
                  handleToggleUpdate();
                },
              },
            ]}
            active={active}
            onEscKeyDown={handleToggle}
            onOverlayClick={handleToggle}
          >
            <h3>Are you sure?</h3>
            <p>
              Deleting this API key will disable any integration that uses it,
              so you will need to re-integrate using a new key.
            </p>
          </Dialog>
          {loading && <ProgressBar mode="indeterminate" />}
        </>
      )}
    </Mutation>
  );
};

CreateApiKey.propTypes = {
  organisationId: PropTypes.string.isRequired,
  hasKey: PropTypes.bool.isRequired,
};

DeleteApiKey.propTypes = {
  organisationId: PropTypes.string.isRequired,
};

CopyApiKey.propTypes = {
  id: PropTypes.string.isRequired,
};
