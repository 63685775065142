import { Button, ProgressBar } from "react-toolbox";
import { Field, Form, reduxForm } from "redux-form";
import { Mutation } from "react-apollo";
import { compose } from "redux";
import React from "react";

import { PropTypes } from "prop-types";

import CountryPicker from "../country-picker";
import RenderField from "../field";

import updateOfficeMutation from "./update-office.graphql";

const UpdateOfficeForm = ({ handleSubmit, organisationId, officeId }) => (
  <Mutation mutation={updateOfficeMutation}>
    {(updateOffice, { loading }) => (
      <Form
        onSubmit={handleSubmit((office) =>
          updateOffice({ variables: { office, organisationId, officeId } })
        )}
      >
        {loading && <ProgressBar mode="indeterminate" />}
        <Field component={RenderField} label="Name" name="name" />
        <Field component={RenderField} label="Street" name="street" />
        <Field component={RenderField} label="Postcode" name="postcode" />
        <Field component={RenderField} label="City" name="city" />
        <Field name="country" label="Country" component={CountryPicker} />
        <Button type="submit" raised primary disabled={loading}>
          Update Office
        </Button>
      </Form>
    )}
  </Mutation>
);

UpdateOfficeForm.propTypes = {
  handleSubmit: PropTypes.func,
  organisationId: PropTypes.string,
  officeId: PropTypes.string,
};

export default compose(
  reduxForm({
    form: "update-office",
  })
)(UpdateOfficeForm);
