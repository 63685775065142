import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import moment from "moment-timezone";

import CreateEventForm from "../create-event-form";
import addEventQuery from "../add-event-dialog/add-event.graphql";

import theme from "./theme.css";

const onSubmit = (copyEvent, handleDialog, resourceId) => ({
  active,
  price,
  city,
  startDate,
  endDate,
  moreInfo,
  currency,
  description,
  street,
  postcode,
  country,
  link,
  timezone,
}) =>
  copyEvent({
    variables: {
      event: {
        location: {
          city,
          street,
          postcode,
          country,
        },
        moreInfo,
        currency,
        country,
        description,
        price,
        startDate: moment
          .tz(moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
          .utc()
          .format(),
        endDate:
          endDate &&
          moment
            .tz(moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
            .utc()
            .format(),
        active,
        link,
        timezone,
      },
      resourceId,
    },
  }).then(handleDialog);

const CopyEventModal = ({
  active,
  copyEvent,
  handleDialog,
  resourceId,
  product,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <CreateEventForm
      handleDialog={handleDialog}
      onSubmit={onSubmit(copyEvent, handleDialog, resourceId)}
      initialValues={{
        city: product.location && product.location.city,
        street: product.location && product.location.street,
        postcode: product.location && product.location.postcode,
        moreInfo: product.moreInfo,
        description: product.description,
        price: product.price,
        startDate: moment
          .utc(
            moment
              .tz(product.startDate, product.timezone)
              .format("YYYY-MM-DDTHH:mm")
          )
          .format(),
        timezone: product.timezone,
        endDate:
          product.endDate &&
          moment
            .utc(
              moment
                .tz(product.endDate, product.timezone)
                .format("YYYY-MM-DDTHH:mm")
            )
            .format(),
        active: Boolean(product.active),
        country: product.country,
        currency: product.currency,
        link: product.link,
        isGlobal: !product.country,
      }}
    />
  </Dialog>
);

CopyEventModal.propTypes = {
  active: PropTypes.bool,
  copyEvent: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  product: PropTypes.object,
};

export default graphql(addEventQuery, {
  name: "copyEvent",
})(CopyEventModal);
