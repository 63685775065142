import { Checkbox } from "react-toolbox";
import React from "react";

import PropTypes from "prop-types";

const RenderCheckbox = ({ input, meta, ...props }) => (
  <Checkbox checked={Boolean(input.value)} {...input} {...props} />
);

RenderCheckbox.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
};

export default RenderCheckbox;
