import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Chip, Avatar } from "react-toolbox";

export const Status = ({ status }) => {
  return (
    <Fragment>
      {status === "purchased" && (
        <Chip>
          <Avatar icon="done_all" style={{ backgroundColor: "#43AB95" }} />
          {status}
        </Chip>
      )}

      {(status === "rejected" || status === "cancelled") && (
        <Chip>
          <Avatar icon="close" style={{ backgroundColor: "#FF4E5B" }} />
          {status}
        </Chip>
      )}

      {status === "approved" && (
        <Chip>
          <Avatar icon="check" style={{ backgroundColor: "#B7DED6" }} />
          {status}
        </Chip>
      )}

      {status === "pending" && (
        <Chip>
          <Avatar icon="block" style={{ backgroundColor: "#D3E2FF" }} />
          {status}
        </Chip>
      )}
    </Fragment>
  );
};

Status.propTypes = {
  status: PropTypes.oneOf([
    "purchased",
    "rejected",
    "cancelled",
    "approved",
    "pending",
  ]).isRequired,
};
