// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".theme-dialog-1Gm5Fw-6 {\n  margin-top: 70px;\n  overflow-y: scroll;\n  padding: 70px auto;\n}\n", "",{"version":3,"sources":["/Users/mnavarro/source/origin/backoffice/src/components/add-internal-event-dialog/theme.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB","file":"theme.css","sourcesContent":[".dialog {\n  margin-top: 70px;\n  overflow-y: scroll;\n  padding: 70px auto;\n}\n"]}]);
// Exports
exports.locals = {
	"dialog": "theme-dialog-1Gm5Fw-6"
};
module.exports = exports;
