import { Button, List, ListItem } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import React from "react";

import PropTypes from "prop-types";

import CreateSupplierDialog from "../create-supplier-dialog";
import SelectSupplierDialog from "../select-supplier-dialog";
import UpdateSupplierDialog from "../update-supplier-dialog";

class Supplier extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      createSupplierDialog: false,
      updateSupplierDialog: false,
      selectSupplierDialog: false,
    };

    this.handleCreateSupplierDialog = this.handleCreateSupplierDialog.bind(
      this
    );
    this.handleUpdateSupplierDialog = this.handleUpdateSupplierDialog.bind(
      this
    );
    this.handleSelectSupplierDialog = this.handleSelectSupplierDialog.bind(
      this
    );
  }

  handleCreateSupplierDialog() {
    this.setState({
      createSupplierDialog: !this.state.createSupplierDialog,
    });
  }

  handleUpdateSupplierDialog() {
    this.setState({
      updateSupplierDialog: !this.state.updateSupplierDialog,
    });
  }

  handleSelectSupplierDialog() {
    this.setState({
      selectSupplierDialog: !this.state.selectSupplierDialog,
    });
  }

  render() {
    const { input } = this.props;

    return (
      <Row>
        <Col sm={7}>
          <h4>Supplier</h4>
          {!input.value && <p>Not set</p>}
          {input.value && (
            <List>
              <ListItem
                caption={input.value.name}
                rightIcon="edit"
                onClick={this.handleUpdateSupplierDialog}
              />
            </List>
          )}
        </Col>
        <Col sm={1}>
          <Button
            icon="add"
            onClick={this.handleCreateSupplierDialog}
            primary
            floating
            mini
          />
        </Col>
        <Col sm={1}>
          <Button
            icon="search"
            onClick={this.handleSelectSupplierDialog}
            primary
            floating
            mini
          />
        </Col>

        <CreateSupplierDialog
          active={this.state.createSupplierDialog}
          handleDialog={this.handleCreateSupplierDialog}
          onSuccess={input.onChange}
        />

        {input.value && (
          <UpdateSupplierDialog
            active={this.state.updateSupplierDialog}
            handleDialog={this.handleUpdateSupplierDialog}
            onArchiveClick={() => {}}
            onSuccess={input.onChange}
            supplier={input.value}
          />
        )}

        <SelectSupplierDialog
          active={this.state.selectSupplierDialog}
          handleDialog={this.handleSelectSupplierDialog}
          onSuccess={input.onChange}
        />
      </Row>
    );
  }
}

Supplier.propTypes = {
  input: PropTypes.object,
};

export default Supplier;
