import { Button, FontIcon, ProgressBar, List, ListItem } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import Joi from "joi-browser";
import React from "react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderField from "../field";

import SubjectPicker from "../subject-picker";

const schema = Joi.object({
  name: Joi.string().max(100).required(),
  description: Joi.string().max(300).required(),
  children: Joi.array().items(Joi.string().guid({ version: ["uuidv4"] })),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
    stripUnknown: true,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const mapFormValuesToMutation = (onSubmit) => ({
  name,
  description,
  children,
}) =>
  onSubmit({
    name,
    description,
    children,
  });

const ResultsComponent = ({ searchResources, onSuccess, reset }) => {
  if (!searchResources) {
    return null;
  }

  if (searchResources.loading) {
    return <ProgressBar mode="indeterminate" />;
  }

  if (!searchResources.searchResources.results.length) {
    return <div>No results</div>;
  }

  return (
    <List>
      {searchResources.searchResources.results.map((result) => (
        <ListItem
          key={result.id}
          rightIcon="playlist_add"
          caption={result.title.substring(0, 38)}
          legend={result.product && result.product.price}
          onClick={() => {
            onSuccess(result);
            reset();
          }}
        />
      ))}
    </List>
  );
};

ResultsComponent.propTypes = {
  searchResources: PropTypes.object,
  onSuccess: PropTypes.func,
  history: PropTypes.object,
  reset: PropTypes.func,
};

const SubjectForm = ({
  onArchiveClick,
  handleDialog,
  handleSubmit,
  onSubmit,
  submitLabel,
  update,
  archived,
}) => (
  <form onSubmit={handleSubmit(mapFormValuesToMutation(onSubmit))}>
    <Field component={RenderField} label="Name" name="name" autoFocus />
    {update && (
      <Row>
        <Col xs={1}>
          <FontIcon value="info" />
        </Col>
        <Col xs>
          <p>
            Changing a subject will change user focuses and resources that use
            this subject
          </p>
        </Col>
      </Row>
    )}
    <Field component={RenderField} label="Description" name="description" />
    <h4>Children</h4>
    <Field name="children" component={SubjectPicker} />
    <Row between="xs">
      <Col>
        {update && !archived && (
          <Button icon="archive" onClick={onArchiveClick} primary>
            Archive Subject
          </Button>
        )}
      </Col>
      <Col>
        <Button onClick={handleDialog}>Cancel</Button>
        <Button type="submit" raised primary>
          {submitLabel}
        </Button>
      </Col>
    </Row>
  </form>
);

SubjectForm.propTypes = {
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  onArchiveClick: PropTypes.func,
  update: PropTypes.bool,
  archived: PropTypes.bool,
};

export default reduxForm({
  form: "subject",
  validate,
})(SubjectForm);
