import React from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  Dialog,
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
} from "react-toolbox";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import moment from "moment";

import archiveIndividualBudget from "./archive-budget.graphql";

import theme from "./theme.css";

const UpdateUserBudgetDialog = ({
  active,
  handleDialog,
  setAlert,
  userId,
  budget,
  archiveIndividualBudget,
}) => {
  const onArchiveBudget = () => {
    archiveIndividualBudget({
      variables: {
        userId,
        budgetId: budget.id,
      },
    })
      .then(() => {
        setAlert({
          isActive: true,
          message: "Succesfully archived budget",
        });
        handleDialog();
      })
      .catch(() => {
        setAlert({
          isActive: true,
          message: "Failed to archived budget",
        });
        handleDialog();
      });
  };

  return (
    <Dialog
      onEscKeyDown={handleDialog}
      onOverlayClick={handleDialog}
      active={active}
      theme={theme}
    >
      {budget && (
        <>
          <Row middle="xs" between="xs">
            <Col xs={6}>
              <h2>Budget</h2>
            </Col>
            <Col xs={6}>
              <Button
                type="button"
                label="Archive Budget"
                disabled={budget && budget.status !== "CREATED"}
                onClick={onArchiveBudget}
                raised
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table selectable={false}>
                <TableHead>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableHead>
                <TableRow>
                  <TableCell>
                    <div>{moment(budget.startDate).format("Do MMM YYYY")}</div>
                  </TableCell>
                  <TableCell>
                    <div>{moment(budget.endDate).format("Do MMM YYYY")}</div>
                  </TableCell>
                </TableRow>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h4>Transactions</h4>
              <Table selectable={false}>
                <TableHead>
                  <TableCell>Status</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Valid On</TableCell>
                  <TableCell>Amount</TableCell>
                </TableHead>
                {budget &&
                  budget.transactions.map(
                    ({ id, status, type, validOn, value }) => (
                      <TableRow key={id}>
                        <TableCell>
                          <div>{status}</div>
                        </TableCell>
                        <TableCell>
                          <div>{type}</div>
                        </TableCell>
                        <TableCell>
                          <div>{moment(validOn).format("Do MMM YYYY")}</div>
                        </TableCell>
                        <TableCell>
                          <div>{(value / 100).toFixed(2)}</div>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </Table>
            </Col>
          </Row>
        </>
      )}
    </Dialog>
  );
};

UpdateUserBudgetDialog.propTypes = {
  active: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  budget: PropTypes.object.isRequired,
  archiveIndividualBudget: PropTypes.func,
};

export default graphql(archiveIndividualBudget, {
  name: "archiveIndividualBudget",
})(UpdateUserBudgetDialog);
