import React from "react";
import { graphql } from "react-apollo";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ProgressBar, Input } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";

import organisationUsersQuery from "./organisation-users.graphql";

const OrganisationUsers = ({ organisationUsers }) => {
  if (organisationUsers.loading) {
    return <ProgressBar mode="indeterminate" />;
  }

  if (!organisationUsers.organisation.members.results.length) {
    return <div>No results</div>;
  }

  const header =
    "Name,Email,User Id,Created at,Onboarded?,Country,Geographic Location,Total Budget,Budget Spent,Budget Remaining,Approvers,Approver Emails\n";

  let rows = [];

  organisationUsers.organisation.members.results.forEach((user) => {
    const row = [
      `${user.firstName} ${user.lastName}`,
      user.email,
      user.id,
      user.createdAt,
      user.onboarded,
      user.country,
      user.geographicLocation,
      user.activeIndividualBudget && user.activeIndividualBudget.total,
      user.activeIndividualBudget && user.activeIndividualBudget.spent,
      user.activeIndividualBudget && user.activeIndividualBudget.remaining,
      user.approvers &&
        user.approvers
          .map((approver) => `${approver.firstName} ${approver.lastName}`)
          .join(" | "),
      user.approvers &&
        user.approvers.map((approver) => approver.email).join(" | "),
    ].join(",");
    rows.push(row);
  });

  const csv = header + rows.join("\n");

  return (
    <div>
      <Row>
        <Col sm={8}>
          <Input value={csv} multiline />
        </Col>
        <Col sm={2} smOffset={1} />
      </Row>
    </div>
  );
};

OrganisationUsers.propTypes = {
  organisationUsers: PropTypes.shape({
    error: PropTypes.bool,
    fetchMore: PropTypes.func,
    loading: PropTypes.bool,
    networkStatus: PropTypes.number,
    organisation: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      members: PropTypes.shape({
        results: PropTypes.arrayOf(
          PropTypes.shape({
            activeIndividualBudget: PropTypes.shape({
              remaining: PropTypes.number,
              spent: PropTypes.number,
              total: PropTypes.number,
            }),
            approvers: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
              })
            ),
            country: PropTypes.string,
            createdAt: PropTypes.string,
            email: PropTypes.string,
            firstName: PropTypes.string,
            geographicLocation: PropTypes.string,
            id: PropTypes.string,
            lastName: PropTypes.string,
            onboarded: PropTypes.bool,
          })
        ),
        searchAfter: PropTypes.string,
      }),
    }),
    refetch: PropTypes.func,
    startPolling: PropTypes.func,
    stopPolling: PropTypes.func,
    subscribeToMore: PropTypes.func,
    updateQuery: PropTypes.func,
  }),
};

export default compose(
  graphql(organisationUsersQuery, {
    name: "organisationUsers",
    options: ({ organisationId }) => ({
      variables: {
        id: organisationId,
      },
    }),
  })
)(OrganisationUsers);
