// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-supplierSearch-Vg7-xyii {\n  padding-bottom: 20px;\n}\n", "",{"version":3,"sources":["/Users/mnavarro/source/origin/backoffice/src/components/search-supplier/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB","file":"styles.css","sourcesContent":[".supplierSearch {\n  padding-bottom: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"supplierSearch": "styles-supplierSearch-Vg7-xyii"
};
module.exports = exports;
