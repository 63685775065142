import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import CreateVirtualCardProductForm from "../create-virtual-card-product-form";

import addVirtualCardProductQuery from "./add-virtual-card-product.graphql";

import theme from "./theme.css";

const onSubmit = (addVirtualCardProduct, handleDialog, resourceId) => (
  data
) => {
  const { isGlobal, ...virtualCardProduct } = data;

  addVirtualCardProduct({
    variables: { virtualCardProduct, resourceId },
  }).then(handleDialog);
};

const AddVirtualCardProductModal = ({
  active,
  addVirtualCardProduct,
  handleDialog,
  resourceId,
  resourceLink,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <CreateVirtualCardProductForm
      handleDialog={handleDialog}
      onSubmit={onSubmit(addVirtualCardProduct, handleDialog, resourceId)}
      initialValues={{ active: true, link: resourceLink }}
    />
  </Dialog>
);

AddVirtualCardProductModal.propTypes = {
  active: PropTypes.bool,
  addVirtualCardProduct: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  resourceLink: PropTypes.string,
};

export default graphql(addVirtualCardProductQuery, {
  name: "addVirtualCardProduct",
})(AddVirtualCardProductModal);
