import PropTypes from "prop-types";
import React from "react";
import { graphql } from "react-apollo";
import { Dialog } from "react-toolbox";

import CreateBookForm from "../create-book-form";

import addBookQuery from "../add-book-dialog/add-book.graphql";

import { GrowthBookProvider } from "../../utils/growthbook/growthbook";

import theme from "./theme.css";

const onSubmit = (copyBook, handleDialog, resourceId) => ({
  active,
  country,
  currency,
  description,
  link,
  price,
  format,
}) =>
  copyBook({
    variables: {
      book: {
        active,
        country,
        currency,
        description,
        link,
        price,
        format,
      },
      resourceId,
    },
  }).then(handleDialog);

const CopyBookModal = ({
  active,
  copyBook,
  handleDialog,
  product,
  resourceId,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <GrowthBookProvider>
      <CreateBookForm
        handleDialog={handleDialog}
        initialValues={{
          active: product.active,
          country: product.country,
          currency: product.currency,
          description: product.description,
          isGlobal: !product.country,
          link: product.link,
          price: product.price,
          format: product.format,
        }}
        onSubmit={onSubmit(copyBook, handleDialog, resourceId)}
      />
    </GrowthBookProvider>
  </Dialog>
);

CopyBookModal.propTypes = {
  active: PropTypes.bool,
  copyBook: PropTypes.func,
  handleDialog: PropTypes.func,
  product: PropTypes.object,
  resourceId: PropTypes.string,
};

export default graphql(addBookQuery, {
  name: "copyBook",
})(CopyBookModal);
