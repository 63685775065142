import React from "react";
import { Query } from "react-apollo";
import { ProgressBar } from "react-toolbox";
import PropTypes from "prop-types";
import moment from "moment";

import { CreateApiKey, DeleteApiKey } from "./api-key-action";
import theme from "./theme.css";
import { getOrganisationApiKey } from "./api-key.graphql";

const GetApiKey = ({ organisationId }) => {
  return (
    <div className={theme.apiContainer}>
      <h3>API Key Integration:</h3>
      <Query
        query={getOrganisationApiKey}
        fetchPolicy="cache-and-network"
        variables={{ id: organisationId }}
      >
        {({ loading, data }) => {
          const isActive = data?.organisation?.oktaInstallation?.isActive;

          if (loading) return <ProgressBar mode="indeterminate" />;
          return (
            <>
              <ApiKeyCard
                hasKey={isActive}
                createdBy={data?.organisation?.oktaInstallation?.createdBy}
                createdAt={data?.organisation?.oktaInstallation?.createdAt}
              />
              <CreateApiKey organisationId={organisationId} hasKey={isActive} />
              {isActive && <DeleteApiKey organisationId={organisationId} />}
            </>
          );
        }}
      </Query>
    </div>
  );
};

const ApiKeyCard = ({ hasKey, createdBy, createdAt }) => {
  const userName = createdBy
    ? `by ${createdBy?.firstName} ${createdBy?.lastName}`
    : "";
  return (
    <>
      <p>
        <strong>{hasKey ? "1 active key" : "No active key"}</strong>
      </p>
      <p>
        {hasKey
          ? `Created on ${moment(createdAt).format("MM-DD-YYYY")} ${userName}`
          : "No API key currently exists"}
      </p>
    </>
  );
};

ApiKeyCard.propTypes = {
  hasKey: PropTypes.bool.isRequired,
  createdBy: PropTypes.object,
  createdAt: PropTypes.string,
};
GetApiKey.propTypes = {
  organisationId: PropTypes.string.isRequired,
};

export default GetApiKey;
