import { Button } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import Joi from "joi-browser";
import React from "react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderField from "../field";
import RenderSwitch from "../switch";

const schema = Joi.object({
  description: Joi.string().allow(null, ""),
  active: Joi.boolean().label("Active"),
  link: Joi.string().required(),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const parseNull = (value) => value || null;

const UpdateVirtualCardProductForm = ({
  handleDialog,
  handleSubmit,
  onSubmit,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Field
      component={RenderField}
      label="Description"
      name="description"
      parse={parseNull}
    />
    <Field component={RenderField} label="Product URL" name="link" />
    <Row between="xs">
      <Col>
        <Field component={RenderSwitch} label="Active" name="active" />
      </Col>
      <Col>
        <Button onClick={handleDialog}>Cancel</Button>
        <Button type="submit" raised primary>
          Update Fulfil Via Virtual Card
        </Button>
      </Col>
    </Row>
  </form>
);

UpdateVirtualCardProductForm.propTypes = {
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
};

export default reduxForm({
  form: "virtualCardProduct",
  validate,
})(UpdateVirtualCardProductForm);
