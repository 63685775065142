import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import UpdateVirtualCardProductForm from "../update-virtual-card-product-form";

import updateVirtualCardProductQuery from "./update-virtual-card-product.graphql";

const onSubmit = (updateVirtualCardProduct, handleDialog, id, resourceId) => (
  data
) =>
  updateVirtualCardProduct({
    variables: { virtualCardProduct: data, id, resourceId },
  }).then(handleDialog);

const UpdateVirtualCardProductDialog = ({
  active,
  updateVirtualCardProduct,
  handleDialog,
  resourceId,
  product,
  resourceLink,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
  >
    <UpdateVirtualCardProductForm
      handleDialog={handleDialog}
      onSubmit={onSubmit(
        updateVirtualCardProduct,
        handleDialog,
        product.id,
        resourceId
      )}
      initialValues={{
        description: product.description,
        active: Boolean(product.active),
        link: product.link || resourceLink || null,
      }}
    />
  </Dialog>
);

UpdateVirtualCardProductDialog.propTypes = {
  active: PropTypes.bool,
  updateVirtualCardProduct: PropTypes.func,
  handleDialog: PropTypes.func,
  resourceId: PropTypes.string,
  product: PropTypes.object,
  resourceLink: PropTypes.string,
};

export default graphql(updateVirtualCardProductQuery, {
  name: "updateVirtualCardProduct",
})(UpdateVirtualCardProductDialog);
