import { Button, List, ListItem } from "react-toolbox";
import { graphql } from "react-apollo";
import { compose } from "redux";
import { Field, Form, reduxForm } from "redux-form";
import React from "react";

import PropTypes from "prop-types";

import papaparse from "papaparse";

import RenderField from "../../components/field";
import SwitchField from "../../components/switch";

import OrganisationPicker from "../../components/organisation-picker";

import createUserQuery from "./create-user.graphql";
import addBudgetQuery from "./add-budget.graphql";
import sendInvitationQuery from "./send-invitation.graphql";

const parse = (onSubmit) => ({ csv, organisation, sendNotification }) => {
  const users = papaparse.parse(csv, {
    header: true,
  }).data;
  onSubmit({ users, organisation, sendNotification });
};

const ParseCSV = reduxForm({
  form: "feature-toggle",
  initialValues: {
    sendNotification: false,
  },
})(({ handleSubmit, onSubmit }) => (
  <Form onSubmit={handleSubmit(parse(onSubmit))}>
    <Field component={RenderField} name="csv" label="CSV" multiline />
    <Field
      label="Organisation"
      component={OrganisationPicker}
      name="organisation"
    />
    <Field
      component={SwitchField}
      label="Send Email Notification"
      name="sendNotification"
    />
    <Button type="submit" raised primary>
      Parse
    </Button>
  </Form>
));

class UserListItemComponent extends React.Component {
  constructor(props) {
    super(props);

    this.onItemClick = this.onItemClick.bind(this);

    this.state = {
      status: "send",
    };
  }

  onItemClick() {
    const {
      createUser,
      addBudget,
      sendInvitation,
      firstName,
      lastName,
      email,
      country,
      budget,
      budgetEndDate,
      approver,
      organisation,
      sendNotification,
      geographicLocation,
    } = this.props;

    this.setState({
      status: "network_check",
    });

    createUser({
      variables: {
        user: {
          firstName,
          lastName,
          email,
          country: country || "GB",
          approvers: [approver],
          organisation,
          geographicLocation,
        },
      },
    })
      .then(({ data: { createUser } }) => createUser)
      .then((user) => {
        if (budget) {
          return addBudget({
            variables: {
              userId: user.id,
              startDate: new Date().toISOString(),
              endDate: budgetEndDate,
              total: parseInt(budget, 10),
            },
          }).then(() => user);
        }

        return user;
      })
      .then((user) => {
        if (sendNotification) {
          return sendInvitation({
            variables: {
              userId: user.id,
            },
          });
        }
      })
      .then(() =>
        this.setState({
          status: "done",
        })
      )
      .catch((error) => {
        /* eslint-disable-next-line no-console */
        console.error(error);
        this.setState({
          status: "error",
        });
      });
  }

  render() {
    const { firstName, lastName, email, budget } = this.props;
    return (
      <ListItem
        onClick={this.onItemClick}
        rightIcon={this.state.status}
        caption={`${firstName} ${lastName}`}
        legend={budget ? `${email}, £${budget / 100}` : email}
      />
    );
  }
}

UserListItemComponent.propTypes = {
  createUser: PropTypes.func,
  addBudget: PropTypes.func,
  sendInvitation: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  country: PropTypes.string,
  budget: PropTypes.string,
  budgetEndDate: PropTypes.string,
  approver: PropTypes.string,
  organisation: PropTypes.string,
  sendNotification: PropTypes.bool,
  geographicLocation: PropTypes.string,
  currentUser: PropTypes.object,
};

const UserListItem = compose(
  graphql(createUserQuery, { name: "createUser" }),
  graphql(addBudgetQuery, { name: "addBudget" }),
  graphql(sendInvitationQuery, { name: "sendInvitation" })
)(UserListItemComponent);

class CSVtoAccount extends React.Component {
  constructor(props) {
    super(props);

    this.addUsers = this.addUsers.bind(this);

    this.state = {
      showForm: true,
      users: [],
    };
  }

  addUsers(data) {
    this.setState({
      showForm: false,
      ...data,
    });
  }

  render() {
    return (
      <div>
        {this.state.showForm && <ParseCSV onSubmit={this.addUsers} />}
        {Boolean(this.state.users.length) && (
          <div>
            <div>
              <strong>Organsiation:</strong> {this.state.organisation}
            </div>
            <div>
              {this.state.sendNotification && (
                <Button icon="check_circle" primary>
                  Will send Notification
                </Button>
              )}
            </div>
            <List>
              {this.state.users.map((user, index) => {
                if (user.firstName) {
                  return (
                    <UserListItem
                      key={index}
                      {...user}
                      {...{
                        organisation: this.state.organisation,
                        sendNotification: this.state.sendNotification,
                      }}
                      currentUser={this.props.currentUser}
                    />
                  );
                }
              })}
            </List>
            <Button
              type="button"
              onClick={() => this.setState({ showForm: true, users: [] })}
              raised
              primary
            >
              Start again
            </Button>
          </div>
        )}
      </div>
    );
  }
}

CSVtoAccount.propTypes = {
  currentUser: PropTypes.object,
};

export default CSVtoAccount;
