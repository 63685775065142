import { Button, Input, ProgressBar, List, ListItem } from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, Form, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { graphql } from "react-apollo";
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import theme from "./theme.css";
import searchUsersQuery from "./search-users.graphql";

const ResultsComponent = ({ searchUsersQuery, history }) => {
  if (!searchUsersQuery) {
    return null;
  }

  if (searchUsersQuery.loading) {
    return <ProgressBar mode="indeterminate" />;
  }

  if (!searchUsersQuery.searchUsers.results.length) {
    return <div>No results</div>;
  }

  return (
    <List>
      {searchUsersQuery.searchUsers.results.map((result) => (
        <ListItem
          key={result.id}
          className={classNames(theme.listItem)}
          rightIcon="edit"
          caption={`${result.firstName} ${result.lastName}`}
          legend={result.organisation.name}
          onClick={() => history.push(`/users/${result.id}/`)}
        />
      ))}
    </List>
  );
};

ResultsComponent.propTypes = {
  searchUsersQuery: PropTypes.object,
  onSuccess: PropTypes.func,
  history: PropTypes.object,
};

const Results = graphql(searchUsersQuery, {
  name: "searchUsersQuery",
  options: ({ value }) => ({ variables: { query: value } }),
  skip: (props) => !props.value,
})(ResultsComponent);

const SelectInput = ({ input, name, onSuccess, history, ...props }) => (
  <div>
    <Input {...input} {...props} label="Search" name={name} />
    <Results value={input.value} onSuccess={onSuccess} history={history} />
  </div>
);

SelectInput.propTypes = {
  input: PropTypes.object,
  name: PropTypes.string,
  onSuccess: PropTypes.func,
  history: PropTypes.object,
};

class Users extends React.Component {
  render() {
    const { history, handleSubmit } = this.props;
    return (
      <Row>
        <Col sm={8}>
          <Form onSubmit={handleSubmit((x) => x)}>
            <Field
              component={SelectInput}
              name="query"
              history={history}
              autoFocus
            />
          </Form>
        </Col>
        <Col sm={2} smOffset={1}>
          <Link to="/users/csv-to-account/">
            <Button>CSV Upload</Button>
          </Link>
        </Col>
      </Row>
    );
  }
}

Users.propTypes = {
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: "searchUsers",
})(Users);
