import {
  Button,
  Input,
  ProgressBar,
  List,
  ListItem,
  ListSubHeader,
} from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import { Field, reduxForm } from "redux-form";
import { graphql } from "react-apollo";
import Joi from "joi-browser";
import React from "react";

import PropTypes from "prop-types";

import { mapJoiErrorToReduxFormError } from "../../utils/forms";
import RenderField from "../field";
import SwitchField from "../../components/switch";

import OrganisationPicker from "../organisation-picker/organisation-picker";

import searchUsersQuery from "./search-users.graphql";

const ResultsComponent = ({
  searchUsersQuery,
  onSuccess,
  userInputValue,
  reset,
}) => {
  if (!searchUsersQuery) {
    return null;
  }

  if (searchUsersQuery.loading) {
    return <ProgressBar mode="indeterminate" />;
  }

  if (!searchUsersQuery.searchUsers.results.length) {
    return <div>No results</div>;
  }

  return (
    <List>
      {searchUsersQuery.searchUsers.results.map((result) => (
        <ListItem
          key={result.id}
          rightIcon="playlist_add"
          caption={`${result.firstName} ${result.lastName}`}
          legend={result.organisation.name}
          onClick={() => {
            onSuccess([...userInputValue, result]);
            reset();
          }}
        />
      ))}
    </List>
  );
};

ResultsComponent.propTypes = {
  searchUsersQuery: PropTypes.object,
  onSuccess: PropTypes.func,
  history: PropTypes.object,
  userInputValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        organisation: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      })
    ),
    PropTypes.string,
  ]),
  reset: PropTypes.func,
};

const Results = graphql(searchUsersQuery, {
  name: "searchUsersQuery",
  options: ({ value }) => ({ variables: { query: value } }),
  skip: (props) => !props.value,
})(ResultsComponent);

const SelectInput = ({
  input,
  name,
  onSuccess,
  history,
  userInputValue,
  ...props
}) => (
  <div>
    <Input {...input} {...props} label="Add user" name={name} />
    <Results
      value={input.value}
      onSuccess={onSuccess}
      userInputValue={userInputValue}
      reset={() => input.onChange([])}
    />
  </div>
);

SelectInput.propTypes = {
  input: PropTypes.object,
  name: PropTypes.string,
  onSuccess: PropTypes.func,
  history: PropTypes.object,
  userInputValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        organisation: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      })
    ),
    PropTypes.string,
  ]),
};

const UsersInput = ({ input }) => (
  <div>
    {input.value && input.value.length > 0 && (
      <List>
        <ListSubHeader caption="Shown to users" />
        {input.value.map((user) => (
          <ListItem
            key={user.id}
            rightIcon="delete"
            caption={`${user.firstName} ${user.lastName}`}
            onClick={() =>
              input.onChange(input.value.filter((item) => item.id !== user.id))
            }
          />
        ))}
      </List>
    )}
    <Field
      component={SelectInput}
      name="searchUsers"
      onSuccess={input.onChange}
      userInputValue={input.value}
    />
  </div>
);

UsersInput.propTypes = {
  input: PropTypes.object,
};

const schema = Joi.object({
  id: Joi.string().max(200).uppercase().required(),
  active: Joi.boolean().required(),
  admin: Joi.boolean().required(),
  user: Joi.boolean().required(),
  users: Joi.array()
    .unique()
    .items(Joi.string().uuid({ version: "uuidv4" }))
    .allow(null),
  organisationIds: Joi.array()
    .unique()
    .items(Joi.string().uuid({ version: "uuidv4" }))
    .allow(null),
});

const validate = (values) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
    convert: false,
    stripUnknown: true,
  });

  if (error) {
    return mapJoiErrorToReduxFormError(error);
  }
};

const mapFormValuesToMutation = (onSubmit) => ({
  users,
  organisations,
  ...data
}) =>
  onSubmit({
    ...data,
    users: users && users.map(({ id }) => id),
    organisations: organisations,
  });

const FeatureToggleForm = ({
  handleDialog,
  handleSubmit,
  onSubmit,
  submitLabel,
  update,
}) => (
  <div>
    <form onSubmit={handleSubmit(mapFormValuesToMutation(onSubmit))}>
      <Field
        component={RenderField}
        label="Key"
        name="id"
        autoFocus={!update}
        disabled={update}
      />
      <Field component={SwitchField} label="Active feature" name="active" />
      <Field
        component={SwitchField}
        label="Show to Learnerbly's admins"
        name="admin"
      />
      <Field component={SwitchField} label="Show to all users" name="user" />
      <Field component={UsersInput} name="users" />
      <Field name="organisations" component={OrganisationPicker} multiple />
      <Row between="xs">
        <Col />
        <Col>
          <Button onClick={handleDialog}>Cancel</Button>
          <Button type="submit" raised primary>
            {submitLabel}
          </Button>
        </Col>
      </Row>
    </form>
  </div>
);

FeatureToggleForm.propTypes = {
  handleDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  update: PropTypes.bool,
};

export default reduxForm({
  form: "feature-toggle",
  validate,
})(FeatureToggleForm);
