import React from "react";
import { Dialog } from "react-toolbox";

import PropTypes from "prop-types";

import UpdateFulfilmentAccountForm from "./update-fulfilment-account-form";

const UpdateFulfilmentAccountDialog = ({
  active,
  handleDialog,
  organisation,
  selectedFulfilmentAccount,
}) => (
  <Dialog
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
  >
    {selectedFulfilmentAccount && (
      <UpdateFulfilmentAccountForm
        handleDialog={handleDialog}
        organisation={organisation}
        selectedFulfilmentAccount={selectedFulfilmentAccount}
        initialValues={{
          autoApprovedThreshold:
            selectedFulfilmentAccount.autoApprovedThreshold,
        }}
      />
    )}
  </Dialog>
);

UpdateFulfilmentAccountDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  organisation: PropTypes.object,
  selectedFulfilmentAccount: PropTypes.object,
};

export default UpdateFulfilmentAccountDialog;
