import { Col, Row } from "react-flexbox-grid";
import { ProgressBar, Button } from "react-toolbox";
import { Query } from "react-apollo";
import React, { useState } from "react";

import PropTypes from "prop-types";

import CreateOfficeForm from "../create-office-form";

import UpdateOfficeForm from "../update-office-form";

import organisationOfficesQuery from "./organisation-offices.graphql";

const Office = ({ organisationId, office: { id, __typename, ...office } }) => {
  const [showForm, setShowForm] = useState(false);
  if (showForm) {
    return (
      <div>
        <UpdateOfficeForm
          officeId={id}
          organisationId={organisationId}
          initialValues={office}
          onSuccess={() => setShowForm(false)}
        />
      </div>
    );
  }

  return (
    <div>
      {office.name}{" "}
      <Button type="button" onClick={() => setShowForm(true)}>
        Edit
      </Button>
    </div>
  );
};

Office.propTypes = {
  organisationId: PropTypes.string,
  office: PropTypes.shape({
    id: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    name: PropTypes.string,
    postcode: PropTypes.string,
    street: PropTypes.string,
    __typename: PropTypes.string,
  }),
};

const OrganisationOffices = ({ organisationId }) => {
  return (
    <Query
      query={organisationOfficesQuery}
      variables={{
        id: organisationId,
      }}
    >
      {({ data, loading }) => {
        if (loading) {
          return <ProgressBar mode="indeterminate" />;
        }

        const {
          organisation: { offices },
        } = data;

        return (
          <Row middle="sm">
            <Col sm={8}>
              {!offices && <div>No Offices</div>}
              {offices &&
                offices.map((office) => (
                  <Office key={office.id} {...{ office, organisationId }} />
                ))}
            </Col>
            <Col sm={4}>
              <h2>Add New Office</h2>
              <CreateOfficeForm organisationId={organisationId} />
            </Col>
          </Row>
        );
      }}
    </Query>
  );
};

OrganisationOffices.propTypes = {
  organisationId: PropTypes.string,
};

export default OrganisationOffices;
