import { Dialog } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import FeatureToggleForm from "../feature-toggle-form";

import featureTogglesQuery from "../../containers/feature-toggles/feature-toggles.graphql";

import createFeatureToggle from "./create-feature-toggle.graphql";

const onSubmit = (createFeatureToggle, handleDialog) => (data) =>
  createFeatureToggle({
    variables: { featureToggle: data },
  }).then(handleDialog);

const CreateFeatureToggleDialog = ({
  active,
  handleDialog,
  createFeatureToggle,
}) => (
  <Dialog
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    active={active}
  >
    <FeatureToggleForm
      initialValues={{
        active: true,
        user: false,
        admin: false,
      }}
      submitLabel="Create Feature Toggle"
      handleDialog={handleDialog}
      onSubmit={onSubmit(createFeatureToggle, handleDialog)}
    />
  </Dialog>
);

CreateFeatureToggleDialog.propTypes = {
  active: PropTypes.bool,
  handleDialog: PropTypes.func,
  createFeatureToggle: PropTypes.func,
};

export default graphql(createFeatureToggle, {
  name: "createFeatureToggle",
  options: {
    update: (proxy, { data: { createFeatureToggle } }) => {
      const data = proxy.readQuery({ query: featureTogglesQuery });
      data.featureToggles.unshift(createFeatureToggle);
      proxy.writeQuery({ query: featureTogglesQuery, data });
    },
  },
})(CreateFeatureToggleDialog);
