import { Snackbar } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import ResourceForm from "../../components/resource-form";

import createResource from "./create-resource.graphql";

const onSubmit = (mutate, history, failure) => (data) =>
  mutate({
    variables: { resource: data },
  })
    .then(({ data: { createResource } }) =>
      history.push(`/resources/${createResource.id}/`)
    )
    .catch(failure);

class CreateResource extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false,
    };
  }

  render() {
    const { mutate, history } = this.props;
    return (
      <div>
        <h1>Create Resource</h1>
        <ResourceForm
          submitLabel="Create Resource"
          onSubmit={onSubmit(mutate, history, () =>
            this.setState({
              alert: true,
              message: "Failure 😞",
            })
          )}
          initialValues={{
            active: true,
            enquirable: false,
            isFree: false,
            isGlobal: true,
          }}
        />
        <Snackbar
          action="Dismiss"
          active={this.state.alert}
          label={this.state.message}
          timeout={2000}
          onTimeout={() => this.setState({ alert: false })}
          type="cancel"
        />
      </div>
    );
  }
}

CreateResource.propTypes = {
  mutate: PropTypes.func,
  history: PropTypes.object,
};

export default graphql(createResource)(CreateResource);
