import { Dialog, Button } from "react-toolbox";
import { graphql } from "react-apollo";
import React from "react";

import PropTypes from "prop-types";

import cancelRequestQuery from "./cancel-request.graphql";

const onSubmit = (cancelRequestQuery, handleDialog, requestId, userId) => () =>
  cancelRequestQuery({ variables: { requestId, userId } }).then(handleDialog);

const CancelRequestDialog = ({
  active,
  cancelRequestQuery,
  handleDialog,
  request,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    title="Cancel Request (ONLY DO THIS HERE IF THE REQUEST IS FULFILLED BY LEARNER AND NOT PURCHASED [THE CARD WILL ALSO BE CANCELLED] OR IF THE REQUEST IS NOT APPROVED — OTHERWISE IN FF)"
  >
    {request && (
      <div>
        <div>
          <img src="https://media.giphy.com/media/ZZOE2MBmVCqav7jXIW/giphy.gif" />
        </div>
        <Button onClick={handleDialog}>Hell no</Button>
        <Button
          type="submit"
          onClick={onSubmit(
            cancelRequestQuery,
            handleDialog,
            request.id,
            request.user.id
          )}
          raised
          primary
        >
          Yep
        </Button>
      </div>
    )}
  </Dialog>
);

CancelRequestDialog.propTypes = {
  active: PropTypes.bool,
  cancelRequestQuery: PropTypes.func,
  handleDialog: PropTypes.func,
  request: PropTypes.shape({
    additionalNote: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      postcode: PropTypes.string,
      street: PropTypes.string,
    }),
    adhoc: PropTypes.bool,
    adhocInfo: PropTypes.string,
    approveNote: PropTypes.string,
    approvedAt: PropTypes.string,
    approvedBy: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    currency: PropTypes.string,
    hasFulfilmentAccount: PropTypes.bool,
    id: PropTypes.string,
    price: PropTypes.number,
    product: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
    requestNote: PropTypes.string,
    resource: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      commission: PropTypes.number,
      link: PropTypes.string,
      supplier: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
    totalCost: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.string,
      accessibleBudgets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      ),
      activeIndividualBudget: PropTypes.shape({
        id: PropTypes.string,
      }),
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      organisation: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
};

export default graphql(cancelRequestQuery, {
  name: "cancelRequestQuery",
})(CancelRequestDialog);
