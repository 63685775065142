import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "react-apollo";
import { Dialog } from "react-toolbox";

import CreateInternalEventForm from "../create-internal-event-form";

import addInternalEventQuery from "../add-internal-event-dialog/add-internal-event.graphql";

import theme from "./theme.css";

const onSubmit = (copyInternalEvent, handleDialog, resourceId) => ({
  active,
  city,
  startDate,
  endDate,
  moreInfo,
  description,
  street,
  postcode,
  country,
  link,
  timezone,
}) =>
  copyInternalEvent({
    variables: {
      internalEvent: {
        active,
        country,
        description,
        link,
        location: {
          city,
          street,
          postcode,
          country,
        },
        moreInfo,
        startDate: moment
          .tz(moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
          .utc()
          .format(),
        endDate:
          endDate &&
          moment
            .tz(moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss"), timezone)
            .utc()
            .format(),
        timezone,
      },
      resourceId,
    },
  }).then(handleDialog);

const CopyInternalEventModal = ({
  active,
  copyInternalEvent,
  handleDialog,
  resourceId,
  product,
}) => (
  <Dialog
    active={active}
    onEscKeyDown={handleDialog}
    onOverlayClick={handleDialog}
    theme={theme}
  >
    <CreateInternalEventForm
      handleDialog={handleDialog}
      initialValues={{
        active: product.active,
        city: product.location && product.location.city,
        country: product.country,
        description: product.description,
        isGlobal: !product.country,
        link: product.link,
        moreInfo: product.moreInfo,
        postcode: product.location && product.location.postcode,
        street: product.location && product.location.street,
        startDate: moment
          .utc(
            moment
              .tz(product.startDate, product.timezone)
              .format("YYYY-MM-DDTHH:mm")
          )
          .format(),
        timezone: product.timezone,
        endDate:
          product.endDate &&
          moment
            .utc(
              moment
                .tz(product.endDate, product.timezone)
                .format("YYYY-MM-DDTHH:mm")
            )
            .format(),
      }}
      onSubmit={onSubmit(copyInternalEvent, handleDialog, resourceId)}
    />
  </Dialog>
);
CopyInternalEventModal.propTypes = {
  active: PropTypes.bool,
  copyInternalEvent: PropTypes.func,
  handleDialog: PropTypes.func,
  product: PropTypes.object,
  resourceId: PropTypes.string,
};

export default graphql(addInternalEventQuery, {
  name: "copyInternalEvent",
})(CopyInternalEventModal);
