import React from "react";
import { graphql } from "react-apollo";
import { compose } from "redux";
import PropTypes from "prop-types";
import {
  ProgressBar,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "react-toolbox";
import { Col, Row } from "react-flexbox-grid";
import classNames from "classnames";

import organisationUsersQuery from "./organisation-users.graphql";
import sendInvitationQuery from "./send-invitation.graphql";
import theme from "./theme.css";

class OrganisationUsers extends React.Component {
  state = { selectedUsers: [] };

  handleSelect = (selectedRows) => {
    const users = this.props.organisationUsers.organisation.members.results;

    // If a user has already signed up, we want to prevent them from getting new invitations.
    const selectedUsers = selectedRows.filter((row) => !users[row].hasPassword);

    this.setState({ selectedUsers });
  };

  sendInvitations = async () => {
    const { selectedUsers } = this.state;

    const hasConfirmed = window.confirm(
      `Are you sure you to send ${selectedUsers.length} invitations?`
    );

    if (!hasConfirmed) return;

    const { sendInvitationQuery, organisationUsers } = this.props;

    const users = organisationUsers.organisation.members.results.filter(
      (_, idx) => selectedUsers.includes(idx)
    );

    this.setState({
      isSendingInvitations: true,
      // Set it to a bit over 0 so that the user can see the process has started.
      barProgress: 0.2,
      hasFailed: false,
      hasSucceeded: false,
    });

    try {
      for (const user of users) {
        await sendInvitationQuery({
          variables: {
            userId: user.id,
          },
        });

        this.setState((state) => ({
          barProgress: state.barProgress + 1,
        }));
      }

      this.setState({
        hasSucceeded: true,
      });
    } catch (error) {
      this.setState({
        hasFailed: true,
      });
      /* eslint-disable-next-line no-console */
      console.error(error);
    } finally {
      this.setState({
        isSendingInvitations: false,
      });
    }
  };

  render() {
    const {
      organisationUsers: { loading, organisation },
    } = this.props;

    if (loading) {
      return <ProgressBar mode="indeterminate" />;
    }

    const users = organisation.members.results;

    if (users.length === 0) {
      return <div>No user in this organisation 🙈</div>;
    }

    const {
      selectedUsers,
      isSendingInvitations,
      barProgress,
      hasFailed,
      hasSucceeded,
    } = this.state;

    return (
      <Row>
        <Col md={6}>
          <ProgressBar
            mode="determinate"
            max={selectedUsers.length}
            value={barProgress}
            className={classNames({
              [theme.progressBar]: true,
              [theme.error]: hasFailed,
              [theme.success]: hasSucceeded,
            })}
          />
          <Button
            primary
            raised
            disabled={selectedUsers.length === 0 || isSendingInvitations}
            icon="email"
            label="send invitations"
            onClick={this.sendInvitations}
          />
          <Table onRowSelect={this.handleSelect} multiSelectable>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Has signed up</TableCell>
            </TableHead>

            {users.map((user, idx) => (
              <TableRow
                key={idx}
                selected={selectedUsers.includes(idx)}
                disabled={user.hasPassword}
              >
                <TableCell>
                  {user.firstName} {user.lastName}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.hasPassword ? "✅ Yes" : "❌ No"}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Col>
      </Row>
    );
  }
}

OrganisationUsers.propTypes = {
  organisationUsers: PropTypes.shape({
    error: PropTypes.bool,
    fetchMore: PropTypes.func,
    loading: PropTypes.bool,
    networkStatus: PropTypes.number,
    organisation: PropTypes.shape({
      analyticsDashboard: PropTypes.bool,
      id: PropTypes.string,
      internalLearning: PropTypes.bool,
      members: PropTypes.shape({
        results: PropTypes.arrayOf(
          PropTypes.shape({
            activeIndividualBudget: PropTypes.shape({
              remaining: PropTypes.number,
              spent: PropTypes.number,
              total: PropTypes.number,
            }),
            approvers: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
              })
            ),
            country: PropTypes.string,
            createdAt: PropTypes.string,
            email: PropTypes.string,
            firstName: PropTypes.string,
            geographicLocation: PropTypes.string,
            id: PropTypes.string,
            lastName: PropTypes.string,
            onboarded: PropTypes.bool,
            hasPassword: PropTypes.bool,
          })
        ),
        searchAfter: PropTypes.string,
      }),
    }),
    refetch: PropTypes.func,
    startPolling: PropTypes.func,
    stopPolling: PropTypes.func,
    subscribeToMore: PropTypes.func,
    updateQuery: PropTypes.func,
    variables: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  sendInvitationQuery: PropTypes.func,
};

export default compose(
  graphql(organisationUsersQuery, {
    name: "organisationUsers",
    options: ({ organisationId }) => ({
      variables: {
        id: organisationId,
      },
    }),
  }),
  graphql(sendInvitationQuery, {
    name: "sendInvitationQuery",
  })
)(OrganisationUsers);
